<app-navbar></app-navbar>
<!-- Container with  create button and expor to excel buttons -->
<div class="row">
  <div class="col">
    <div class="filtersContainer">
      <div class="row">
        <div class="col-3">

        </div>
        <div class="col-3 offset-3">
          <button type="button" class="btn btn-info btn-block" (click)="openForm(null)">
            <i class="fa fa-plus"></i>
            Nuevo incentivo
          </button>
        </div>
        <div class="col-3">
          <button type="button" class="btn btn-outline-dark btn-block"
            [disabled]="true">
            <i class="fa fa-download"></i>
            Descargar Excel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Table with datas -->
<div *ngIf="!loading">
  <table class="table sticky-yacao green table-striped table-hover table-sm">
    <thead>
      <tr>
        <th>#</th>
        <th>Nombre</th>
        <th>Descripción</th>
        <th>Cantidad por QQ</th>
        <th>Cantidad por KG</th>
        <th>Certificado</th>
        <th>Activo</th>
        <th>Acciones</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let addon of addons | paginate: { itemsPerPage: 15, currentPage: p }; let i= index;">
        <td>{{i + 1}}</td>
        <td>{{addon.name}}</td>
        <td>{{addon.description}}</td>
        <td>{{addon.amount*50}}</td>
        <td>{{addon.amount}}</td>
        <td>{{addon.certificate?.name}}</td>
        <td>
          <mat-icon class="font-weight-bold">{{ addon.active ? 'check' : 'close' }}</mat-icon>
        </td>
        <td>
          <div class="col-12">
            <div class="nav-item dropdown" ngbDropdown>
              <a class="btn btn-success btn-sm dropdown-toggle white" ngbDropdownToggle>
                Selecciona acción
              </a>

              <div class="dropdown-menu" aria-labelledby="admin" ngbDropdownMenu>
                <button class="btn btn-sm dropdown-item" (click)="openForm(addon)">
                  <i class="fa fa-edit text-primary"></i>
                  Editar
                </button>

                <button class="btn btn-sm dropdown-item" (click)="deactivateActivateAddon(addon)">
                  <i class="fa fa-trash text-danger"></i>
                  {{ addon.active ? 'Desactivar' : 'Activar' }}
                </button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="loading" *ngIf="loading">
  <ng-template #loadingTemplate></ng-template>
  <ngx-loading [show]="loading" [template]="loadingTemplate"></ngx-loading>
</div>

<div class="paginationContainer" *ngIf="!loading">
  <pagination-controls autoHide="true" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
    (pageChange)="p= $event"></pagination-controls>
</div>

<app-addon-form [addons]="addons" (result)="setAddons($event)"></app-addon-form>