import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';

// Models
import { CommissionModel } from 'src/app/models/commission.model';

// Services
import { CommissionService } from 'src/app/services/commission.service';

// Components
import { CommissionFormComponent } from '../modals/commission-form/commission-form.component';

@Component({
  selector: 'app-commissions-index',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss']
})
export class CommissionsPage implements OnInit {
  @ViewChild(CommissionFormComponent, { static: true }) commissionForm: CommissionFormComponent;

  commissions: Array<CommissionModel> = new Array<CommissionModel>();
  loading = true;

  p = 1;

  constructor(private _commissionService: CommissionService) { }

  ngOnInit() {
    this.getCommissions();
  }

  openForm(commission: CommissionModel) {
    this.commissionForm.openForm(commission);
  }

  deactivateActivateCommission(commission: CommissionModel) {
    commission['active'] = !commission['active'];
    this.commissionForm.updateCommision(commission);
  }

  setCommissions(commissions: CommissionModel[]): void {
    this.commissions = commissions;
  }

  private getCommissions() {
    this.loading = true;
    this._commissionService.getCommissions()
      .then(
        res => {
          this.commissions = res['commissions'];
          this.loading = false;
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }
}
