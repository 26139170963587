import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

// Models
import { DeliveryGroupModel } from 'src/app/models/delivery-group.model';
import { ContactModel } from 'src/app/models/contact.model';

// services
import { DeliveryGroupService } from 'src/app/services/delivery-group.service';
import { GlobalsService } from 'src/app/services/globals.service';

@Component({
  selector: 'app-delivery-group-producers',
  templateUrl: './delivery-group-producers.component.html',
  styleUrls: ['./delivery-group-producers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeliveryGroupProducersComponent implements OnInit {
  @ViewChild('producerModal', { static: true}) private modal;

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'full-modal'
  };

  deliveryGroup: DeliveryGroupModel = new DeliveryGroupModel();
  contacts: ContactModel[] = new Array<ContactModel>();
  producers: ContactModel[] = new Array<ContactModel>();

  producerPage: number = 1;

  constructor(
    private _modalService: NgbModal, 
    private _deliveryGroupService: DeliveryGroupService,
    public globals: GlobalsService) { }

  ngOnInit() {
  }

  setDeliveryGroupAndOpenModal(deliveryGroup: DeliveryGroupModel) {
    this.getDeliveryGroupBy(deliveryGroup.id);
    this.openModal();
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  getQuotaOfContact(contact: ContactModel, alias: string) {
    const cert = _.find(contact['list_certificates'], { alias: alias })
    return cert ? cert['quota'] : 0;
  }

  haveCertificate(element: any, alias: string) {
    return !!_.find(element['bool_certificates'], { alias: alias });
  }

  private getDeliveryGroupBy(id: number) {
    this._deliveryGroupService
    .getDeliveryGroup(id)
    .then(
      (response) => {
        this.deliveryGroup = response['delivery_group'];
        this.contacts = response['contacts'];
        this.producers = _.filter(this.contacts, { producer: true });

      }
    )
    .catch(error => console.error(error));
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }
}
