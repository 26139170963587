import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';

// Models
import { UnloadModel } from 'src/app/models/unload.model';
import { BigBagModel } from 'src/app/models/big-bag.model';
import { CertificateModel } from 'src/app/models/certificate.model';
import { UserModel } from 'src/app/models/user.model';
import { CocoaTypeModel } from 'src/app/models/cocoa-type.model';
import { CocoaQualityModel } from 'src/app/models/cocoa_quality.model';
import { CenterModel } from 'src/app/models/center.model';
import { TicketModel } from 'src/app/models/ticket.model';
import { FilterModel } from 'src/app/models/filter.model';

// Services
import { UnloadService } from 'src/app/services/unload.service';
import { DownloadFileService } from 'src/app/services/download-file.service';

import { UnloadType, UnloadState, TicketUnloadState } from 'src/app/app.enum';
import { GlobalsService } from 'src/app/services/globals.service';
import { FinalUnloadFormComponent } from '../modals/final-unload-form/final-unload-form.component';
import { ProvisionalUnloadFormComponent } from '../modals/provisional-unload-form/provisional-unload-form.component';
import { TranslateService } from '@ngx-translate/core';
export class unloadFilters {
  'q[created_at_gteq]': string;
  'q[created_at_lteq]': string;
  'q[type_cd_eq]': number;
  'q[cocoa_quality_id_eq]': number;
  'q[user_id_eq]': number;
  'q[accepted_eq]': boolean;
  g = [
    {
      m: 'or',
      tickets_talbook_sheet_cont: null,
      provisional_unloads_ticket_talbook_sheet_cont: null
    }
  ];
}

@Component({
  selector: 'app-unloads-index',
  templateUrl: './unloads.page.html',
  styleUrls: ['./unloads.page.scss']
})
export class UnloadsPage implements OnInit {
  @ViewChild('sendingNotification', { static: true }) customNotificationTmpl;
  @ViewChild(FinalUnloadFormComponent) finalUnloadForm: FinalUnloadFormComponent;
  @ViewChild(ProvisionalUnloadFormComponent) provisionalUnloadForm: ProvisionalUnloadFormComponent;

  unloads: Array<UnloadModel> = new Array<UnloadModel>();

  bigBag: BigBagModel = new BigBagModel();

  certificates: Array<CertificateModel> = new Array<CertificateModel>();
  unloadManagers: Array<UserModel> = new Array<UserModel>();
  cocoaTypes: Array<CocoaTypeModel> = new Array<CocoaTypeModel>();
  cocoaQualities: Array<CocoaQualityModel> = new Array<CocoaQualityModel>();

  centers: Array<CenterModel> = new Array<CenterModel>();
  selectedBigBags: Array<BigBagModel> = new Array<BigBagModel>();

  tickets: Array<TicketModel> = new Array<TicketModel>();

  unloadType = UnloadType;

  filters: unloadFilters = new unloadFilters();
  unloadTypeFilter = [
    { name: 'Provisional', value: UnloadType.provisional },
    { name: 'Definitiva', value: UnloadType.final }
  ];
  yesOrNot = [
    { name: 'Si', value: true },
    { name: 'No', value: false }
  ];

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy'
    // other options are here...
  };

  isCollapsed = false;

  currentPage = 1;
  itemsPerPage = 25;
  totalItems = 0;

  sending = false;
  loading = true;
  loadingExcel = false;

  unloadState = UnloadState;

  unsaveChanges = false;

  currentUserRoles = {};

  allIds: [] = [];
  allBarcodes: [] = [];
  allTickets: [] = [];
  ticketSettlementStates = [{ name: 'Pre negociado' }, { name: 'Resultado seco' }];

  constructor(
    private _downloadFileService: DownloadFileService,
    private unloadService: UnloadService,
    public globals: GlobalsService,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('es');
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  ngOnInit() {
    this.getUnloads();
    this.currentUserRoles = {};
    _.each(this.globals.currentUserRoles, role => {
      this.currentUserRoles[role['alias']] = true;
    });
  }

  openForm(unload: UnloadModel) {
    unload.type_cd === UnloadType.final ? this.finalUnloadForm.openForm(unload) : this.provisionalUnloadForm.openForm(unload);
  }

  onDateChanged(event, filter: string) {
    this.filters[filter] = event.singleDate.jsDate;
  }



  // openAlertModal(content, params: {}) {
  //   this.changeModalsZIndexTo(0);

  //   const options: NgbModalOptions = {
  //     backdrop: 'static',
  //     keyboard: false,
  //     windowClass: 'alert-modal',
  //     centered: true,
  //     size: 'lg'
  //   };

  //   switch (params['type']) {
  //     case 'ticket':
  //       this.alertModalMessage = `¿Está completamente seguro de eliminar el recibo ${params['value']['talbook_sheet']}?`
  //       break;
  //     case 'bigBag':
  //       this.alertModalMessage = `¿Está completamente seguro de eliminar el lote ${this.selectedBigBags[params['value']]['barcode_number']}?`
  //       break;
  //     case 'accepted':
  //       this.alertModalMessage = `¿Está completamente seguro de validar la descarga? Una vez validada no se podra modificar.`
  //       break;
  //   }

  //   this.alertModalRef = this.modalService.open(content, options);
  //   this.alertModalRef.result.then(
  //     result => {
  //       console.log('result', result);
  //     },
  //     reason => {
  //       switch (reason) {
  //         case 'alert-accepted':
  //           this.changeModalsZIndexTo(1050);
  //           switch (params['type']) {
  //             case 'ticket':
  //               this.unsaveChanges = true;
  //               this.removeTicketFromBigBag(params['value']);
  //               break;
  //             case 'bigBag':
  //               this.unsaveChanges = true;
  //               this.removeBigBag(params['value']);
  //               break;
  //             case 'accepted':
  //               this.validateUnloadAndSave(params['value']);
  //               break;
  //           }
  //           break;
  //         case 'alert-cancel':
  //           this.changeModalsZIndexTo(1050);
  //           break;
  //       }
  //       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //     }
  //   );
  // }


  // getTicketBigBags(ticket: TicketModel) {
  //   this.bigBagsOfSelectedTicket = _.filter(this.unload['big_bags'], bag => {
  //     return _.includes(_.map(bag['tickets'], 'id'), ticket['ticket_id']);
  //   });
  // }

  trackByFn(index, item) {
    return index;
  }


  getUnloads(page: number = 1, per_page: number = 25, params: {} = {}, format: string = 'json') {
    let parameters = _.cloneDeep(params);

    if (format === 'json') {
      this.loading = true;
    }

    if (format === 'xlsx') {
      this.loadingExcel = true;
    }

    parameters = Object.assign(parameters, { format: format });

    _.each(parameters, (value, key) => {
      if (typeof parameters[key] === 'string') {
        parameters[key] = parameters[key].trim();
      }
      value == null && delete parameters[key];
    });

    if (parameters.g && parameters['g'][0].tickets_talbook_sheet_cont && parameters['g'][0].tickets_talbook_sheet_cont !== '') {
      parameters = Object.assign(parameters, { g: JSON.stringify({ g: parameters['g'] }) });
    } else {
      delete parameters.g;
    }

    this.unloadService
      .getUnloads(page, per_page, parameters)
      .then(
        res => {
          if (format === 'json') {
            this.unloads = res['unloads'];
            this.allIds = res['all_ids'];
            this.allBarcodes = res['all_barcodes'];
            this.allTickets = res['all_tickets'];
            this.certificates = res['list_certificates'];
            this.unloadManagers = res['unload_managers'];
            this.cocoaQualities = res['cocoa_qualities'];
            this.cocoaTypes = res['cocoa_types'];
            this.centers = res['centers'];

            this.totalItems = res['total_items'];
            this.currentPage = res['current_page'];
            this.loading = false;
          } else {
            this.loadingExcel = false;
            this._downloadFileService.downloadFile(res, 'descargas', 'application/vnd.ms-excel');
          }
        }
      )
      .catch(
        err => {
          console.error(err);
          this.loading = false;
          this.loadingExcel = false;
        }
      );
  }


  allTicketsAreUnloaded(unload: UnloadModel): boolean {
    if (unload.type_cd === UnloadType.final) {
      return !_.find(unload['tickets'], ticket => {
        if (ticket['unloading_state_cd'] !== TicketUnloadState.unloaded) {
          return ticket;
        }
      });
    } else {
      return !_.find(unload['provisionals'], ticket => {
        if (ticket['unloading_state_cd'] !== TicketUnloadState.unloaded) {
          return ticket;
        }
      });
    }
  }
}
