import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectControlValueAccessor } from '@angular/forms';

import * as _ from 'lodash';

// Models
import { UserModel } from '../../../models/user.model';
import { CenterModel } from '../../../models/center.model';
import { DeliveryGroupModel } from '../../../models/delivery-group.model';
import { ZoneModel } from '../../../models/zone.model';
import { ContactModel } from '../../../models/contact.model';
import { CertificateModel } from '../../../models/certificate.model';
import { TicketModel } from '../../../models/ticket.model';
import { AddonModel } from 'src/app/models/addon.model';
import { FilterModel } from 'src/app/models/filter.model';

// Services
import { ContactService } from '../../../services/contact.service';
import { SupplierService } from '../../../services/supplier.service';

// Globals
import { DownloadFileService } from 'src/app/services/download-file.service';
import { GlobalsService } from 'src/app/services/globals.service';

// Components
import { SupplierFormComponent } from '../modals/supplier-form/supplier-form.component';
import { DeliveryGroupsComponent } from '../modals/delivery-groups/delivery-groups.component';
import { PdfService } from 'src/app/services/pdf.service';
import { SeasonService } from 'src/app/services/season.service';

@Component({
  selector: 'app-suppliers-index',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss']
})
export class SuppliersPage implements OnInit {
  @ViewChild(SupplierFormComponent, { static: true }) private formComponent: SupplierFormComponent;
  @ViewChild(DeliveryGroupsComponent, { static: true }) private deliveryGroupComponent: DeliveryGroupsComponent;
  suppliers: Array<ContactModel> = new Array<ContactModel>();

  allSuppliers: Array<ContactModel> = new Array<ContactModel>();

  proffixes: [] = [];
  dicerosIds: number[] = [];

  buyers: Array<UserModel> = new Array<UserModel>();
  centers: Array<CenterModel> = new Array<CenterModel>();
  zones: Array<ZoneModel> = new Array<ZoneModel>();

  deliveryGroups: Array<DeliveryGroupModel> = new Array<DeliveryGroupModel>();
  performance: number;

  listCertificates: Array<CertificateModel> = new Array<CertificateModel>();
  boolCertificates: Array<CertificateModel> = new Array<CertificateModel>();
  tickets: Array<TicketModel> = new Array<TicketModel>();

  addons: Array<AddonModel> = new Array<AddonModel>();

  yesOrNot = [{ name: 'Si', value: true }, { name: 'No', value: false }];
  filters: FilterModel = new FilterModel();

  loading: boolean;
  isCollapsed = false;

  itemsPerPage = 25;
  totalItems = 0;
  currentPage = 1;

  loadingExcel = false;

  constructor(
    public globals: GlobalsService,
    private _contactService: ContactService,
    private _downloadFileService: DownloadFileService,
    private _pdfService: PdfService,
    private _seasonService: SeasonService,
    private supplierService: SupplierService
  ) { }

  ngOnInit() {
    // this.filters = Object.assign(this.filters, { 'q[active_as_supplier_eq]': true, 'q[supplier_eq]': true });
    delete this.filters['g'];
    this.filters = Object.assign(this.filters, { 'q[supplier_eq]': true });
    this.getSuppliers(1, this.itemsPerPage, this.filters);
  }

  openSupplierForm(supplier: ContactModel) {
    this.formComponent.initialize(supplier);
  }

  changeActiveValue(supplier: ContactModel) {
    supplier['active_as_supplier'] = !supplier['active_as_supplier'];
    this.formComponent.updateSupplier(supplier);
  }

  // generatePDF(supplier: ContactModel) {
  generatePDF(supplierId: number) {
    // const copy = _.cloneDeep(supplier);
    const utzId = _.find(this.boolCertificates, { alias: 'utz' })['id'];

    this._seasonService
      .getSeasons()
      .then(
        (seasonRes) => {
          const currentSeason = _.last(_.filter(seasonRes['seasons'], { current: true }));

          this._contactService
            .getContact(supplierId)
            .then(
              (contactRes) => {
                const supplier = contactRes['contact'];

                _.each(supplier['delivery_groups_as_supplier'], (group) => {
                  this._pdfService.downloadPDF(group, utzId, currentSeason);
                });
              }
            );
        }
      ).catch();

  }

  getQuotaOf(supplier: ContactModel, alias: string): number {
    const cert = _.find(this.listCertificates, { alias: alias });
    const supplierCert = _.find(supplier['max_quotas'], { certificate_id: cert['id'] });

    return (supplierCert && supplierCert['quota']) ? supplierCert['quota'] : 0;
  }

  haveCertificate(element: any, alias: string) {
    return !!_.find(element['bool_certificates'], { alias: alias });
  }

  getSuppliers(page: number = 1, per_page: number = 25, params: {} = {}, format: string = 'json') {
    let parameters = _.cloneDeep(params);

    if (format === 'json') {
      this.loading = true;
    }

    if (format === 'xlsx') {
      this.loadingExcel = true;
    }

    parameters = Object.assign(parameters, { format: format });

    _.each(parameters, (value, key) => {
      value == null && delete parameters[key];
    });

    this.supplierService
      .getSuppliers(page, per_page, parameters)
      .then(
        res => {
          if (format === 'json') {
            this.suppliers = [];

            _.each(res['contacts'], (contact) => {
              contact.zones = _.last(contact.zones);
              this.suppliers.push(contact);
            });

            const contacts = res['all_contacts'];

            this.allSuppliers = _.filter(contacts, { supplier: true });
            this.proffixes = _.map(contacts, 'proffix').filter(x => x).sort();
            this.dicerosIds = _.map(contacts, 'diceros_id').filter((x: number) => x ).sort();
            
            this.zones = res['zones'];
            this.centers = res['centers'];
            this.listCertificates = res['list_certificates'];
            this.boolCertificates = res['bool_certificates'];

            this.deliveryGroups = res['delivery_groups'];
            this.buyers = res['buyers'];
            this.totalItems = res['total_items'];
            this.currentPage = res['current_page'];
            this.loading = false;
          } else {
            this.loadingExcel = false;
            this._downloadFileService.downloadFile(res, 'suplidores', 'application/vnd.ms-excel');
          }
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }

  openDeliveryGroupModal(supplier: ContactModel, certificates: Array<CertificateModel>) {
    this.deliveryGroupComponent.openModal(supplier, certificates);
  }

  setSuppliers(suppliers: ContactModel[]): void {
    this.suppliers = suppliers;
  }
}
