import { ContactModel } from './contact.model';
import { CertificateModel } from './certificate.model';

export class DeliveryGroupModel {
  id: number;
  coordinator: ContactModel;
  coordinator_id: number;
  producers: Array<ContactModel>;
  list_certificates: Array<CertificateModel>;
  bool_certificate: Array<CertificateModel>;
  name: string;
  active: boolean;
}
