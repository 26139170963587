import { Component, OnInit, ViewChild } from '@angular/core';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import * as _ from 'lodash';

// Models
import { FilterModel } from 'src/app/models/filter.model';
import { PaymentModel } from 'src/app/models/payment.model';
import { UserModel } from 'src/app/models/user.model';
import { ContactModel } from 'src/app/models/contact.model';
import { ConceptModel } from 'src/app/models/concept.model';

// Services
import { GlobalsService } from 'src/app/services/globals.service';
import { TicketService } from 'src/app/services/ticket.service';

// Constants
import { TicketType } from 'src/app/app.enum';

// Components
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { ImagesModalComponent } from 'src/app/components/modals/images-modal/images-modal.component';


@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
  @ViewChild(PaymentFormComponent, { static: true }) paymentForm: PaymentFormComponent;
  @ViewChild(ImagesModalComponent, { static: true }) private gallery: ImagesModalComponent;
  readonly myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy'
    // other options are here...
  };
  
  filters: FilterModel = new FilterModel();
  yesOrNot = [{ name: 'Si', value: true }, { name: 'No', value: false }];
  ticketTypeFilter = [
    { name: 'Ingreso', value: TicketType.income },
    { name: 'Egreso', value: TicketType.outcome }
  ];

  payments: PaymentModel[] = [];
  talbook_sheets: string[] = [];
  buyers: UserModel[] = [];
  suppliers: ContactModel[] = [];
  concepts: ConceptModel[] = [];

  itemsPerPage: number = 25;
  totalItems: number = 0;
  currentPage: number = 1;

  loadingExcel: boolean = false;
  loading: boolean = false;
  isCollapsed: boolean = false;

  currentUserRoles = {};

  constructor(
    public globals: GlobalsService,
    private _paymentService: TicketService
  ) { }

  ngOnInit(): void {
    this.filters['q[ticket_type_cd_in]'] = JSON.stringify([TicketType.income, TicketType.outcome]);


    this.getTickets(this.currentPage, this.itemsPerPage, this.filters);
    this.currentUserRoles = {};

    _.each(this.globals.currentUserRoles, role => {
      this.currentUserRoles[role['alias']] = true;
    });
  }

  getTickets(page: number = 1, per_page: number = 25, params: {} = {}, format: string = 'json') {
    let parameters = _.cloneDeep(params);

    if (format === 'json') {
      this.loading = true;
    }

    if (format === 'xlsx') {
      this.loadingExcel = true;
    }


    parameters = Object.assign(parameters, { format: format });

    _.each(parameters, (value, key) => {
      if (typeof parameters[key] === 'string') {
        parameters[key] = parameters[key].trim();
      }

      (value == null) && delete parameters[key];
    });

    if (parameters['g']) {
      parameters = Object.assign(parameters, { g: JSON.stringify({ g: parameters['g'] }) });
    }

    this._paymentService
      .getTickets(page, per_page, parameters)
      .then(
        (response) => {
          console.log('res', response);
          if (format === 'json') {
            this.payments = response['tickets'];
            this.buyers = response['buyers'];
            this.suppliers = response['suppliers'];
            this.concepts = response['concepts'];

            this.talbook_sheets = response['talbook_sheets'];


            this.totalItems = response['total_items'];
            this.currentPage = response['current_page'];
            this.loading = false;
          } else {
            this.loadingExcel = false;
            // this._downloadFileService.downloadFile(response, 'recibos', 'application/vnd.ms-excel');
          }
        }
      )
      .catch(
        (error) => console.error(error)
      );
  }

  getStateOf(payment: PaymentModel) {
    let state = 'Incompleto';

    if (payment.nulled) {
      state = 'Anulado';
      return state;
    }

    if (payment.completed) {
      state = 'Completado';
    } else {
      state = 'Incompleto';
    }

    return state;
  }

  setPayment(payment: PaymentModel): void {
    const index: number = _.findIndex(this.payments, { id: payment.id });
    if (index !== -1) {
      this.payments[index] = payment;
    }
  }

  editPayment(payment: PaymentModel): void {
    this.paymentForm.openForm(payment);
  }

  openGallery(title: string, images: string[]) {
    this.gallery.openGallery(title, images);
  }

  onDateChanged(event, filter: string) {
    console.log(event)
    if (filter === 'startDate') {
      this.filters.g[0].contract_starts_gteq = event.singleDate.jsDate;
      this.filters.g[0].collected_date_gteq = event.singleDate.jsDate;
    } else if (filter === 'endDate') {
      this.filters.g[1].collected_date_lteq = event.singleDate.jsDate;
      this.filters.g[1].contract_starts_lteq = event.singleDate.jsDate;
    }
  }
}
