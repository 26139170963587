import { Component, OnInit, ViewChild } from "@angular/core";
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
  NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import * as _ from "lodash";

// models
import { CertificateTypeModel } from "../../../models/certificate-type.model";
import { ExportToExcelService } from "../../../services/export-to-excel.service";

// services
import { CertificateTypeService } from "../../../services/certificate-type.service";

@Component({
  selector: "app-certificate-types",
  templateUrl: "./index.page.html",
  styleUrls: ["./index.page.scss"]
})
export class CertificateTypesPage implements OnInit {
  @ViewChild('sendingNotification', {static: true}) customNotificationTmpl;

  certificateTypes: Array<CertificateTypeModel> = new Array<
    CertificateTypeModel
  >();
  certificatetype: CertificateTypeModel = new CertificateTypeModel();
  modalRef: NgbModalRef;
  closeResult = "";

  filters: any = {};
  p = 0;

  loading: boolean;
  isCollapsed = false;
  sending = false;
  timeout: any;

  constructor(
    private certificateTypeService: CertificateTypeService,
    private modalService: NgbModal,
    private excelService: ExportToExcelService,

  ) { }

  ngOnInit() {
    delete this.filters['g'];
    // this.loading = true;
    // this.globals.observer$.forEach(
    //   value => {
    //     value['certificateTypes'] && this.certificateTypes.length !== this.globals.certificateTypes.length && this.getCertificateTypes();
    //   }
    // );
  }

  getCertificateTypes() {
    // this.certificateTypes = this.globals.getLocalCertificateTypes();
    this.loading = true;
    this.certificateTypeService.getCertificateTypes()
      .then(
        res => {
          this.certificateTypes = res['certificate_types'];
          this.loading = false;
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      )
  }

  createCertificateType(certificatetype: CertificateTypeModel) {
    this.sending = true;
    this.clearTimeout();
    // this.notifierService.hideAll();
    this.showCustomNotification('Creando...');
    _.each(document.getElementsByClassName('modal'), (element, index) => {
      document.getElementsByClassName('modal')[index]['style']['z-index'] = 0;
    });

    this.certificateTypeService
      .createCertificateType(certificatetype)
      .then(
        res => {
          certificatetype['id'] = res['certificate_type']['id'];
          this.certificateTypes.push(certificatetype);

          // this.notifierService.hideAll();
          // this.notifierService.notify('success', 'Creado correctamente', 'create');
          this.hiddeNotificationBy('create');
          this.sending = false;
          _.each(document.getElementsByClassName('modal'), (element, index) => {
            document.getElementsByClassName('modal')[index]['style']['z-index'] = 1050;
          });

          this.getCertificateTypes();
          this.cleanValues();
        }
      )
      .catch(
        err => {
          _.each(document.getElementsByClassName('modal'), (element, index) => {
            document.getElementsByClassName('modal')[index]['style']['z-index'] = 1050;
          });
          this.sending = false;
          // this.notifierService.hideAll();
          console.error(err);
        }
      );
  }

  updateCertificateType(certificateType: CertificateTypeModel) {
    this.sending = true;
    this.clearTimeout();
    // this.notifierService.hideAll();
    this.showCustomNotification('Actualizando...');
    _.each(document.getElementsByClassName('modal'), (element, index) => {
      document.getElementsByClassName('modal')[index]['style']['z-index'] = 0;
    });

    this.certificateTypeService
      .updateCertificateType(certificateType)
      .then(
        res => {
          let index = _.findIndex(this.certificateTypes, { id: certificateType['id'] });
          this.certificateTypes[index] = certificateType;

          // this.notifierService.hideAll();
          // this.notifierService.notify('success', 'Actualizado correctamente', 'update');
          this.hiddeNotificationBy('update');
          this.sending = false;
          _.each(document.getElementsByClassName('modal'), (element, index) => {
            document.getElementsByClassName('modal')[index]['style']['z-index'] = 1050;
          });

          this.getCertificateTypes();
          this.closeModal();
        }
      )
      .catch(
        err => {
          this.sending = false;
          _.each(document.getElementsByClassName('modal'), (element, index) => {
            document.getElementsByClassName('modal')[index]['style']['z-index'] = 1050;
          });
          // this.notifierService.hideAll();
          console.error(err);
        }
      );
  }

  deleteCertificateType(certificatetypeId: number) {
    this.certificateTypeService
      .deleteCertificateType(certificatetypeId.toString())
      .then(
        res => {
          console.log(res);
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }

  deactivateActivateCertificateType(certificatetype: CertificateTypeModel) {
    certificatetype["active"] = !certificatetype["active"];
    this.updateCertificateType(certificatetype);
  }

  openModal(content) {
    let options: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg',
    }

    this.modalRef = this.modalService.open(content, options);
    this.modalRef.result.then(
      result => {
        this.closeResult = `Close with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }


  closeModal() {
    this.modalRef && this.modalRef.dismiss();
  }

  cleanValues() {
    this.certificatetype = new CertificateTypeModel();
  }

  setCertificateType(certificatetype, modal: any) {
    this.certificatetype = certificatetype;
    this.openModal(modal);
  }

  exportToExcel(json: Array<CertificateTypeModel>, filename: string) {
    const clone = JSON.parse(JSON.stringify(json));
    _.each(clone, certificateType => {
      certificateType["Nombre"] = certificateType["name"];
      certificateType["Activo"] = certificateType["active"] ? "Si" : "No";

      delete certificateType["id"];
      delete certificateType["name"];
      delete certificateType["active"];
    });
    this.excelService.exportAsExcelFile(clone, filename);
  }

  filterCertificateTypes($event, type: string) {
    /*this.certificatetypes.subscribe(types => {
      if ($event) {
        if (type.startsWith("is")) {
          this.filters[type] = $event === "Si" ? true : false;
          this.certificateTypesFiltered = _.filter(types, this.filters);
        } else {
          this.filters[type] = $event[type];
          this.certificateTypesFiltered = _.filter(types, this.filters);
        }
      } else {
        delete this.filters[type];
        this.certificateTypesFiltered = _.filter(types, this.filters);
      }
    });*/
  }

  private hiddeNotificationBy(id: string) {
    this.timeout = setTimeout(() => {
      //  this.notifierService.hide(id);
     }, 10000);
   }
 
   private clearTimeout() {
     this.timeout && clearTimeout(this.timeout);
   }

  private showCustomNotification(message: string) {
    // this.notifierService.show({
    //   message: message,
    //   type: 'info',
    //   template: this.customNotificationTmpl
    // });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.cleanValues();
      this.getCertificateTypes();
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.cleanValues();
      this.getCertificateTypes();
      return "by clicking on a backdrop";
    } else {
      this.cleanValues();
      this.getCertificateTypes();
      return `with: ${reason}`;
    }
  }
}
