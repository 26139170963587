import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Models
import { SeasonModel } from '../models/season.model';

import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SeasonService {
  ROUTE = environment.routes.api + environment.routes.seasons;

  constructor(
    private _http: HttpClient
  ) { }

  getSeasons() {
    return new Promise((resolve, reject) => {
      this._http.get(this.ROUTE, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        )
    });
  }

  getSeason(season_id: number) {
    return new Promise((resolve, reject) => {
      this._http.get(`${this.ROUTE}/${season_id}`, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        )
    });
  }

  createSeason(season: SeasonModel) {
    return new Promise((resolve, reject) => {
      this._http.post(this.ROUTE, { season: season }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        )
    });
  }

  updateSeason(season: SeasonModel) {
    return new Promise((resolve, reject) => {
      this._http.put(`${this.ROUTE}/${season['id']}`, { season: season }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        )
    });
  }

  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('yacao:app:token'),
        'user_id': localStorage.getItem('yacao:app:user_id')
      }
    );
  }
}
