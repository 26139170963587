import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';

import { environment } from '../../environments/environment';
import { GlobalsService } from './globals.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  headers: HttpHeaders = new HttpHeaders();
  loginroute = environment.routes.api + environment.routes.login;
  logoutroute = environment.routes.api + environment.routes.logout;

  constructor(private http: HttpClient, 
    private globals: GlobalsService,
    private router: Router) { }

  login(email: string, password: string): Promise<any> {
    console.log(this.loginroute);
    return new Promise((resolve, reject) => {
      this.http
        .post(this.loginroute, { email: email, password: password }, { headers: this.headers })
        .toPromise()
        .then(
          res => {
            if (res.hasOwnProperty('errors')) {
              reject(res)
            } else {
              localStorage.setItem('yacao:app:token', res['auth_token']);
              localStorage.setItem('yacao:app:user_id', res['user']['id']);
              resolve(res);
            }
          }
        ).catch(
          err => {
            reject(err);
          }
        )
    });
  }

  logout(): Promise<any> {
    return new Promise((resolve, reject) => {
      const user_id: string = localStorage.getItem('yacao:app:user_id');
      console.log(user_id);
      localStorage.clear();
      this.globals.currentUserRolesAsBooleanObject = {};
      this.globals.currentUserCenterId = null;
      this.globals.currentUserRoles = [];
  
      this.router.navigate(['/login']);

      this.http.post(this.logoutroute, { id: user_id }, { headers: this.headers })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        )
    });
  }
}
