import { Component, OnInit, ViewChild } from '@angular/core';

import * as _ from 'lodash';

// Models
import { CenterModel } from '../../../models/center.model';

// Services
import { CenterService } from '../../../services/center.service';

// Components
import { CenterFormComponent } from '../modals/center-form/center-form.component';

@Component({
  selector: 'app-centers',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss']
})
export class CentersPage implements OnInit {
  @ViewChild(CenterFormComponent, { static: true }) centerForm: CenterFormComponent;

  centers: Array<CenterModel> = new Array<CenterModel>();
  p = 0;

  loading: boolean;
  isCollapsed = false;

  constructor(private centerService: CenterService) { }

  ngOnInit() {
    this.getCenters();
  }

  getCenters() {
    this.loading = true;
    this.centerService.getCenters()
      .then(
        res => {
          this.centers = res['centers'];
          this.loading = false;
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }

  openForm(center: CenterModel) {
    this.centerForm.openForm(center);
  }

  deactivateActivateCenter(center: CenterModel) {
    center['active'] = !center['active'];
    this.centerForm.updateCenter(center);
  }

  setCenters(centers: CenterModel[]): void {
    this.centers = centers;
  }
}
