import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';

// Models
import { TalbookTypeModel } from 'src/app/models/talbook-type.model';

// Services
import { TalonaryService } from '../../../services/talonary.service';

// Components
import { TalbookTypeFormComponent } from '../modals/talbook-type-form/talbook-type-form.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-talonaries',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss']
})
export class TalonariesPage implements OnInit {
  @ViewChild(TalbookTypeFormComponent, { static: true }) talbookTypeForm: TalbookTypeFormComponent;

  talonaries: Array<TalbookTypeModel> = new Array<TalbookTypeModel>();

  p: number = 1;

  loading: boolean;
  isCollapsed: boolean = false;

  constructor(
    private talonaryService: TalonaryService ,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('es');
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  ngOnInit() {
    this.getTalonaries();
  }

  getTalonaries() {
    this.loading = true;
    this.talonaryService.getTalonaries()
      .then(
        res => {
          this.talonaries = res['talbook_types'];
          this.loading = false;
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }

  deactivateActivateTalonary(talonary: TalbookTypeModel) {
    talonary['active'] = !talonary['active'];
    this.talbookTypeForm.updateTalonary(talonary);
  }

  openForm(talbookType: TalbookTypeModel) {
    this.talbookTypeForm.openForm(talbookType);
  }

  setTalbookTypes(talbookTypes: TalbookTypeModel[]) {
    this.talonaries = talbookTypes;
  }
}
