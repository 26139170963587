import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

// Services
import { TalonaryService } from 'src/app/services/talonary.service';
import { NotificationService } from 'src/app/services/notification.service';

// Models
import { TalbookTypeModel } from 'src/app/models/talbook-type.model';

// Constants
import { TypeOfTalonary } from 'src/app/app.enum';

@Component({
  selector: 'app-talbook-type-form',
  templateUrl: './talbook-type-form.component.html',
  styleUrls: ['./talbook-type-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TalbookTypeFormComponent implements OnInit {
  @ViewChild('content', { static: true }) private modal;
  @Output() result: EventEmitter<TalbookTypeModel[]> = new EventEmitter<TalbookTypeModel[]>();

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };

  @Input() talbookTypes: TalbookTypeModel[] = new Array<TalbookTypeModel>();

  types = [{ name: 'Acuerdo de compra', value: TypeOfTalonary.contracts },
  { name: 'Recepcion de cacao', value: TypeOfTalonary.cocoa_receipt },
  { name: 'Ingreso', value: TypeOfTalonary.income },
  { name: 'Egreso', value: TypeOfTalonary.outcome }];

  talbookType: TalbookTypeModel = new TalbookTypeModel();
  sending: boolean = false;

  constructor(private _modalService: NgbModal,
    private _talbookTypeService: TalonaryService,
    private _notification: NotificationService) { }

  ngOnInit() {
  }

  openForm(talbookType: TalbookTypeModel) {
    this.talbookType = _.cloneDeep(talbookType) || new TalbookTypeModel();
    this.openModal();
  }

  formValid(talonary: TalbookTypeModel): boolean {
    // const result = `^[0-9]{${talonary.number_of_digits}}$`;
    const result = '[0-9]';
    const regex = new RegExp(result);
    return (regex.test(talonary.first_page) && talonary.number_of_digits === talonary.first_page.length);
  }

  onSubmit(talonary: TalbookTypeModel): void {
    if (this.formValid(talonary)) {
      talonary['id'] ? this.updateTalonary(talonary) : this.createTalonary(talonary);
    }
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }


  compareFn(a, b): boolean {
    if (a.id) {
      return a['id'] === b;
    } else {
      return a['value'] === b;
    }
  }

  updateTalonary(talbookType: TalbookTypeModel): void {
    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    this._talbookTypeService
      .updateTalonary(talbookType)
      .then(
        res => {
          const index = _.findIndex(this.talbookTypes, { id: res['talbook_type']['id'] });
          this.talbookTypes[index] = res['talbook_type'];
          this.result.emit(this.talbookTypes);

          this._notification.clear();
          this._notification.success('Actualizado correctamente');
          this.closeModal();
          this.sending = false;
        })
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      );
  }



  private createTalonary(talbookType: TalbookTypeModel): void {
    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);
    this.talbookType.label = `E${this.talbookType.label + this.talbookType.pattern + this.talbookType.first_page}`;

    this._talbookTypeService
      .createTalonary(talbookType)
      .then(
        res => {

          this.talbookTypes.push(res['talbook_type']);
          this.result.emit(this.talbookTypes);

          this._notification.clear();
          this._notification.success('Creado correctamente');
          this.closeModal();
          this.sending = false;
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al crear');
          }
        }
      );
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }
}
