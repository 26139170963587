<ng-template #content let-modal let-d="dismiss">
  <form #userForm="ngForm" (ngSubmit)="userForm.form.valid && onSubmit(user)">
    <div class="modal-header">
      <h4 class="modal-title">{{ user.id ? 'Actualizar usuario' : 'Crear usuario'}}</h4>

      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>

    </div>

    <div class="modal-body">

      <div class="form-group">
        <label for="userName">Nombre completo</label>

        <input type="text" #userName="ngModel" class="form-control" id="userName" [(ngModel)]="user.name"
          placeholder="Name" name="userName" [ngClass]="{ 'is-invalid': userForm.submitted && userName.invalid}"
          required>

        <div *ngIf="userForm.submitted && userName.invalid" class="invalid-tooltip">
          <div *ngIf="userName.errors.required">El nombre es obligatorio</div>
        </div>
      </div>

      <div class="form-group">
        <label for="userEmail">Email corporativo</label>

        <input type="email" [(ngModel)]="user.email" class="form-control" id="userEmail" placeholder="example@yacao.com"
          name="userEmail" [ngClass]="{ 'is-invalid': userForm.submitted && !emailValid(user.email)}" required>


        <div *ngIf="userForm.submitted && !emailValid(user.email)" class="invalid-tooltip">
          <div>El email es obligatorio</div>
        </div>
      </div>

      <div class="form-group" *ngIf="!user.id">
        <label for="userPassword">Contraseña</label>

        <input type="password" #userPassowrd="ngModel" class="form-control" id="userPassword"
          [(ngModel)]="user.password" placeholder="Password" name="userPassword"
          [ngClass]="{ 'is-invalid': userForm.submitted && userPassowrd.invalid}" required>

        <div *ngIf="userForm.submitted && userPassowrd.invalid" class="invalid-tooltip">
          <div *ngIf="userPassowrd.errors.required">La contraseña es obligatoria</div>
        </div>
      </div>

      <div class="form-group"
        *ngIf="user.id && (globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor)">
        <label for="userPassword">Contraseña</label>

        <input type="password" #userPassowrd="ngModel" class="form-control" id="userPassword"
          [(ngModel)]="user.password" placeholder="Password" name="userPassword">
      </div>

      <!-- Roles -->
      <div class="col-12 p-0">
        <div class="col-12 d-inline-block position-relative align-top pl-0">
          <label class="d-block col-12 p-0">Selecciona los roles</label>
          <div *ngFor="let role of roles; let i= index" class="d-inline-block col-6">
            <mat-checkbox [checked]="userHaveOnwPropertyRole(user, role)" *ngIf="role.active" name="userRole"
              (change)="addRemoveRoleToUser($event, role)">
              {{ role.name }}
            </mat-checkbox>
          </div>
          <div *ngIf="userForm.submitted && (!user.roles || user.roles.length === 0)" class="col-12">
            <label class="text-danger">*DEBE SELECCIONAR ALMENOS UN ROL</label>
          </div>
        </div>
      </div>

      <!-- Center -->
      <div class="form-group mt-2">
        <label for="userCenterId">Centro</label>

        <ng-select id="userCenterId" [items]="centers" [searchable] [clearSearchOnAdd] [hideSelected]="true"
          notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name" [(ngModel)]="user.center"
          name="userCenter" placeholder="Selecciona el centro" [compareWith]="compareFn" [multiple]="false"
          (change)="setCentersCanManager($event)"
          [disabled]="!userHaveRole(user, 'driver') && !userHaveRole(user, 'manager') && !userHaveRole(user, 'unload_manager')">
        </ng-select>
      </div>

      <!-- Work centers -->
      <div class="col-12 d-inline-block position-relative align-top pl-0"
        *ngIf="userHaveRole(user, 'manager') && user.center">
        <label class="d-block col-12 p-0" for="workCenters">Centros que administra</label>

        <div *ngFor="let center of centersCanManager" class="d-inline-block col-4">
          <mat-checkbox *ngIf="center.active" name="userWorkCenters"
            [checked]="userHaveOnwPropertyWorkCenter(user, center)" (change)="addRemoveWorkCenterToUser($event, center)"
            [disabled]="!userHaveRole(user, 'manager')">
            {{ center.name }}
          </mat-checkbox>
        </div>
      </div>

      <!-- Zones -->
      <div class="col-12 d-inline-block position-relative align-top pl-0">
        <label class="d-block col-12 p-0" for="userZoneId">Selecciona las zonas</label>

        <div *ngFor="let zone of zones" class="d-inline-block col-4">
          <mat-checkbox *ngIf="zone.active" name="userZones" [checked]="userHaveOnwPropertyZone(user, zone)"
            (change)="addRemoveZoneToUser($event, zone)"
            [disabled]="(!userHaveRole(user, 'driver') || (userHaveRole(user, 'driver') && !user.center)) && !userHaveRole(user, 'buyer')">
            {{ zone.name }}
          </mat-checkbox>
        </div>
      </div>


      <!-- Contacts -->
      <div class="form-group mt-2" *ngIf="userHaveRole(user, 'buyer')">
        <div class="col-8 d-inline-block">
          <label for="userContactId">Contacto</label>

          <ng-select id="userContactId" [items]="suppliers" [searchable] [clearSearchOnAdd] [hideSelected]="true"
            notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="full_name" bindValue="diceros_id"
            [(ngModel)]="user.supplier_diceros_id" name="userContact" placeholder="Selecciona el contacto"
            [compareWith]="compareFn" [multiple]="false"
            [ngClass]="{'select-invalid': userForm.submitted && !user.supplier_diceros_id }" required>
          </ng-select>
        </div>

        <div class="col-4 d-inline-block">
          <label for="userCommission">Comisión por kg suplido</label>
          <input type="number" min="0" #userCommission="ngModel" [(ngModel)]="user.commission" class="form-control"
            id="userCommission" [ngClass]="{ 'is-invalid': userForm.submitted && userCommission.invalid}"
            name="userCommissionFormName" required>

          <div *ngIf="userForm.submitted && userCommission.invalid" class="invalid-tooltip">
            <div *ngIf="userCommission.errors.required">La comisión es obligatoria</div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-6">
          <label class="">Estado del usuario</label>
          <div class="col-12">
            <mat-checkbox [(ngModel)]="user.active" name="userisActive">Activo</mat-checkbox>
          </div>
        </div>

        <div class="col-6">
          <label class="">Puede traducir</label>
          <div class="col-12">
            <mat-checkbox [(ngModel)]="user.is_a_translator" name="is_a_translator"
              [disabled]="!globals.currentUserRolesAsBooleanObject.admin">Traductor</mat-checkbox>
          </div>
        </div>
      </div>


    </div>
    <div class="modal-footer">

      <button type="submit" class="btn btn-primary" name="submitUser" [disabled]="sending">
        {{ user.id ? 'Guardar' : 'Crear' }}
      </button>

      <button type="button" class="btn btn-danger" (click)="d('button click')" name="closeModal"
        [disabled]="sending">Cancelar</button>
    </div>
  </form>
</ng-template>