
<ng-template #content let-modal let-d="dismiss">
    <form #zoneForm="ngForm" (ngSubmit)="zoneForm.form.valid && onSubmit(zone)">
      <div class="modal-header">
        <h4 class="modal-title"> {{zone.id ? 'Actualizar zona' : 'Crear zona' }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
  
      <div class="modal-body">
  
        <div class="form-group">
          <label for="zoneNameId">Nombre</label>
  
          <input type="text" #zoneName="ngModel" class="form-control" id="zoneNameId" [(ngModel)]="zone.name"
            placeholder="Name" name="zoneName" [ngClass]="{ 'is-invalid': zoneForm.submitted && zoneName.invalid}"
            required>
  
          <div *ngIf="zoneForm.submitted && zoneName.invalid" class="invalid-tooltip">
            <div *ngIf="zoneName.errors.required">El nombre es obligatorio</div>
          </div>
        </div>
  
        <div class="form-group">
          <label for="zoneGroupId">Grupo al que pertenece</label>
  
          <ng-select id="zoneGroupId" [items]="zoneGroups" [searchable] [clearSearchOnAdd]
            notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name" [(ngModel)]="zone.zone_group"
            name="zoneGroup" placeholder="Selecciona el grupo" [compareWith]="compareFn"
            [ngClass]="{'select-invalid': zoneForm.submitted && !zone.zone_group }" required></ng-select>
        </div>
  
        <div class="form-group">
          <mat-checkbox [(ngModel)]="zone.active" name="zoneActive">
            Activo
          </mat-checkbox>
        </div>
  
      </div>
  
      <div class="modal-footer">
  
        <button class="btn btn-primary" name="updateZone" [disabled]="sending">
          {{ zone.id ? 'Guarzar' : 'Crear'}}
        </button>
  
        <button class="btn btn-danger" (click)="d('button click')" name="closeModal" [disabled]="sending">
          Cancelar
        </button>
      </div>
    </form>
  </ng-template>
  