import { Component, OnInit, ViewChild, ViewEncapsulation, Input, ChangeDetectorRef } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ExportationModel } from 'src/app/models/exportation.model';

@Component({
  selector: 'app-exportation-details',
  templateUrl: './exportation-details.component.html',
  styleUrls: ['./exportation-details.component.scss']
})
export class ExportationDetailsComponent implements OnInit {
  @ViewChild('showModal', { static: true }) private modal;

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };

  exportation: ExportationModel = new ExportationModel();

  page: number = 1;

  constructor(private _modalService: NgbModal) { }

  ngOnInit() {
  }

  show(exportation: ExportationModel) {
    this.exportation = exportation;
    this.openModal();
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }

}
