import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
// import { isNumber, toInteger, padNumber } from '@ng-bootstrap/ng-bootstrap/util/util';
import * as _ from 'lodash';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('-');
      if (dateParts.length === 1 && _.isNumber(dateParts[0])) {
        return {day: _.toInteger(dateParts[0]), month: null, year: null};
      } else if (dateParts.length === 2 && _.isNumber(dateParts[0]) && _.isNumber(dateParts[1])) {
        return {day: _.toInteger(dateParts[0]), month: _.toInteger(dateParts[1]), year: null};
      } else if (dateParts.length === 3 && _.isNumber(dateParts[0]) && _.isNumber(dateParts[1]) && _.isNumber(dateParts[2])) {
        return {day: _.toInteger(dateParts[0]), month: _.toInteger(dateParts[1]), year: _.toInteger(dateParts[2])};
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    return date ?
        `${_.isNumber(date.day) ? this.padNumber(date.day) : ''}/${_.isNumber(date.month) ? this.padNumber(date.month) : ''}/${date.year}` :
        '';
  }

  padNumber(value: number) {
    if (_.isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }
}

