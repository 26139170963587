<!-- Notification template -->


<ng-template #supplierContent let-modal let-d="dismiss">
  <form #supplierForm="ngForm" (ngSubmit)="supplierForm.form.valid && onSubmitSupplier(supplier)">
    <div class="modal-header">
      <h4 class="modal-title">{{supplier.supplier ? 'Actualizar suplidor' : 'Crear suplidor'}}</h4>

      <button type="button" class="close" aria-label="Close" (click)="d('close-supplier-modal')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <!-- Personal info -->
      <div class="col-12">
        <h4 class="d-block">Información del contacto</h4>
        <!-- Contact -->
        <div class="form-group col-4 d-inline-block" *ngIf="!supplier.supplier">
          <label for="supplierNameId">
            {{ loadAvailableSuppliers ? 'Cargando contactos' : 'Contacto'}}
          </label>

          <div class="load-container" *ngIf="loadAvailableSuppliers">
            <div class="loader-green d-inline-block"></div>
          </div>

          <div>
            <ng-select id="supplierContactId" [items]="availableSuppliers" [searchable]
              notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="code" bindValue="id"
              [(ngModel)]="supplier.id" name="contact" placeholder="Selecciona el contacto" [compareWith]="compareFn"
              [multiple]="false" [ngClass]="{'select-invalid': supplierForm.submitted && !supplier.id}" required>
            </ng-select>
          </div>

          <div *ngIf="supplierForm.submitted && !supplier.id" class="invalid-tooltip">
            <div>El contacto es obligatorio</div>
          </div>
        </div>

        <!-- Name -->
        <div class="form-group col-3 d-inline-block" *ngIf="supplier.supplier">
          <label for="supplierName">
            Nombre
          </label>

          <input id="supplierName" placeholder="Nombre" class="form-control" type="text" [(ngModel)]="supplier.name"
            name="supplierName" readonly />
        </div>

        <!-- Surname -->
        <div class="form-group col-3 d-inline-block" *ngIf="supplier.supplier">
          <label for="supplierSurname">
            Apellidos
          </label>

          <input id="supplierSurname" placeholder="Apellido" class="form-control" type="text"
            [(ngModel)]="supplier.surname" name="supplierSurname" readonly />
        </div>


        <!-- Code -->
        <div class="form-group col-3 d-inline-block" *ngIf="supplier.supplier">
          <label for="supplierCode">
            Codigo
          </label>

          <input id="supplierCode" placeholder="Codigo" class="form-control" type="text" [(ngModel)]="supplier.code"
            name="supplierCode" readonly />
        </div>

        <!-- Diceros ID -->
        <div class="form-group col-3 d-inline-block" *ngIf="supplier.supplier">
          <label for="supplierDicerosId">
            ID Diceros
          </label>

          <input id="supplierDicerosId" placeholder="Codigo" class="form-control" type="text" [(ngModel)]="supplier.diceros_id"
            name="supplierDicerosId" readonly />
        </div>
      </div>

      <!-- Company info -->
      <div class="col-12">
        <h4 class="d-block">Información de la compañia</h4>
        <!-- Company name -->
        <div class="form-group d-inline-block col-4">
          <label for="supplierCompany">
            Nombre de la compañía
          </label>

          <input id="supplierCompany" placeholder="Compañia" class="form-control" type="text"
            [(ngModel)]="supplier.company_name" name="supplierCompany" />
        </div>

        <!-- Proffix -->
        <div class="form-group d-inline-block col-4">
          <label for="supplierProffixId">
            Proffix
          </label>

          <input id="supplierProffixId" #supplierProffix="ngModel" class="form-control" placeholder="Proffix"
            type="text" [(ngModel)]="supplier.proffix" name="supplierProffix" />


          <!-- <div *ngIf="supplierForm.submitted && supplierProffix.invalid" class="invalid-tooltip">
            <div *ngIf="supplierProffix.errors.required">El proffix es obligatorio</div>
          </div>

          <div *ngIf="supplierForm.submitted && checkIfProffixExists(supplier)" class="invalid-tooltip">
            <div>El proffix debe ser unico</div>
          </div> -->
        </div>

        <!-- Performance -->
        <div class="form-group d-inline-block col-4">
          <label for="supplierPerformanceId">
            Rendimiento
          </label>

          <input id="supplierPerformanceId" placeholder="Rendimiento" class="form-control" type="number"
            [(ngModel)]="supplier.performance" name="supplierPerformance" min="0" required
            [disabled]="!globals.currentUserRolesAsBooleanObject.admin" />
        </div>

        <!-- Center -->
        <div class="form-group d-inline-block col-4">
          <label for="supplierCenterId">
            Centro
          </label>

          <ng-select id="supplierCenterId" [items]="centers" [searchable] notFoundText="No hay coincidencias"
            loadingText="Buscando" bindLabel="name" [(ngModel)]="supplier.center" name="center"
            placeholder="Selecciona el centro" [compareWith]="compareFn" [multiple]="false"></ng-select>
        </div>

        <!-- Zone -->
        <div class="form-group d-inline-block col-4">
          <label for="supplierZoneId">
            Zona
          </label>

          <ng-select id="supplierZoneId" [items]="zones" [searchable] [clearSearchOnAdd]
            notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name" [(ngModel)]="supplier.zones"
            name="supplierZones" placeholder="Selecciona las zonas" [compareWith]="compareFn" [multiple]="false">
          </ng-select>
        </div>

        <!-- Buyer -->
        <div class="form-group d-inline-block col-4">
          <label for="supplierBuyerId">
            Comprador
          </label>

          <ng-select id="supplierBuyerId" [items]="buyers" [searchable] notFoundText="No hay coincidencias"
            loadingText="Buscando" bindLabel="name" [(ngModel)]="supplier.buyer" name="buyer"
            placeholder="Selecciona el comprador" [compareWith]="compareFn" [multiple]="false"></ng-select>
        </div>

        <!-- Delivery group -->
        <div class="form-group d-inline-block col-6">
          <label for="supplierDeliveryGroupsId">
            Grupos de entrega
          </label>

          <ng-select id="supplierDeliveryGroupsId" [items]="deliveryGroups" [searchable] [clearSearchOnAdd]
            notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name" [hideSelected]="true"
            [(ngModel)]="supplier.delivery_groups_as_supplier" name="supplierDeliveryGroups"
            placeholder="Selecciona los grupo" [compareWith]="compareFn" [multiple]="true">
          </ng-select>
        </div>

        <!-- Settlement type-->
        <div class="form-group d-inline-block col-6">
          <label for="supplierSettlementTypeId">
            Forma de pago
          </label>

          <ng-select id="supplierSettlementTypeId" [items]="contactSettlementTypes" [searchable]
            notFoundText="No hay coincidencias" loadingText="Buscando" [(ngModel)]="supplier.settlement_type_cd"
            bindValue="id" bindLabel="name" name="settlementType" placeholder="Selecciona la forma de pago"
            [compareWith]="compareFn" [multiple]="false" [disabled]="!globals.currentUserRolesAsBooleanObject.admin">
          </ng-select>
        </div>

        <div class="form-group d-inline-block col-6" *ngIf="supplier.settlement_type_cd === settlementType.prebilling">
          <label for="supplierPrebillingWeight">
            Peso a prefacturar
          </label>

          <ng-select id="supplierPrebillingWeight" [items]="contactPrebillingWeight" [searchable]
            [ngClass]="{'select-invalid': supplierForm.submitted && supplier.prebilling_weight_cd == null }"
            notFoundText="No hay coincidencias" loadingText="Buscando" [(ngModel)]="supplier.prebilling_weight_cd"
            bindValue="id" bindLabel="name" name="supplierPrebillingWeight"
            placeholder="Selecciona a que precio se prefacturara" [compareWith]="compareFn" [multiple]="false"
            [disabled]="!globals.currentUserRolesAsBooleanObject.admin" required>
          </ng-select>
        </div>

        <div class="form-group d-inline-block col-6" *ngIf="supplier.settlement_type_cd === settlementType.prebilling">
          <label for="supplierMaxDiff">
            Diferencia máxima merma entre Peso Suplidor y Receptor
          </label>

          <input id="supplierMaxDiff" #supplierMaxDiff="ngModel" class="form-control" placeholder="%" type="number"
            min="0" max="100" [(ngModel)]="supplier.max_diff" name="supplierMaxDiff"
            [ngClass]="{ 'is-invalid': supplierForm.submitted && (supplierMaxDiff.invalid || (supplier.max_diff < 0 || supplier.max_diff > 100)) }"
            required [disabled]="!globals.currentUserRolesAsBooleanObject.admin" />

          <div *ngIf="supplierForm.submitted && supplierMaxDiff.invalid" class="invalid-tooltip">
            <div *ngIf="supplierMaxDiff.errors.required">Este campo es obligatorio</div>
          </div>

          <div class="invalid-tooltip"
            *ngIf="supplierForm.submitted && (supplier.max_diff < 0 || supplier.max_diff > 100)">
            La diferencia debe ser mayor o igual a 0 y menor o igual a 100
          </div>
        </div>

        <!-- Commissions -->
        <div class="form-group d-inline-block col-6">
          <label for="supplierCommissionId">
            Comisión por KG
          </label>

          <input id="supplierCommissionId" class="form-control" placeholder="Comision" type="number" min="0"
            [(ngModel)]="supplier.commission_value" name="supplierCommission"
            [disabled]="!globals.currentUserRolesAsBooleanObject.admin || !supplier.intermediary" />
        </div>

        <div class="form-group d-inline-block col-4">
          <label for="supplierCommissionIdPerQQ">
            Comisión por QQ
          </label>

          <div>
            {{supplier.commission_value ? supplier.commission_value*50 : 0}} $RD por QQ
          </div>
        </div>

      </div>

      <!-- Aditional info -->
      <div class="row">
        <h4 class="col-12 ml-3">Información adicional</h4>
        <div class="col-2 ml-3">
          <mat-checkbox [(ngModel)]="supplier.active_as_supplier" name="supplierIsActive">
            Activo
          </mat-checkbox>
        </div>
        <div class="col-4">
          <mat-checkbox [(ngModel)]="supplier.trusted" name="supplierIsTrusted"
            [disabled]="!globals.currentUserRolesAsBooleanObject.admin">
            Autorizar inclusión incentivos
          </mat-checkbox>
        </div>
        <div class="col-3">
          <mat-checkbox [(ngModel)]="supplier.processor" name="supplierIsProcessor">
            Procesa producto
          </mat-checkbox>
        </div>

        <div class="col-2">
          <mat-checkbox [(ngModel)]="supplier.corporate" name="supplierIsCorporate">
            Corporativo
          </mat-checkbox>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" name="updateSupplier" [disabled]="sending">
        {{ supplier.supplier ? 'Guardar' : 'Crear'}}
      </button>

      <button type="button" class="btn btn-danger" (click)="d('close-supplier-modal')" name="closeModal"
        [disabled]="sending">
        Cancelar
      </button>
    </div>
  </form>
</ng-template>