<app-navbar></app-navbar>
<div class="row">
  <!-- SEARCH FILTERS-->
  <div class="col">
    <div class="filtersContainer">
      <div class="row">
        <div class="col-3">
          <button type="button" class="btn btn-outline-dark btn-block" (click)="isCollapsed= !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="collapsibleFilters">
            <!-- <mat-icon class="position-relative top-5 m-0">{{ isCollapsed ? 'visibility_off' : 'visibility' }}</mat-icon> -->
            <i class="{{ isCollapsed ? 'fa fa-eye-slash' : 'fa fa-eye' }}"></i>
            {{ (isCollapsed ? 'commons.filters.filter_button_hidde' : 'commons.filters.filter_button_show') | translate }}
          </button>
        </div>
      </div>

      <div class="" id="collapsibleFilters" [ngbCollapse]="!isCollapsed">
        <div class="row">
          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.creation_date_search_start' | translate}}
            </label>

            <div class="input-group">
              <!-- input box -->
              <input class="form-control" name="mydate" placeholder="{{ 'commons.filters.placeholders.date_format' | translate}}" angular-mydatepicker
                #dp3="angular-mydatepicker" [options]="myDpOptions"
                (dateChanged)="onDateChanged($event, 'q[collected_date_gteq]')" [locale]="'es'"
                (click)="dp3.toggleCalendar()" />

              <!-- clear date button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="filters['q[collected_date_gteq]']"
                  (click)="dp3.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>

              <!-- toggle calendar button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp3.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>

            </div>

            <!-- <input class="form-control" type="date" [(ngModel)]="filters['q[contract_ends_lteq]']" /> -->
          </div>

          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.supplier' | translate}}
            </label>

            <ng-select [items]="suppliers" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="full_name" bindValue="id" name="producer" placeholder="Suplidor"
              [multiple]="false" [(ngModel)]="filters['q[contact_id_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.completed' | translate}}
            </label>

            <ng-select [items]="yesOrNot" bindValue="value" bindLabel="name" [searchable] [hideSelected]="true"
              notFoundText="No hay coincidencias" loadingText="Buscando" name="isComplete" placeholder="Completo"
              [multiple]="false" [(ngModel)]="filters['q[completed_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.contract_number' | translate}}
            </label>

            <input class="form-control" type="text" [(ngModel)]="filters['q[talbook_sheet_cont]']"
              placeholder="Número del acuerdo" />
          </div>

          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.creation_date_search_finish' | translate}}
            </label>

            <div class="input-group">
              <!-- input box -->
              <input class="form-control" name="mydate" placeholder="{{ 'commons.filters.placeholders.date_format' | translate}}" angular-mydatepicker
                #dp4="angular-mydatepicker" [options]="myDpOptions"
                (dateChanged)="onDateChanged($event, 'q[collected_date_lteq]')" [locale]="'es'"
                (click)="dp4.toggleCalendar()" />

              <!-- clear date button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="filters['q[collected_date_lteq]']"
                  (click)="dp4.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>

              <!-- toggle calendar button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp4.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>

            </div>

            <!-- <input class="form-control" type="date" [(ngModel)]="filters['q[contract_ends_lteq]']" /> -->
          </div>



          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.certificate' | translate}}
            </label>

            <ng-select [items]="certificates" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="name" bindValue="id" name="certificates" placeholder="Certificados"
              [multiple]="false" [(ngModel)]="filters['q[certificate_id_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.validated' | translate}}
            </label>

            <ng-select [items]="yesOrNot" bindValue="value" bindLabel="name" [searchable] [hideSelected]="true"
              notFoundText="No hay coincidencias" loadingText="Buscando" name="isValidated" placeholder="Validado"
              [multiple]="false" [(ngModel)]="filters['q[validated_eq]']">
            </ng-select>
          </div>


          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.contract_type' | translate}}
            </label>

            <ng-select [items]="ticketTypeFilter" bindValue="value" bindLabel="name" [searchable] [hideSelected]="true"
              notFoundText="No hay coincidencias" loadingText="Buscando" name="isNull" placeholder="Tipo de contrato"
              [multiple]="false" [(ngModel)]="filters['q[ticket_type_cd_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.contract_start_date' | translate}}
            </label>

            <div class="input-group">
              <!-- input box -->
              <input class="form-control" name="mydate" placeholder="{{ 'commons.filters.placeholders.date_format' | translate}}" angular-mydatepicker
                #dp="angular-mydatepicker" [options]="myDpOptions"
                (dateChanged)="onDateChanged($event, 'q[contract_starts_gteq]')" [locale]="'es'"
                (click)="dp.toggleCalendar()" />

              <!-- clear date button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="filters['q[contract_starts_gteq]']"
                  (click)="dp.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>

              <!-- toggle calendar button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>

            </div>

            <!-- <input class="form-control" type="date" [(ngModel)]="filters['q[contract_starts_gteq]']" /> -->
          </div>


          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.cocoa_type' | translate}}
            </label>

            <ng-select [items]="cocoaTypes" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="name" bindValue="id" name="cocoatype" placeholder="Tipo de cacao"
              [multiple]="false" [(ngModel)]="filters['q[cocoa_type_id_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.nulled' | translate}}
            </label>

            <ng-select [items]="yesOrNot" bindValue="value" bindLabel="name" [searchable] [hideSelected]="true"
              notFoundText="No hay coincidencias" loadingText="Buscando" name="isNull" placeholder="Nulo"
              [multiple]="false" [(ngModel)]="filters['q[nulled_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.buyer' | translate}}
            </label>

            <ng-select [items]="buyers" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="name" bindValue="id" name="receiver" placeholder="Comprador"
              [multiple]="false" [(ngModel)]="filters['q[original_receiver_id_eq]']">
            </ng-select>
          </div>


          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.contract_end_date' | translate}}
            </label>

            <div class="input-group">
              <!-- input box -->
              <input class="form-control" name="mydate" placeholder="{{ 'commons.filters.placeholders.date_format' | translate}}" angular-mydatepicker
                #dp1="angular-mydatepicker" [options]="myDpOptions"
                (dateChanged)="onDateChanged($event, 'q[contract_ends_lteq]')" [locale]="'es'"
                (click)="dp1.toggleCalendar()" />

              <!-- clear date button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="filters['q[contract_ends_lteq]']"
                  (click)="dp1.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>

              <!-- toggle calendar button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp1.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>

            </div>

            <!-- <input class="form-control" type="date" [(ngModel)]="filters['q[contract_ends_lteq]']" /> -->
          </div>

          <!-- <div class="col-3">
            <label>
              Tipo de contrato de compra
            </label>

            <ng-select [items]="ticketTypeFilter" bindValue="value" bindLabel="name" [searchable] [hideSelected]="true"
              notFoundText="No hay coincidencias" loadingText="Buscando" name="isNull" placeholder="Tipo de contrato"
              [multiple]="false" [(ngModel)]="filters['q[ticket_type_cd_eq]']">
            </ng-select>
          </div> -->

        </div>

        <div class="row mt-3 align-bottom">
          <div class="col-3 offset-6 d-flex align-items-end">
            <button type="button" class="btn btn-outline-dark btn-block"
              (click)="getTickets(currentPage, itemsPerPage, filters, 'xlsx')">
              <div class="load-container" *ngIf="loadingExcel">
                <div class="loader-green d-inline-block"></div>
              </div>
              <i class="fa fa-download" *ngIf="!loadingExcel"></i>
              {{ 'commons.filters.excel_download_button' | translate}}
            </button>
          </div>

          <div class="col-3 d-flex align-items-end">
            <button type="button" class="btn btn-success btn-block" (click)="getTickets(1, itemsPerPage, filters)">
              <i class="fa fa-filter"></i>
              {{ 'commons.filters.filter_button' | translate}}
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-contract-form [addons]="addons" [tickets]="tickets" [certificates]="certificates" [cocoaTypes]="cocoaTypes"
  [cocoaQualities]="cocoaQualities" [buyers]="buyers" [suppliers]="suppliers" (result)="setTickets($event)">
</app-contract-form>

<!-- LIST OF RECEIPT -->
<div *ngIf="!loading">
  <table class="table sticky-yacao green table-striped table-hover table-sm">
    <thead>
      <tr>
        <th>
          {{ 'pages.table_headers.creation_date' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.start_date' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.end_date' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.direct_purchase' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.contract_number' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.contract_photo' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.status' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.buyer' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.supplier' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.cocoa_type' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.cocoa_quality' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.certificate' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.total_amount' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.base_price' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.maximum_price' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.incentive' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.included' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.commission' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.included' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.contract_price' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.authorization' | translate}}
        </th>
        <!-- <th>Facturado</th> -->
        <th>
          {{ 'pages.table_headers.actions' | translate}}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let ticket of tickets | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems}; let i= index;">
        <td>

          {{ (ticket.ticket_type_cd === contractType.direct_purchase ? ticket.contract_starts : ticket.collected_date) | date:'dd/MM/yy' }}
        </td>
        <td>
          {{ ticket.contract_starts | date:'dd/MM/yy' }}
        </td>
        <td>
          {{ ticket.contract_ends | date:'dd/MM/yy' }}
        </td>
        <td>
          <mat-icon *ngIf="ticket.ticket_type_cd === contractType.direct_purchase">
            check
          </mat-icon>
          <mat-icon *ngIf="ticket.ticket_type_cd === contractType.contracts">
            close
          </mat-icon>
        </td>
        <td class="talbook-sheet-column">
          {{ ticket.talbook_sheet }}
        </td>
        <td>
          <mat-icon class="pointer" *ngIf="ticket.receipt"
            (click)="openGallery(('Imagen del recibo ' + ticket.talbook_sheet ), [ticket.receipt.url])">
            image
          </mat-icon>
          <mat-icon *ngIf="!ticket.receipt">
            close
          </mat-icon>
        </td>
        <td>
          <span *ngIf="ticket.nulled">
            {{ 'commons.status.nulled' | translate}}
          </span>
          <span *ngIf="!ticket.nulled && ticket.validated">
            {{ 'commons.status.validated' | translate}}
          </span>
          <span *ngIf="!ticket.nulled && ticket.completed">
            {{ 'commons.status.completed' | translate}}
          </span>
          <span
            *ngIf="(!ticket.nulled && !ticket.validated && !ticket.completed) || (!ticket.nulled && ticket.validated && !ticket.completed)">
            {{ 'commons.status.incompleted' | translate}}
          </span>
        </td>
        <td>
          {{ ticket.original_receiver?.name }}
        </td>
        <td [className]="ticket.supplier_not_found ? 'text-danger' : 'text-dark'">
          {{ ticket.supplier_not_found ? 'Suplidor no encontrado' : ticket.supplier?.full_name }}
        </td>
        <td>
          {{ ticket.cocoa_type?.name }}
        </td>
        <td>
          <span *ngIf="ticket.cocoa_quality">
            {{ ticket.cocoa_quality?.name }}
          </span>
          <mat-icon *ngIf="!ticket.cocoa_quality"></mat-icon>
        </td>
        <td>
          {{ ticket.certificate?.name }}
        </td>
        <td>
          {{ getQuantity(ticket) }}
        </td>
        <td>
          {{ toInteger(ticket.base_price * 50) }}
        </td>
        <td>
          {{ toInteger(ticket.max_price * 50) }}
        </td>
        <td>
          {{ toInteger(ticket.addon_price * 50) }}
        </td>
        <td>
          <mat-icon *ngIf="ticket.addon_toggle">
            check
          </mat-icon>
          <mat-icon *ngIf="!ticket.addon_toggle">
            close
          </mat-icon>
        </td>
        <td>
          {{ toInteger(ticket.commission_price * 50) }}
        </td>
        <td>
          <mat-icon *ngIf="ticket.commission_toggle">
            check
          </mat-icon>
          <mat-icon *ngIf="!ticket.commission_toggle">
            close
          </mat-icon>
        </td>
        <td>
          {{ toInteger(ticket.contract_price*50) }}
        </td>
        <td>
          <mat-icon class="pointer" *ngIf="ticket.authorization"
            (click)="openGallery(('Autorización del acuerdo ' + ticket.talbook_sheet ), [ticket.authorization.url])">
            image
          </mat-icon>
          <mat-icon *ngIf="!ticket.authorization && ticket.special_price">
            close
          </mat-icon>
          <mat-icon *ngIf="!ticket.authorization && !ticket.special_price">
          </mat-icon>
        </td>

        <!-- <td> Para saber si ha sido facturado
          <i class="{{ ticket.billed ? 'fa fa-check' : 'fa fa-times' }}"></i>
        </td> -->

        <!--
          Dropdown with options
        -->
        <td>
          <div class="nav-item dropdown" ngbDropdown>
            <a class="btn btn-success btn-sm dropdown-toggle white" ngbDropdownToggle>
              {{ 'commons.actions_dropdown.title' | translate}}
            </a>

            <div class="dropdown-menu" aria-labelledby="admin" ngbDropdownMenu>
              <button class="btn btn-sm dropdown-item" (click)="openForm(ticket)">
                <mat-icon class="position-relative top-5 text-primary">
                  edit
                </mat-icon>
                {{ 'commons.actions_dropdown.options.edit' | translate}}
              </button>

              <!-- <button class="btn btn-sm dropdown-item" (click)="validateTicket(ticket)"
                [disabled]="ticket.nulled || (!globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor)">
                <mat-icon class="position-relative top-5 text-danger" *ngIf="ticket.validated">
                  close
                </mat-icon>
                <mat-icon class="position-relative top-5 text-warning" *ngIf="!ticket.validated">
                  settings
                </mat-icon>
                {{ ticket.validated ? 'No validado' : 'Validado' }}
              </button> -->
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!-- DIV LOADING-->
<div class="loading" *ngIf="loading">
  <ng-template #loadingTemplate></ng-template>
  <ngx-loading [show]="loading" [template]="loadingTemplate"></ngx-loading>
</div>

<!-- PAGER -->
<div class="paginationContainer" *ngIf="!loading">
  <pagination-controls autoHide="true" responsive="true" previousLabel="{{ 'commons.pagination_labels.prev' | translate}}" nextLabel="{{ 'commons.pagination_labels.next' | translate}}"
    (pageChange)="getTickets($event, itemsPerPage, filters)">
  </pagination-controls>
</div>

<app-images-modal></app-images-modal>