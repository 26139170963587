import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModalRef, NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

// services
import { SettingService } from 'src/app/services/setting.service';

// Constants
import { CONSTANTS } from 'src/app/app.consts';

export interface SettingInterface {
  id: number;
  client_name: string;
  primary_language: string;
}

@Component({
  selector: 'app-setting-form',
  templateUrl: './setting-form.component.html',
  styleUrls: ['./setting-form.component.scss']
})
export class SettingFormComponent implements OnInit {
  @ViewChild('modal', { static: true }) private modal: TemplateRef<any>;

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };

  form: FormGroup;

  readonly primariesLanguages: any[] = [
    { name: 'es' },
    { name: 'en' }
  ];

  loading: boolean = true;

  constructor(
    private _modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _settingService: SettingService,
    private _translateService: TranslateService) { }

  ngOnInit(): void {
  }

  async onSubmit(): Promise<void> {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const setting: SettingInterface = this.form.getRawValue();
    setting.id ? await this._update(setting) : await this._create(setting);
    this.closeModal();
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  async openForm(): Promise<void> {
    this.loading = true;
    this.form = this._formBuilder.group({});
    this.openModal();
    await this._getSetting();
    this.loading = false;
  }

  compareFn(o1, o2) {
    return o1['name'] === o2;
  };

  private _buildForm(setting: SettingInterface): void {
    if (setting) {
      this.form = this._formBuilder.group({
        id: [setting.id],
        client_name: [setting.client_name, Validators.required],
        primary_language: [setting.primary_language, [Validators.required, Validators.maxLength(2)]]
      });
    } else {
      this.form = this._formBuilder.group({
        id: [null],
        client_name: [null, Validators.required],
        primary_language: [null, [Validators.required, Validators.maxLength(2)]]
      });
    }

  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }

  private async _getSetting(): Promise<void> {
    await this._settingService.getAppSetting()
      .then(
        (res: any) => {
          const setting: SettingInterface = res['setting'];
          console.log('la setting', setting);
          this._buildForm(setting);
        }
      ).catch(
        (err: any) => {
          if (err['status'] !== 404) {
            console.error(err);
          }

          this._buildForm(null);
        });
  }

  private async _update(setting: SettingInterface): Promise<void> {
    await this._settingService.updateSetting(setting)
      .then(
        (res: any) => {
          const setting: { client_name: string, primary_language: string, have_language: boolean } = res['setting'];
          let language: string = setting.have_language ? (`${setting.client_name}_${setting.primary_language}`) : setting.primary_language;

          CONSTANTS.APP_LANGUAGE = language;
          this._translateService.use(language);
        }
      ).catch(err => console.error(err));
  }

  private async _create(setting: SettingInterface): Promise<void> {
    delete setting.id;
    await this._settingService.createSetting(setting)
      .then(
        (res: any) => {
          const setting: { client_name: string, primary_language: string, have_language: boolean } = res['setting'];
          let language: string = setting.have_language ? (`${setting.client_name}_${setting.primary_language}`) : setting.primary_language;

          CONSTANTS.APP_LANGUAGE = language;
          this._translateService.use(language);
        }
      ).catch(err => console.error(err));
  }
}
