import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';

// Models
import { RoleModel } from '../../../models/role.model';

// Services
import { RoleService } from '../../../services/role.service';
import { GlobalsService } from 'src/app/services/globals.service';

// Components
import { RoleFormComponent } from '../modals/role-form/role-form.component';


@Component({
  selector: 'app-roles-index',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss']
})
export class RolesPage implements OnInit {
  @ViewChild(RoleFormComponent, { static: true }) roleForm: RoleFormComponent;

  roles: RoleModel[] = new Array<RoleModel>();

  p: number = 0;

  loading: boolean;
  isCollapsed = false;

  constructor(
    private roleService: RoleService,
    private globals: GlobalsService
  ) { }

  ngOnInit() {
    this.getRoles();
  }

  getRoles() {
    this.loading = true;
    this.roleService.getRoles()
      .then(
        res => {
          this.roles = res['roles'];
          this.loading = false;
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }

  openForm(role: RoleModel) {
    this.roleForm.openForm(role);
  }

  deactivateActivateRole(role: RoleModel) {
    role['active'] = !role['active'];
    this.roleForm.updateRole(role);
  }

  disableEditAdminAliasIfCurrentNotAreAdmin(role: RoleModel): boolean {
    return role['alias'] === 'admin' ? this.globals.currentUserRolesAsBooleanObject.admin : true;
  }

  setRoles(roles: RoleModel[]): void {
    this.roles = roles;
  }
}
