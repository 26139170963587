import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

// Components
import { RootModalComponent } from 'src/app/components/modals/root-modal/root-modal.component';

// Models
import { UnloadModel } from 'src/app/models/unload.model';
import { CertificateModel } from 'src/app/models/certificate.model';
import { UserModel } from 'src/app/models/user.model';
import { CocoaTypeModel } from 'src/app/models/cocoa-type.model';
import { CocoaQualityModel } from 'src/app/models/cocoa_quality.model';
import { TicketModel } from 'src/app/models/ticket.model';
import { BigBagModel } from 'src/app/models/big-bag.model';
import { LotImageModel } from 'src/app/models/lot_image.model';

// Constants
import { BigBagProcess, UnloadState } from 'src/app/app.enum';

// Services
import { UnloadService } from 'src/app/services/unload.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LotFormComponent } from './lot-form/lot-form.component';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import { GlobalsService } from 'src/app/services/globals.service';
import { TranslateService } from '@ngx-translate/core';
import { BigBagService } from 'src/app/services/big-bag.service';


@Component({
  selector: 'app-final-unload-form',
  templateUrl: './final-unload-form.component.html',
  styleUrls: ['./final-unload-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FinalUnloadFormComponent implements OnInit {
  @ViewChild(RootModalComponent) modal: RootModalComponent;
  @ViewChild(LotFormComponent, { static: true }) lotModal: LotFormComponent;
  @ViewChild(AlertModalComponent) alertModal: AlertModalComponent;

  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'full-modal'
  };

  @Output() result: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() private unloads: UnloadModel[] = new Array<UnloadModel>();
  @Input() certificates: CertificateModel[] = new Array<CertificateModel>();
  @Input() unloadManagers: UserModel[] = new Array<UserModel>();
  @Input() cocoaTypes: CocoaTypeModel[] = new Array<CocoaTypeModel>();
  @Input() cocoaQualities: CocoaQualityModel[] = new Array<CocoaQualityModel>();

  tickets: TicketModel[] = new Array<TicketModel>();
  selectedBigBags: BigBagModel[] = new Array<BigBagModel>();
  lotsImages: LotImageModel[] = [];
  unload: UnloadModel = new UnloadModel();
  ticketToDisplay: TicketModel = new TicketModel();

  processes: any[];

  totalTicketsCollectedWeight: number = 0;
  totalTicketsUnloadWeight: number = 0;
  totalTicketsWeightLoss: number = 0;

  unloadState = UnloadState;
  bigBagProcess = BigBagProcess;

  warningMessage: string;
  weightLossNotExistWarning: boolean;
  allTicketsSelected: boolean = true;
  sending: boolean = false;
  displayTicketInfo: boolean = false;
  unsaveChanges: boolean = false;
  ticketSettlementStates = [{ name: 'Pre negociado' }, { name: 'Resultado seco' }];

  refoundTickets: any[] = new Array<any>();
  loading: boolean = true;
  bigBagPage: number = 1;

  constructor(
    private unloadService: UnloadService, 
    private _notification: NotificationService, 
    public globals: GlobalsService,
    private translate: TranslateService,
    private _lotService: BigBagService
  ) {
    translate.setDefaultLang('es');
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  ngOnInit() {
    this.lotsImages = [];
  }

  onSubmit(unload: UnloadModel) {
    this.updateUnload(unload);
    this.closeTicketInfo();
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  compareFn(a: any, b: any): boolean {
    if (a['ticket_id'] && b['ticket_id']) {
      return Number(a['ticket_id']) === Number(b['ticket_id']);
    } else {
      return Number(a['id']) === Number(b);
    }
  }

  openForm(unload: UnloadModel) {
    this.loading = true;
    // this.unload = _.cloneDeep(unload);
    this.initialize(unload);
  }

  openBigBagForm(bigBag: BigBagModel) {
    console.log(this.lotsImages);
    this.lotModal.openForm(bigBag);
  }

  async initialize(unload: UnloadModel) {
    await this.getTickets(unload.id);
    await this.getUnload(unload);
    this.warningMessage = '';
    this.weightLossNotExistWarning = false;
    await this.initialiceProcess();
    // const weightLoss = _.find(this.unload.big_bags, { process_cd: BigBagProcess.weightloss });
    // !weightLoss && this.createWeightLossIfNotExist();

    await this.setUnloadWeightLossAndUnloadWeight();
    await this.getBigBagsFromSelectedTickets(this.unload['tickets'], this.unload);
    await this.openModal();
  }

  openModal() {
    this.modal.show(this.modalOptions);
  }

  closeModal() {
    this.modal.hide();
  }

  getTicketWeigthLoss(ticket: TicketModel): number {
    const bigBag = _.find(this.unload['big_bags'], { process_cd: BigBagProcess.weightloss });
    const t = _.find(bigBag['tickets'], { ticket_id: ticket['ticket_id'] });
    return t ? t['weight'] : 0;
  }

  calculatePercentajeOfWeightLoss(tickets: Array<TicketModel>): string {
    let total_collected = 0;
    let total_weight_loss = 0;
    _.each(tickets, ticket => {
      total_collected += Number(ticket.receiver_weight);
      total_weight_loss += this.getTicketWeigthLoss(ticket);
    });
    if (total_collected > 0 || total_collected < 0) {
      return ((100 * total_weight_loss) / total_collected).toFixed(2);
    } else {
      return '0';
    }
  }

  getFilteredTicketsWeightInBag(bag: BigBagModel): number {
    let total = 0;

    _.each(bag['tickets'], ticket => {
      const t = _.find(this.unload['tickets'], { ticket_id: ticket['ticket_id'] });
      if (t && t['selected']) {
        total += Number(ticket['weight']);
      }
    });

    return total;
  }

  drySelected(obj): boolean {
    if (obj['cocoa_type_name']) {
      return obj['cocoa_type_name'].toLocaleLowerCase() === 'seco';
    } else {
      const cocoa_type = _.find(this.cocoaTypes, { id: obj['cocoa_type_id'] }) as CocoaTypeModel;
      return cocoa_type.name.toLocaleLowerCase() === 'seco';
    }
  }

  getWeightToFixed(weight: string): string {
    return Number(weight).toFixed(1);
  }

  getTotalWieghtOfTicketsInProcess(process_cd: number): number {
    let total = 0;
    const bigBags = _.filter(this.selectedBigBags, { process_cd: process_cd });
    _.each(bigBags, bigBag => {
      _.each(bigBag['tickets'], ticket => {
        const t = _.find(this.unload['tickets'], { ticket_id: ticket['ticket_id'] });
        if (t && t['selected']) {
          total += Number(ticket['weight']);
        }
      });
    });

    return total;
  }

  getBigBagsFromSelectedTickets(tickets: Array<TicketModel>, unload: UnloadModel) {
    let bigBags = [];
    _.each(tickets, ticket => {
      const filterBigBags = _.filter(unload['big_bags'], bag => {
        if (_.includes(_.map(bag['tickets'], 'ticket_id'), ticket['ticket_id']) && ticket['selected']) {
          return bag;
        }
      });

      bigBags = [...bigBags, ...filterBigBags];
    });

    this.selectedBigBags = _.uniq(bigBags);
  }

  selectUnselectTickets(checked: boolean, tickets: Array<TicketModel>) {
    _.each(tickets, (ticket) => {
      const index = _.findIndex(this.unload['tickets'], { ticket_id: ticket['ticket_id'] });
      this.unload['tickets'][index]['selected'] = checked;
    });

    this.allTicketsSelected = Object.values(_.map(this.unload['tickets'], 'selected')).every(Boolean);
    this.getBigBagsFromSelectedTickets(this.unload['tickets'], this.unload);
  }

  showTicketInfo(ticket: TicketModel) {
    this.displayTicketInfo = true;
    this.ticketToDisplay = ticket;
  }

  closeTicketInfo() {
    this.displayTicketInfo = false;
  }

  setUnloadBigBags(bigBags: BigBagModel[]) {
    this.unload.big_bags = bigBags;
    this.selectedBigBags = _.cloneDeep(this.unload.big_bags);
    this.setUnloadWeightLossAndUnloadWeight();
  }

  updateUnload(unload: UnloadModel) {

    // unload = this.removeEmptyBigBags(unload); no se usa por peticion del cliente.
    this.unload['big_bags_attributes'] = this.unload['big_bags'];

    if (unload.cocoa_type.name.toLowerCase() === 'seco' && unload.accepted) {
      const valid = this.ticketsWeightValid(unload);

      if (!valid) {
        this._notification.error('El peso decargado supera el peso recibido, por favor revisar y corregir', 'Pesos erroneos');
        this.closeModal();
        return;
      }
    }

    let current_unload: UnloadModel = _.cloneDeep(unload);
    current_unload = this._removeWeightLossBigBag(current_unload);

    this._notification.info('Enviando datos de la descarga.');
    this._notification.changeModalZIndexTo(1001);
    this.unloadService.updateUnload(current_unload)
      .then(
        async res => {
          const index = _.findIndex(this.unloads, { id: unload['id'] });
          const response_unload: UnloadModel = res['unload'];
          this.unloads[index] = response_unload;
          this._notification.clear();

          await this._updateLotsImages(response_unload);


          this._notification.success('Actualizado correctamente');

          this.unsaveChanges = false;

          this.closeModal();
          this.sending = false;
        }
      )
      .catch(
        err => {
          if (err['unload']) {
            this.unload = err['unload'];
          }
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      );
  }

  removeCocoaQualityIfBabaSelected(event, type: string) {
    if (event.name.toLocaleLowerCase() === 'baba') {
      switch (type) {
        case 'unload':
          this.unload['cocoa_quality'] = null;
          this.unload['cocoa_quality_id'] = null;
          break;
      }
    }
  }

  openAlertModal(value: any, to: string) {
    let header = '';
    let body = '';
    switch (to) {
      case 'bigBag':
        header = `¿Seguro que desea continuar con la eliminacion del lote ${this.selectedBigBags[value]['barcode_number']}?`;
        body = 'Una vez guardada la descarga los cambios son irreversibles';
        break;
      case 'accepted-unload':
        header = `¿Está completamente seguro de ${this.unload.accepted ? 'invalidar' : 'validar'} la descarga?`;
        body = this.unload.accepted ? 'Asegurese de que ha borrado los datos pertinentes en Diceros antes de proceder' :
          'Asegurese de que los datos de los recibos y los lotes de esta descarga son correctos antes de aceptar';
        break;
    }

    this.alertModal.openModal(header, body, value);
  }

  validateUnloadAndSave(unload: UnloadModel) {
    unload['accepted'] = !unload['accepted'];
    this.updateUnload(unload);
  }

  alertModalResult(result: { reason: string, returnValue: any }) {
    if (result.reason === 'alert-accepted') {
      switch (typeof result.returnValue) {
        case 'number':
          this.unsaveChanges = true;
          this.removeBigBag(result.returnValue);
          break;
        case 'object':
          this.validateUnloadAndSave(result.returnValue);
          break;
      }
    }
  }

  addBigBagToProcess(processCd: number) {
    if (!this.unload['accepted']) {
      this.unsaveChanges = true;
      let bigBag = new BigBagModel();
      const attributes = {
        certificate: this.unload['certificate'],
        certificate_id: this.unload['certificate_id'],
        cocoa_type: this.unload['cocoa_type'],
        cocoa_type_id: this.unload['cocoa_type_id'],
        cocoa_quality: this.unload['cocoa_quality'],
        cocoa_quality_id: this.unload['cocoa_quality_id'],
        process_cd: processCd,
        tickets: []
      };

      bigBag = Object.assign(bigBag, attributes);
      this.openBigBagForm(bigBag);
    }
  }

  setBarcodeTo(ticket: any, index: number) {
    if (this.selectedBigBags[index].barcode_number) {
      this.removeBigBag(index);
      let refoundLot = new BigBagModel();
      refoundLot = Object.assign(refoundLot, {
        barcode_number: ticket.talbook_sheet,
        certificate: this.unload['certificate'],
        certificate_id: this.unload['certificate_id'],
        cocoa_type: this.unload['cocoa_type'],
        cocoa_type_id: this.unload['cocoa_type_id'],
        cocoa_quality: this.unload['cocoa_quality'],
        cocoa_quality_id: this.unload['cocoa_quality_id'],
        process_cd: BigBagProcess.refund,
        tickets: [{
          ticket_id: ticket.ticket_id,
          talbook_sheet: ticket.talbook_sheet,
          weight: 0
        }]
      });

      this.selectedBigBags.push(refoundLot);
      this.unsaveChanges = true;

      const i = _.findIndex(this.selectedBigBags, lot => {
        if (lot.process_cd === BigBagProcess.refund) {
          return _.includes(_.map(lot.tickets, 'ticket_id'), ticket.ticket_id);
        }
      });

      this.lotModal.updateUnloadWithBigBagDatasAndRecalculateWeightLoss(this.selectedBigBags[i]);
    } else {
      this.selectedBigBags = _.cloneDeep(this.selectedBigBags);
      this.selectedBigBags[index].barcode_number = ticket.talbook_sheet;
      this.lotModal.updateUnloadWithBigBagDatasAndRecalculateWeightLoss(this.selectedBigBags[index]);
    }

    this.setRefundTickets(this.tickets);
  }

  removeBigBag(index: number) {
    this.unsaveChanges = true;
    this.unload['tickets'] = [];
    const barcode_number = this.selectedBigBags[index]['barcode_number'];
    const big_bag_unload_index = _.findIndex(this.unload['big_bags'], { barcode_number: barcode_number });
    _.pullAt(this.unload['big_bags'], big_bag_unload_index);
    const weight_loss_big_bag_index = _.findIndex(this.unload['big_bags'], { process_cd: BigBagProcess.weightloss });
    this.unload['big_bags'][weight_loss_big_bag_index]['tickets'] = [];
    _.pullAt(this.selectedBigBags, index);

    this.lotModal.updateUnloadTicketsAndWeightLoss();
    this.unsaveChanges = true;
  }

  canAcceptedUnload(unload: UnloadModel): boolean {
    if (unload.state_cd !== UnloadState.finished) {
      return false;
    } else {
      if (!unload.accepted && !this.unsaveChanges) {
        const tickets = _.flattenDeep(_.map(unload['big_bags'], 'tickets'));
        return tickets.every(ticket => ticket['reviewed']);
      } else if (unload.accepted && !this.unsaveChanges) {
        const tickets = _.flattenDeep(_.map(unload['big_bags'], 'tickets'));
        return !tickets.every(ticket => ticket['billed']);
      } else {
        return false;
      }
    }
  }

  addRefoundBag() {
    if (this.unload.accepted) {
      return;
    }

    if (!this.unload.big_bags) {
      this.unload.big_bags = [];
    }

    let refoundLot = new BigBagModel();
    refoundLot = Object.assign(refoundLot, {
      barcode_number: '',
      certificate: this.unload['certificate'],
      certificate_id: this.unload['certificate_id'],
      cocoa_type: this.unload['cocoa_type'],
      cocoa_type_id: this.unload['cocoa_type_id'],
      cocoa_quality: this.unload['cocoa_quality'],
      cocoa_quality_id: this.unload['cocoa_quality_id'],
      process_cd: BigBagProcess.refund,
      tickets: [{
        ticket_id: null,
        talbook_sheet: null,
        weight: 0
      }]
    });

    this.selectedBigBags.push(refoundLot);
    this.unsaveChanges = true;
  }

  setRefoundWeightTo(bigBag: BigBagModel, weight: number) {
    bigBag.weight = Number(weight);
    bigBag.tickets[0].weight = Number(weight);
    this.unload.big_bags = _.cloneDeep(this.selectedBigBags);
    this.lotModal.updateUnloadTicketsAndWeightLoss();
    this.unsaveChanges = true;
  }

  setRefundTickets(tickets: any[]): void {
    this.refoundTickets = [];
    _.each(tickets, ticket => {
      const refundLots = _.filter(this.unload.big_bags, { process_cd: BigBagProcess.refund });

      const exist = _.find(refundLots, lot => {
        return _.includes(_.map(lot.tickets, 'ticket_id'), ticket.id);
      });

      if (!exist) {
        const t = Object.assign({}, {
          ticket_id: ticket.id,
          talbook_sheet: ticket.talbook_sheet,
          weight: 0,
          certificate: {
            name: ticket.certificate ? ticket.certificate.name : ''
          },
          reviewed: ticket.reviewed
        });

        this.refoundTickets.push(t);
      }
    });
  }

  isTicketBilled(ticket: any): boolean {
    const tickets = _.flattenDeep(_.map(this.unload.big_bags, 'tickets'))
    const t = _.find(tickets, { ticket_id: ticket.ticket_id });
    return t ? t.billed : false;
  }

  unloadEmpty(unload: UnloadModel) {
    const tickets = _.map(unload.big_bags, 'tickets');
    return _.isEmpty(tickets);
  }

  deleteUnload(unload: UnloadModel) {
    this._notification.info('Eliminando descarga...');
    this._notification.changeModalZIndexTo(1001);
    this.unloadService.deleteUnload(unload.id)
      .then(
        res => {
          this._notification.clear();
          this._notification.success('Actualizado correctamente');

          this.unsaveChanges = false;
          this.result.emit(true);

          this.closeModal();
          this.sending = false;
        }
      )
      .catch(
        err => {
          if (err['unload']) {
            this.unload = err['unload'];
          }
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al borrar');
          }
        }
      );
  }


  fixedNumber(number: number, decimals: number = 2): string {
    return number.toFixed(decimals);
  }

  setLotImages(image: LotImageModel): void {
    const index: number = _.findIndex(this.lotsImages, { barcode: image.barcode });
    if (index !== -1) {
      this.lotsImages[index] = image;
    } else {
      this.lotsImages.push(image);
    }
  }

  private ticketsWeightValid(unload: UnloadModel): boolean {
    let valid: boolean = true;
    _.each(unload.tickets, ticket => {
      const percentaje = Number(this.calculatePercentajeOfWeightLoss([ticket]));
      if (ticket.receiver_weight < 100) {
        valid = true;
      } else if (ticket.receiver_weight === 100) {
        if (percentaje <= -30) {
          valid = false;
          return false;
        }
      } else if (ticket.receiver_weight > 100 && ticket.receiver_weight <= 1000) {
        if (percentaje <= -10) {
          valid = false;
          return false;
        }
      } else {
        if (percentaje <= -5) {
          valid = false;
          return false;
        }
      }
      console.log(percentaje);
      console.log(ticket.receiver_weight);
    });

    return valid;
  }

  private initialiceProcess() {
    const commonProcess = [
      { name: 'Devolución', process_cd: BigBagProcess.refund }
    ];

    if (this.unload.cocoa_type.name.toLowerCase() === 'seco') {
      this.processes = [
        { name: 'Seco', process_cd: BigBagProcess.dry },
        { name: 'Semi seco 1', process_cd: BigBagProcess.semi_dry_1 },
        { name: 'Semi seco 2', process_cd: BigBagProcess.semi_dry_2 }
      ];
    } else {
      this.processes = [
        { name: 'Fermentación', process_cd: BigBagProcess.fermentation },
        { name: 'Semi fermentación', process_cd: BigBagProcess.semi_fermentation },
        { name: 'Sanchez', process_cd: BigBagProcess.sanchez },
        { name: 'Impurezas', process_cd: BigBagProcess.impurity }
      ];
    }

    this.processes.push(...commonProcess);
  }

  private setUnloadWeightLossAndUnloadWeight() {
    this.totalTicketsCollectedWeight = 0;
    this.totalTicketsUnloadWeight = 0;
    this.totalTicketsWeightLoss = 0;

    _.each(this.unload['tickets'], ticket => {
      ticket['selected'] = true;
      this.totalTicketsCollectedWeight += Number(ticket['receiver_weight']);
      this.totalTicketsUnloadWeight += Number(ticket['weight']);
      const weightLoss = this.getTicketWeigthLoss(ticket);
      if (typeof weightLoss === 'number') {
        this.totalTicketsWeightLoss += Number(weightLoss);
      }
    });
  }

  private async getUnload(unload: UnloadModel) {
    await this.unloadService.getUnload(unload['id'])
      .then(
        async res => {
          this.unload = res['unload'];
          this.loading = false;
          const weightLoss = _.find(this.unload.big_bags, { process_cd: BigBagProcess.weightloss });
          if (!weightLoss && this.unload.big_bags && this.unload.big_bags.length > 0) {
            this.createWeightLossIfNotExist();
            this.lotModal.updateUnloadTicketsAndWeightLoss();

            this.warningMessage = 'La merma ha sido recalculada, por favor haz click en guardar.';
          }
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }

  private async getTickets(unloadId: number) {
    await this.unloadService.getTicketCanUnload(unloadId)
      .then(
        async res => {
          this.tickets = res['tickets'];
          this.setRefundTickets(this.tickets);
        }
      ).catch(err => console.error(err));
  }


  private createWeightLossIfNotExist() {
    this.warningMessage = 'Ha habido un error con la merma se está recalculando...';
    let weightLossBigBag = new BigBagModel();
    weightLossBigBag = Object.assign(weightLossBigBag, {
      barcode_number: 'W00001',
      process_cd: BigBagProcess.weightloss,
      tickets: [],
      certificate_id: this.unload['certificate_id']
    });

    if (!this.unload['big_bags']) {
      this.unload['big_bags'] = [];
    }

    this.unload['big_bags'].push(weightLossBigBag);
    this.weightLossNotExistWarning = true;
  }

  private removeEmptyBigBags(unload: UnloadModel): UnloadModel {
    const indexes = [];
    _.each(unload['big_bags'], (big_bag, index) => {
      if (big_bag['tickets'].length < 1) {
        indexes.push(index);
      }
    });

    _.pullAt(unload['big_bags'], indexes);
    return unload;
  }

  private _removeWeightLossBigBag(unload: UnloadModel): UnloadModel {
    const index: number = _.findIndex(unload['big_bags_attributes'], { process_cd: BigBagProcess.weightloss });

    if (index !== -1) {
      _.pullAt(unload['big_bags_attributes'], index);
    }

    return unload;
  }

  private async _updateLotsImages(unload: UnloadModel): Promise<void> {
    _.each(this.lotsImages, (image: LotImageModel) => {
      const big_bag: BigBagModel = _.find(unload.big_bags, { barcode_number: image.barcode });
      if (big_bag) {
        image.lot_id = big_bag.id;
      }
    });

    await this._uploadLotsImages(this.lotsImages);
  }

  private async _uploadLotsImages(images: LotImageModel[]): Promise<void> {
    this._notification.clear();
   
    let to_remove_index: number = 0;
    let to_upload_index: number = 0;
    const images_to_upload: LotImageModel[] = _.filter(images, { to_remove: false });
    const images_to_remove: LotImageModel[] = _.filter(images, { to_remove: true });

    for (const index in images) {
      if (images[index]) {
        let message: string = '';
        const image: LotImageModel = images[index];

        if (images_to_upload.length > 0) {
          message = `Subiendo imagen ${to_upload_index} de ${images_to_upload.length}`;
        }
;
        if (images_to_remove.length > 0) {
          message += `<br> Eliminando imagen ${to_remove_index} de ${images_to_remove.length}`;
        }

        this._notification.createOrUpdateInfoMessage(message);

        if (image.to_remove) {
          to_remove_index += 1;
          await this._lotService.removeBigBagImage(image)
            .catch(err => console.error(err));
        } else {
          to_upload_index += 1;
          await this._lotService.uploadBigBagImage(image)
            .catch(err => console.error(err));
        }
      }
    }

    this._notification.clear();
  }
}
