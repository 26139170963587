import { Component, OnInit, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

// Components
import { RootModalComponent } from 'src/app/components/modals/root-modal/root-modal.component';

// Models
import { UnloadModel } from 'src/app/models/unload.model';
import { CertificateModel } from 'src/app/models/certificate.model';
import { UserModel } from 'src/app/models/user.model';
import { CocoaTypeModel } from 'src/app/models/cocoa-type.model';
import { CocoaQualityModel } from 'src/app/models/cocoa_quality.model';
import { TicketUnloadState } from 'src/app/app.enum';

// Services
import { UnloadService } from 'src/app/services/unload.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-provisional-unload-form',
  templateUrl: './provisional-unload-form.component.html',
  styleUrls: ['./provisional-unload-form.component.scss']
})
export class ProvisionalUnloadFormComponent implements OnInit {
  @ViewChild(RootModalComponent) modal: RootModalComponent;
  @Input() certificates: CertificateModel[] = new Array<CertificateModel>();
  @Input() unloadManagers: UserModel[] = new Array<UserModel>();
  @Input() cocoaTypes: CocoaTypeModel[] = new Array<CocoaTypeModel>();
  @Input() cocoaQualities: CocoaQualityModel[] = new Array<CocoaQualityModel>();

  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };

  unload: UnloadModel = new UnloadModel();
  ticketUnloadState = TicketUnloadState;
  bigBagPage: number = 1;
  constructor(
    private unloadService: UnloadService,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('es');
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  ngOnInit() {
  }

  async openForm(unload: UnloadModel) {
    await this.getUnload(unload);
    this.openModal();
  }

  compareFn(a: any, b: any): boolean {
    return a['id'] === b;
  }

  openModal() {
    this.modal.show(this.modalOptions);
  }

  closeModal() {
    this.modal.hide();
  }

  drySelected(obj): boolean {
    const cocoa_type = _.find(this.cocoaTypes, { id: obj['cocoa_type_id'] }) as CocoaTypeModel;
    return cocoa_type.name.toLocaleLowerCase() === 'seco';
  }

  trackByFn(index: any, item: any) {
    return index;
  }


  private async getUnload(unload: UnloadModel) {
    await this.unloadService.getUnload(unload['id'])
      .then(
        async res => {
          this.unload = res['unload'];
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }
}
