import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';

// models
import { CertificateModel } from '../../../models/certificate.model';

// services
import { CertificateService } from '../../../services/certificate.service';

// Components
import { CertificateFormComponent } from '../modals/certificate-form/certificate-form.component';

@Component({
  selector: 'app-certificate',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss']
})
export class CertificatesPage implements OnInit {
  @ViewChild(CertificateFormComponent, {static: true}) certificateForm: CertificateFormComponent;

  certificates: Array<CertificateModel> = new Array<CertificateModel>();

  p = 1;

  loading: boolean;
  isCollapsed = false;

  constructor(private certificateService: CertificateService) { }

  ngOnInit() {
    this.getCertificates();
  }

  openForm(certificate: CertificateModel) {
    this.certificateForm.openForm(certificate);
  }

  deactivateActivateCertificate(certificate: CertificateModel) {
    certificate['active'] = !certificate['active'];
    this.certificateForm.updateCertificate(certificate);
  }

  setCertificates(certificates: CertificateModel[]): void {
    this.certificates = certificates;
  }

  private getCertificates() {
    this.loading = true;
    this.certificateService.getCertificates()
      .then(
        res => {
          this.certificates = res['certificates'];
          this.loading = false;
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }

}
