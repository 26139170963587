import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';

// Models
import { TicketModel } from "../models/ticket.model";

//Environment
import { environment } from '../../environments/environment';
import { TicketType } from '../app.enum';
import { ReceiptModel } from '../models/receipt.model';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  ticketsRoute = environment.routes.api + environment.routes.tickets;
  provenanceSheetRoute = environment.routes.provenance_sheet_info;

  constructor(
    private http: HttpClient
  ) { }

  getTickets(page: number = 1, per_page: number = 25, parameters: {} = {}) {
    let params = {
      'q[used_eq]': 'true',
      page: page.toString(),
      per_page: per_page.toString()
    };

    params = Object.assign(params, parameters);

    let options = {
      headers: this.getHeaders(),
      params: params
    };

    options = Object.assign(options, { responseType: params['format'] === 'json' ? 'json' : 'arraybuffer' });

    return new Promise((resolve, reject) => {
      this.http.get(`${this.ticketsRoute}/tickets`, options)
        .toPromise()
        .then(
          res => {
            if (params.hasOwnProperty('format') && params['format'] === 'xlsx') {
              resolve(res);
            } else {
              res['success'] ? resolve(res) : reject(res);
            }
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  createTicket(ticket: ReceiptModel) {
    return new Promise((resolve, reject) => {
      this.http.post(this.ticketsRoute, { ticket: ticket }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  updateTicket(ticket: ReceiptModel) {
    return new Promise((resolve, reject) => {
      this.http.put(
        `${this.ticketsRoute}/${ticket['id']}`, { ticket: ticket, state: 'update' }, { headers: this.getHeaders() }
      ).toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  deleteTicket(ticketId: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${this.ticketsRoute}/${ticketId}`)
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  getTicket(id: number) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.ticketsRoute}/${id}`, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  getTicketsByReceiverCenter(centerId: number) {
    return new Promise((resolve, reject) => {
      this.http.get(
        this.ticketsRoute, { headers: this.getHeaders(), params: { 'q[receiver_center_id_eq]': centerId.toString(), 'q[used_eq]': 'true' } }
      ).toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  deleteReceiptImage(ticket: ReceiptModel, type: string) {
    switch (type) {
      case 'receipt':
        return new Promise((resolve, reject) => {
          this.http.put(`${this.ticketsRoute}/${ticket['id']}/images/${ticket['remove_receipt']}/remove`, { ticket: {} }, { headers: this.getHeaders() }
          ).toPromise()
            .then(
              res => {
                res['success'] ? resolve(res) : reject(res);
              }
            )
            .catch(
              err => {
                reject(err);
              }
            );
        });
        break;
      case 'authorization':
        return new Promise((resolve, reject) => {
          this.http.put(`${this.ticketsRoute}/${ticket['id']}/images/${ticket['remove_authorization']}/remove`, { ticket: {} }, { headers: this.getHeaders() }
          ).toPromise()
            .then(
              res => {
                res['success'] ? resolve(res) : reject(res);
              }
            )
            .catch(
              err => {
                reject(err);
              }
            );
        });
        break;
    }

  }

  uploadImages(ticket: TicketModel, type: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(`${this.ticketsRoute}/${ticket['id']}/images/upload/${type}`, { ticket: ticket }, { headers: this.getHeaders() }
      ).toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  updateProvenanceSheet(ticket: TicketModel) {
    return new Promise((resolve, reject) => {
      this.http.put(
        `${this.ticketsRoute}/${ticket['id']}/${this.provenanceSheetRoute}`, { ticket: ticket }, { headers: this.getHeaders() }
      ).toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  getReports(kind: string) {
    return new Promise((resolve, reject) => {
      this.http.get(
        `${this.ticketsRoute}/reports/${kind}`, { headers: this.getHeaders(), responseType: 'arraybuffer' }
      ).toPromise()
        .then(
          res => {
            resolve(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  // getTicketsFromADateToday(date: number) {
  //   return this.afstore
  //     .list<TicketModel>("tickets", ref =>
  //       ref.orderByChild('dateCreated').startAt(date)
  //     )
  //     .snapshotChanges()
  //     .pipe(
  //       map(actions => {
  //         return actions.map(a => {
  //           const data = a.payload.val();
  //           const key = a.payload.key;
  //           return { key, ...data };
  //         });
  //       })
  //     );
  // }

  // getTickets = (params: any = {}) => {
  //   const dateCreated = parseInt(params['dateCreated']);

  //   return this.afstore
  //     .list<TicketModel>('tickets', ref => ref.orderByChild('dateCreated').startAt(dateCreated))
  //     .snapshotChanges()
  //     .pipe(
  //       map(actions => {
  //         const tickets = actions.map(
  //           (action) => {
  //             const data = action.payload.val(),
  //               key = action.payload.key;
  //             return { key, ...data };
  //           }
  //         );

  //         delete params['dateCreated'];
  //         return _.orderBy(_.filter(tickets, params), (ticket) => {
  //           return new Date(ticket['dateCreated'])
  //         }, ['desc']);
  //       })
  //     )
  // }

  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('yacao:app:token'),
        'user_id': localStorage.getItem('yacao:app:user_id')
      }
    );
  }
}
