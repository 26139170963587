<!-- NAVBAR -->
<nav class="navbar fixed-top navbar-expand-md navbar-light bg-light">
  <a *ngIf="globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor || globals.currentUserRolesAsBooleanObject.manager"
    class="navbar-brand" [routerLink]="['tickets']">
    <a *ngIf="globals.currentUserRolesAsBooleanObject.certification_manager" class="navbar-brand"
      [routerLink]="['contacts']"></a>
    <img src="/assets/images/logo-yacao.png" height="30" class="d-inline-block
      align-top" />
  </a>

  <button class="navbar-toggler hidden-sm-up" type="button" (click)="isNavbarCollapsed= !isNavbarCollapsed"
    data-target="#navbarsDefault" aria-controls="navbarsDefault" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault">
    <ul class="navbar-nav mr-auto">
      <li [routerLinkActive]="['active']" class="nav-item" [class.active_path]="isCurrentPath('/contracts')"
        *ngIf="globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor || globals.currentUserRolesAsBooleanObject.buyer">
        <a class="nav-link" [routerLink]="['/contracts']" *ngIf="!isVisible">
          {{ 'navbar.purchase_agreements.current_value' | translate}}
        </a>

        <button class="btn btn-sm text-primary" (click)="openTranslationForm()" *ngIf="isVisible">
          <label for="translation" id="translation">
            {{ 'navbar.purchase_agreements.current_value' | translate}}
          </label>
        </button>
      </li>

      <li [routerLinkActive]="['active']" class="nav-item" [class.active_path]="isCurrentPath('/tickets')"
        *ngIf="globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor || globals.currentUserRolesAsBooleanObject.manager || globals.currentUserRolesAsBooleanObject.buyer">
        <a class="nav-link" [routerLink]="['/tickets']" *ngIf="!isVisible">
          {{ 'navbar.cocoa_deliveries.current_value' | translate}}
        </a>
        <button class="btn btn-sm text-primary" (click)="openTranslationForm()" *ngIf="isVisible">
          <label for="translation" id="translation">
            {{ 'navbar.cocoa_deliveries.current_value' | translate}}
          </label>
        </button>
      </li>

      <!-- <li [routerLinkActive]="['active']" class="nav-item" [class.active_path]="isCurrentPath('/payments')"
        *ngIf="globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor">
        <a class="nav-link" [routerLink]="['/payments']">
          Pagos
        </a>
      </li> -->

      <li [routerLinkActive]="['active']" class="nav-item" [class.active_path]="isCurrentPath('/unloads')"
        *ngIf="globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor || globals.currentUserRolesAsBooleanObject.manager">
        <a class="nav-link" [routerLink]="['/unloads']" *ngIf="!isVisible">
          {{ 'navbar.unloads.current_value' | translate}}
        </a>
        <button class="btn btn-sm text-primary" (click)="openTranslationForm()" *ngIf="isVisible">
          <label for="translation" id="translation">
            {{ 'navbar.unloads.current_value' | translate}}
          </label>
        </button>
      </li>

      <li [routerLinkActive]="['active']" class="nav-item" [class.active_path]="isCurrentPath('/talonary-books')">
        <a class="nav-link" [routerLink]="['/talonary-books']"
          *ngIf="globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor || globals.currentUserRolesAsBooleanObject.manager">
          Talonarios
        </a>
        <button class="btn btn-sm text-primary" (click)="openTranslationForm()" *ngIf="isVisible">
          <label for="translation" id="translation">
            {{ 'navbar.talonaries.current_value' | translate}}
          </label>
        </button>
      </li>

      <li [routerLinkActive]="['active']" class="nav-item" [class.active_path]="isCurrentPath('/suppliers')"
        *ngIf="globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor">
        <a class="nav-link" [routerLink]="['/suppliers']" *ngIf="!isVisible">
          {{ 'navbar.suppliers.current_value' | translate}}
        </a>
        <button class="btn btn-sm text-primary" (click)="openTranslationForm()" *ngIf="isVisible">
          <label for="translation" id="translation">
            {{ 'navbar.suppliers.current_value' | translate}}
          </label>
        </button>
      </li>

      <li [routerLinkActive]="['active']" class="nav-item" [class.active_path]="isCurrentPath('/delivery-groups')"
        *ngIf="globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor || globals.currentUserRolesAsBooleanObject.certification_manager">
        <a class="nav-link" [routerLink]="['/delivery-groups']" *ngIf="!isVisible">
          {{ 'navbar.delivery_groups.current_value' | translate}}
        </a>
        <button class="btn btn-sm text-primary" (click)="openTranslationForm()" *ngIf="isVisible">
          <label for="translation" id="translation">
            {{ 'navbar.delivery_groups.current_value' | translate}}
          </label>
        </button>
      </li>

      <li [routerLinkActive]="['active']" class="nav-item" [class.active_path]="isCurrentPath('/contacts')"
        *ngIf="globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor || globals.currentUserRolesAsBooleanObject.certification_manager">
        <a class="nav-link" [routerLink]="['/contacts']" *ngIf="!isVisible">
          {{ 'navbar.contacts.current_value' | translate}}
        </a>
        <button class="btn btn-sm text-primary" (click)="openTranslationForm()" *ngIf="isVisible">
          <label for="translation" id="translation">
            {{ 'navbar.contacts.current_value' | translate}}
          </label>
        </button>
      </li>

      <li [routerLinkActive]="['active']" class="nav-item" [class.active_path]="isCurrentPath('/exportations')"
        *ngIf="globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor">
        <a class="nav-link" [routerLink]="['/exportations']" *ngIf="!isVisible">
          {{ 'navbar.exports.current_value' | translate}}
        </a>
        <button class="btn btn-sm text-primary" (click)="openTranslationForm()" *ngIf="isVisible">
          <label for="translation" id="translation">
            {{ 'navbar.exports.current_value' | translate}}
          </label>
        </button>
      </li>



      <li [routerLinkActive]="['active']" class="options-dropdown nav-item dropdown" ngbDropdown
        *ngIf="globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor">
        <a class="nav-link dropdown-toggle" id="admin" ngbDropdownToggle style="cursor: pointer;">
          {{ 'navbar.administration.current_value' | translate}}
        </a>

        <div class="dropdown-menu" aria-labelledby="admin" ngbDropdownMenu>
          <a class="dropdown-item" [routerLink]="['/roles']" *ngIf="globals.currentUserRolesAsBooleanObject.admin"
            [class.active_path]="isCurrentPath('/roles')">
            {{ 'navbar.roles.current_value' | translate}}
          </a>

          <a class="dropdown-item" [routerLink]="['/cocoa-types']" [class.active_path]="isCurrentPath('/cocoa-types')"
            *ngIf="globals.currentUserRolesAsBooleanObject.admin">
            {{ 'navbar.cocoa_types.current_value' | translate}}
          </a>

          <a class="dropdown-item" [routerLink]="['/talonaries']" [class.active_path]="isCurrentPath('/talonaries')"
            *ngIf="globals.currentUserRolesAsBooleanObject.admin">
            {{ 'navbar.talonary_types.current_value' | translate}}
          </a>

          <a class="dropdown-item" [routerLink]="['/certificates']" [class.active_path]="isCurrentPath('/certificates')"
            *ngIf="globals.currentUserRolesAsBooleanObject.admin">
            {{ 'navbar.certificates.current_value' | translate}}
          </a>

          <a class="dropdown-item" [routerLink]="['/centers']" [class.active_path]="isCurrentPath('/centers')"
            *ngIf="globals.currentUserRolesAsBooleanObject.admin">
            {{ 'navbar.centers.current_value' | translate}}
          </a>

          <a class="dropdown-item" [routerLink]="['/addons']" [class.active_path]="isCurrentPath('/addons')"
            *ngIf="globals.currentUserRolesAsBooleanObject.admin">
            {{ 'navbar.incentives.current_value' | translate}}
          </a>

          <a class="dropdown-item" [routerLink]="['/seasons']" [class.active_path]="isCurrentPath('/seasons')"
            *ngIf="globals.currentUserRolesAsBooleanObject.admin">
            {{ 'navbar.seasons.current_value' | translate}}
          </a>

          <a class="dropdown-item" [routerLink]="['/trade-prices']"
            [class.active_path]="isCurrentPath('/trade-prices')">
            {{ 'navbar.prices.current_value' | translate}}
          </a>

          <a class="dropdown-item" [routerLink]="['/zone-groups']" [class.active_path]="isCurrentPath('/zone-groups')"
            *ngIf="globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor"
            [routerLinkActive]="['active']">
            {{ 'navbar.zone_groups.current_value' | translate}}
          </a>

          <a class="dropdown-item" [routerLink]="['/zones']" [class.active_path]="isCurrentPath('/zones')"
            *ngIf="globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor"
            [routerLinkActive]="['active']">
            {{ 'navbar.zones.current_value' | translate}}
          </a>

          <a class="dropdown-item" [routerLink]="['/users']" [class.active_path]="isCurrentPath('/users')"
            *ngIf="globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor"
            [routerLinkActive]="['active']">
            {{ 'navbar.users.current_value' | translate}}
          </a>

          <a class="dropdown-item" [routerLink]="['/concepts']" [class.active_path]="isCurrentPath('/concepts')"
            *ngIf="globals.currentUserRolesAsBooleanObject.admin" [routerLinkActive]="['active']">
            {{ 'navbar.payments_concepts.current_value' | translate}}
          </a>

          <a class="dropdown-item" [routerLink]="['/translate']" [class.active_path]="isCurrentPath('/translate')"
            *ngIf="globals.currentUserRolesAsBooleanObject.admin" [routerLinkActive]="['active']">
            {{ 'navbar.translation.current_value' | translate}}
          </a>

          <a class="dropdown-item" (click)="getReportOf('addons')"
            *ngIf="globals.currentUserRolesAsBooleanObject.admin">
            {{ 'navbar.incentives_report_2018.current_value' | translate}}
          </a>

          <a class="dropdown-item" (click)="editSettings()"
            *ngIf="globals.currentUserRolesAsBooleanObject.admin">
            {{ 'navbar.settings.current_value' | translate}}
          </a>
        </div>
      </li>

    </ul>

    <!-- <div class="row my-2">
      <div class="col">
        <button class="mx-2" (click)="translateMode()">LEM</button>
        <button class="mx-2" (click)="useLanguage('en')">en</button>
        <button class="mx-2" (click)="useLanguage('es')">es</button>
      </div>
    </div> -->

    <div [routerLinkActive]="['active']" id="navbarSupportedContent">
      <button (click)="logout()" class="btn btn-danger" *ngIf="!isVisible">
        <!-- <mat-icon class="position-relative top-5">exit_to_app</mat-icon> -->
        <i class="fa fa-sign-out"></i>
        {{ 'buttons.button_logout.current_value' | translate}}
      </button>

      <button class="btn btn-danger" (click)="openTranslationForm()" *ngIf="isVisible">
        <label for="translation" id="translation">
          <i class="fa fa-sign-out"></i>
          {{ 'buttons.button_logout.current_value' | translate}}
        </label>
      </button>
    </div>
  </div>
</nav>
<app-translation-edit-modal></app-translation-edit-modal>
<app-setting-form></app-setting-form>