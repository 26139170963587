<!-- MODAL WITH IMAGES FOR TICKETS AND SHEET -->
<app-root-modal>
  <div class="modal-header">
    <!-- TÍTULO HOJA DE PROCEDENCIA -->
    <h4 class="modal-title">
      <div class="row">
        <div class="col">
          {{ 'pages.tickets_forms.provenance_form' | translate}}
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          {{ 'pages.tickets_forms.talbook_sheet_prefix' | translate}}{{ ticket.talbook_sheet }}
        </div>

        <div class="col-4">
          {{ 'pages.tickets_forms.supplier' | translate}}{{ ticket.contact_id ? ticket.supplier?.full_name : 'no asignado' }}
        </div>

        <div class="col-4">
          {{ 'pages.tickets_forms.certificate' | translate}}{{ticket.certificate?.name}}
        </div>
      </div>
    </h4>


    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">
        &times;
      </span>
    </button>
  </div>

  <!-- HOJA DE PROCEDENCIA Y PRODUCTORES -->
  <div class="modal-body modal-provenance-body" style="display: block;">
    <div class="filtersContainer">
      <div class="container">
        <div class="row justify-content-md-center">

          <!-- <div class="col-3 text-left">
            <label>
              Nombre productor
            </label>

            <ng-select [items]="provenanceSheetProducers" [searchable] [hideSelected]="true"
              notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="fullName" name="producer"
              placeholder="Productor" [multiple]="false" (change)="filterProducersBy($event)">
            </ng-select>
          </div>

          <div class="col-3 text-left">
            <label>
              Apodo
            </label>

            <ng-select [items]="provenanceSheetProducers" [searchable] [hideSelected]="true"
              notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="nickname" name="producerAlias"
              placeholder="Apodo del productor" [multiple]="false" (change)="filterProducersBy($event)">
            </ng-select>
          </div> -->
          <!-- <button  class="btn btn-outline-dark btn-sm" style="float: right;" (click)="addNewColumnToProvenanceSheetProducers()">
            <span class="oi oi-plus"></span>
            Añadir productor
          </button> -->
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-5" appNotRightClick>
        <div class="col-12 mt-2 mb-2 p-0" *ngIf="!ticket.finished && provenanceSheetImgs.length > 0">
          <button class="btn btn-danger btn-sm btn-block" (click)="removeImage(ticket)"
            [disabled]="((globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor) && (ticket.billed || ticket.finished))
              || (!globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor && (ticket.billed || ticket.reviewed || ticket.finished))">
            Quitar imagen
          </button>
        </div>

        <div class="col-12 mt-2 mb-2 p-0">
          <button class="btn btn-success btn-sm btn-block"
          [disabled]="((globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor) && (ticket.billed || ticket.finished))
              || (!globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor && (ticket.billed || ticket.reviewed || ticket.finished))">
            Subir imagenes
            <input type="file" name="sheetstImageUpload" class="upload-image-input" accept="image/*" (change)="setSheetsImages($event)" multiple>
          </button>
        </div>

        <div class="col-12">
          <div class="row">
            <angular-image-viewer *ngIf="provenanceSheetImgs.length > 0" [src]="provenanceSheetImgs"
              [(index)]="imageIndex" [config]="{ wheelZoom: true }"></angular-image-viewer>
          </div>

          <div class="d-flex justify-content-center row font-weight-bold" *ngIf="provenanceSheetImgs.length > 0">
            {{ imageIndex + 1 }}/{{ provenanceSheetImgs.length }}
          </div>
          <!-- <app-image-viewer [images]="provenanceSheetImgs" [loadOnInit]="true" [showPDFOnlyOption]="false" primaryColor="#168a41"
            [download]="false" [idContainer]="'sheetImages'" [fullscreen]="false">
          </app-image-viewer> -->


        </div>
      </div>

      <div class="col-7">
        <div class="col-12">
          <div class="table-responsive" #provenanceSheetDatas>
            <table class="table table-striped table-hover table-sm">
              <thead>
                <tr class="green-success">
                  <th>{{ 'pages.tickets_forms.code' | translate}}</th>
                  <th>{{ 'pages.tickets_forms.aka' | translate}}</th>
                  <th>{{ 'pages.tickets_forms.name' | translate}}</th>
                  <th>{{ 'pages.tickets_forms.group' | translate}}</th>
                  <th>
                    {{ 'pages.tickets_forms.quota_limit' | translate}}
                  </th>
                  <th>{{ 'pages.tickets_forms.units' | translate}}</th>
                  <th>
                    <button class="btn btn-primary" (click)="addNewColumnToProvenanceSheetProducers()"
                      [disabled]="ticket.billed || ticket.provenance_sheet_reviewed">
                      <mat-icon class="position-relative top-5 font-weight-bold">add</mat-icon>
                    </button>
                  </th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr
                  *ngFor="let producer of ticket.provenance_sheet_info?.producers_info | paginate: { itemsPerPage: producersPerPage, currentPage: producerPage, id: 'modal-paginator' }; let i= index; trackBy:trackByFn;">
                  <td style="min-width: 100px">
                    <ng-select [items]="provenanceSheetContacts" [searchable] notFoundText="No hay coincidencias"
                      loadingText="Buscando" bindLabel="code" [clearable]="false" placeholder="Código Productor"
                      [multiple]="false"
                      [(ngModel)]="ticket.provenance_sheet_info.producers_info[globals.getAbsoluteIndex(producersPerPage, producerPage, i)]"
                      (change)="hideSelectedContacts()" [disabled]="ticket.billed || ticket.provenance_sheet_reviewed">
                    </ng-select>
                  </td>
                  <td>{{producer?.nickname}}</td>
                  <td>{{producer?.full_name}}</td>
                  <td>
                    <div *ngFor="let groups of producer?.producer_in_delivery_groups">
                      {{groups?.name}}
                    </div>
                  </td>
                  <td>{{getQuotaOf(producer, ticket.certificate)}}</td>
                  <td style="max-width: 100px !important; min-width: 80px !important;">
                    <input type="number" name="producerWeight" class="form-control input-sm"
                      (input)="addProducerAndTotalWeight($event, globals.getAbsoluteIndex(producersPerPage, producerPage, i))" min="0"
                      [disabled]="!ticket.provenance_sheet_info?.producers_info[globals.getAbsoluteIndex(producersPerPage, producerPage, i)]?.id || ticket.billed || ticket.provenance_sheet_reviewed"
                      [value]="ticket.provenance_sheet_info?.producers_info[globals.getAbsoluteIndex(producersPerPage, producerPage, i)]?.weight" />
                  </td>

                  <td>
                    <button class="btn btn-danger"
                      (click)="deleteFromProvenanceSheetProducers(globals.getAbsoluteIndex(producersPerPage, producerPage, i))"
                      [disabled]="ticket.billed || ticket.provenance_sheet_reviewed">
                      <mat-icon class="position-relative top-5">delete</mat-icon>
                    </button>
                  </td>
                  <td>
                    <div class="col">
                      <div class="row">
                        <button class="btn btn-sm btn-light" (click)="changeProducerPosition(ticket, globals.getAbsoluteIndex(producersPerPage, producerPage, i), -1)" [disabled]="false">
                          <mat-icon class="">keyboard_arrow_up</mat-icon>
                        </button>
                      </div>
                      <div class="row">
                        <button class="btn btn-sm btn-light" (click)="changeProducerPosition(ticket, globals.getAbsoluteIndex(producersPerPage, producerPage, i), 1)" [disabled]="false">
                          <mat-icon class="">keyboard_arrow_down</mat-icon>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-12">
          <div class="paginationContainer">
            <pagination-controls autoHide="true" id="modal-paginator" responsive="true" previousLabel="Anterior"
              nextLabel="Siguiente" (pageChange)="producerPage= $event;">
            </pagination-controls>
          </div>
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col-4 offset-8">
              <div class="custom-form-group form-group">
                <label>{{ 'pages.tickets_forms.total' | translate}}</label>
                <input disabled type="number" name="kgTotal" class="form-control"
                  [value]="fixedNumberTo(ticket.provenance_sheet_info?.total_weight)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="setProvenanceSheetReviewed(ticket)"
      [disabled]="sending || ticket.billed || ticket.finished">
      {{ (ticket.provenance_sheet_reviewed ? 'pages.tickets_forms.button_forward_provenance' : 'pages.tickets_forms.button_review_provenance') | translate}}
    </button>

    <button class="btn btn-primary" (click)="onSubmit(ticket)"
      [disabled]="sending || ticket.provenance_sheet_reviewed || ticket.billed || ticket.finished">
      Guardar
    </button>

    <button class="btn btn-danger" (click)="closeModal()" name="updateTicket" [disabled]="sending">
      {{ 'pages.tickets_forms.button_cancel' | translate}}
    </button>
  </div>
</app-root-modal>