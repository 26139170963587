
<!-- Modal to create contact -->
<ng-template #content let-modal let-d="dismiss">
  <form #Form="ngForm" (ngSubmit)="Form.form.valid && onSubmit(contact)">

    <div class="modal-header">
      <h4 *ngIf="!contact.id" class="modal-title">Crear contacto</h4>
      <h4 *ngIf="contact.id" class="modal-title">Actualizar contacto</h4>

      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>

    </div>

    <div class="modal-body">
      <!-- Personal information-->
      <div class="col-12 mb-3">
        <h4 class="d-block">Informacion personal</h4>
        <div class="col-12">
          <!-- Name -->
          <div class="form-group col-5">
            <label for="contactNameId">Nombre del contacto</label>
            <input type="text" #contactName="ngModel" class="form-control" id="contactNameId" [(ngModel)]="contact.name"
              placeholder="Name" name="contactName">
          </div>
          <!-- Surname -->
          <div class="form-group col-5">
            <label for="contactSirNameId">Apellidos</label>

            <input type="text" #contactSurName="ngModel" class="form-control" id="contactSirNameId"
              [(ngModel)]="contact.surname" placeholder="Surname" name="contactSurname"
              [ngClass]="{ 'is-invalid': Form.submitted && contactSurName.invalid}" required>

            <div *ngIf="Form.submitted && contactSurName.invalid" class="invalid-tooltip">
              <div *ngIf="contactSurName.errors.required">El apellido es obligatorio</div>
            </div>
          </div>

          <!-- NickName -->
          <div class="form-group col-5">
            <label for="contactNicknameId">Apodo</label>

            <input type="text" class="form-control" id="contactNicknameId" [(ngModel)]="contact.nickname"
              placeholder="Nickname" name="contactNickname">
          </div>

          <!-- Email -->

          <div class="form-group col-5">
            <label for="contactEmailId">Email</label>

            <input type="email" class="form-control" id="contactEmailId" [(ngModel)]="contact.email"
              placeholder="ej: contact@yacao.com" name="contactEmail"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$">
          </div>

          <!-- Phone number -->
          <div class="form-group col-5">
            <label for="contactPhoneId">Teléfono de contacto</label>

            <input type="tel" class="form-control" id="contactPhoneId" [(ngModel)]="contact.phone" placeholder="Phone"
              name="contactPhone">
          </div>

          <!-- Nº of cedula -->
          <div class="form-group col-5">
            <label for="contactCedulaId">Cedula de identificación</label>

            <input type="tel" class="form-control" id="contactCedulaId" [(ngModel)]="contact.cedula"
              placeholder="Cedula" name="contactCedula">
          </div>
        </div>
      </div>

      <!-- Profesional information -->

      <div class="col-12 mb-2">
        <h4 class="d-block">Información profesional</h4>
        <!-- Code -->
        <div class="form-group col-5 d-inline-block">
          <label for="contactCodeId">Código</label>

          <input type="text" #code="ngModel" class="form-control col-xs-4" id="contactCodeId" [(ngModel)]="contact.code"
            placeholder="Code" name="contactCode"
            [ngClass]="{ 'is-invalid': (Form.submitted && code.invalid)|| (Form.submitted && codeExist(contact))}"
            required>

          <!-- Errors display-->
          <div *ngIf="Form.submitted && code.invalid" class="invalid-tooltip">
            <div *ngIf="code.errors && code.errors.required">El código es obligatorio</div>
          </div>

          <div *ngIf="Form.submitted && codeExist(contact)" class="invalid-tooltip">
            <div>El codigo existe</div>
          </div>
        </div>

        <!-- Producer in-->
        <div class="form-group col-5 inline-block">
          <label for="deliveryGroupProducerId">Es productor en:</label>

          <ng-select id="deliveryGroupProducerId" [items]="deliveryGroups" [searchable] [clearSearchOnAdd]
            [hideSelected]="true" notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name"
            [(ngModel)]="contact.producer_in" name="contactDeliveryGroupProducer" placeholder="Selecciona el grupo"
            [compareWith]="compareFn" [multiple]="false">
          </ng-select>
        </div>

        <!-- Contact zone -->
        <div class="form-group col-5 d-inline-block">
          <label for="ContactZoneId">
            Zona
          </label>

          <ng-select id="ContactZoneId" [items]="zones" [searchable] [clearSearchOnAdd]
            notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name" [(ngModel)]="contact.zones"
            name="ContactZones" placeholder="Selecciona las zonas" [compareWith]="compareFn" [multiple]="false"
            [disabled]="!contact.producer_in && !contact.supplier">
          </ng-select>
        </div>

        <!-- Contact image -->
        <!-- <div class="col-5 d-inline-block">
          <label class="d-inline-block">Foto del contacto</label>
          <div class="custom-file form-group col-12">
            <input type="file" class="custom-file-input" id="validatedCustomFile" name="avatar"
              (change)="addAvatar($event)" accept="image/*">
            <label class="custom-file-label" for="validatedCustomFile">
              {{contactAvatar ? contactAvatar.name : 'Seleccione una foto...'}}
            </label>

            <div class="invalid-feedback">
              Archivo seleccionado invalido
            </div>
          </div>
        </div> -->

        <!-- Certificate info-->
        <div class="col-12">
          <div class="form-group position-relative align-top col-5 d-inline-block p-0">
            <label for="contactListCertificateId" class="d-block">Certificados Orgánicos</label>
            <div *ngFor="let list of listCertificates; let i= index" class="d-inline-block col-12">
              <mat-checkbox [checked]="selectCertificate(contact, list, 'list')" name="contactListCertificate"
                (change)="addCertificateToContact($event, contact, list, 'list')">
                {{ list.name }}
              </mat-checkbox>
            </div>
          </div>

          <div class="form-group position-relative align-top col-5 d-inline-block">
            <label for="contactBoolCertificateId" class="d-block">Certificados Sociales</label>
            <div *ngFor="let bool of boolCertificates; let i= index" class="d-inline-block col-12">
              <mat-checkbox [checked]="selectCertificate(contact, bool, 'bool')" name="contactBoolCertificate"
                (change)="addCertificateToContact($event, contact, bool, 'bool')">
                {{ bool.name }}
              </mat-checkbox>
            </div>
          </div>

          <div *ngIf="contact.list_certificates && contact.list_certificates.length> 0" class="col-12 p-0">
            <div *ngFor="let list of contact.list_certificates; let i= index;"
              [className]="(list.alias === 'conv.') ? 'd-none' : 'col-4 d-inline-block'">
              <div *ngIf="list.alias !== 'conv.'">
                <label>
                  Max. cuota {{list.name.toLowerCase()}}
                </label>

                <input class="form-control" type="number" min="0" name="quota" placeholder="Ej: 200"
                  [value]="list.quota" (change)="setQuotaToCertificate($event.target.value, list)">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <h4 class="col-12 ml-3">Información adicional</h4>
        <div class="col-3 ml-4 mt-1">
          <mat-checkbox [(ngModel)]="contact.active_as_contact" name="contactIsActive">
            Activo
          </mat-checkbox>
        </div>

        <div class="col-3 mt-1">
          <mat-checkbox [(ngModel)]="contact.fundopo" name="contactIsFundopo">
            FUNDOPO
          </mat-checkbox>
        </div>

        <div class="col-3 mt-1">
          <mat-checkbox [(ngModel)]="contact.processor" name="contactIsProcessor">
            Procesa producto
          </mat-checkbox>
        </div>

      </div>
    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" name="submitContact" [disabled]="sending">
        {{ contact.id ? 'Guardar' : 'Crear' }}
      </button>

      <button type="button" class="btn btn-danger" (click)="d('button click')" name="closeModal" [disabled]="sending">
        Cancelar
      </button>
    </div>
  </form>
</ng-template>