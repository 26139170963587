import { Component, OnInit, ViewChild } from '@angular/core';

import * as _ from 'lodash';


// Models
import { ZoneModel } from '../../../models/zone.model';
import { ZoneGroupModel } from '../../../models/zone-group.model';

// Services
import { ZoneService } from '../../../services/zone.service';
import { ZoneGroupService } from '../../../services/zone-group.service';

// Components
import { ZoneFormComponent } from '../modals/zone-form/zone-form.component';


@Component({
  selector: 'app-zones',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss']
})
export class ZonesPage implements OnInit {
  @ViewChild(ZoneFormComponent, { static: true }) zoneForm: ZoneFormComponent;

  zones: Array<ZoneModel> = new Array<ZoneModel>();
  zoneGroups: Array<ZoneGroupModel> = new Array<ZoneGroupModel>();
  p: number = 0;

  loading: boolean;
  isCollapsed = false;

  constructor(
    private zoneService: ZoneService,
    private zoneGroupService: ZoneGroupService,
  ) { }

  ngOnInit() {
    this.getZones();
    this.getZoneGroups();
  }

  getZones() {
    this.loading = true;
    this.zoneService.getZones()
      .then(
        res => {
          this.zones = res['zones'];
          this.loading = false;
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }

  getZoneGroups() {
    const params = {
      'q[active_eq]': true
    };

    this.zoneGroupService.getZoneGroups(params)
      .then(
        res => {
          this.zoneGroups = res['zone_groups'];
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }

  deactivateActivateZone(zone: ZoneModel) {
    zone['active'] = !zone['active'];
    this.zoneForm.updateZone(zone);
  }

  openForm(zone: ZoneModel): void {
    this.zoneForm.openForm(zone);
  }

  setZones(zones: ZoneModel[]): void {
    this.zones = zones;
  }
}
