import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../models/user.model';

//Environment
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userRoute = environment.routes.api + environment.routes.users;

  constructor(private http: HttpClient) { }

  createUser(user: UserModel) {
    return new Promise((resolve, reject) => {
      this.http.post(this.userRoute, { user: user }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  getUsers(page: number = 1, per_page: number = 25, parameters: {} = {}) {
    let params = {
      page: page.toString(),
      per_page: per_page.toString()
    };

    params = Object.assign(params, parameters);

    let options = {
      params: params,
      headers: this.getHeaders()
    };

    options = Object.assign(options, { responseType: params['format'] === 'json' ? 'json' : 'arraybuffer' });

    return new Promise(
      (resolve, reject) => {
        this.http.get(this.userRoute, options)
          .toPromise()
          .then(
            res => {
              if (params.hasOwnProperty('format') && params['format'] === 'xlsx') {
                resolve(res);
              } else {
                console.log(res);
                res['success'] ? resolve(res) : reject(res);
              }
            }
          )
          .catch(
            err => {
              reject(err);
            }
          );
      }
    );
  }

  getUsersBy(role: string) {
    return new Promise((resolve, reject) => {
      this.http.get(this.userRoute, { params: { 'q[role_alias]': role }, headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  getUsersByCenter(center_id: number) {
    return new Promise((resolve, reject) => {
      this.http.get(this.userRoute, { params: { 'q[center_id_eq]': center_id.toString() }, headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  // getUserByEmail(email) {
  //   return this.afstore
  //     .list('users', ref => ref.orderByChild('email').equalTo(email))
  //     .snapshotChanges()
  //     .pipe(
  //       map(actions => {
  //         return actions.map(a => {
  //           const data = a.payload.val() as UserModel;
  //           const uid = a.payload.key;
  //           return { uid, ...data };
  //         });
  //       })
  //     );
  // }

  deleteUser(userId: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${this.userRoute}/${userId}`, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }


  updateUser(user: UserModel) {
    return new Promise((resolve, reject) => {
      this.http.put(`${this.userRoute}/${user['id']}`, { user: user }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  getUserBy(id: string) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.userRoute}/${id}`, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  generatePincode(id: string) {
    return new Promise((resolve, reject) => {
      this.http.put(`${this.userRoute}/${id}/pincode`, { user: null }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + (localStorage.getItem('yacao:app:token') || ""),
        'user_id': localStorage.getItem('yacao:app:user_id') || ""
      }
    );
  }

}
