
<app-navbar></app-navbar>
<div class="row">
  <div class="col">
    <div class="filtersContainer">
      <div class="row">
        <div class="col-3">
          <h5 class="text-dark font-weight-light">
            Listado de certificados
          </h5>
        </div>
        <div class="col-3 offset-3">
          <button type="button" class="btn btn-info btn-block" (click)="openForm(null)">
            <!-- <mat-icon class="position-relative top-5 font-weight-bold">add</mat-icon> -->
            <i class="fa fa-plus"></i>
            Nuevo certificado
          </button>
        </div>
        <!-- <div class="col-3">
          <button type="button" class="btn btn-outline-dark btn-block"
          (click)="exportToExcel(certificates,'certificados')" [disabled]="true">
            <i class="fa fa-download"></i>
            Descargar Excel
          </button>
        </div> -->
      </div>
    </div>
  </div>
</div>

<div *ngIf="!loading">
  <table class="table sticky-yacao green table-striped table-hover table-sm">
    <thead>
      <tr>
        <th>#</th>
        <th>Tipo</th>
        <th>Nombre</th>
        <th>Descripcion</th>
        <th>Activo</th>
        <th>Acciones</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let certificate of certificates | paginate: { itemsPerPage: 15, currentPage: p }; let i= index;">
        <td>{{i + 1}}</td>
        <td>{{certificate.certificate_type?.list ? 'Certificado Orgánico' : 'Certificado Social' }}</td>
        <td>{{certificate.name}}</td>
        <td>{{certificate.description}}</td>
        <td>
          <mat-icon class="font-weight-bold">
            {{ certificate.active ? 'check' : 'close'}}
          </mat-icon>
        </td>
        <td>
          <div class="col-12">
            <div class="nav-item dropdown" ngbDropdown>
              <a class="btn btn-success btn-sm dropdown-toggle white" ngbDropdownToggle>
                Selecciona acción
              </a>

              <div class="dropdown-menu" aria-labelledby="admin" ngbDropdownMenu>
                <button class="btn btn-sm dropdown-item" (click)="openForm(certificate)">
                  <i class="fa fa-edit text-primary"></i>
                  Editar
                </button>

                <button class="btn btn-sm dropdown-item"
                  (click)="deactivateActivateCertificate(certificate)">
                  <i class="fa fa-trash text-danger"></i>
                  {{ certificate.active ? 'Desactivar' : 'Activar' }}
                </button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="loading" *ngIf="loading">
  <ng-template #loadingTemplate></ng-template>
  <ngx-loading [show]="loading" [template]="loadingTemplate"></ngx-loading>
</div>

<div class="paginationContainer" *ngIf="!loading">
  <pagination-controls autoHide="true" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
    (pageChange)="p= $event"></pagination-controls>
</div>

<app-certificate-form [certificates]="certificates" (result)="setCertificates($event)"></app-certificate-form>