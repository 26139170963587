import { Injectable } from '@angular/core';
import * as _ from 'lodash';

// Models
import { CertificateModel } from '../models/certificate.model';
import { ContactModel } from '../models/contact.model';
import { DeliveryGroupModel } from '../models/delivery-group.model';
// import { ConsoleReporter } from 'jasmine';

declare var jsPDF: any;

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  marginTop = 40;
  marginRight = 40;
  marginBottom = 40;
  marginLeft = 40;
  standardFontSize = 12;
  pageWidth: any;
  maxCellWidth: 20;

  constructor() { }

  downloadPDF(deliveryGroup: DeliveryGroupModel, utzId: number, season: any = {}) {
    const doc = new jsPDF('p', 'pt', 'letter'),
      totalPagesExp = '{total_pages_count_string}',
      maxRows = 22;
    // maxRows = (heightOfHeader + (heightOfRow * (numberOfRows % maxNumberOfRowsInPage)));
    // maxRows = (160 + ( * ( % )));

    let cont = 0,
      certificateName,
      certificateAlias,
      haveUTZCert = !!_.find(deliveryGroup['bool_certificates'], { alias: 'utz' }),
      columns = [],
      rows = [],
      certificateId: number;

    const ffl = !!_.find(deliveryGroup['bool_certificates'], { alias: 'ffl' });
    const flo = !!_.find(deliveryGroup['bool_certificates'], { alias: 'flo' });

    this.pageWidth = doc.internal.pageSize.getWidth();

    const pageContent = (data) => {
      this.addHeader(doc,
        data,
        deliveryGroup['name'],
        certificateName,
        certificateAlias,
        flo,
        ffl,
        haveUTZCert,
        totalPagesExp,
        deliveryGroup,
        certificateId,
        season['name']);
    };

    const options = {
      theme: 'plain',
      didDrawPage: pageContent,
      margin: { top: 30 },
      startY: 160,
      pageBreak: 'avoid',
      showHead: 'everyPage',
      styles: {
        lineColor: 0,
        lineWidth: 1,
        valign: 'middle',
        halign: 'center'
      },
      headerStyles: {
        fillColor: 0,
        textColor: 255
      },
      bodyStyles: {
        overflow: 'linebreak'
        // cellPadding: 10
      },
      columnStyles: {
        index: {
          cellPadding: 0,
          cellWidth: 15
        },
        code: {
          cellWidth: 60
        },
        nickname: {
          cellWidth: 60
        },
        name: {
          cellWidth: 160,
          overflow: 'ellipsize'
        }
      }
    };

    const deliveryGroupListCertificates = _.filter(deliveryGroup['list_certificates'], (certificate) => {
      return certificate['alias'] !== 'conv.';
    });

    // _.each(_.uniqBy(deliveryGroup['list_certificates'], 'id'), (certificate, certIndex) => {
    _.each(_.uniqBy(deliveryGroupListCertificates, 'id'), (certificate, certIndex) => {
      rows = [];
      certificateId = certificate['id'];
      certificateAlias = certificate['alias'];
      cont = 1; // <-- indicates how many times producer is greater than 23 (max colmuns per page)

      /* Add new page if iteration it's not the first NEED REVIEW */
      if (certIndex !== 0) {
        doc.addPage();
      }

      certificateName = certificate['name'];

      // Headers of table
      switch (certificate['alias']) {
        case 'trans':
          columns = [
            { title: '#', dataKey: 'index' },
            { title: 'Código', dataKey: 'code' },
            { title: 'Apodo', dataKey: 'nickname' },
            { title: 'Nombre completo', dataKey: 'name' },
            { title: 'KG máx.', dataKey: 'estimateKG' },
            { title: 'QQ máx.', dataKey: 'estimateQQ' },
            { title: 'KG entregados', dataKey: 'weight' },
            { title: 'Nº comprobante', dataKey: 'ticketNumber' }
          ];
          break;
        case 'conv.':
          columns = [
            { title: '#', dataKey: 'index' },
            { title: 'Código', dataKey: 'code' },
            { title: 'Apodo', dataKey: 'nickname' },
            { title: 'Nombre completo', dataKey: 'name' },
            { title: 'KG entregados', dataKey: 'weight' },
            { title: 'Nº comprobante', dataKey: 'ticketNumber' }
          ];
          break;
        default:
          columns = [
            { title: '#', dataKey: 'index' },
            { title: 'Código', dataKey: 'code' },
            { title: 'Apodo', dataKey: 'nickname' },
            { title: 'Nombre completo', dataKey: 'name' },
            // { title: 'UTZ', dataKey: 'utz' },
            { title: 'KG máx.', dataKey: 'estimateKG' },
            { title: 'QQ máx.', dataKey: 'estimateQQ' },
            { title: 'KG entregados', dataKey: 'weight' },
            { title: 'Nº comprobante', dataKey: 'ticketNumber' }
          ];
          break;
      }

      const producers = _.filter(deliveryGroup['producers'], producer => {
        return _.includes(_.map(producer['list_certificates'], 'id'), certificate['id']);
      });

      if (certificateAlias === 'trans') {
        /**
         * We divided in two new arrays one have utz and other not have utz because need dived in two difrents tables
         */
        const producersWithUtz = _.filter(producers, producer => {
          return _.includes(_.map(producer['bool_certificates'], 'id'), utzId);
        });

        const producersWithOutUtz = _.filter(producers, producer => {
          return !_.includes(_.map(producer['bool_certificates'], 'id'), utzId);
        });

        _.each(producersWithUtz, (producer, index) => {
          haveUTZCert = true;

          rows.push({
            index: `${index + 1}`,
            code: producer['code'],
            nickname: producer['nickname'] ? producer['nickname'] : '',
            name: producer['full_name'],
            estimateKG: (_.find(producer['list_certificates'], { id: certificate['id'] })['quota']),
            estimateQQ: (_.find(producer['list_certificates'], { id: certificate['id'] })['quota'] / 50),
            weight: '        ',
            ticketNumber: '        '
          });

          if (index === (maxRows * cont) && index !== (producersWithUtz.length - 1)) {
            doc.autoTable(columns, rows, options);

            if (typeof doc.putTotalPages === 'function') {
              doc.putTotalPages(totalPagesExp);
            }

            doc.addPage();
            rows = [];
            cont += 1;
          }
        });

        // check if producers with utz is greater than 0 because if 0 not need add footer or page
        if (producersWithUtz.length > 0) {
          cont = 1;
          doc.autoTable(columns, rows, options);

          if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
          }

          this.addFooter(doc);
          if (producersWithOutUtz.length > 0) {
            doc.addPage();
          }
          rows = [];
        }

        _.each(producersWithOutUtz, (producer, index) => {
          haveUTZCert = false;

          rows.push({
            index: `${index + 1}`,
            code: producer['code'],
            nickname: producer['nickname'] ? producer['nickname'] : '',
            name: producer['full_name'],
            estimateKG: (_.find(producer['list_certificates'], { id: certificate['id'] })['quota']),
            estimateQQ: (_.find(producer['list_certificates'], { id: certificate['id'] })['quota'] / 50),
            weight: '                ',
            ticketNumber: '        '
          });

          if (index === (maxRows * cont) && index !== (producersWithOutUtz.length - 1)) {
            doc.autoTable(columns, rows, options);
            if (typeof doc.putTotalPages === 'function') {
              doc.putTotalPages(totalPagesExp);
            }

            doc.addPage();
            rows = [];
            cont += 1;
          }
        });

        if (producersWithOutUtz.length > 0) {
          cont = 1;
          doc.autoTable(columns, rows, options);

          if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
          }

          this.addFooter(doc);
          rows = [];
        }
      } else {
        _.each(producers, (producer, index) => {
          if (certificateAlias === 'conv.') {
            rows.push({
              index: `${index + 1}`,
              code: producer['code'],
              nickname: producer['nickname'] ? producer['nickname'] : '',
              name: producer['full_name'],
              // utz: _.includes(_.map(producer['bool_certificates'], 'id'), utzId) ? 'Si' : 'No',
              weight: '                ',
              ticketNumber: '        '
            });
          } else {
            rows.push({
              index: `${index + 1}`,
              code: producer['code'],
              nickname: producer['nickname'] ? producer['nickname'] : '',
              name: producer['full_name'],
              estimateKG: (_.find(producer['list_certificates'], { id: certificate['id'] })['quota']),
              estimateQQ: (_.find(producer['list_certificates'], { id: certificate['id'] })['quota'] / 50),
              // utz: _.includes(_.map(producer['bool_certificates'], 'id'), utzId) ? 'Si' : 'No',
              weight: '                ',
              ticketNumber: '        '
            });
          }

          // check if producer index is greater than 12 (max rows per page)
          if (index === (maxRows * cont) && index !== (producers.length - 1)) {
            doc.autoTable(columns, rows, options);
            if (typeof doc.putTotalPages === 'function') {
              doc.putTotalPages(totalPagesExp);
            }

            doc.addPage();
            rows = [];
            cont += 1;
          }
        });

        doc.autoTable(columns, rows, options);

        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp);
        }

        this.addFooter(doc);
      }
    });

    /* Descargar documento */
    doc.save(`${deliveryGroup['name']}.pdf`);

    /* Ver inline */
    /*let output = doc.output('datauristring'),
        embed = "<embed width='100%' height='100%' src='" + output + "' />",
        x = window.open();
    x.document.open();
    x.document.write(embed);
    x.document.close();*/
  }

  private addFooter(doc: any) {
    const totalWeightText = 'KG totales compra',
      totalWeightTextWidth = doc.getStringUnitWidth(totalWeightText) * this.standardFontSize / doc.internal.scaleFactor,
      bagsText = 'Cantidad de sacos',
      bagsTextWidth = doc.getStringUnitWidth(bagsText) * this.standardFontSize / doc.internal.scaleFactor,
      collectedDateText = 'Fecha de recogida:',
      collectedDateTextWidth = doc.getStringUnitWidth(collectedDateText) * this.standardFontSize / doc.internal.scaleFactor,
      points = '.................................',
      pointsWidth = doc.getStringUnitWidth(points) * this.standardFontSize / doc.internal.scaleFactor,
      supplierText = 'Firma Suplidor:',
      supplierTextWidth = doc.getStringUnitWidth(supplierText) * this.standardFontSize / doc.internal.scaleFactor,
      yacaoSignText = 'Firma:',
      yacaoSignTextWidth = doc.getStringUnitWidth(yacaoSignText) * this.standardFontSize / doc.internal.scaleFactor,
      yacaoEmployeeText = 'Nombre empleado Yacao:',
      yacaoEmployeeTextWidth = doc.getStringUnitWidth(yacaoEmployeeText) * this.standardFontSize / doc.internal.scaleFactor;

    doc.setFontSize(this.standardFontSize);
    doc.setFontStyle('bold');

    /* Para enviar al fondo de la página */
    doc.setDrawColor(0, 0, 0);

    /* WEIGHT FIELDS */
    doc.text(totalWeightText, this.marginLeft, doc.internal.pageSize.height - 95);
    doc.rect(totalWeightTextWidth + 105, doc.internal.pageSize.height - 120, 110, 40);

    /* BAGS FIELDS */
    doc.text(bagsText, (this.pageWidth / 2) + 40, doc.internal.pageSize.height - 95);
    doc.rect((this.pageWidth / 2) + bagsTextWidth + 75, doc.internal.pageSize.height - 120, 80, 40);

    /* SIGNATURE FIELDS */
    /* Collected date */
    doc.text(collectedDateText, this.marginLeft, doc.internal.pageSize.height - 50);
    doc.text(points, (this.pageWidth / 2) - pointsWidth + 20, doc.internal.pageSize.height - 50);

    /* Supplier signature */
    doc.text(supplierText, (this.pageWidth / 2) + 40, doc.internal.pageSize.height - 50);
    doc.text(points, this.pageWidth - pointsWidth - this.marginRight, doc.internal.pageSize.height - 50);

    /* Yacao's employee name */
    doc.text(yacaoEmployeeText, this.marginLeft, doc.internal.pageSize.height - 20);
    doc.text(points, (this.pageWidth / 2) - pointsWidth + 20, doc.internal.pageSize.height - 20);

    /* Yacao's employee signature */
    doc.text(yacaoSignText, (this.pageWidth / 2) + 90, doc.internal.pageSize.height - 20);
    doc.text(points, this.pageWidth - pointsWidth - this.marginRight, doc.internal.pageSize.height - 20);
  }

  private addHeader(doc: any,
    data: any,
    deliveryGroupName: string,
    certificateName: string,
    certificateAlias: string,
    flo: boolean,
    ffl: boolean,
    haveUTZCert: boolean,
    totalPagesExp: string,
    deliveryGroup: DeliveryGroupModel,
    certificateId: number,
    season: string = ''
  ) {
    const date = new Date().toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      dateWidth = doc.getStringUnitWidth(date) * this.standardFontSize / doc.internal.scaleFactor,
      endDateWith = doc.getStringUnitWidth('30/06/2020') * this.standardFontSize / doc.internal.scaleFactor,
      validFromTextWidth = doc.getStringUnitWidth('Válido a partir de:---') * this.standardFontSize / doc.internal.scaleFactor,
      validToTextWidth = doc.getStringUnitWidth('Fecha validez:---') * this.standardFontSize / doc.internal.scaleFactor,
      seasonTextWidth = doc.getStringUnitWidth('Cosecha:---') * this.standardFontSize / doc.internal.scaleFactor,
      receiptTextWidth = doc.getStringUnitWidth('Rec.:---') * this.standardFontSize / doc.internal.scaleFactor,
      pageTitleTextWith = doc.getStringUnitWidth('Página:---') * this.standardFontSize / doc.internal.scaleFactor,
      title = 'Formulario de procedencia de cacao'.toLocaleUpperCase(),
      titleFontSize = 14,
      titleWidth = doc.getStringUnitWidth(title) * titleFontSize / doc.internal.scaleFactor,
      quotaKG = this.getQuotaOf(deliveryGroup, certificateId),
      quotaQQ = quotaKG / 50,
      titleQuotaQQTextWidth = doc.getStringUnitWidth('QQ máximos a entregar:---') * this.standardFontSize / doc.internal.scaleFactor,
      titleQuotaKGTextWidth = doc.getStringUnitWidth('KG máximos a entregar:---') * this.standardFontSize / doc.internal.scaleFactor,
      quotaQQWidth = doc.getStringUnitWidth(`${quotaQQ}`) * this.standardFontSize / doc.internal.scaleFactor,
      quotaKGWidth = doc.getStringUnitWidth(`${quotaKG}`) * this.standardFontSize / doc.internal.scaleFactor;

    /* FONTS */
    doc.setFontSize(this.standardFontSize);

    /* FECHA DE VALIDEZ */
    /* DESDE */
    doc.setFontStyle('bold');
    doc.text('Válido a partir de:', this.marginLeft, 30);
    doc.setFontStyle('normal');
    doc.text(date, this.marginLeft + validFromTextWidth, 30);

    /* HASTA */
    doc.setFontStyle('bold');
    doc.text('Fecha validez:', this.pageWidth - this.marginRight - dateWidth - validToTextWidth, 30);
    doc.setFontStyle('normal');
    doc.text('31/03/2020', this.pageWidth - this.marginRight - dateWidth, 30);

    /* COSECHA */
    doc.setFontStyle('bold');
    doc.text('Cosecha:', this.marginLeft, this.marginTop + 5);
    doc.setFontStyle('normal');
    doc.text(season, this.marginLeft + seasonTextWidth, this.marginTop + 5);

    /* NUMERO DE RECIBO */
    doc.setFontStyle('bold');
    doc.text('Rec.:', this.marginLeft + 180, this.marginTop + 5);
    doc.setFontStyle('normal');
    doc.text('........................................', this.marginLeft + receiptTextWidth + 180, this.marginTop + 5);

    /* TÍTULO */
    doc.setFontSize(titleFontSize);
    doc.setTextColor(40);
    doc.setFontStyle('bold');
    doc.text(title, (this.pageWidth - titleWidth) / 2, this.marginTop + 30);

    /* GRUPO DE ENTREGA */
    doc.setFontSize(this.standardFontSize);
    doc.setFontStyle('bold');
    doc.text('Grupo de entrega: ', this.marginLeft, 100);
    doc.setFontStyle('normal');
    doc.text(deliveryGroupName, 190, 100);

    /* CERTIFICACIÓN ORGÁNICA */
    doc.setFontStyle('bold');
    doc.text('Certificación Orgánica: ', this.marginLeft, 120);
    doc.setFontStyle('normal');
    doc.text(certificateName, 190, 120);

    if (certificateAlias !== 'conv.') {
      /* CUOTAS */
      /* En quintales */
      doc.setFontStyle('bold');
      doc.text('QQ máx. a entregar:', this.pageWidth - this.marginRight - 20 - titleQuotaQQTextWidth, 120);
      doc.setFontStyle('normal');
      doc.text(quotaQQ.toString(), this.pageWidth - this.marginRight - quotaQQWidth, 120);

      /* En kilogramos */
      doc.setFontStyle('bold');
      doc.text('KG máx. a entregar:', this.pageWidth - this.marginRight - 20 - titleQuotaKGTextWidth, 140);
      doc.setFontStyle('normal');
      doc.text(quotaKG.toString(), this.pageWidth - this.marginRight - quotaKGWidth, 140);

      /* CERTIFICACIÓN SOCIAL */
      doc.setFontStyle('bold');
      doc.text('Certificación Social:', this.marginLeft, 140);

      // if (certificateAlias !== 'trans') {
      /* FLO */
      doc.setFontStyle('normal');
      doc.text('FLO', 190, 140);
      doc.setFontStyle('bold');
      doc.text(`${flo && certificateAlias !== 'conv.' ? 'SI' : 'NO'}`, 220, 140);

      if (certificateAlias !== 'trans') {
        /* FFL */
        doc.setFontStyle('normal');
        doc.text('FFL', 250, 140);
        doc.setFontStyle('bold');
        doc.text(`${ffl && certificateAlias !== 'conv.' ? 'SI' : 'NO'}`, 280, 140);
      }

      /* UTZ */
      doc.setFontStyle('normal');
      // doc.text('UTZ', ((certificateAlias !== 'trans') ? 310 : 190), 140);
      doc.text('UTZ', ((certificateAlias !== 'trans') ? 310 : 250), 140);
      doc.setFontStyle('bold');
      // doc.text(`${haveUTZCert ? 'SI' : 'NO'}`, ((certificateAlias !== 'trans') ? 340 : 220), 140);
      doc.text(`${haveUTZCert ? 'SI' : 'NO'}`, ((certificateAlias !== 'trans') ? 340 : 280), 140);
    }

    /* PAGE COUNT */
    let currentPageNumber = data.pageCount;

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      currentPageNumber = totalPagesExp;
    }

    // doc.setFontSize(10);
    // doc.setFontStyle('normal');

    // let pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    // doc.text(str, (pageWidth - doc.getStringUnitWidth(str) * 10 / doc.internal.scaleFactor) / 2, pageHeight - 20);
    doc.setFontStyle('bold');
    doc.text(
      'Página:',
      this.pageWidth - this.marginRight - endDateWith - pageTitleTextWith,
      this.marginTop + 5
    );
    doc.setFontStyle('normal');
    doc.text(
      currentPageNumber.toString(),
      this.pageWidth - this.marginRight - 5,
      this.marginTop + 5
    );

    /* Final text */
    doc.setFontSize(8);
    doc.setFontStyle('bold');
    const floIdText = 'FLO ID FUNDOPO 2574';
    doc.text(floIdText, this.marginLeft, 152.5);
    const finalText = 'Cantidades máximas a entregar se refiere a cacao seco',
      finalTextWidth = doc.getStringUnitWidth(finalText) * 8 / doc.internal.scaleFactor;
    doc.text(finalText, this.pageWidth - finalTextWidth - this.marginRight, 152.5);
  }

  private getQuotaOf(deliveryGroup: DeliveryGroupModel, certificateId: number) {
    const groupCert = _.find(deliveryGroup['max_quotas'], { certificate_id: certificateId });
    return groupCert ? groupCert['quota'] : 0;
  }
}
