import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

// Service
import { DeliveryGroupService } from 'src/app/services/delivery-group.service';
import { NotificationService } from 'src/app/services/notification.service';

// Models
import { ContactModel } from 'src/app/models/contact.model';
import { DeliveryGroupModel } from 'src/app/models/delivery-group.model';

@Component({
  selector: 'app-delivery-group-form',
  templateUrl: './delivery-group-form.component.html',
  styleUrls: ['./delivery-group-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeliveryGroupFormComponent implements OnInit {
  @ViewChild('content', { static: true }) private modal;

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };

  @Input() private deliveryGroups: DeliveryGroupModel[] = new Array<DeliveryGroupModel>();
  @Input() private totalItems: number;
  @Input() private itemsPerPage: number;
  @Input() private currentPage: number;
  @Output() saveDeliveryGroup: EventEmitter<DeliveryGroupModel[]> = new EventEmitter<DeliveryGroupModel[]>();

  deliveryGroup: DeliveryGroupModel = new DeliveryGroupModel();
  contacts: ContactModel[] = new Array<ContactModel>();
  producers: ContactModel[] = new Array<ContactModel>();

  sending: boolean = false;


  constructor(private _deliveryGroupService: DeliveryGroupService, 
    private _modalService: NgbModal,
    private _notification: NotificationService) { }

  ngOnInit() {
  }

  compareFn(a, b) {
    return a['id'] === b;
  }

  openForm(deliveryGroup: DeliveryGroupModel) {

    if (deliveryGroup) {
      this.getDeliveryGroupBy(deliveryGroup.id);
      this.deliveryGroup = deliveryGroup;
    } else {
      this.new();
      this.deliveryGroup = new DeliveryGroupModel();
    }

    this.openModal();
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  onSubmit(deliveryGroup: DeliveryGroupModel) {
    deliveryGroup['id'] ? this.updateDeliveryGroup(deliveryGroup) : this.createDeliveryGroup(deliveryGroup);
  }

  updateDeliveryGroup(deliveryGroup: DeliveryGroupModel) {
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);
    deliveryGroup = this.addAutomaticInfo(deliveryGroup);

    this.sending = true;

    this._deliveryGroupService
      .updateDeliveryGroup(deliveryGroup, this.getDeliveryGroupsIdsAsString())
      .then(
        async res => {

          this._notification.clear();
          this._notification.success('Actualizado correctamente');

          this.sending = false;

          this.saveDeliveryGroup.emit(res['delivery_groups']);

          this.closeModal();
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      );
  }

  new() {
    this._deliveryGroupService
      .new(

      ).then(
        (response) => {
          this.contacts = response['contacts'];
          this.producers = _.cloneDeep(this.contacts);
        }
      ).catch(error => console.error(error));
  }

  getDeliveryGroupBy(id: number) {
    this._deliveryGroupService
      .getDeliveryGroup(id)
      .then(
        (response) => {
          this.deliveryGroup = response['delivery_group'];
          this.contacts = response['contacts'];
          this.producers = _.filter(this.contacts, { producer: true });
        }
      )
      .catch(error => console.error(error));
  }

  private createDeliveryGroup(deliveryGroup: DeliveryGroupModel) {
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);
    deliveryGroup = this.addAutomaticInfo(deliveryGroup);
    this.sending = true;

    this._deliveryGroupService
      .createDeliveryGroup(deliveryGroup, this.getDeliveryGroupsIdsAsString())
      .then(
        async res => {

  
          this._notification.clear();
          this._notification.success('Creado correctamente');

          this.totalItems += 1;
          if (this.currentPage === _.ceil(this.totalItems / this.itemsPerPage)) {
            this.deliveryGroups.push(res['delivery_group']);
          }

          this.saveDeliveryGroup.emit([...res['delivery_groups'], ...[res['delivery_group']]]);
          this.sending = false;
          this.closeModal();
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al crear');
          }
        }
      );
  }


  private addAutomaticInfo(deliveryGroup: DeliveryGroupModel) {

    if (!!deliveryGroup['coordinator']) {
      deliveryGroup['coordinator_id'] = deliveryGroup['coordinator']['id'];
    } else {
      deliveryGroup['coordinator_id'] = null;
    }

    if (deliveryGroup['producers'] && deliveryGroup['producers'].length > 0) {
      deliveryGroup['producer_ids'] = _.map(deliveryGroup['producers'], 'id');

      deliveryGroup['delivery_group_list_certificates'] = _.uniqBy(_.flattenDeep(_.map(deliveryGroup['producers'], 'list_certificates')), 'id');
      deliveryGroup['delivery_group_bool_certificates'] = _.uniqBy(_.flattenDeep(_.map(deliveryGroup['producers'], 'bool_certificates')), 'id');
      // deliveryGroup['delivery_group_list_certificates'] = [];
      // deliveryGroup['delivery_group_bool_certificates'] = [];

      // _.each(deliveryGroup['producers'], p => {
      //   let producer = _.cloneDeep(_.find(this.contacts, { id: p['id'] }));

      //   // if (producer) {
      //   //   _.each(producer['list_certificates'], (c, index) => {
      //   //     let certificate = _.cloneDeep(_.find(this.certificates, { id: c['id'] }));
      //   //     if (certificate) {
      //   //       deliveryGroup['delivery_group_list_certificates'].push(certificate);
      //   //     }
      //   //   });

      //   //   _.each(producer['bool_certificates'], (c, index) => {
      //   //     let certificate = _.cloneDeep(_.find(this.certificates, { id: c['id'] }));
      //   //     if (certificate) {
      //   //       deliveryGroup['delivery_group_bool_certificates'].push(certificate);
      //   //     }
      //   //   });
      //   // }
      // });
    } else {
      deliveryGroup['delivery_group_list_certificates'] = [];
      deliveryGroup['delivery_group_bool_certificates'] = [];
    }

    return deliveryGroup;
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }

  private getDeliveryGroupsIdsAsString(): Object {
    return { current_delivery_groups: JSON.stringify(_.map(this.deliveryGroups, 'id')) };
  }
}
