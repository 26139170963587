<!-- LOGIN FORM -->
<form class="form-signin mt-5">
  <img src="/assets/images/logo-yacao.png" class="mb-4" alt />

  <label for="alias" class="sr-only">
    {{ 'pages.login.alias' | translate}}
  </label>
  <input id="alias" name="alias" type="text" [(ngModel)]="user.alias" placeholder="{{'pages.login.placeholder_username' | translate}}"
    class="form-control" [required] />

  <label for="password" class="sr-only">
    {{ 'pages.login.password' | translate}}
  </label>
  <input id="password" name="password" type="password" [(ngModel)]="user.password" placeholder="{{'pages.login.placeholder_password' | translate}}"
    class="form-control" [required] />

  <button mat-button (click)="login()" [disabled]="!user.alias || !user.password" type="submit"
    class="mt-5 login-button">
    <mat-icon>
      input
    </mat-icon>
    <span class="ml-2">
      {{ 'pages.login.button' | translate}}
    </span>
  </button>
</form>