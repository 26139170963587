import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

// Services
import { AddonService } from 'src/app/services/addon.service';
import { CertificateService } from 'src/app/services/certificate.service';
import { NotificationService } from 'src/app/services/notification.service';

// Models
import { CertificateModel } from 'src/app/models/certificate.model';
import { AddonModel } from 'src/app/models/addon.model';



@Component({
  selector: 'app-addon-form',
  templateUrl: './addon-form.component.html',
  styleUrls: ['./addon-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddonFormComponent implements OnInit {
  @ViewChild('content', { static: true }) private modal;
  @Input() private addons: AddonModel[] = new Array<AddonModel>();
  @Output() result: EventEmitter<AddonModel[]> = new EventEmitter<AddonModel[]>();

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };

  certificates: Array<CertificateModel> = new Array<CertificateModel>();
  addon: AddonModel = new AddonModel();
  sending: boolean = false;

  constructor(
    private _modalService: NgbModal,
    private _addonService: AddonService,
    private _certificateService: CertificateService,
    private _notification: NotificationService
    ) { }

  ngOnInit() {
  }

  onSubmit(addon: AddonModel) {
    addon['id'] ? this.updateAddon(addon) : this.createAddon(addon);
  }

  closeModal(reason: string = '', options?: NgbModalOptions): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  openForm(addon: AddonModel): void {
    this.getCertificates();
    this.addon = addon ? _.cloneDeep(addon) : new AddonModel();
    this.openModal();
  }

  compareFn(a: any, b: any): boolean {
    return a['id'] === b;
  }

  updateAddon(addon: AddonModel): void {
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    this.sending = true;

    this._addonService
      .updateAddon(addon)
      .then(
        res => {
          const index = _.findIndex(this.addons, { id: res['addon']['id'] });
          this.addons[index] = res['addon'];
          this.result.emit(this.addons);

          this._notification.clear();
          this._notification.success('Actualizado correctamente');

          this.sending = false;
          this.closeModal();
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      );
  }

  private createAddon(addon: AddonModel): void {
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    this.sending = true;
    this._addonService
      .createAddon(addon)
      .then(
        res => {
          this.addons.push(res['addon']);
          this.result.emit(this.addons);
          this._notification.clear();
          this._notification.success('Creado correctamente');
          this.closeModal();
          this.sending = false;
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al crear');
          }
        }
      );
  }

  private getCertificates(): void {
    const params = {
      'q[certificate_type_list_eq]': true
    };

    this._certificateService.getCertificates(params)
      .then(
        res => {
          this.certificates = res['certificates'];
          // this.openModal(content);
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }

}
