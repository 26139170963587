import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Models
import { ZoneGroupModel } from '../models/zone-group.model';

//Environment
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ZoneGroupService {
  zoneGroupRoute = environment.routes.api + environment.routes.zone_groups;

  constructor(
    private http: HttpClient
  ) {
  }

  getZoneGroups(params: {} = {}) {
    const options = {
      params: params,
      headers: this.getHeaders()
    }
    return new Promise((resolve, reject) => {
      this.http.get(this.zoneGroupRoute, options)
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  createZoneGroup(zoneGroup: ZoneGroupModel) {
    return new Promise((resolve, reject) => {
      this.http.post(this.zoneGroupRoute, { zone_group: zoneGroup }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  updateZoneGroup(zoneGroup: ZoneGroupModel) {
    return new Promise((resolve, reject) => {
      this.http.put(
        `${this.zoneGroupRoute}/${zoneGroup['id']}`, { zone_group: zoneGroup }, { headers: this.getHeaders() }
      ).toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  deleteZoneGroup(zoneGroupId: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${this.zoneGroupRoute}/${zoneGroupId}`)
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('yacao:app:token'),
        'user_id': localStorage.getItem('yacao:app:user_id')
      }
    );
  }
}
