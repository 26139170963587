import { CocoaTypeModel } from "./cocoa-type.model";
import { CocoaQualityModel } from "./cocoa_quality.model";
import { CertificateModel } from "./certificate.model";

export class TradePriceModel {
  id: number;
  name: string;
  amount: number;
  active: boolean;
  cocoa_type_id: number;
  cocoa_type: CocoaTypeModel;
  cocoa_quality_id: number;
  cocoa_quality: CocoaQualityModel;
  certificate_id: number;
  certificate: CertificateModel;
  position: number;
}