import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Models
import { ContactModel } from "../models/contact.model";

//Environment
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: "root"
})
export class SupplierService {
  supplierRoute = environment.routes.api + environment.routes.contacts;

  constructor(
    private http: HttpClient
  ) { }

  getSuppliers(page: number = 1, per_page: number = 25, parameters: {} = {}): Promise<any> {
    let params = {
      page: page.toString(),
      per_page: per_page.toString()
    };

    params = Object.assign(params, parameters);

    let options = {
      params: params,
      headers: this.getHeaders()
    };

    options = Object.assign(options, { responseType: params['format'] === 'json' ? 'json' : 'arraybuffer' });

    return new Promise(
      (resolve, reject) => {
        this.http
          .get(this.supplierRoute, options)
          .toPromise()
          .then(
            res => {
              if (params.hasOwnProperty('format') && params['format'] === 'xlsx') {
                resolve(res);
              } else {
                res['success'] ? resolve(res) : reject(res);
              }
            }
          )
          .catch(
            err => {
              console.error(err);
              reject(err);
            }
          );
      }
    );
  }

  createSupplier(supplier: ContactModel): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.supplierRoute, { supplier: supplier }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  updateSupplier(supplier: ContactModel): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(`${this.supplierRoute}/${supplier['id']}`, { contact: supplier }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  deleteSupplier(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .delete(`${this.supplierRoute}/${id}`, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('yacao:app:token'),
        'user_id': localStorage.getItem('yacao:app:user_id')
      }
    );
  }
}
