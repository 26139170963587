import { Injectable } from '@angular/core';
import { RoleModel } from '../models/role.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  roleRoute = environment.routes.api + environment.routes.roles;

  constructor(
    private http: HttpClient
  ) { }

  getRoles(params: {} = {}) {
    const options = {
      params: params,
      headers: this.getHeaders()
    }
    
    return new Promise((resolve, reject) => {
      this.http.get(this.roleRoute, options)
        .toPromise()
          .then(
            res => {
              res['success'] ? resolve(res) : reject(res);
            }
          )
          .catch(
            err => {
              reject(err);
            }
          );
    });
  }

  createRole(role: RoleModel) {
    return new Promise((resolve, reject) => {
      this.http.post(this.roleRoute, { role: role }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            resolve(res);
          }
        ).catch(
          err => {
            reject(err)
          }
        )
    });
  }

  deleteRole(roleId: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${this.roleRoute}/${roleId}`, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            resolve(res);
          }
        ).catch(
          err => {
            reject(err)
          }
        );
    });
  }

  updateRole(role: RoleModel) {
    return new Promise((resolve, reject) => {
      this.http.put(`${this.roleRoute}/${role['id']}`, { role: role }, { headers: this.getHeaders() })
        .toPromise()
          .then(
            res => {
              res['success'] ? resolve(res) : reject(res);
            }
          )
          .catch(
            err => {
              reject(err);
            }
          );
    });
  }


  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + (localStorage.getItem('yacao:app:token') || ""),
        'user_id': localStorage.getItem('yacao:app:user_id') || ""
      }
    );
  }
}
