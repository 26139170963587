import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons
} from '@ng-bootstrap/ng-bootstrap';
import { SelectControlValueAccessor } from '@angular/forms';
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';


import * as _ from 'lodash';

// Services
import { ExportToExcelService } from '../../../services/export-to-excel.service';
import { ExportationService } from 'src/app/services/exportation.service';
import { CocoaQualityService } from 'src/app/services/cocoa-quality.service';
import { DownloadFileService } from 'src/app/services/download-file.service';
import { NotificationService } from 'src/app/services/notification.service';

// Models
import { ExportationModel } from 'src/app/models/exportation.model';
import { TicketModel } from 'src/app/models/ticket.model';
import { ExportationTicketModel } from 'src/app/models/ticket-to-export.model';
import { CocoaQualityModel } from 'src/app/models/cocoa_quality.model';
import { ContactModel } from 'src/app/models/contact.model';
import { FilterModel } from 'src/app/models/filter.model';
import { GlobalsService } from 'src/app/services/globals.service';

// Components
import { ExportationFormsComponent } from '../modals/exportation-forms/exportation-forms.component';
import { ExportationDetailsComponent } from '../modals/exportation-details/exportation-details.component';


@Component({
  selector: 'app-exportations-index',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExportationsPage implements OnInit {
  @ViewChild(ExportationFormsComponent, { static: true }) public exportationForm: ExportationFormsComponent;
  @ViewChild(ExportationDetailsComponent, { static: true }) public exportationShowModal: ExportationDetailsComponent;

  exportations: Array<ExportationModel> = new Array<ExportationModel>();
  allExportations: Array<ExportationModel> = new Array<ExportationModel>();

  cocoaQualities: Array<CocoaQualityModel> = new Array<CocoaQualityModel>();

  tickets: Array<TicketModel> = new Array<TicketModel>();
  selectableTickets: Array<TicketModel> = new Array<TicketModel>();

  itemsPerPage = 25;
  totalItems = 0;
  currentPage = 1;

  filters: FilterModel = new FilterModel();

  loading: boolean;
  isCollapsed = false;

  loadingExcel = false;

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy'
    // other options are here...
  };


  constructor(
    private exportationService: ExportationService,
    private _downloadFileService: DownloadFileService
  ) { }

  ngOnInit() {
    delete this.filters['g'];
    this.getExportations();
  }


  onDateChanged(event, filter: string) {
    this.filters[filter] = event.singleDate.jsDate;
  }

  showExportationInfo(exportation) {
    this.exportationForm.openForm(exportation);
  }

  openForm(exportation: ExportationModel) {
    this.exportationForm.openForm(exportation);
  }

  getExportation(exportation: ExportationModel, format: string = 'json') {
    // const exportDate = new Date(exportation['exportation_date']);
    this.loadingExcel = (format === 'xlsx');
    this.exportationService
      .getExportation(exportation['id'], format)
      .then(
        (response) => {
          if (format === 'json') {
            // this.exportation = response['exportation'];

            // _.each(this.exportation.exportation_tickets, (t, index) => {
            //   this.exportation.exportation_tickets[index]['weight'] = Number(this.exportation.exportation_tickets[index]['weight']);
            // });

            // this.exportation['temp_exportation_date'] = {
            //   day: Number(exportDate.getDate()),
            //   month: Number(exportDate.getMonth() + 1),
            //   year: Number(exportDate.getFullYear())
            // };
          } else {
            console.log(response);
            this.loadingExcel = false;
            this._downloadFileService.downloadFile(
              response,
              `exportacion_${exportation['container_name'].toLocaleLowerCase().replace('-', '_')}`,
              'application/vnd.ms-excel'
            );
          }
        }
      )
      .catch(error => console.error(error));
  }



  getExportations(page: number = 1, per_page: number = 25, params: {} = {}, format: string = 'json') {
    let parameters = _.cloneDeep(params);

    if (format === 'json') {
      this.loading = true;
    }

    if (format === 'xlsx') {
      this.loadingExcel = true;
    }

    parameters = Object.assign(parameters, { format: format });

    _.each(parameters, (value, key) => {
      if (typeof parameters[key] === 'string') {
        parameters[key] = parameters[key].trim();
      }
      
      value == null && delete parameters[key];
    });

    this.exportationService
      .getExportations(page, per_page, parameters)
      .then(
        res => {
          if (format === 'json') {
            this.exportations = res['exportations'];
            this.allExportations = res['all_exportations'];
            this.tickets = res['tickets'];
            // this.selectableTickets = _.cloneDeep(_.filter(this.tickets, { nulled: false, finished: true }));
            this.cocoaQualities = res['cocoa_qualities'];
            this.totalItems = res['total_items'];
            this.currentPage = res['current_page'];
            this.loading = false;
          } else {
            this.loadingExcel = false;
            this._downloadFileService.downloadFile(res, 'exportaciones', 'application/vnd.ms-excel');
          }
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }

  setExportations(exportations: ExportationModel[]) {
    this.exportations = exportations;
  }

  changePageAt(page: number) {
    this.currentPage = page;
  }
}
