<app-navbar></app-navbar>
<ng-template #sendingNotification let-notificationData="notification">
  <div type="notificationData.type">
    {{ notificationData.message }}
    <div class="load-container">
      <div class="loader"></div>
    </div>
  </div>
</ng-template>

<h1 class="lead">
  Tipos de certificado
</h1>

<div class="row">
  <div class="col-9">
    <!-- <div class="filtersContainer">
      <button type="button" class="btn btn-outline-dark btn-sm" (click)="isCollapsed= !isCollapsed"
        [attr.aria-expanded]="!isCollapsed" aria-controls="collapsibleFilters">
        <span class="oi oi-eye mr5"></span>Mostrar/Ocultar Filtros
      </button>

      <div class="container" id="collapsibleFilters" [ngbCollapse]="!isCollapsed">
        <div class="row justify-content-md-center">
          <div class="col-md-6">
            <ng-select [items]="certificateTypesFiltered" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="name" name="name" placeholder="Nombre" [multiple]="false" required
              (change)="filterCertificateTypes($event, 'name')"></ng-select>
          </div>

          <div class="col-md-6">
            <ng-select [items]="['Si','No']" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" name="isActive" placeholder="Activo" [multiple]="false" required (change)="filterCertificateTypes($event, 'isActive')"></ng-select>
          </div>
        </div>
      </div>
    </div> -->
  </div>

  <div class="col-3">
    <div class="exportContainer d-flex justify-content-center">
      <button class="btn btn-outline-dark mr-2" (click)="openModal(content);">
        <mat-icon class="position-relative top-5 font-weight-bold">add</mat-icon>
        Nuevo tipo de certificado
      </button>

      <button class="btn btn-outline-dark">
        <mat-icon class="position-relative top-5">save_alt</mat-icon>
        Descargar excel
      </button>
    </div><!-- .exportContainer -->
  </div>
</div>

<ng-template #content let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 *ngIf="!certificatetype.id" class="modal-title">Crear un tipo de certificado</h4>
    <h4 *ngIf="certificatetype.id" class="modal-title">Actualizar tipo de certificado</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form #Form="ngForm">
      <div class="form-group">
        <label for="certificateTypeNameId">Nombre del tipo de certificado</label>
        <input type="text" class="form-control" id="certificateTypeNameId" [(ngModel)]="certificatetype.name"
          placeholder="Name" name="certificatetypeName" required>
      </div>

      <div>
        <mat-checkbox [(ngModel)]="certificatetype.active" name="certificatetypeeActive">Activo</mat-checkbox>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button *ngIf="!certificatetype.id" class="btn btn-primary" (click)="createCertificateType(certificatetype)"
      [disabled]="!Form.form.valid" name="submitCertificatetype" [disabled]="sending">Crear</button>

    <button *ngIf="certificatetype.id" class="btn btn-primary" (click)="updateCertificateType(certificatetype)"
      [disabled]="!Form.form.valid" name="updateCertificatetype" [disabled]="sending">Guardar</button>

    <button class="btn btn-danger" (click)="d('button click')" name="closeModal" [disabled]="sending">Cancelar</button>
  </div>
</ng-template>

<div class="table-responsive" *ngIf="!loading">
  <table class="table table-striped table-hover table-sm">
    <thead class="thead-success">
      <tr>
        <th>#</th>
        <th>Nombre</th>
        <th>Activo</th>
        <th>Acciones</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let certificatetype of certificateTypes | paginate: { itemsPerPage: 15, currentPage: p }; let i= index;">
        <td>{{i + 1}}</td>
        <td>{{certificatetype.name}}</td>
        <td>
          <mat-icon class="font-weight-bold">
            {{ certificatetype.active ? 'check' : 'close' }}
          </mat-icon>
        </td>
        <td>
          <div class="col-12"></div>
          <div class="col-12"></div>
          <button (click)="setCertificateType(certificatetype, content)" type="button" class="btn btn-primary btn-sm">
            <mat-icon class="position-relative top-5 font-weight-bold">edit</mat-icon>
            Editar
          </button>
          <button type="button" class="btn btn-danger btn-sm" (click)="deactivateActivateCertificateType(certificatetype)">
            <mat-icon class="position-relative top-5">delete</mat-icon>
            Eliminar
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="loading" *ngIf="loading">
  <ng-template #loadingTemplate></ng-template>
  <ngx-loading [show]="loading" [template]="loadingTemplate"></ngx-loading>
</div>

<div class="paginationContainer" *ngIf="!loading">
  <pagination-controls autoHide="true" responsive="true" previousLabel="Anterior" nextLabel="Siguiente" (pageChange)="p= $event"></pagination-controls>
</div>