<app-navbar></app-navbar>
<div class="row">
  <div class="col">
    <div class="filtersContainer">
      <div class="row">
        <div class="col-3">
          <button type="button" class="btn btn-outline-dark btn-block" (click)="isCollapsed= !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="collapsibleFilters">
            <i class="{{ isCollapsed ? 'fa fa-eye-slash' : 'fa fa-eye' }}"></i>
            {{ isCollapsed ? 'Ocultar Filtros' : 'Mostrar Filtros' }}
          </button>
        </div>

        <div class="col-3 offset-6">
          <button class="btn btn-info btn-block" (click)="openForm(null)">
            <i class="fa fa-plus"></i>
            Nuevo contacto
          </button>
        </div>
      </div>

      <div class="" id="collapsibleFilters" [ngbCollapse]="!isCollapsed">
        <div class="row">

          <div class="col-3">
            <label>
              Certificado Orgánico
            </label>

            <ng-select [items]="listCertificates" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindValue="id" bindLabel="name" name="contactrListCertificate"
              placeholder="Certificado list" [multiple]="false"
              [(ngModel)]="filters['q[contacts_list_certificates_certificate_id_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              Productor
            </label>

            <ng-select [items]="yesOrNot" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" name="contactIsProducer" placeholder="Productor" [multiple]="false"
              bindValue="value" bindLabel="name" [(ngModel)]="filters['q[producer_eq]']"></ng-select>
          </div>

          <div class="col-3">
            <label>
              Coordinador
            </label>

            <ng-select [items]="yesOrNot" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" name="contactIsCoordinator" placeholder="Coordinador" [multiple]="false"
              bindValue="value" bindLabel="name" [(ngModel)]="filters['q[coordinator_eq]']"></ng-select>
          </div>

          <div class="col-3">
            <label>
              Contacto
            </label>

            <ng-select [items]="allContacts" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="full_name" bindValue="id" name="contactName" placeholder="Contacto"
              [multiple]="false" [(ngModel)]="filters['q[id_eq]']"></ng-select>
          </div>

          <div class="col-3">
            <label>
              Certificado social
            </label>

            <ng-select [items]="boolCertificates" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindValue="id" bindLabel="name" name="contactBoolCertificate"
              placeholder="Certificados bool" [multiple]="false"
              [(ngModel)]="filters['q[contacts_bool_certificates_certificate_id_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              Activo
            </label>

            <ng-select [items]="yesOrNot" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" name="contactIsActive" placeholder="Activo" [multiple]="false" bindValue="value"
              bindLabel="name" [(ngModel)]="filters['q[active_as_contact_eq]']"></ng-select>
          </div>

          <div class="col-3">
            <label>
              Apodo
            </label>

            <ng-select [items]="nicknames" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" name="contactNickname" placeholder="Apodo del contacto" [multiple]="false"
              [(ngModel)]="filters['q[nickname_eq]']"></ng-select>
          </div>

          <div class="col-3">
            <label>
              Grupo Entrega
            </label>

            <ng-select [items]="deliveryGroups" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindValue="id" bindLabel="name" name="contactDeliveryGroup"
              placeholder="Grupo del contacto" [multiple]="false"
              [(ngModel)]="filters['q[delivery_groups_contacts_delivery_group_id_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              FUNDOPO
            </label>

            <ng-select [items]="yesOrNot" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" name="contactIsFundopo" placeholder="Fundopo" [multiple]="false" bindValue="value"
              bindLabel="name" [(ngModel)]="filters['q[fundopo_eq]']"></ng-select>
          </div>


          <div class="col-3">
            <label>
              Procesa producto
            </label>

            <ng-select [items]="yesOrNot" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" name="contactIsProcessor" placeholder="Procesa el producto" [multiple]="false"
              bindValue="value" bindLabel="name" [(ngModel)]="filters['q[processor_eq]']"></ng-select>
          </div>

          <div class="col-3">
            <label>
              Suplidor
            </label>

            <ng-select [items]="yesOrNot" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" name="contactIsSupplier" placeholder="Suplidor" [multiple]="false"
              bindValue="value" bindLabel="name" [(ngModel)]="filters['q[supplier_eq]']"></ng-select>
          </div>

          <div class="col-3">
            <label>
              Código
            </label>

            <ng-select [items]="allContacts" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="code" bindValue="code" name="contactCode"
              placeholder="Codigo del contacto" [multiple]="false" [(ngModel)]="filters['q[code_eq]']">
            </ng-select>
          </div>

        </div>

        <div class="row mt-3 align-bottom">
          <div class="col-3 offset-6 d-flex align-items-end">
            <button type="button" class="btn btn-outline-dark btn-block"
              (click)="getContacts(1, itemsPerPage, filters, 'xlsx')">
              <div class="load-container" *ngIf="loadingExcel">
                <div class="loader-green d-inline-block"></div>
              </div>
              <i class="fa fa-download" *ngIf="!loadingExcel"></i>
              Descargar excel
            </button>
          </div>
          <div class="col-3 d-flex align-items-end">
            <button type="button" class="btn btn-success btn-block" (click)="getContacts(1, itemsPerPage, filters)">
              <i class="fa fa-filter"></i>
              Filtrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!loading">
  <table class="table sticky-yacao green table-striped table-hover table-sm">
    <thead>
      <tr>
        <th>#</th>
        <th>Código</th>
        <th>Suplidor</th>
        <th>Coordinador</th>
        <th>Productor</th>
        <th>Nombre</th>
        <th>Apodo</th>
        <th>
          Coordinador en
        </th>
        <th>
          Productor en
        </th>
        <th>
          Activo
        </th>
        <th>
          BSS.
        </th>
        <th>
          UE/NOP
        </th>
        <th>
          Trans.
        </th>
        <th>
          FFL
        </th>
        <th>
          FLO
        </th>
        <th>
          UTZ
        </th>
        <th>Acciones</th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let contact of contacts | paginate: { itemsPerPage: 25, currentPage: currentPage, totalItems: totalItems }; let i= index;">
        <td>
          {{globals.getAbsoluteIndex(25, currentPage, i) + 1}}
        </td>

        <td>
          {{contact.code}}
        </td>

        <td>
          <mat-icon class="font-weight-bold">
            {{ contact.supplier ? 'check' : 'close' }}
          </mat-icon>
        </td>

        <td>
          <mat-icon class="font-weight-bold">
            {{ contact.coordinator ? 'check' : 'close' }}
          </mat-icon>
        </td>

        <td>
          <mat-icon class="font-weight-bold">
            {{ contact.producer ? 'check' : 'close' }}
          </mat-icon>
        </td>

        <td>
          {{contact.name}} {{contact.surname}}
        </td>

        <td>
          {{contact.nickname}}
        </td>

        <td>
          <div class="col-12" *ngFor="let groups of contact.coordinator_in_delivery_groups">
            {{groups?.name}}
          </div>
        </td>

        <td>
          <div class="col-12" *ngFor="let groups of contact.producer_in_delivery_groups">
            {{groups?.name}}
          </div>
        </td>

        <td>
          <mat-icon class="font-weight-bold">
            {{ contact.active_as_contact ? 'check' : 'close' }}
          </mat-icon>
        </td>

        <td>
          {{getQuotaOf(contact, 'biosuisse')}}
        </td>

        <td>
          {{getQuotaOf(contact, 'ue/nop')}}
        </td>

        <td>
          {{getQuotaOf(contact, 'trans')}}
        </td>

        <td>
          <mat-icon class="font-weight-bold">
            {{ haveCertificate(contact, 'ffl') ? 'check' : 'close' }}
          </mat-icon>
        </td>
        <td>
          <mat-icon class="font-weight-bold">
            {{ haveCertificate(contact, 'flo') ? 'check' : 'close' }}
          </mat-icon>
        </td>
        <td>
          <mat-icon class="font-weight-bold">
            {{ haveCertificate(contact, 'utz') ? 'check' : 'close' }}
          </mat-icon>
        </td>

        <td>
          <div class="nav-item dropdown" ngbDropdown>
            <a class="btn btn-success btn-sm dropdown-toggle white" ngbDropdownToggle>
              Selecciona acción
            </a>

            <div class="dropdown-menu" aria-labelledby="admin" ngbDropdownMenu>
              <button class="btn btn-sm dropdown-item" (click)="openForm(contact)">
                <mat-icon class="position-relative top-5 text-primary">edit</mat-icon>
                Editar
              </button>

              <button class="btn btn-sm dropdown-item" (click)="deactivateActivateContact(contact)">
                <mat-icon class="position-relative top-5 text-danger">delete</mat-icon>
                {{contact.active_as_contact ? 'Desactivar' : 'Activar'}}
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!-- Loading div-->
<div class="loading" *ngIf="loading">
  <ng-template #loadingTemplate></ng-template>
  <ngx-loading [show]="loading" [template]="loadingTemplate"></ngx-loading>
</div>

<!-- PAGER -->
<div class="paginationContainer" *ngIf="!loading">
  <pagination-controls autoHide="true" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
    (pageChange)="getContacts($event, itemsPerPage, filters)">
  </pagination-controls>
</div>

<app-contact-form [totalItems]="totalItems" [currentPage]="currentPage" [itemsPerPage]="itemsPerPage"
  [contacts]="contacts" [allContacts]="allContacts" [zones]="zones" [listCertificates]="listCertificates"
  [boolCertificates]="boolCertificates" [deliveryGroups]="deliveryGroups" (result)="setContacts($event)">
</app-contact-form>