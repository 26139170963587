import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {
  @ViewChild('alertModal', { static: true }) alertModal;
  @Output() result: EventEmitter<{ reason: string, returnValue: any }> = new EventEmitter<{ reason: string, returnValue: any }>();

  alertModalRef: NgbModalRef;
  alertModalHeader: string = '';
  alertModalMessage: string = '';

  returnValue: any;

  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };

  constructor(
    private _modalService: NgbModal,
    private translate: TranslateService) {
    // translate.setDefaultLang('es');
  }

  ngOnInit() {

  }

  openModal(modal_header: string, modal_body: string, returnValue: any = null, modals_z_index: number = 0, options?: NgbModalOptions) {
    this.changeModalsZIndexTo(modals_z_index);
    this.alertModalHeader = modal_header;
    this.alertModalMessage = modal_body;
    this.returnValue = returnValue;
    this.alertModalRef = this._modalService.open(this.alertModal, options ? options : this.modalOptions);
  }

  closeModal(cause: string) {
    this.alertModalRef && this.alertModalRef.dismiss(cause);
    this.result.emit({ reason: cause, returnValue: this.returnValue });
    this.changeModalsZIndexTo(1050);
  }

  changeModalsZIndexTo(value: number = 0) {
    _.each(document.getElementsByClassName('modal'), (element, index) => {
      document.getElementsByClassName('modal')[index]['style']['z-index'] = value;
    });
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }
}
