

<ng-template #content let-modal let-d="dismiss">
  <form #Form="ngForm" (ngSubmit)="Form.form.valid && onSubmit(talbook)">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ (talbook.id ? 'pages.talbook_form.update' : 'pages.talbook_form.create') | translate }}{{'pages.talbook_form.title_label' | translate}}
      </h4>

      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="form-group">
        <label for="talbookLabel">
          {{ (generateLabel ? 'pages.talbook_form.generate_label' : 'pages.talbook_form.book_label') | translate}}
        </label>

        <div class="load-container" *ngIf="generateLabel">
          <div class="loader-green d-inline-block"></div>
        </div>

        <input type="text" class="form-control" id="talbookLabel" [(ngModel)]="talbook.label"
          placeholder="Etiqueta del libro" name="talbookLabel" disabled>
      </div>

      <div class="form-group" *ngIf="talbook.id">
        <label for="talbookNumberCurrent">
          {{'pages.talbook_form.current_page' | translate}}
        </label>

        <input type="text" class="form-control" id="talbookNumberCurrent" [(ngModel)]="talbook.current_page"
          name="talbookNumberCurrent" [disabled]="!globals.currentUserRolesAsBooleanObject.admin">
      </div>

      <div class="form-group" *ngIf="talbook.id && talbook.talbook_type.id === 1">
        <label for="talbookCenterId">
          {{'pages.talbook_form.center' | translate}}
        </label>

        <ng-select id="talbookCenterId" [items]="allowedCenters" [searchable] [clearSearchOnAdd]
          notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name" [(ngModel)]="talbook.center"
          (change)="getAllowedUsers(talbook)" name="talbookCenter" placeholder="Selecciona el Centro"
          [compareWith]="compareFn" [multiple]="false" 
          [disabled]="!globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor">
        </ng-select>
      </div>

      <div class="form-group" *ngIf="talbook.id && !talbook.completed">
        <label for="talbookDriverId">
          {{'pages.talbook_form.receiver' | translate}}
        </label>

        <ng-select id="talbookDriverId" [items]="allowedUsers" [searchable] [clearSearchOnAdd]
          notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name" [(ngModel)]="talbook.receiver"
          (change)="clearTalbookCenterIfNecesary($event, talbook);getAllowedCenters(talbook)" name="talbookReceiver" placeholder="Selecciona el receptor"
          [compareWith]="compareFn" [multiple]="false">
        </ng-select>
      </div>

      <div class="form-group" *ngIf="!talbook.id">
        <label for="talbookTalonaryId">
          {{'pages.talbook_form.talbook' | translate}}
        </label>

        <ng-select id="talbookTalonaryId" [items]="activeTalonaries" [searchable] [clearSearchOnAdd]
          notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name" [(ngModel)]="talbook.talbook_type"
          name="talbookTalonary" placeholder="Selecciona el talonario" [compareWith]="compareFn" [multiple]="false"
          [clearable]="false" (change)="generateSheetsAndLabel($event)"
          [disabled]="!globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor" 
           required >
        </ng-select>

        <div *ngIf="Form.submitted && Form.form.invalid">
          <label style="color: red">{{'pages.talbook_form.obligatory_field' | translate}}</label>
        </div>
      </div>

      <div class="form-group" *ngIf="!talbook.id && talbook.label">
        <label for="talbookInitialPage">
          {{'pages.talbook_form.first_page' | translate}}
        </label>

        <input type="text" class="form-control" id="talbookInitialPage" [(ngModel)]="talbook.first_page"
          name="talbookNumberCurrent" [disabled]="!globals.currentUserRolesAsBooleanObject.admin"
          [ngClass]="{ 'is-invalid': Form.submitted && !firstPageValid(talbook)}"
          (input)="calculateLastPage(talbook)">

        <div *ngIf="Form.submitted && !firstPageValid(talbook)" class="invalid-tooltip">
          <div *ngIf="!firstPageValid(talbook)">
            {{'pages.talbook_form.pattern_label1' | translate}}
            {{ talbook.label.split('E')[1].split('N')[0] }}{{'pages.talbook_form.pattern_label2' | translate}}{{ talbook.talbook_type.number_of_digits }}{{'pages.talbook_form.pattern_label3' | translate}}
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="talbook.id">
        <mat-checkbox [(ngModel)]="talbook.active" name="talbookActive">
          {{'pages.talbook_form.active' | translate}}
        </mat-checkbox>
      </div>

      <div class="form-group" *ngIf="talbook.id">
        <mat-checkbox [(ngModel)]="talbook.completed" name="talbookComplete">
          {{'pages.talbook_form.completed' | translate}}
        </mat-checkbox>
      </div>
    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" name="{{talbook.id ? 'update' : 'submit'}}Talbook"
        [disabled]="sending">
        {{ (talbook.id ? 'pages.talbook_form.update' : 'pages.talbook_form.create') | translate }}
      </button>

      <button type="button" class="btn btn-danger" (click)="d('button click')" name="closeModal"
        [disabled]="sending">{{'pages.talbook_form.cancel' | translate}}</button>
    </div>
  </form>
</ng-template>