import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import * as _ from 'lodash';

// Models
import { DeliveryGroupModel } from 'src/app/models/delivery-group.model';
import { ContactModel } from 'src/app/models/contact.model';
import { CertificateModel } from 'src/app/models/certificate.model';

// Services 
import { ContactService } from 'src/app/services/contact.service';

// Component
import { DeliveryGroupsProducersComponent } from '../delivery-groups-producers/delivery-groups-producers.component';

@Component({
  selector: 'delivery-groups-component',
  templateUrl: './delivery-groups.component.html',
  styleUrls: ['./delivery-groups.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeliveryGroupsComponent implements OnInit {
  @ViewChild('deliveryGroupModal', { static: true }) private modal;
  @ViewChild(DeliveryGroupsProducersComponent, { static: true }) private producersModal: DeliveryGroupsProducersComponent;

  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };

  private modalRef: NgbModalRef;

  deliveryGroups: Array<DeliveryGroupModel> = new Array<DeliveryGroupModel>();
  deliveryGroupPage = 1;

  listCertificates: Array<CertificateModel> = new Array<CertificateModel>();
  supplier: ContactModel = new ContactModel();

  constructor(private _modalService: NgbModal, private _contactService: ContactService) { }

  ngOnInit() {
  }

  openModal(supplier: ContactModel, certificates: Array<CertificateModel>) {
    this.deliveryGroups = [];
    this.supplier = new ContactModel();
    this.getDeliveryGroupOfSupplier(supplier.id);
    this.supplier = supplier;
    this.listCertificates = certificates;
    this.modalRef = this._modalService.open(this.modal, this.modalOptions);
  }

  closeModal() {
    this.modalRef && this.modalRef.dismiss();
  }

  getQuotaOfDeliveryGroup(deliveryGroup: DeliveryGroupModel, alias: string): number {
    const cert = _.find(this.listCertificates, { alias: alias });
    const groupCert = _.find(deliveryGroup['max_quotas'], { certificate_id: cert['id'] });

    return groupCert ? groupCert['quota'] : 0;
  }

  haveCertificate(element: any, alias: string) {
    return !!_.find(element['bool_certificates'], { alias: alias });
  }

  openProducerModal(deliveryGroup: DeliveryGroupModel) {
    this.producersModal.setDeliveryGroupValue(deliveryGroup);
  }

  private async getDeliveryGroupOfSupplier(supplier_id: number) {
    await this._contactService
      .getContactDeliveryGroups(supplier_id)
      .then(
        res => {
          console.log(res);
          this.deliveryGroups = res['delivery_groups'];
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      )
  }

}
