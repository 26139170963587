import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Models


import { environment } from '../../environments/environment';
import { ConceptModel } from '../models/concept.model';

@Injectable({
  providedIn: 'root'
})
export class ConceptsService {
  private readonly ROUTE = environment.routes.api + environment.routes.concepts;

  constructor(  private _http: HttpClient) { }

  getConcepts(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(this.ROUTE, { headers: this.getHeaders() })
        .subscribe(
          (res: any) => {
            res['success'] ? resolve(res) : reject(res);
          },
          (err: any) => {
            reject(err);
          }
        )
    });
  }

  getConcept(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(`${this.ROUTE}/${id}`, { headers: this.getHeaders() })
      .subscribe(
        (res: any) => {
          res['success'] ? resolve(res) : reject(res);
        },
        (err: any) => {
          reject(err);
        }
      )
    });
  }

  createConcept(concept: ConceptModel): Promise<any>{
    return new Promise((resolve, reject) => {
      this._http.post(this.ROUTE, { concept: concept }, { headers: this.getHeaders() })
      .subscribe(
        (res: any) => {
          res['success'] ? resolve(res) : reject(res);
        },
        (err: any) => {
          reject(err);
        }
      )
    });
  }

  updateConcept(concept: ConceptModel): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.put(`${this.ROUTE}/${concept.id}`, { concept: concept }, { headers: this.getHeaders() })
      .subscribe(
        (res: any) => {
          res['success'] ? resolve(res) : reject(res);
        },
        (err: any) => {
          reject(err);
        }
      )
    });
  }

  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('yacao:app:token'),
        'user_id': localStorage.getItem('yacao:app:user_id')
      }
    );
  }
}
