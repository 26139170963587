import { CenterModel } from "./center.model";
import { RoleModel } from "./role.model";
import { ZoneModel } from "./zone.model";

export class UserModel {
  active: boolean = true;
  alias: string;
  center: Array<CenterModel>;
  center_id: number;
  email: string;
  id: number;
  name: string;
  roles: Array<RoleModel> = new Array<RoleModel>();
  role_ids: Array<number>;
  zones: Array<ZoneModel> = new Array<ZoneModel>();
  zone_ids: Array<number>;
  password: string = '';
  user_password: string = '';
  supplier_diceros_id: number;
  commission: number;
  work_center_ids: number[];
  work_centers: CenterModel[] = new Array<CenterModel>();
  logged_in: boolean;
  is_a_translator: boolean;
}
