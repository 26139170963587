<app-navbar></app-navbar>

<div class="row">
<!-- FILTROS -->
<div class="col">
  <div class="filtersContainer">
    <div class="row">
      <div class="col-3">
        <button type="button" class="btn btn-outline-dark btn-block" (click)="isCollapsed= !isCollapsed"
          [attr.aria-expanded]="!isCollapsed" aria-controls="collapsibleFilters">
          <!-- <mat-icon class="position-relative top-5 m-0">{{ isCollapsed ? 'visibility_off' : 'visibility' }}</mat-icon> -->
          <i class="{{ isCollapsed ? 'fa fa-eye-slash' : 'fa fa-eye' }}"></i>
          {{ isCollapsed ? 'Ocultar Filtros' : 'Mostrar Filtros' }}
        </button>
      </div>
    </div>

    <div class="" id="collapsibleFilters" [ngbCollapse]="!isCollapsed">
      <div class="row">
        <div class="col-3">
          <label>
            Fecha creación desde
          </label>

          <div class="input-group">
            <!-- input box -->
            <input class="form-control" name="mydate" placeholder="dd/mm/aaaa" angular-mydatepicker
              #dp="angular-mydatepicker" [options]="myDpOptions" (dateChanged)="onDateChanged($event, 'startDate')"
              [locale]="'es'" (click)="dp.toggleCalendar()" />

            <!-- clear date button -->
            <div class="input-group-append">
              <button type="button" class="btn btn-secondary" *ngIf="filters.g[0].contract_starts_gteq"
                (click)="dp.clearDate()">
                <i class="fa fa-close"></i>
              </button>
            </div>

            <!-- toggle calendar button -->
            <div class="input-group-append">
              <button type="button" class="btn btn-secondary" (click)="dp.toggleCalendar()">
                <i class="fa fa-calendar-o"></i>
              </button>
            </div>

          </div>
        </div>

        <div class="col-3">
          <label>
            Suplidor
          </label>

          <ng-select [items]="suppliers" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
            loadingText="Buscando" bindLabel="full_name" bindValue="id" name="producer" placeholder="Suplidor"
            [multiple]="false" [(ngModel)]="filters['q[contact_id_eq]']">
          </ng-select>
        </div>


        <div class="col-3">
          <label>
            Completado
          </label>

          <ng-select [items]="yesOrNot" bindValue="value" bindLabel="name" [searchable] [hideSelected]="true"
            notFoundText="No hay coincidencias" loadingText="Buscando" name="isComplete" placeholder="Completo"
            [multiple]="false" [(ngModel)]="filters['q[completed_eq]']">
          </ng-select>
        </div>

        <div class="col-3">
          <label>
            Recibo
          </label>

          <input class="form-control" type="text" [(ngModel)]="filters['q[talbook_sheet_cont]']"
            placeholder="Número del recibo" />
        </div>

        <div class="col-3">
          <label>
            Fecha creación hasta
          </label>

          <!-- <input class="form-control" type="date" [(ngModel)]="filters.g[1].collected_date_lteq"
              [(ngModel)]="filters.g[1].contract_starts_lteq" /> -->


          <div class="input-group">
            <!-- input box -->
            <input class="form-control" name="mydate" placeholder="dd/mm/aaaa" angular-mydatepicker
              #dp1="angular-mydatepicker" [options]="myDpOptions" (dateChanged)="onDateChanged($event, 'endDate')"
              [locale]="'es'" (click)="dp1.toggleCalendar()" />

            <!-- clear date button -->
            <div class="input-group-append">
              <button type="button" class="btn btn-secondary" *ngIf="filters.g[1].collected_date_lteq"
                (click)="dp1.clearDate()">
                <i class="fa fa-close"></i>
              </button>
            </div>

            <!-- toggle calendar button -->
            <div class="input-group-append">
              <button type="button" class="btn btn-secondary" (click)="dp1.toggleCalendar()">
                <i class="fa fa-calendar-o"></i>
              </button>
            </div>

          </div>
        </div>

        <div class="col-3">
          <label>
            Anulado
          </label>

          <ng-select [items]="yesOrNot" bindValue="value" bindLabel="name" [searchable] [hideSelected]="true"
            notFoundText="No hay coincidencias" loadingText="Buscando" name="isNull" placeholder="Nulo"
            [multiple]="false" [(ngModel)]="filters['q[nulled_eq]']">
          </ng-select>
        </div>

        <div class="col-3">
          <label>
            Creador
          </label>

          <ng-select [items]="buyers" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
            loadingText="Buscando" bindLabel="name" bindValue="id" name="receiver" placeholder="Receptor"
            [multiple]="false" [(ngModel)]="filters['q[original_receiver_id_eq]']">
          </ng-select>
        </div>

        <div class="col-3">
          <label>
            Tipo de pago
          </label>

          <ng-select [items]="ticketTypeFilter" bindLabel="name" bindValue="value" [searchable] [hideSelected]="true"
              notFoundText="No hay coincidencias" loadingText="Buscando" name="isNull" placeholder="Tipo de contrato"
              [multiple]="false" [(ngModel)]="filters['q[ticket_type_cd_eq]']">
            </ng-select>
        </div>

      </div>

      <div class="row mt-3 align-bottom">
        <div class="col-3 offset-6 d-flex align-items-end">
          <button type="button" class="btn btn-outline-dark btn-block"
            (click)="getTickets(1, itemsPerPage, filters, 'xlsx')">
            <div class="load-container" *ngIf="loadingExcel">
              <div class="loader-green d-inline-block"></div>
            </div>
            <i class="fa fa-download" *ngIf="!loadingExcel"></i>
            Descargar excel
          </button>
        </div>

        <div class="col-3 d-flex align-items-end">
          <button type="button" class="btn btn-success btn-block" (click)="getTickets(1, itemsPerPage, filters)">
            <i class="fa fa-filter"></i>
            Filtrar
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
</div>

<!-- Loading div-->
<div class="loading" *ngIf="loading">
  <ng-template #loadingTemplate></ng-template>
  <ngx-loading [show]="loading" [template]="loadingTemplate"></ngx-loading>
</div>

<!-- LISTADO DE RECIBOS -->
<div *ngIf="!loading">
  <table class="table sticky-yacao green table-striped table-hover table-sm">
    <thead>
      <tr>
        <th>
          Fecha
          <br />
          creación
        </th>

        <th>
          Estado
        </th>

        <th>
          Nro.
          <br />
          recibo
        </th>

        <th>
          Recibo
        </th>

        <th>
          Creador
        </th>

        <th>
          Concepto
        </th>

        <th>Precio</th>

        <th>
          Suplidor
        </th>

        <th>
          Acciones
        </th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let payment of payments | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; index as i;">
        <td>
          <span>
            {{payment.collected_date | date:'dd/MM/yy'}}
          </span>
        </td>

        <td>
          {{ getStateOf(payment) }}
        </td>

        <td>
          {{payment.talbook_sheet}}
        </td>



        <td>
          <mat-icon class="pointer" *ngIf="payment.receipt"
            (click)="openGallery(('Recibo ' + payment.talbook_sheet), [payment.receipt.url])">
            image
          </mat-icon>

          <mat-icon *ngIf="!payment.receipt">
            close
          </mat-icon>
        </td>

        <td>
          {{payment.original_receiver?.name}}
        </td>
        
        <td><span *ngFor="let concept of payment.concepts"> {{ concept.title }}</span></td>

        <td>{{ payment.contract_price }}</td>

        <td class="text-dark">
          {{  payment.supplier?.full_name }}
        </td>

        <td>
          <div class="nav-item dropdown" ngbDropdown>
            <a class="btn btn-success btn-sm dropdown-toggle white" ngbDropdownToggle>
              Opciones
            </a>

            <div class="dropdown-menu" aria-labelledby="admin" ngbDropdownMenu>
              <button class="btn btn-sm dropdown-item" (click)="editPayment(payment)">
                <mat-icon class="position-relative top-5 text-primary">
                  edit
                </mat-icon>
                Editar
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<app-payment-form [suppliers]="suppliers" [buyers]="buyers" [concepts]="concepts" (result)="setPayment($event)"></app-payment-form>

<app-images-modal></app-images-modal>