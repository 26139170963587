<!-- Molda template -->


<ng-template #content let-modal let-d="dismiss">
  <form #Form="ngForm" (ngSubmit)="Form.form.valid && onSubmit(commission)">
    <div class="modal-header">
      <h4 class="modal-title">{{ commission.id ? 'Actualizar comision' : 'Crear comision'}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">


      <div class="form-group">
        <label for="commissionNameId">Nombre de la comision</label>
        <input type="text" #commissionName="ngModel" class="form-control" id="commissionNameId" [(ngModel)]="commission.name"
          placeholder="Name" name="commissionName" [ngClass]="{ 'is-invalid': Form.submitted && commissionName.invalid}" required>

        <div *ngIf="Form.submitted && commissionName.invalid" class="invalid-tooltip">
          <div *ngIf="commissionName.errors.required">El nombre es obligatorio</div>
        </div>
      </div>

      <div class="form-group">
        <label for="commissionDescriptionId">Descripcion de la comision</label>
        <textarea name="commissionDescription" type="text" id="commissionDescriptionId" class="md-textarea form-control"
          mdbInputDirective [(ngModel)]="commission.description" aria-describedby="commissionDescription"></textarea>
        <small id="commissionDescription" class="form-text text-muted">Descripción de la comision</small>
      </div>

      <div class="form-group">
        <label for="commissionAmountId">Cantidad de la comision</label>
        <input type="number" #commissionAmount="ngModel" class="form-control" id="commissionAmountId" [(ngModel)]="commission.amount"
          placeholder="Cantidad" name="commissionAmount" [ngClass]="{ 'is-invalid': Form.submitted && commissionAmount.invalid}" min="0" required>

        <div *ngIf="Form.submitted && commissionAmount.invalid" class="invalid-tooltip">
          <div *ngIf="commissionAmount.errors.required">La cantidad es obligatoria</div>
        </div>
      </div>

      <div class="form-group">
        <mat-checkbox [(ngModel)]="commission.active" name="commissionActive">Activo</mat-checkbox>
      </div>

    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" name="submitcommission"
        [disabled]="sending">{{ commission.id ? 'Guardar' : 'Crear'}}</button>
      <button type="button" class="btn btn-danger" (click)="d('button click')" name="closeModal"
        [disabled]="sending">Cancelar</button>
    </div>
  </form>
</ng-template>