<app-navbar></app-navbar>
<div class="row">
  <div class="col">
    <div class="filtersContainer">
      <div class="row">
        <div class="col-3">
          <button type="button" class="btn btn-outline-dark btn-block" (click)="isCollapsed= !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="collapsibleFilters">
            <!-- <mat-icon class="position-relative top-5 m-0">{{ isCollapsed ? 'visibility_off' : 'visibility' }}</mat-icon> -->
            <i class="{{ isCollapsed ? 'fa fa-eye-slash' : 'fa fa-eye' }}"></i>
            {{ (isCollapsed ? 'commons.filters.filter_button_hidde' : 'commons.filters.filter_button_show') | translate}}
          </button>
        </div>

        <div class="col-3 offset-6">
          <button class="btn btn-info btn-block" (click)="openForm(null)"
            *ngIf="globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor">
            <!-- <mat-icon class="position-relative top-5 font-weight-bold">add</mat-icon> -->
            <i class="fa fa-plus"></i>
           {{'commons.filters.buttons.new_talonary' | translate}}
          </button>
        </div>
      </div>

      <div class="" id="collapsibleFilters" [ngbCollapse]="!isCollapsed">
        <div class="row">
          <div class="col-3"
            *ngIf="globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor">
            <label>
              {{'commons.filters.labels.center' | translate}}
            </label>

            <ng-select [items]="centers" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="name" bindValue="id" name="talbooktalbookcenter" placeholder="Centro"
              [multiple]="false" [(ngModel)]="filters['q[center_id_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              {{'commons.filters.labels.receiver_buyer' | translate}}
            </label>

            <ng-select [items]="users" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="name" bindValue="id" name="talbookreceiver"
              placeholder="Receptor/Comprador" [multiple]="false" [(ngModel)]="filters['q[receiver_id_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              {{'commons.filters.labels.active' | translate}}
            </label>

            <ng-select [items]="yesOrNot" bindLabel="name" bindValue="value" [searchable] [hideSelected]="true"
              notFoundText="No hay coincidencias" loadingText="Buscando" name="talbookisActive" placeholder="Activo"
              [multiple]="false" [(ngModel)]="filters['q[active_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              {{'commons.filters.labels.completed' | translate}}
            </label>

            <ng-select [items]="yesOrNot" bindLabel="name" bindValue="value" [searchable] [hideSelected]="true"
              notFoundText="No hay coincidencias" loadingText="Buscando" name="talbookisComplete" placeholder="Completo"
              [multiple]="false" [(ngModel)]="filters['q[completed_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              {{'commons.filters.labels.talonary_type' | translate}}
            </label>
            <ng-select [items]="talonaries" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="name" bindValue="id" name="talbookType" placeholder="Tipo de talonario"
              [multiple]="false" [(ngModel)]="filters['q[talbook_type_id_eq]']">
            </ng-select>
          </div>
        </div>

        <div class="row mt-3 align-bottom">
          <div class="col-3 offset-6 d-flex align-items-end">
            <button type="button" class="btn btn-outline-dark btn-block"
              (click)="getTalBooks(1, itemsPerPage, filters, 'xlsx')">
              <div class="load-container" *ngIf="loadingExcel">
                <div class="loader-green d-inline-block"></div>
              </div>
              <i class="fa fa-download" *ngIf="!loadingExcel"></i>
              {{'commons.filters.excel_download_button' | translate}}
            </button>
          </div>
          <div class="col-3  d-flex align-items-end">
            <button type="button" class="btn btn-success btn-block" (click)="getTalBooks(1, itemsPerPage, filters)">
              <i class="fa fa-filter"></i>
              {{'commons.filters.filter_button' | translate}}
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-talbook-form [users]="users" [talbooks]="talbooks" [centers]="centers" [currentPage]="currentPage"
  [itemsPerPage]="itemsPerPage" [totalItems]="totalItems" [talonaries]="talonaries" [receivers]="receivers"
  [buyers]="buyers" [payers]="payers" (result)="setTalbooks($event)"></app-talbook-form>

<div *ngIf="!loading">
  <table class="table sticky-yacao green table-striped table-hover table-sm">
    <thead>
      <tr>
        <th>
          {{'pages.table_headers.#' | translate}}
        </th>
        <th>
          {{'pages.table_headers.label' | translate}}
        </th>
        <th>
          {{'pages.table_headers.ticket_type' | translate}}
          </th>
        <th>
          {{'pages.table_headers.center' | translate}}
        </th>
        <th>
          {{'pages.table_headers.receiver_buyer' | translate}}
        </th>
        <th>
          {{'pages.table_headers.active' | translate}}
        </th>
        <th>
          {{'pages.table_headers.completed' | translate}}
        </th>
        <th>
          {{'pages.table_headers.current_page' | translate}}
        </th>
        <th>
          {{'pages.table_headers.pages' | translate}}
        </th>
        <th>
          {{'pages.table_headers.actions' | translate}}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let talbook of talbooks | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i= index;">
        <td>{{globals.getAbsoluteIndex(itemsPerPage, currentPage, i) + 1}}</td>
        <td>{{talbook.label}}</td>
        <td>
          {{ talbook.talbook_type?.name }}
        </td>
        <td>{{talbook.center?.name}}</td>
        <td>{{talbook.receiver?.name}}</td>
        <td>
          <mat-icon class="font-weight-bold"> {{ talbook.active ? 'check' : 'close' }}</mat-icon>
        </td>
        <td>
          <mat-icon class="font-weight-bold"> {{ talbook.completed ? 'check' : 'close' }}</mat-icon>
        </td>
        <td>{{talbook.current_page}}</td>
        <td>{{talbook.first_page}} | {{talbook.last_page}}</td>
        <td>
          <div class="nav-item dropdown" ngbDropdown>
            <a class="btn btn-success btn-sm dropdown-toggle white" ngbDropdownToggle>
              {{'commons.actions_dropdown.title' | translate}}
            </a>

            <div class="dropdown-menu" aria-labelledby="admin" ngbDropdownMenu>
              <button class="btn btn-sm dropdown-item" (click)="openForm(talbook)">
                <mat-icon class="position-relative top-5 text-primary">edit</mat-icon>
                {{'commons.actions_dropdown.options.edit' | translate}}
              </button>

              <button class="btn btn-sm dropdown-item" (click)="deactivateActivateTalBook(talbook)"
                [disabled]="!globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor">
                <mat-icon class="position-relative top-5 text-danger">delete</mat-icon>
                {{ (talbook.active ? 'commons.actions_dropdown.options.deactivate' : 'commons.actions_dropdown.options.activate') | translate}}
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="loading" *ngIf="loading">
  <ng-template #loadingTemplate></ng-template>
  <ngx-loading [show]="loading" [template]="loadingTemplate"></ngx-loading>
</div>

<div class="paginationContainer" *ngIf="!loading">
  <pagination-controls autoHide="true" responsive="true" previousLabel="{{ 'commons.pagination_labels.prev' | translate }}" nextLabel="{{ 'commons.pagination_labels.next' | translate }}"
    (pageChange)="getTalBooks($event, itemsPerPage, filters)"></pagination-controls>
</div>
