
<!-- Modal to create deliveryGroup-->
<ng-template #content let-modal let-d="dismiss">
  <form #Form="ngForm" (ngSubmit)="Form.form.valid && onSubmit(deliveryGroup)">
    <div class="modal-header">
      <h4 class="modal-title">
        {{!deliveryGroup.id ? 'Crear' : 'Actulizar'}} grupo
      </h4>

      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="form-group col-12">
        <label for="groupNameId">
          Nombre grupo
        </label>

        <input type="text" #deliveryGroupName="ngModel" class="form-control col-xs-4" id="groupNameId"
          [(ngModel)]="deliveryGroup.name" placeholder="Name" name="groupName"
          [ngClass]="{ 'is-invalid': Form.submitted && deliveryGroupName.invalid}" required>

        <div *ngIf="Form.submitted && deliveryGroupName.invalid" class="invalid-tooltip">
          <div *ngIf="deliveryGroupName.errors.required">El nombre es obligatorio</div>
        </div>
      </div>

      <div class="form-group col-12">
        <label for="groupCoordinatorId">
          Coordinador
        </label>

        <ng-select id="groupCoordinatorId" [items]="contacts" [searchable] [clearSearchOnAdd] loadingText="Buscando"
          bindLabel="code" [(ngModel)]="deliveryGroup.coordinator" name="deliveryGroupCoordinator"
          placeholder="Selecciona el coordinador del grupo" [compareWith]="compareFn" [multiple]="false">
        </ng-select>
      </div>

      <div class="form-group col-12">
        <label for="groupProducersId">
          Productores
        </label>

        <ng-select id="groupProducersId" [items]="producers" [searchable] [clearSearchOnAdd]
          notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="code"
          [(ngModel)]="deliveryGroup.producers" name="deliveryGroupProducers"
          placeholder="Selecciona a quienes representa" [compareWith]="compareFn" [multiple]="true"
          [hideSelected]="true"></ng-select>
      </div>

      <div class="form-group col-12">
        <mat-checkbox [(ngModel)]="deliveryGroup.active" name="deliveryGroupIsActive">
          Activo
        </mat-checkbox>
      </div>

    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" name="submitdeliveryGroup"
        [disabled]="sending">
        {{ deliveryGroup.id ? 'Guardar' : 'Crear'}}
      </button>
      
      <button class="btn btn-danger" (click)="d('button click')" name="closeModal" [disabled]="sending">
        Cancelar
      </button>
    </div>
  </form>
</ng-template>