import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';

import { UserModel } from '../models/user.model';

import { AuthService } from '../services/auth.service';

import * as _ from 'lodash';
import { UserService } from '../services/user.service';
import { TicketService } from '../services/ticket.service';
import { DownloadFileService } from '../services/download-file.service';
import { GlobalsService } from '../services/globals.service';
import { ToastrService } from 'ngx-toastr';
import { CONSTANTS } from '../app.consts';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../services/translation.service';
import { SettingService } from '../services/setting.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [GlobalsService]
})
export class AppComponent {

  title = 'Yacao App';
  user: UserModel = new UserModel();
  isNavbarCollapsed = true;
  mode = new FormControl('over');
  token: boolean;
  default_lang: string = 'es';

  constructor(
    public auth: AuthService,
    public globals: GlobalsService,
    private userService: UserService,
    private _translateService: TranslateService,
    private _translationService: TranslationService,
    private _settingService: SettingService
  ) {
    this.token = !!localStorage.getItem('yacao:app:token');
    this.initialice();
  }

  async getCurrentUser() {
    await this.userService.getUserBy(localStorage.getItem('yacao:app:user_id'))
      .then(
        async res => {
          if (res['status'] === 200) {
            this.setGlobalsCurrentUserDatas(res['user']);
          }
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }

  async initialice() {
    // const browser_lang: string = this._translateService.getBrowserLang();
    // this._translateService.use(browser_lang);
    this._getLanguage();
    CONSTANTS.CURRENT_USER_ROLES = [];
    const helper = new JwtHelperService();
    const token: string = localStorage.getItem('yacao:app:token');
    if (helper.isTokenExpired(token)) {
      token && await this.auth.logout();
      console.log('entra en initialize');
    } else {
      this.token && await this.getCurrentUser();
    }
  }

  // private canEnter(): boolean {
  //   let canEnter = false;
  //   if (this.globals.currentUserRolesAsBooleanObject['admin']
  //     || this.globals.currentUserRolesAsBooleanObject['manager']
  //     || this.globals.currentUserRolesAsBooleanObject['certification_manager']
  //     || this.globals.currentUserRolesAsBooleanObject['supervisor']
  //     || this.globals.currentUserRolesAsBooleanObject['buyer']) {
  //     canEnter = true;
  //   }
  //   return canEnter;
  // }


  private setGlobalsCurrentUserDatas(user: UserModel) {
    user['center'] && (this.globals.currentUserCenterId = user['center_id']);
    this.globals.currentUserWorkCentersIds = _.map(user.work_centers, 'id');

    this.globals.currentUserRoles = [];
    this.globals.currentUserRolesAsBooleanObject = {};
    this.globals.currentUserCanTranslate = user.is_a_translator;

    _.each(user['roles'], (role: any) => {
      this.globals.currentUserRoles.push(role);
 
      this.globals.currentUserRolesAsBooleanObject[role['alias']] = true;
    });

    CONSTANTS.CURRENT_USER_ROLES = _.map(this.globals.currentUserRoles, 'alias');
  }

  private async _getLanguage(): Promise<void> {
    await this._settingService.getAppSetting()
      .then(
        (res: any) => {
          const setting: { client_name: string, primary_language: string, have_language: boolean } = res['setting'];
          let language: string = setting.have_language ? (`${setting.client_name}_${setting.primary_language}`) : setting.primary_language;

          CONSTANTS.APP_LANGUAGE = language;
          this._translateService.use(language);
        }
      ).catch((err: any) => console.error(err));
  }
}
