export enum SettlementType {
  'prebilling',
  'dry_result'
}

export enum TicketType {
  'cocoa_receipt',
  'direct_purchase',
  'contracts',
  'income',
  'outcome'
}

export enum UnloadType {
  'provisional',
  'final'
}

export enum BigBagProcess {
  'fermentation',
  'semi_fermentation',
  'sanchez',
  'semi_dry_1',
  'semi_dry_2',
  'dry',
  'impurity',
  'refund',
  'weightloss'
}

export enum TicketUnloadState {
  'not_unloaded',
  'unloading',
  'provisional_unloaded',
  'unloaded',
  'in_center'
}

export enum UnloadState {
  'pending',
  'in_progress',
  'finished'
}

export enum TypeOfTalonary {
  'cocoa_receipt',
  'contracts',
  'income',
  'outcome'
}

export enum ContactPrebillingWeight {
  'receiver_weight',
  'supplier_weight'
}


export enum Roles {
  'admin',
  'supervisor',
  'manager',
  'buyer',
  'driver',
  'certification_manager',
  'unload_manager'
}