import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';

// Services
import { TradePriceService } from 'src/app/services/trade-price.service';
import { DownloadFileService } from 'src/app/services/download-file.service';

// Models
import { TradePriceModel } from 'src/app/models/trade-price';
import { FilterModel } from 'src/app/models/filter.model';

//  Components
import { TradePricesFormComponent } from '../modals/trade-prices-form/trade-prices-form.component';

@Component({
  selector: 'app-trade-prices-index',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss']
})
export class TradePricesPage implements OnInit {
  @ViewChild(TradePricesFormComponent, { static: true }) tradePriceForm: TradePricesFormComponent;

  tradePrices: Array<TradePriceModel> = new Array<TradePriceModel>();

  loading: boolean = true;
  loadingExcel: boolean = false;
  p: number = 1;
  itemsPerPage: number = 25;

  filters: FilterModel = new FilterModel();

  constructor(
    private _downloadFileService: DownloadFileService,
    private _tradePricesServices: TradePriceService) { }

  ngOnInit() {
    delete this.filters['g'];
    this.getTradePrices(1, this.itemsPerPage, this.filters);
  }

  openForm(tradePrice: TradePriceModel) {
    this.tradePriceForm.openForm(tradePrice);
  }



  // tradePriceHaveCertificate(certifidateAlias: string, tradePrice: TradePriceModel): boolean {
  //   const certificate = _.find(this.certificates, { alias: certifidateAlias });
  //   return certificate ? (tradePrice['certificate_id'] === certificate['id']) : false;
  // }

  deactivateActivateTradePrice(tradePrice: TradePriceModel) {
    tradePrice['active'] = !tradePrice['active'];
    this.tradePriceForm.updateTradePrice(tradePrice);
  }

  getTradePrices(page: number = 1, per_page: number = 25, params: {} = {}, format: string = 'json') {
    let parameters = _.cloneDeep(params);

    if (format === 'json') {
      this.loading = true;
    }

    if (format === 'xlsx') {
      this.loadingExcel = true;
    }

    parameters = Object.assign(parameters, { format: format });
    parameters['q[active_eq]'] = true;

    this._tradePricesServices
      .getTradePrices(page, per_page, parameters)
      .then(
        tradePriceRes => {
          if (format === 'json') {
            this.tradePrices = tradePriceRes['trade_prices'];
            this.loading = false;
          } else {
            this.loadingExcel = false;
            this._downloadFileService.downloadFile(tradePriceRes, 'prices', 'application/vnd.ms-excel');
          }
        }
      )
      .catch(
        tradePriceError => {
          console.error(tradePriceError);
        }
      );
  }

  setTradePrices(tradePrices: TradePriceModel[]): void {
    this.tradePrices = tradePrices;
  }

}
