import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

// Components
import { NotificationService } from 'src/app/services/notification.service';

// services
import { CocoaTypesService } from 'src/app/services/cocoatype.service';

// Models
import { CocoaTypeModel } from 'src/app/models/cocoa-type.model';

@Component({
  selector: 'app-cocoa-type-form',
  templateUrl: './cocoa-type-form.component.html',
  styleUrls: ['./cocoa-type-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CocoaTypeFormComponent implements OnInit {
  @ViewChild('content', { static: true }) private modal;
  @Input() private cocoaTypes: CocoaTypeModel[] = new Array<CocoaTypeModel>();
  @Output() result: EventEmitter<CocoaTypeModel[]> = new EventEmitter<CocoaTypeModel[]>();

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };

  cocoaType: CocoaTypeModel = new CocoaTypeModel();
  sending: boolean = false;

  constructor(
    private _modalService: NgbModal, 
    private _cocoaTypeService: CocoaTypesService,
    private _notification: NotificationService) { }

  ngOnInit() {
  }

  onSubmit(cocoaType: CocoaTypeModel): void {
    cocoaType.id ? this.updateCocoaType(cocoaType) : this.createCocoaType(cocoaType);
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  openForm(cocoaType: CocoaTypeModel): void {
    this.cocoaType = cocoaType ? _.cloneDeep(cocoaType) : new CocoaTypeModel();
    this.openModal();
  }

  updateCocoaType(cocoaType: CocoaTypeModel) {
    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    this._cocoaTypeService
      .updateCocoaType(cocoaType)
      .then(
        res => {
          const index = _.findIndex(this.cocoaTypes, { id: cocoaType['id'] });
          this.sending = false;
          this.cocoaTypes[index] = res['cocoa_type'];
          this.result.emit(this.cocoaTypes);
          this._notification.clear();
          this._notification.success('Actualizado correctamente');
          this.closeModal();
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      );
  }

  createCocoaType(cocoaType: CocoaTypeModel) {
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    this._cocoaTypeService
      .createCocoaType(cocoaType)
      .then(
        res => {
          this.cocoaTypes.push(res['cocoa_type']);
          this.result.emit(this.cocoaTypes);
     
          this._notification.clear();
          this._notification.success('Creado correctamente');

          this.closeModal();
        }
      )
      .catch(
        error => {
          this.sending = false;
          console.error(error);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (error.hasOwnProperty('errors')) {
            this._notification.error(error['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al crear');
          }
        }
      );
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }

}
