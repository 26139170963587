import { Injectable } from '@angular/core';
import { RoleModel } from '../models/role.model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  currentUserCenterId: number;
  currentUserWorkCentersIds: number[];
  currentUserRoles: Array<RoleModel> = new Array<RoleModel>();
  currentUserCanTranslate: boolean = false;
  currentUserRolesAsBooleanObject: any = {
    admin: false,
    supervisor: false,
    buyer: false,
    driver: false,
    unload_manager: false,
    certification_manager: false,
    manager: false
  };

  constructor() { }

  getAbsoluteIndex(itemsPerPage: number, currentPage: number, index: number): number {
    return (itemsPerPage * (currentPage - 1) + index);
  }

  truncateDecimals(number: number, decimals: number): string {
    return number ? number.toFixed(decimals) : null;
  }

  serverDateToPluginDate(date: string, format: string = 'DD-MM-YYYY'): any {
    const array_date: any[] = moment(date).format(format).split('-');
    return {
      day: Number(array_date[0]),
      month: Number(array_date[1]),
      year: Number(array_date[2])
    };
  }

  pluginDateToServerFormat(json: { year: number, month: number, day: number }, format: string = 'DD-MM-YYYY' ): any {
    const date: Date = new Date(json.year, (json.month -1), json.day);
    return moment(date, moment.ISO_8601, true).startOf('day').format(format);
  }
}
