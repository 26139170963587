import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';

// Models
import { BigBagModel } from '../models/big-bag.model';
@Injectable({
  providedIn: 'root'
})
export class BigBagService {
  ROUTE = environment.routes.api + environment.routes.big_bags;
  constructor(
    private _http: HttpClient
  ) { }

  updateBigBag(bigBag: BigBagModel)  {
    return new Promise((resolve, reject) => {
      this._http.put(`${this.ROUTE}/${bigBag['id']}`, { big_bag: bigBag }, { headers: this.getHeaders() })
      .toPromise()
      .then(
        res => {
          res['success'] ? resolve(res) : reject(res);
        }
      )
      .catch(
        err => {
          reject(err);
        }
      )
    });
  }

  uploadBigBagImage(image: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.put(`${this.ROUTE}/${image.lot_id}/images/upload`, { big_bag: image }, { headers: this.getHeaders() })
        .subscribe(
          res => {
            res['success'] ? resolve(res) : reject(res);
          },
          err => {
            reject(err);
          }
        )
    });
  }

  removeBigBagImage(image: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.put(`${this.ROUTE}/${image.lot_id}/images/${image.blob_id}/remove`, {big_bag: image}, {headers: this.getHeaders()})
        .subscribe(
          res => {
            res['success'] ? resolve(res) : reject(res);
          },
          err => {
            reject(err);
          }
        )
    });
  }

  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('yacao:app:token'),
        'user_id': localStorage.getItem('yacao:app:user_id')
      }
    );
  }
}
