
<ng-template #content let-modal let-d="dismiss">
  <form #roleForm="ngForm" (ngSubmit)="roleForm.form.valid && onSubmit(role)">
    <div class="modal-header">
      <h4 *ngIf="!role.id" class="modal-title">Crear rol</h4>
      <h4 *ngIf="role.id" class="modal-title">Actualizar rol</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">

      <div class="form-group">
        <label for="roleName">Nombre del rol</label>
        <input type="text" #roleName="ngModel" class="form-control" id="roleName" [(ngModel)]="role.name"
          placeholder="Name" name="roleName" [ngClass]="{ 'is-invalid': roleForm.submitted && roleName.invalid}"
          required>

        <div *ngIf="roleForm.submitted && roleName.invalid" class="invalid-tooltip">
          <div *ngIf="roleName.errors.required">El nombre es obligatorio</div>
        </div>
      </div>

      <!-- <div class="form-group">
        <label for="roleAlias">Alias del rol</label>
        <input type="text" #roleAlias="ngModel" class="form-control" id="roleAlias" [(ngModel)]="role.alias"
          placeholder="Alias" name="roleAlias" [ngClass]="{ 'is-invalid': roleForm.submitted && roleAlias.invalid}"
          required>

        <div *ngIf="roleForm.submitted && roleAlias.invalid" class="invalid-tooltip">
          <div *ngIf="roleAlias.errors.required">El alias es obligatorio</div>
        </div>
      </div> -->
      <div>
        <mat-checkbox [(ngModel)]="role.active" name="roleActive">Activo</mat-checkbox>
      </div>

    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" name="submitRole"
        [disabled]="sending">{{ role.id ? 'Guardar' : 'Crear'}}</button>

      <button type="button" class="btn btn-danger" (click)="d('button click')" name="closeModal"
        [disabled]="sending">Cancelar</button>
    </div>
  </form>
</ng-template>