export const CONSTANTS = {
  ELEMENTS_PER_PAGE: 25,
  CURRENT_USER_ROLES: [],
  APP_LANGUAGE: 'es'
}

export const TICKET_FIELDS_DICCIONARY = {
  contact_id: 'Suplidor',
  original_receiver_id: 'Comprador',
  cocoa_type_id: 'Tipo Cacao',
  certificate_id: 'Certificado',
  contract_price: 'Precio contrato',
  contract_weight: 'Peso contrato',
  contract_starts: 'Fecha inicio',
  contract_ends: 'Fecha fin',
  receiver_weight: 'Peso receptor',
  collected_date: 'Día de creación',
  receipt: 'Imagen acuerdo',
  temp_contract_ends: 'Fecha fin',
  temp_contract_starts: 'Fecha inicio',
  cocoa_quality_id: 'Calidad cacao',
  provenance_sheet: 'Imagenes hoja de procedencia',
  authorization: 'Imagen authorizacion',
  temp_contract_collected_date: 'Fecha creación'
}