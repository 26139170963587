<app-navbar></app-navbar>
<div class="row">
  <div class="col">
    <div class="filtersContainer">
      <div class="row">
        <div class="col-3">
          <button type="button" class="btn btn-outline-dark btn-block" (click)="isCollapsed= !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="collapsibleFilters">
            <mat-icon class="position-relative top-5 m-0">{{ isCollapsed ? 'visibility_off' : 'visibility' }}</mat-icon>
            {{ isCollapsed ? 'Ocultar Filtros' : 'Mostrar Filtros' }}
          </button>
        </div>

        <div class="col-3 offset-6">
          <button class="btn btn-info btn-block" (click)="openSupplierForm(null)">
            <mat-icon class="position-relative top-5 font-weight-bold">add</mat-icon>
            Crear nuevo
          </button>
        </div>
      </div>

      <div class="" id="collapsibleFilters" [ngbCollapse]="!isCollapsed">
        <div class="row">

          <div class="col-3">
            <label>
              Certificado Orgánico
            </label>

            <ng-select [items]="listCertificates" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindValue="id" bindLabel="name" name="supplierCertificates"
              placeholder="Certificado" [multiple]="false"
              [(ngModel)]="filters['q[suppliers_list_certificates_certificate_id_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              Comprador
            </label>

            <ng-select [items]="buyers" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindValue="id" bindLabel="name" name="supplierBuyerName" placeholder="Comprador"
              [multiple]="false" [(ngModel)]="filters['q[buyer_id_eq]']"></ng-select>
          </div>

          <div class="col-3">
            <label>
              Centro
            </label>

            <ng-select [items]="centers" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindValue="id" bindLabel="name" name="supplierCenter" placeholder="Centro"
              [multiple]="false" [(ngModel)]="filters['q[center_id_eq]']"></ng-select>
          </div>

          <div class="col-3">
            <label>
              Suplidor
            </label>

            <ng-select [items]="allSuppliers" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="full_name" bindValue="id" name="supplierName" placeholder="Nombre"
              [multiple]="false" [(ngModel)]="filters['q[id_eq]']"></ng-select>
          </div>

          <div class="col-3">
            <label>
              Corporativo
            </label>

            <ng-select [items]="yesOrNot" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" name="supplierIsCorporate" placeholder="Corporativo" [multiple]="false"
              bindValue="value" bindLabel="name" [(ngModel)]="filters['q[corporate_eq]']"></ng-select>
          </div>

          <div class="col-3">
            <label>
              Activo
            </label>

            <ng-select [items]="yesOrNot" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" name="supplierIsActive" placeholder="Activo" bindValue="value" bindLabel="name"
              [multiple]="false" [(ngModel)]="filters['q[active_as_supplier_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              Zona
            </label>

            <ng-select [items]="zones" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindValue="id" bindLabel="name" name="supplierZone" placeholder="Zona"
              [multiple]="false" [(ngModel)]="filters['q[contacts_zones_zone_id_eq]']"></ng-select>
          </div>

          <div class="col-3">
            <label>
              Grupo Entrega
            </label>

            <ng-select [items]="deliveryGroups" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindValue="id" bindLabel="name" name="supplierDeliveryGrops"
              placeholder="Grupo de entrega" [multiple]="false"
              [(ngModel)]="filters['q[suppliers_delivery_groups_delivery_group_id_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              Código Diceros
            </label>

            <ng-select [items]="dicerosIds" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" name="supplierDicerosId" placeholder="Código Diceros" [multiple]="false"
              [(ngModel)]="filters['q[diceros_id_eq]']"></ng-select>
          </div>

          <div class="col-3">
            <label>
              Procesa producto
            </label>

            <ng-select [items]="yesOrNot" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" name="supplierIsProcessor" placeholder="Procesa producto" [multiple]="false"
              bindValue="value" bindLabel="name" [(ngModel)]="filters['q[processor_eq]']"></ng-select>
          </div>

        </div>
        <div class="row mt-3 align-bottom">
          <div class="col-3 offset-6 d-flex align-items-end">
            <button type="button" class="btn btn-outline-dark btn-block"
              (click)="getSuppliers(1, itemsPerPage, filters, 'xlsx')">
              <div class="load-container" *ngIf="loadingExcel">
                <div class="loader-green d-inline-block"></div>
              </div>
              <i class="fa fa-download" *ngIf="!loadingExcel"></i>
              Descargar excel
            </button>
          </div>
          <div class="col-3 d-flex align-items-end">
            <button type="button" class="btn btn-success btn-block" (click)="getSuppliers(1, itemsPerPage, filters)">
              <i class="fa fa-filter"></i>
              Filtrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-supplier-form [suppliers]="suppliers" [centers]="centers" [zones]="zones" [deliveryGroups]="deliveryGroups"
  [buyers]="buyers" (result)="setSuppliers($event)"></app-supplier-form>

<!-- Table With Supplier Datas -->
<div *ngIf="!loading">
  <table class="table sticky-yacao green table-striped table-hover table-sm">
    <thead>
      <tr>
        <th>#</th>
        <th>Nombre</th>
        <th>Proffix</th>
        <th>Comprador</th>
        <th>Procesa</th>
        <th>Corporativo</th>
        <th>Rendimiento</th>
        <th>
          Grupos
          <br />
          entrega
        </th>
        <th>Zona</th>
        <th>Centro</th>
        <th>Activo</th>
        <th>BSS</th>
        <th>UE/NOP</th>
        <th>Trans.</th>
        <th>Acciones</th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let supplier of suppliers | paginate: { id:'supplierPagination', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; index as i;">
        <td>
          {{globals.getAbsoluteIndex(itemsPerPage, currentPage, i) + 1}}
        </td>

        <td>
          {{ supplier.company_name ? supplier.company_name : supplier.full_name}}
        </td>

        <td>
          {{ supplier?.proffix }}
        </td>

        <td>
          {{ supplier.buyer?.name }}
        </td>

        <td>
          <mat-icon class="font-weight-bold"> {{ supplier.processor ? 'check' : 'close' }}</mat-icon>
        </td>

        <td>
          <mat-icon class="font-weight-bold"> {{ supplier.corporate ? 'check' : 'close' }}</mat-icon>
        </td>

        <td>
          {{ supplier.performance }}
        </td>

        <td>
          <button class="btn btn-sm btn-info" (click)="openDeliveryGroupModal(supplier, listCertificates)">
            Ver detalles
          </button>
        </td>

        <td>
          {{ supplier.zones?.name }}
        </td>

        <td>
          {{ supplier.center?.name }}
        </td>


        <td>
          <mat-icon class="font-weight-bold"> {{ supplier.active_as_supplier ? 'check' : 'close' }}</mat-icon>
        </td>

        <td>
          {{getQuotaOf(supplier, 'biosuisse')}}
        </td>

        <td>
          {{getQuotaOf(supplier, 'ue/nop')}}
        </td>

        <td>
          {{getQuotaOf(supplier, 'trans')}}
        </td>

        <td>
          <div class="nav-item dropdown" ngbDropdown>
            <a class="btn btn-success btn-sm dropdown-toggle white" ngbDropdownToggle>
              Selecciona acción
            </a>

            <div class="dropdown-menu" aria-labelledby="admin" ngbDropdownMenu>
              <button class="btn btn-sm dropdown-item" (click)="openSupplierForm(supplier)">
                <mat-icon class="position-relative top-5 text-primary">edit</mat-icon>
                Editar
              </button>

              <button class="btn btn-sm dropdown-item" (click)="changeActiveValue(supplier)">
                <mat-icon class="position-relative top-5 text-danger">delete</mat-icon>
                {{supplier.active_as_supplier ? 'Desactivar' : 'Activar'}}
              </button>

              <button class="btn btn-sm dropdown-item" (click)="generatePDF(supplier.id)">
                <mat-icon class="position-relative top-5 text-secundary">picture_as_pdf</mat-icon>
                Ver PDF
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="paginationContainer" *ngIf="!loading">
  <pagination-controls id="supplierPagination" autoHide="true" responsive="true" previousLabel="Anterior"
    nextLabel="Siguiente" (pageChange)="getSuppliers($event, itemsPerPage, filters)"></pagination-controls>
</div>

<div class="loading" *ngIf="loading">
  <ng-template #loadingTemplate></ng-template>
  <ngx-loading [show]="loading" [template]="loadingTemplate"></ngx-loading>
</div>

<delivery-groups-component></delivery-groups-component>