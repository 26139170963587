import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

// Models
import { CertificateModel } from 'src/app/models/certificate.model';
import { CertificateTypeModel } from 'src/app/models/certificate-type.model';

// Services
import { CertificateService } from 'src/app/services/certificate.service';
import { CertificateTypeService } from 'src/app/services/certificate-type.service';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
  selector: 'app-certificate-form',
  templateUrl: './certificate-form.component.html',
  styleUrls: ['./certificate-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CertificateFormComponent implements OnInit {
  @ViewChild('content', { static: true }) private modal;
  @Output() result: EventEmitter<CertificateModel[]> = new EventEmitter<CertificateModel[]>();
  @Input() private certificates: Array<CertificateModel> = new Array<CertificateModel>();

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };

  certificateTypes: Array<CertificateTypeModel> = new Array<CertificateTypeModel>();
  certificate: CertificateModel = new CertificateModel();
  sending: boolean = false;

  constructor(
    private _modalService: NgbModal,
    private _certificateService: CertificateService,
    private _certificateTypeService: CertificateTypeService,
    private _notification: NotificationService) { }

  ngOnInit() {
  }


  compareFn(a: any, b: any): boolean {
    return a['id'] === b;
  }

  onSubmit(certificate: CertificateModel): void {
    certificate.id ? this.updateCertificate(certificate) : this.createCertificate(certificate);
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  openForm(certificate: CertificateModel): void {
    this.getCertificateTypes();
    this.certificate = certificate ? _.cloneDeep(certificate) : new CertificateModel();
    this.openModal();
  }

  updateCertificate(certificate: CertificateModel) {
    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    this._certificateService
      .updateCertificate(certificate)
      .then(
        res => {
          const index = _.findIndex(this.certificates, { id: certificate['id'] });
          this.certificates[index] = certificate;
          this.result.emit(this.certificates);
          this._notification.clear();
          this._notification.success('Actualizado correctamente');
          this.closeModal();
          this.sending = false;
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      );
  }

  setCertificateTypeId(certificateType: CertificateTypeModel) {
    this.certificate.certificate_type_id = certificateType.id;
  }

  private createCertificate(certificate: CertificateModel) {
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);
    this.sending = true;
    this._certificateService
      .createCertificate(certificate)
      .then(
        response => {
          certificate['id'] = response['certificate']['id'];
          this.certificates.push(certificate);
          this.result.emit(this.certificates);

          this._notification.clear();
          this._notification.success('Creado correctamente');

          this.sending = false;
          this.closeModal();
        })
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al crear');
          }
        }
      );
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }

  private getCertificateTypes() {
    this._certificateTypeService.getCertificateTypes()
      .then(
        res => {
          this.certificateTypes = res['certificate_types'];
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }
}
