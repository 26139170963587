import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';

// Models
import { SeasonModel } from 'src/app/models/season.model';

// Services
import { SeasonService } from 'src/app/services/season.service';

// Components
import { AlertModalComponent } from '../../../components/modals/alert-modal/alert-modal.component';
import { SeasonFormComponent } from '../modals/form/form.component';

@Component({
  selector: 'app-index',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss']
})
export class SeasonsPage implements OnInit {
  @ViewChild(AlertModalComponent, { static: true }) private alertModal: AlertModalComponent;
  @ViewChild(SeasonFormComponent, { static: true }) private formModal: SeasonFormComponent;

  seasons: SeasonModel[] = new Array<SeasonModel>();

  sending = false;
  loading: boolean = true;
  p: number = 1;

  constructor(
    private _seasonService: SeasonService
  ) { }

  ngOnInit() {
    this.getSeasons();
  }

  openForm(season: SeasonModel) {
    this.formModal.openForm(season);
  }

  updateCurrentSeason(season: SeasonModel) {
    const current_season = _.find(this.seasons, { current: true });
    if (current_season && (new Date(current_season['season_starts_at']) > new Date(season['season_starts_at']))) {
      this.openAlertModal('Estás a punto de desactivar la temporada en curso.', '¿Estás seguro de que quieres hacerlo?.', season);
    } else {
      this.deactivateActivateSeason(season);
    }
  }

  openAlertModal(modal_header: string, modal_body: string, season: SeasonModel) {
    this.alertModal.openModal(modal_header, modal_body);
    this.alertModal.alertModalRef.result
      .then(
        result => {
          console.log('result', result);
        },
        reason => {
          switch (reason) {
            case 'alert-accepted':
              this.alertModal.changeModalsZIndexTo(1050);
              this.deactivateActivateSeason(season);
              break;
            case 'alert-cancel':
              this.alertModal.changeModalsZIndexTo(1050);
              break;
          }
        }
      );
  }

  deactivateActivateSeason(season: SeasonModel) {
    season['current'] = !season['current'];
    this.formModal.updateSeason(season);
  }

  compareFn(a: any, b: any): boolean {
    return a['id'] === b;
  }

  closeSeason(season: SeasonModel) {
    season['closed'] = true;
    this.formModal.updateSeason(season);
  }

  setSeasons(seasons: SeasonModel[]) {
    this.seasons = seasons;
  }

  private getSeasons() {
    this.loading = true;
    this._seasonService.getSeasons()
      .then(
        res => {
          this.seasons = res['seasons'];
          this.loading = false;
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }

}
