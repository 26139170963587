/* ANGULAR */
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { HttpModule, Http, RequestOptions } from '@angular/http';
import { NgModule } from '@angular/core';

/* ANGULAR BOOTSTRAP */
import { NgbModule, NgbDropdownModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

/* ANGULAR MATERIAL */
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

/* ANGULAR SELECT */
import { NgSelectModule } from '@ng-select/ng-select';

/* PLUGINS  */
// Dates
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
// add search in select
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// notifications

import { ToastrModule } from 'ngx-toastr';
// paginate table
import { NgxPaginationModule } from 'ngx-pagination';
// Loading
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
// Image zoom rotate efects
import { AngularImageViewerModule } from 'angular-x-image-viewer';
// Translations
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

/* COMPONENTS */
import { AppComponent } from './components/app.component';
import { AlertModalComponent } from './components/modals/alert-modal/alert-modal.component';
import { SeasonFormComponent } from './pages/seasons/modals/form/form.component';
import { SupplierFormComponent } from './pages/suppliers/modals/supplier-form/supplier-form.component';
import { DeliveryGroupsComponent } from './pages/suppliers/modals/delivery-groups/delivery-groups.component';
import { DeliveryGroupsProducersComponent } from './pages/suppliers/modals/delivery-groups-producers/delivery-groups-producers.component';

// import { ModalComponent } from './components/modal/modal.component';

/* ENVIRONMENT CONFIGURATION */
import { environment } from '../environments/environment';

/* GUARDS */
import { AuthGuard } from './guards/auth.guard';

/* MODELS */

/* PAGES */
import { UsersPage } from './pages/users/index/index.page';
import { RolesPage } from './pages/roles/index/index.page';
import { CocoaTypesPage } from './pages/cocoa-types/index/index.page';
import { CertificatesPage } from './pages/certificates/index/index.page';
import { CentersPage } from './pages/centers/index/index.page';
import { ZonesPage } from './pages/zones/index/index.page';
import { ZoneGroupsPage } from './pages/zone-groups/index/index.page';
import { CertificateTypesPage } from './pages/certificate-types/index/index.page';
import { TalonariesPage } from './pages/talonaries/index/index.page';
import { TalbooksPage } from './pages/talbooks/index/index.page';
import { ContactsPage } from './pages/contacts/index/index.page';
import { TicketsPage } from './pages/tickets/index/index.page';
import { DeliveryGroupsPage } from './pages/delivery-groups/index/index.page';
import { SuppliersPage } from './pages/suppliers/index/index.page';
import { ExportationsPage } from './pages/exportations/index/index.page';
import { AddonsPage } from './pages/addons/index/index.page';
import { CommissionsPage } from './pages/commissions/index/index.page';
import { TradePricesPage } from './pages/trade-prices/index/index.page';
import { ContractsPage } from './pages/contracts/index/index.page';
import { UnloadsPage } from './pages/unloads/index/unloads.page';
import { SeasonsPage } from './pages/seasons/index/index.page';

/* PIPES */
import { QuintalPipe } from './pipes/quintal.pipe';

/* ROUTES */
import { AppRoutingModule } from './app-routing.module';

/* SERVICES */
import { AuthService } from './services/auth.service';
import { CocoaTypesService } from './services/cocoatype.service';
import { AddonFormComponent } from './pages/addons/modals/addon-form/addon-form.component';
import { CenterFormComponent } from './pages/centers/modals/center-form/center-form.component';
import { CertificateFormComponent } from './pages/certificates/modals/certificate-form/certificate-form.component';
import { CocoaTypeFormComponent } from './pages/cocoa-types/modals/cocoa-type-form/cocoa-type-form.component';
import { CommissionFormComponent } from './pages/commissions/modals/commission-form/commission-form.component';
import { ContactFormComponent } from './pages/contacts/modals/contact-form/contact-form.component';
import { ContractFormComponent } from './pages/contracts/modals/contract-form/contract-form.component';
import { ImagesModalComponent } from './components/modals/images-modal/images-modal.component';
import { DeliveryGroupFormComponent } from './pages/delivery-groups/modals/delivery-group-form/delivery-group-form.component';
import { DeliveryGroupProducersComponent } from './pages/delivery-groups/modals/delivery-group-producers/delivery-group-producers.component';
import { RoleFormComponent } from './pages/roles/modals/role-form/role-form.component';
import { TalbookFormComponent } from './pages/talbooks/modals/talbook-form/talbook-form.component';
import { TalbookTypeFormComponent } from './pages/talonaries/modals/talbook-type-form/talbook-type-form.component';
import { TradePricesFormComponent } from './pages/trade-prices/modals/trade-prices-form/trade-prices-form.component';
import { UsersFormComponent } from './pages/users/modals/users-form/users-form.component';
import { ZoneGroupsFormComponent } from './pages/zone-groups/modals/zone-groups-form/zone-groups-form.component';
import { ZoneFormComponent } from './pages/zones/modals/zone-form/zone-form.component';
import { NotRightClickDirective } from './directives/not-right-click.directive';
import { ProvenanceSheetFormComponent } from './pages/tickets/modals/provenance-sheet-form/provenance-sheet-form.component';
import { TicketFormComponent } from './pages/tickets/modals/ticket-form/ticket-form.component';
import { RootModalComponent } from './components/modals/root-modal/root-modal.component';
import { PackageFormComponent } from './pages/tickets/modals/ticket-form/package-form/package-form.component';
import { ContractPackageFormComponent } from './pages/contracts/modals/package-form/package-form.component';

import { LotFormComponent } from './pages/unloads/modals/final-unload-form/lot-form/lot-form.component';
import { ProvisionalUnloadFormComponent } from './pages/unloads/modals/provisional-unload-form/provisional-unload-form.component';
import { FinalUnloadFormComponent } from './pages/unloads/modals/final-unload-form/final-unload-form.component';
import { ExportationFormsComponent } from './pages/exportations/modals/exportation-forms/exportation-forms.component';
import { ExportationDetailsComponent } from './pages/exportations/modals/exportation-details/exportation-details.component';
import { NgbDateCustomParserFormatter } from './services/ngb-date-custom-parser-formatter.service';
import { NoCacheHeadersInterceptor } from './interceptors/not-cache-headers.interceptor';
import { LoginComponent } from './pages/login/login.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { TranslationEditModalComponent } from './components/modals/translation-edit-modal/translation-edit-modal.component';
import { TranslationService } from './services/translation.service';
import { TranslateComponent } from './pages/translate/translate.component';
import { PaymentsComponent } from './pages/payments/payments.component';
import { PaymentFormComponent } from './pages/payments/payment-form/payment-form.component';
import { ConceptsComponent } from './pages/concepts/concepts.component';
import { ConceptFormComponent } from './pages/concepts/concept-form/concept-form.component';
import { SettingFormComponent } from './pages/settings/setting-form/setting-form.component';


@NgModule({
  declarations: [
    AppComponent,
    AddonsPage,
    CentersPage,
    CertificatesPage,
    CertificateTypesPage,
    CocoaTypesPage,
    ContactsPage,
    ContractsPage,
    CommissionsPage,
    DeliveryGroupsPage,
    ExportationsPage,
    QuintalPipe,
    RolesPage,
    SuppliersPage,
    TalbooksPage,
    TalonariesPage,
    TicketsPage,
    TradePricesPage,
    UsersPage,
    ZoneGroupsPage,
    ZonesPage,
    ContractsPage,
    UnloadsPage,
    SeasonsPage,
    AlertModalComponent,
    SeasonFormComponent,
    SupplierFormComponent,
    DeliveryGroupsComponent,
    DeliveryGroupsProducersComponent,
    AddonFormComponent,
    CenterFormComponent,
    CertificateFormComponent,
    CocoaTypeFormComponent,
    CommissionFormComponent,
    ContactFormComponent,
    ContractFormComponent,
    ImagesModalComponent,
    DeliveryGroupFormComponent,
    DeliveryGroupProducersComponent,
    RoleFormComponent,
    TalbookFormComponent,
    TalbookTypeFormComponent,
    TradePricesFormComponent,
    UsersFormComponent,
    ZoneGroupsFormComponent,
    ZoneFormComponent,
    NotRightClickDirective,
    ProvenanceSheetFormComponent,
    TicketFormComponent,
    RootModalComponent,
    PackageFormComponent,
    ContractPackageFormComponent,
    LotFormComponent,
    ProvisionalUnloadFormComponent,
    FinalUnloadFormComponent,
    ExportationFormsComponent,
    ExportationDetailsComponent,
    LoginComponent,
    NavbarComponent,
    TranslationEditModalComponent,
    TranslationEditModalComponent,
    TranslateComponent,
    PaymentsComponent,
    PaymentFormComponent,
    ConceptsComponent,
    ConceptFormComponent,
    SettingFormComponent
  ],
  imports: [
    AppRoutingModule,
    AngularImageViewerModule,
    AngularMyDatePickerModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    // HttpModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgbModule,
    NgbDropdownModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.01)',
      backdropBorderRadius: '0px',
      primaryColour: '#00cc66',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff'
    }),
    NgxMatSelectSearchModule,
    NgxPaginationModule,
    NgSelectModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      tapToDismiss: true,
      preventDuplicates: true
    }),
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
          provide: TranslateLoader,
          // useFactory: HttpLoaderFactory,
          useClass: TranslationService,
          deps: [HttpClient]
      }
  })
  ],
  providers: [
    AuthGuard,
    AuthService,
    CocoaTypesService,
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
