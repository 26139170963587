import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// modesl
import { ContactModel } from '../models/contact.model';

//Environment
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  contactRoute = environment.routes.api + environment.routes.contacts;

  constructor(
    private http: HttpClient
  ) { }

  getContacts(page: Number = 1, per_page: Number = 25, parameters: any = {}) {
    let params = {
      page: page.toString(),
      per_page: per_page.toString()
    };

    params = Object.assign(params, parameters);

    let options = {
      params: params,
      headers: this.getHeaders()
    };

    options = Object.assign(options, { responseType: params['format'] === 'json' ? 'json' : 'arraybuffer' });

    return new Promise((resolve, reject) => {
      this.http.get(this.contactRoute, options)
        .toPromise()
        .then(
          res => {
            if (params.hasOwnProperty('format') && params['format'] === 'xlsx') {
              resolve(res);
            } else {
              console.log(res);
              res['success'] ? resolve(res) : reject(res);
            }
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  getContactWillBeSupplier() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.contactRoute}/new`, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  getContactDeliveryGroups(contact_id: number) {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.contactRoute}/${contact_id}/delivery_groups`, { params: { mobile: 'false' }, headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            console.log(res);
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  createContact(contact: ContactModel) {
    return new Promise((resolve, reject) => {
      this.http.post(this.contactRoute, { contact: contact }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  updateContact(contact: ContactModel) {
    return new Promise((resolve, reject) => {
      this.http.put(`${this.contactRoute}/${contact['id']}`, { contact: contact }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  deleteContact(contactId: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${this.contactRoute}/${contactId}`, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  getContact(id: number) {
    return new Promise(
      (resolve, reject) => {
        this.http
          .get(`${this.contactRoute}/${id}.json`, { headers: this.getHeaders() })
          .toPromise()
          .then(
            (res) => {
              res['success'] ? resolve(res) : reject(res);
            }
          )
          .catch(
            (err) => {
              reject(err);
            }
          );
      }
    );
  }

  getContactByBuyer(buyerId: string) {
    return new Promise((resolve, reject) => {
      this.http.get(this.contactRoute, { params: { 'q[buyer_id_eq]': buyerId }, headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  getContactsLikeSupplier() {
    return new Promise((resolve, reject) => {
      this.http.get(this.contactRoute, { params: { 'q[supplier_eq]': "true" }, headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }
  // uploadContactAvatar(avatar: File, contactId: string) {
  //   let ref;
  //   return new Promise((resolve, reject) => {
  //     ref = this.afStorage.ref(
  //       `/contacts/${contactId}/avatar/${avatar["name"]}`
  //     );
  //     this.task = ref.put(avatar);
  //     this.uploadProgress = this.task.percentageChanges();
  //     this.task
  //       .then(res => {
  //         resolve(res);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // }

  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('yacao:app:token'),
        'user_id': localStorage.getItem('yacao:app:user_id')
      }
    );
  }
}
