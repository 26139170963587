import { Component, OnInit, ViewChild, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

// Models
import { ContactModel } from 'src/app/models/contact.model';
import { CertificateModel } from 'src/app/models/certificate.model';
import { DeliveryGroupModel } from 'src/app/models/delivery-group.model';
import { ZoneModel } from 'src/app/models/zone.model';

// Services
import { ContactService } from 'src/app/services/contact.service';
import { NotificationService } from 'src/app/services/notification.service';

export class Quota {
  quota: number;
  certificateName: string;
}

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ContactFormComponent implements OnInit {
  @ViewChild('content', { static: true }) private modal;
  @Output() result: EventEmitter<ContactModel[]> = new EventEmitter<ContactModel[]>();
  @Input() private totalItems: number = 0;
  @Input() private currentPage: number = 1;
  @Input() private itemsPerPage: number = 25;
  @Input() private contacts: ContactModel[] = new Array<ContactModel>();
  @Input() private allContacts: ContactModel[] = new Array<ContactModel>();
  @Input() zones: ZoneModel[] = new Array<ZoneModel>();
  @Input() listCertificates: CertificateModel[] = new Array<CertificateModel>();
  @Input() boolCertificates: CertificateModel[] = new Array<CertificateModel>();
  @Input() deliveryGroups: DeliveryGroupModel[] = new Array<DeliveryGroupModel>();

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };

  contact: ContactModel = new ContactModel();

  sending: boolean = false;

  constructor(private _modalService: NgbModal, 
    private _contactService: ContactService,
    private _notification: NotificationService) { }

  ngOnInit() {
  }

  onSubmit(contact: ContactModel): void {
    if (!this.codeExist(contact)) {
      contact['id'] ? this.updateContact(contact) : this.createContact(contact);
    }
  }

  openForm(contact: ContactModel): void {
    this.contact = contact ? _.cloneDeep(contact) : new ContactModel();
    this.openModal();
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  updateContact(contact: ContactModel): void {
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);
    this.sending = true;
    contact = this.addAutomaticInfoTo(contact);

    this._contactService
      .updateContact(contact)
      .then(
        res => {
          contact = res['contact'];
          const index = _.findIndex(this.contacts, { id: contact['id'] });
          this.contacts[index] = contact;
          this.result.emit(this.contacts);

          this._notification.clear();
          this._notification.success('Actualizado correctamente');
          this.closeModal();
          this.sending = false;
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      );
  }

  private createContact(contact: ContactModel): void {
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);
    this.sending = true;
    contact = this.addAutomaticInfoTo(contact);

    this._contactService
      .createContact(contact)
      .then(
        res => {

          this.totalItems += 1;

          if (this.currentPage === _.ceil(this.totalItems / this.itemsPerPage)) {
            this.contacts.push(res['contact']);
          }

          this.result.emit(this.contacts);

          this.sending = false;

          this._notification.clear();
          this._notification.success('Creado correctamente');
          this.closeModal();
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al crear');
          }
        }
      );
  }

  compareFn(a, b): boolean {
    if (b['id']) {
      return a['id'] === b['id'];
    } else {
      return a['id'] === b;
    }
  }

  addCertificateToContact($event, contact: ContactModel, certificate: CertificateModel, type: string): void {
    const cert = _.cloneDeep(certificate);
    let index;
    if ($event.checked) {
      if (type === 'list') {
        if (!contact['list_certificates']) {
          contact['list_certificates'] = [];
        }

        contact['list_certificates'].push(cert);
      } else {
        if (!contact['bool_certificates']) {
          contact['bool_certificates'] = [];
        }
        contact['bool_certificates'].push(cert);
      }
    } else {
      if (type === 'list') {
        index = _.findIndex(contact['list_certificates'], { id: cert['id'] });
        _.pullAt(contact['list_certificates'], index);
      } else {
        index = _.findIndex(contact['bool_certificates'], { id: cert['id'] });
        _.pullAt(contact['bool_certificates'], index);
      }
    }
  }

  selectCertificate(contact: ContactModel, certificate: CertificateModel, type: string): boolean {
    if (type === 'list') {
      return !!_.find(contact['list_certificates'], { id: certificate['id'] });
    } else {
      return !!_.find(contact['bool_certificates'], { id: certificate['id'] });
    }
  }

  setQuotaToCertificate($event, certificate): void {
    const index = _.findIndex(this.contact['list_certificates'], { id: certificate['id'] });
    this.contact['list_certificates'][index]['quota'] = Number($event);
  }


  codeExist(contact: ContactModel): boolean {
    return !!_.find(this.allContacts, c => {
      if (contact.id !== c.id && contact.code === c.code) {
        return c;
      }
    });
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }

  private addAutomaticInfoTo(contact: ContactModel): ContactModel {
    contact['bool_certificate_ids'] = [];
    contact['list_certificates_as_contact'] = [];
    contact['zone_ids'] = contact['zones'] && [contact['zones']['id']];

    contact['controller_name'] = 'contacts';

    _.each(contact['list_certificates'], (certificate, index) => {
      if (certificate['alias'] === 'conv.') {
        certificate['quota'] = 0;
      }
      contact['list_certificates_as_contact'].push(certificate);
    });

    _.each(contact['bool_certificates'], (certificate, index) => {
      contact['bool_certificate_ids'].push(certificate['id']);
    });

    if (contact['producer_in'] && contact['producer_in']['id']) {
      contact['delivery_group_ids'] = [contact['producer_in']['id']];
      contact['producer'] = true;
    } else {
      contact['delivery_group_ids'] = [];
      contact['producer'] = false;
    }

    if (contact['coordinator_in_delivery_groups'] && contact['coordinator_in_delivery_groups'].length > 0) {
      contact['coordinator'] = true;
    } else {
      contact['coordinator'] = false;
    }

    _.each(contact, (value, key) => {
      if (contact[key] === null || contact[key] === undefined) {
        delete contact[key];
      }
    });

    return contact;
  }

}
