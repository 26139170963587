import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { NgbModalOptions, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

// Components
import { RootModalComponent } from 'src/app/components/modals/root-modal/root-modal.component';

// Models
import { BigBagModel } from 'src/app/models/big-bag.model';
import { CertificateModel } from 'src/app/models/certificate.model';
import { CocoaTypeModel } from 'src/app/models/cocoa-type.model';
import { CocoaQualityModel } from 'src/app/models/cocoa_quality.model';
import { TicketModel } from 'src/app/models/ticket.model';
import { UnloadModel } from 'src/app/models/unload.model';

// Constants
import { BigBagProcess, UnloadState } from 'src/app/app.enum';
import { TranslateService } from '@ngx-translate/core';
import { LotImageModel } from 'src/app/models/lot_image.model';

@Component({
  selector: 'app-lot-form',
  templateUrl: './lot-form.component.html',
  styleUrls: ['./lot-form.component.scss']
})
export class LotFormComponent implements OnInit {
  @ViewChild(RootModalComponent) modal: RootModalComponent;
  @ViewChild('alertModal') alertModal: TemplateRef<any>;

  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'lot-modal'
  };

  private alertModalRef: NgbModalRef;

  @Input() certificates: CertificateModel[] = new Array<CertificateModel>();
  @Input() cocoaTypes: CocoaTypeModel[] = new Array<CocoaTypeModel>();
  @Input() cocoaQualities: CocoaQualityModel[] = new Array<CocoaQualityModel>();
  @Input() tickets: TicketModel[] = new Array<TicketModel>();
  @Input() unload: UnloadModel = new UnloadModel();
  @Input() unsaveChanges: boolean;
  @Input() lotsImages: LotImageModel[] = [];

  @Output() bigBagsEmit: EventEmitter<BigBagModel[]> = new EventEmitter<BigBagModel[]>();
  @Output() lotImagesEmit: EventEmitter<LotImageModel> = new EventEmitter<LotImageModel>();

  bigBag: BigBagModel;
  lotImage: LotImageModel;

  bigBagProcess = BigBagProcess;
  unloadState = UnloadState;

  bigBagImages: LotImageModel[] = [];

  imageIndex: number = 0;
  ticket: TicketModel;
  ticketToRemove: TicketModel;
  removedTickets: any[];
  bigBagPage: number = 1;
  sending: boolean = false;

  constructor(
    private modalService: NgbModal,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('es');
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  ngOnInit() {
  }

  onSubmit(bigBag: BigBagModel) {
    this.updateUnloadWithBigBagDatasAndRecalculateWeightLoss(bigBag);
  }

  compareFn(a: any, b: any): boolean {
    return a['id'] === b;
  }

  openForm(bigBag: BigBagModel) {
    this.bigBagImages = [];
    this.lotImage = new LotImageModel();
    this.bigBag = bigBag;
    _.each(this.bigBag.tickets, ticket => {
      ticket['fixed_weight'] = Number(this.fixedNumber(ticket.weight));
    });

    this.bigBagImages = [];
    this.removedTickets = [];

    const lot_image: LotImageModel = _.find(this.lotsImages, { barcode: bigBag.barcode_number });

    if (lot_image) {
      this.lotImage = lot_image;
      this.bigBagImages.push(this.lotImage);
    } else if (bigBag['image']) {
      this.lotImage = Object.assign(this.lotImage, {
        lot_id: bigBag.id,
        lot_content: bigBag['image'].url
      });
      this.bigBagImages.push(this.lotImage);
    }

    this.bigBag = _.cloneDeep(bigBag);
    this.openModal();
  }

  openModal() {
    this.modal.show(this.modalOptions);
  }

  closeModal() {
    this.modal.hide();
  }


  drySelected(obj): boolean {
    const cocoa_type = _.find(this.cocoaTypes, { id: obj['cocoa_type_id'] }) as CocoaTypeModel;
    return cocoa_type.name.toLocaleLowerCase() === 'seco';
  }

  addTicketToCurrentBigBag(ticket: TicketModel) {
    this.unsaveChanges = true;
    const exist = !!_.find(this.bigBag['tickets'], { ticket_id: ticket['id'] });

    if (!exist) {
      if (this.bigBag['process_cd'] !== this.bigBagProcess.refund) {

        const t = {
          ticket_id: ticket['id'],
          talbook_sheet: ticket['talbook_sheet'],
          weight: 0,
          certificate: ticket['certificate'],
          reviewed: ticket.reviewed,
          fixed_weight: 0
        };

        if (!this.bigBag['tickets']) {
          this.bigBag['tickets'] = [];
        }

        this.bigBag['tickets'].push(t);
      } else {
        const existInRefund = !!_.find(this.unload['big_bags'], bigBag => {
          if (bigBag['process_cd'] === this.bigBagProcess.refund) {
            return _.includes(_.map(bigBag['tickets'], 'ticket_id'), ticket['id']);
          }
        });

        if (!existInRefund && (!this.bigBag['tickets'] || this.bigBag['tickets'].length < 1)) {
          const t = {
            ticket_id: ticket['id'],
            talbook_sheet: ticket['talbook_sheet'],
            weight: 0,
            certificate: ticket['certificate'],
            fixed_weight: 0
          };

          if (!this.bigBag['tickets']) {
            this.bigBag['tickets'] = [];
          }

          this.bigBag['barcode_number'] = ticket['talbook_sheet'];
          this.bigBag['tickets'].push(t);
        }
      }
    }
  }

  setTicketWeightInBigBag(value: number, ticketId: number) {
    this.unsaveChanges = true;
    const index = _.findIndex(this.bigBag['tickets'], { ticket_id: ticketId });
    const value_number = Number(value);
    this.bigBag['tickets'][index]['weight'] = value_number;
    this.bigBag['tickets'][index]['fixed_weight'] = Number(this.fixedNumber(value_number));

    this.bigBag = this.calculateBigBagWeight(this.bigBag);
  }

  openAlertModal(ticket: TicketModel) {
    this.alertModalRef = this.modalService.open(this.alertModal);
    this.ticketToRemove = ticket;
  }

  closeAlertModal(action: string) {
    if (action === 'accepted') {
      this.unsaveChanges = true;
      this.removeTicketFromBigBag(this.ticketToRemove);
    }

    this.ticketToRemove = null;
    this.alertModalRef.dismiss();
  }

  removeTicketFromBigBag(ticket: TicketModel) {
    const index = _.findIndex(this.bigBag['tickets'], { ticket_id: ticket['ticket_id'] });
    const t = _.cloneDeep(this.bigBag['tickets'][index]);
    const exist = !!_.find(this.unload['big_bags'], bag => {
      return _.includes(_.map(bag['tickets'], 'ticket_id'), ticket['id']);
    });

    !exist && this.removedTickets.push(t);

    _.pullAt(this.bigBag['tickets'], index);
    this.bigBag = this.calculateBigBagWeight(this.bigBag);
  }

  removeCocoaQualityIfBabaSelected(event, type: string) {
    if (event.name.toLocaleLowerCase() === 'baba') {
      switch (type) {
        case 'bigBag':
          this.bigBag['cocoa_quality_id'] = null;
          break;
      }
    }
  }

  fixedNumber(number: number, decimals: number = 2): string {
    return number.toFixed(decimals);
  }

  updateUnloadWithBigBagDatasAndRecalculateWeightLoss(bigBag: BigBagModel) {
    // Añadir control para que todas los recibos dentro de una sacona pesen mas de 0
    _.each(bigBag.tickets, (ticket: any, index: number) => {
      ticket.position = index;
    });

    let index = _.findIndex(this.unload['big_bags'], { process_cd: BigBagProcess.weightloss });
    if (!this.unload['big_bags']) {
      this.unload['big_bags'] = [];
    }

    if (index === -1) {
      let weightlossBag = new BigBagModel();
      weightlossBag = Object.assign(weightlossBag, {
        barcode_number: 'W00001',
        process_cd: BigBagProcess.weightloss,
        tickets: [],
        certificate_id: this.unload['certificate_id']
      });

      this.unload['big_bags'].push(weightlossBag);
      index = _.findIndex(this.unload['big_bags'], { process_cd: BigBagProcess.weightloss });
    } else {
      this.unload['big_bags'][index]['tickets'] = [];
    }

    const lot_exist: boolean = !!_.find(this.unload.big_bags, { barcode_number: bigBag.barcode_number });

    if (lot_exist) {
      const bigBagIndex = _.findIndex(this.unload['big_bags'], { id: bigBag['id'] });
      this.unload['big_bags'][bigBagIndex] = bigBag;
    } else {
      this.unload['big_bags'].push(bigBag);
    }

    // const bigBags = _.filter(this.unload['big_bags'], big_bag => {
    //   if (big_bag['process_cd'] !== BigBagProcess.weightloss) {
    //     return big_bag;
    //   }
    // });

    // const tickets = _.uniqBy(_.flatten(_.map(bigBags, 'tickets')), 'ticket_id');
    // this.unload['tickets'] = [];

    // _.each(tickets, ticket => {
    //   const ticketTotalUnload = this.calculateTicketTotalUnload(ticket['ticket_id']);
    //   this.addTicketToUnloadTickets(ticket, ticketTotalUnload);
    //   this.updateWeightLossBigBag(ticket, ticketTotalUnload, index);
    // });


    // this.bigBagsEmit.emit(this.unload.big_bags);
    this.updateUnloadTicketsAndWeightLoss();
    this.closeModal();
  }

  updateUnloadTicketsAndWeightLoss() {
    const index = _.findIndex(this.unload['big_bags'], { process_cd: BigBagProcess.weightloss });
    const bigBags = _.filter(this.unload['big_bags'], big_bag => {
      if (big_bag['process_cd'] !== BigBagProcess.weightloss) {
        return big_bag;
      }
    });

    const tickets = _.uniqBy(_.flatten(_.map(bigBags, 'tickets')), 'ticket_id');
    this.unload['tickets'] = [];
    this.unload.big_bags[index].tickets = [];

    _.each(tickets, ticket => {
      const ticketTotalUnload = this.calculateTicketTotalUnload(ticket['ticket_id']);
      this.addTicketToUnloadTickets(ticket, ticketTotalUnload);
      this.updateWeightLossBigBag(ticket, ticketTotalUnload, index);
    });

    this.lotImage = Object.assign(this.lotImage, {
      barcode: this.bigBag.barcode_number
    });

    this.bigBagsEmit.emit(this.unload.big_bags);

    // console.log(this.lotImage.lot_content);
    if ((this.lotImage.lot_content && this.lotImage.lot_content.includes(';base64,')) || this.lotImage.to_remove) {
      this.lotImagesEmit.emit(this.lotImage);
    }
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  changeTicketPosition(from: number, to: number): void {
    let tickets: any[] = this.bigBag.tickets;
    const new_index = from + to;
    if (new_index >= 0 && new_index <= (tickets.length - 1)) {
      this.bigBag.tickets.splice(new_index, 0, tickets.splice(from, 1)[0]);
    }
  }

  addImage(lot: BigBagModel, event: any): void {
    if (event && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let lot_image: LotImageModel = new LotImageModel();
        lot_image = Object.assign(lot_image, {
          barcode: lot.barcode_number,
          lot_id: lot.id,
          lot_content: reader.result
        });

        this.lotImage = lot_image;
        this.bigBagImages[this.imageIndex] = this.lotImage;
      }
    }
  }

  removeImage(lot: BigBagModel): void {
    let lot_image: LotImageModel = new LotImageModel();
    lot_image = Object.assign(lot_image, {
      barcode: lot.barcode_number,
      lot_id: lot.id
    });

    if (lot.id) {
      lot_image = Object.assign(lot_image, {
        blob_id: lot['image'].blob_id,
        to_remove: true
      });
    }

    this.lotImage = lot_image;
    _.pullAt(this.bigBagImages, this.imageIndex);
  }

  private calculateBigBagWeight(bigBag: any) {
    bigBag['weight'] = 0;
    _.each(this.bigBag['tickets'], ticket => {
      bigBag['weight'] += (Number(ticket['weight']));
      bigBag['weight'] = bigBag['weight'];
    });

    return bigBag;
  }

  private calculateTicketTotalUnload(ticket_id: number) {
    let ticketTotalUnload = 0;
    _.each(this.unload['big_bags'], b => {
      const t = _.find(b['tickets'], { ticket_id: ticket_id });
      if (b['process_cd'] !== BigBagProcess.weightloss && t) {
        ticketTotalUnload += Number(t['weight']);
      }
    });

    return ticketTotalUnload;
  }

  private addTicketToUnloadTickets(ticket, total: number) {
    const ticketToAdd = _.find(this.tickets, { id: ticket['ticket_id'] });
    if (!ticketToAdd) {
      console.log(ticket);
    }
    const t = {
      ticket_id: ticket['ticket_id'],
      talbook_sheet: ticket['talbook_sheet'],
      receiver_weight: ticketToAdd['receiver_weight'],
      certificate: ticket['certificate'],
      weight: total,
      reviewed: ticket.reviewed,
      supplier_name: ticketToAdd['supplier_name'],
      supplier_weight: ticketToAdd.supplier_weight
    };

    this.unload['tickets'].push(t);
  }

  private updateWeightLossBigBag(ticket: Object, totalWeight: number, index: number) {
    const receiverWeight = _.find(this.tickets, { id: ticket['ticket_id'] })['receiver_weight'];

    const t = {
      ticket_id: ticket['ticket_id'],
      talbook_sheet: ticket['talbook_sheet'],
      weight: (Number(receiverWeight) - Number(totalWeight))
    };

    this.unload['big_bags'][index]['tickets'].push(t);
  }
}
