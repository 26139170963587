import { CertificateModel } from "./certificate.model";
import { DeliveryGroupModel } from "./delivery-group.model";
import { CenterModel } from "./center.model";
import { UserModel } from "./user.model";
import { ZoneModel } from "./zone.model";

export class ContactModel {
  id: number;
  name: string;
  surname: string;
  nickname: string;
  brithday: Date;
  cedula: string;
  phone: string;
  code: string;
  oldCode: string;
  list_certificates: Array<CertificateModel> = new Array<CertificateModel>();
  bool_certificates: Array<CertificateModel> = new Array<CertificateModel>();
  contacts_list_certificates_ids: Array<number>;
  contacts_bool_certificates_ids: Array<CertificateModel>;
  bool_certificates_ids: Array<number>;
  delivery_groups_attributes: Array<DeliveryGroupModel>;
  active_as_contact: boolean;
  active_as_supplier: boolean;
  coordinator: boolean;
  fundopo: boolean;
  processor: boolean;
  producer: boolean;
  supplier: boolean;
  proffix = '';
  center: CenterModel;
  center_id: number;
  buyer: UserModel;
  buyer_id: number;
  intermediary: boolean;
  corporate: boolean;
  performance: number;
  delivery_groups: Array<DeliveryGroupModel>;
  delivery_groups_ids: Array<number>;
  list_certificates_as_supplier: Array<CertificateModel>;
  bool_certificates_as_supplier: Array<CertificateModel>;
  company_name: string;
  commission_value: number;
  commission_ids: Array<number>;
  addon_value: number;
  addon_ids: Array<number>;
  trusted: boolean;
  settlement_type_cd: number;
  email: string;
  producer_in: Array<any>;
  zones: Array<any>;
  delivery_groups_as_supplier: Array<DeliveryGroupModel>;
  prebilling_weight_cd: number;
  max_diff: number;
  diceros_id: number;
}
