

<!-- Molda template -->
<ng-template #content let-modal let-d="dismiss">
  <form #Form="ngForm" (ngSubmit)="Form.form.valid && onSubmit(tradePrice)">
    <div class="modal-header">
      <h4 class="modal-title">{{ tradePrice.id ? 'Actualizar' : 'Crear'}} precio</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <div class="form-group">
        <label for="tradePriceNameId">Nombre del precio</label>
        <input type="text" #tradePriceName="ngModel" class="form-control" id="tradePriceNameId"
          [(ngModel)]="tradePrice.name" placeholder="Name" name="tradePriceName"
          [ngClass]="{ 'is-invalid': Form.submitted && tradePriceName.invalid}" required>

        <div *ngIf="Form.submitted && tradePriceName.invalid" class="invalid-tooltip">
          <div *ngIf="tradePriceName.errors.required">El nombre es obligatorio</div>
        </div>
      </div>

      <div class="form-group">
        <label for="tradePriceCocoaTypeId">Tipo de cacao</label>
        <ng-select id="tradePriceCocoaTypeId" [items]="cocoaTypes" [searchable] [clearSearchOnAdd]
          notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name"
          [(ngModel)]="tradePrice.cocoa_type" name="cocoaTypes" placeholder="Selecciona el tipo"
          [compareWith]="compareFn" (change)="setTradePriceAttributteId('cocoa_type_id', $event.id)"></ng-select>
        <!-- [ngClass]="{'select-invalid': Form.submitted && !tradePrice.cocoa_type_id }" required <- Add this line if required -->
      </div>

      <div class="form-group">
        <label for="tradePriceCertificateId">Certificado</label>
        <ng-select id="tradePriceCertificateId" [items]="certificates" [searchable] [clearSearchOnAdd]
          notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name"
          [(ngModel)]="tradePrice.certificate" name="certificate" placeholder="Selecciona el certificado"
          [compareWith]="compareFn" (change)="setTradePriceAttributteId('certificate_id', $event.id)"></ng-select>
        <!-- [ngClass]="{'select-invalid': Form.submitted && !tradePrice.certificate_id }" required  <- Add this line if required -->
      </div>

      <div class="form-group" *ngIf="tradePrice.cocoa_type && tradePrice.cocoa_type.name.toLowerCase() === 'seco'">
        <label for="tradePriceCocoaQualityId">Calidad del cacao</label>
        <ng-select id="tradePriceCocoaQualityId" [items]="cocoaQualities" [searchable] [clearSearchOnAdd]
          notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name"
          [(ngModel)]="tradePrice.cocoa_quality" name="cocoaQualities" placeholder="Selecciona la calidad"
          [compareWith]="compareFn" (change)="setTradePriceAttributteId('cocoa_quality_id', $event.id)"></ng-select>
        <!-- [ngClass]="{'select-invalid': Form.submitted && !tradePrice.cocoa_quality_id }" required  <- Add this line if required -->
      </div>

      <div class="row">
        <div class="col-6 form-group">
          <label for="tradePriceAmountId">Precio por KG</label>
          <input type="number" #tradePriceAmount="ngModel" class="form-control" id="tradePriceAmountId"
            [(ngModel)]="tradePrice.amount" placeholder="Cantidad" name="tradePriceAmount"
            [ngClass]="{ 'is-invalid': Form.submitted && tradePriceAmount.invalid}" min="0" required>

          <div *ngIf="Form.submitted && tradePriceAmount.invalid" class="invalid-tooltip">
            <div *ngIf="tradePriceAmount.errors.required">La cantidad es obligatoria</div>
          </div>
        </div>

        <div class="col-6">
          <label for="tradePriceAmountInKG">Precio por QQ</label>
          <div>
            {{tradePrice.amount ? tradePrice.amount*50 : 0}} $RD por QQ
          </div>
        </div>
      </div>

      <div class="form-group col-12 p-0">
        <label for="tradePricePosition">Orden</label>
        <input type="number" class="form-control" id="tradePricePosition"
          [(ngModel)]="tradePrice.position" placeholder="Posicion" name="tradePricePosition" step="1" min="0">
      </div>

      <div class="form-group">
        <mat-checkbox [(ngModel)]="tradePrice.active" name="tradePriceActive">Activo</mat-checkbox>
      </div>

    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" name="submitTradePrice"
        [disabled]="sending">{{ tradePrice.id ? 'Guardar' : 'Crear'}}</button>
      <button type="button" class="btn btn-danger" (click)="d('button click')" name="closeModal"
        [disabled]="sending">Cancelar</button>
    </div>
  </form>
</ng-template>
