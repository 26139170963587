<!-- MODAL WITH FORM TO UPDATE PAYMENT -->
<ng-template #paymentForm let-modal let-update="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
      Actualizar pago {{ payment.talbook_sheet }}
    </h4>

    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">
        &times;
      </span>
    </button>
  </div>

  <form [formGroup]="form" novalidate (ngSubmit)="onSubmit(payment)">
    <div class="modal-body edit-payment-modal">

      <div class="row">

        <div class="col-7 d-inline-block">
          <div class="col-12">
            <h3>Imagen pago</h3>

            <div class="col-12 mt-2">
              <button *ngIf="payment.receipt && payment.receipt.url" type="button"
                class="btn btn-danger btn-sm btn-block" (click)="removeReceipt(payment)">
                Quitar imagen
              </button>
            </div>

            <div class="col-12 mt-2">
              <button type="button" class="btn btn-success btn-sm btn-block">
                Subir imagen
                <input type="file" name="receiptImageUpload" class="upload-image-input" accept="image/*"
                  (change)="setReceipt($event)">
              </button>
            </div>

            <div class="col-12 mt-2">
              <angular-image-viewer *ngIf="payment.receipt && payment.receipt.url" [src]="[payment.receipt.url]"
                [(index)]="receiptIndex" [config]="{ wheelZoom: true }"></angular-image-viewer>

              <angular-image-viewer *ngIf="!payment.receipt && payment.receipt_content"
                [src]="[payment.receipt_content.receipt_content]" [(index)]="receiptIndex"
                [config]="{ wheelZoom: true }"></angular-image-viewer>
            </div>
          </div>
        </div>

        <div class="col-4 d-inline-block payment-container-form">
          <div class="form-group col-md-6 d-inline-block">
            <label for="collectDateId">
              Fecha de recogida
            </label>

            <input id="collectDateId" class="form-control" placeholder="Fecha de recogida"
              formControlName="collected_date" ngbDatepicker #collectedDate="ngbDatepicker"
              (click)="collectedDate.toggle()" />
          </div>

          <div class="col-6 d-inline-block">
            <div class="form-gorup">
              <label for="paymentSeason">
                Temporada
              </label>

              <input id="paymentSeason" type="text" class="form-control" readonly [value]="payment.season_name">
            </div>

          </div>


          <!--  For admins -->
          <div class="form-group col-md-6 d-inline-block" *ngIf="globals.currentUserRolesAsBooleanObject.admin">
            <label for="paymentReceiverId">
              Receptor
            </label>

            <ng-select id="paymentReceiverId" [items]="buyers" [searchable] [clearSearchOnAdd]
              notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name"
              formControlName="original_receiver" placeholder="Selecciona el receptor" [compareWith]="compareFn"
              [multiple]="false">
            </ng-select>
          </div>

          <!-- No admins -->
          <div class="form-group col-md-6 d-inline-block" *ngIf="!globals.currentUserRolesAsBooleanObject.admin">
            <label for="paymentReceiverId">
              Receptor
            </label>

            <ng-select id="paymentReceiverId" [items]="buyers" [searchable] [clearSearchOnAdd]
              notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name"
              formControlName="original_receiver" placeholder="Selecciona el receptor" [compareWith]="compareFn"
              [multiple]="false" disabled>
            </ng-select>
          </div>

          <div class="form-group col-md-6 d-inline-block">
            <label for="paymentSupplierId">
              Suplidor
            </label>

            <ng-select id="paymentSupplierId" [items]="suppliers" [searchable] [clearSearchOnAdd]
              formControlName="supplier" notFoundText="No hay coincidencias" loadingText="Buscando"
              bindLabel="full_name_and_diceros_id" placeholder="Selecciona el suplidor" [compareWith]="compareFn"
              [multiple]="false">
            </ng-select>
          </div>

          <div class="form-group col-md-6 d-inline-block">
            <label for="concept">
              Concepto
            </label>

            <ng-select id="concept" [items]="concepts" [searchable] [clearSearchOnAdd] formControlName="concept"
              notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="title"
              placeholder="Selecciona el concepto" [compareWith]="compareFn" [multiple]="false">
            </ng-select>
          </div>

          <div class="form-gorup col-md-6 d-inline-block">
            <label for="price">
              RD$
            </label>

            <input id="price" type="number" class="form-control" formControlName="contract_price">
          </div>


          <div class="pl-3 row">

            <div class="form-group col-md-12">
              <label for="paymentComments">
                Comentarios
              </label>

              <textarea class="form-control" id="paymentComments" rows="5" formControlName="comments"></textarea>
            </div>

            <div class="form-group col-md-12">

              <div class="col-md-6">
                <mat-checkbox formControlName="completed">
                  Completo
                </mat-checkbox>
              </div>

              <div class="col-md-6">
                <mat-checkbox formControlName="nulled">
                  Nulo
                </mat-checkbox>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="modal-footer">
      <button class="btn btn-primary" type="submit">
        Guardar
      </button>

      <button type="button" class="btn btn-danger" (click)="closeModal()" name="closeModal">
        Cancelar
      </button>
    </div>
  </form>
</ng-template>