import { Component, OnInit, ViewChild, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

// Models
import { ContactModel } from 'src/app/models/contact.model';
import { CenterModel } from 'src/app/models/center.model';
import { ZoneModel } from 'src/app/models/zone.model';
import { DeliveryGroupModel } from 'src/app/models/delivery-group.model';
import { UserModel } from 'src/app/models/user.model';

// Services
import { ContactService } from 'src/app/services/contact.service';
import { NotificationService } from 'src/app/services/notification.service';
// Contants
import { SettlementType, ContactPrebillingWeight } from 'src/app/app.enum';
import { GlobalsService } from 'src/app/services/globals.service';


@Component({
  selector: 'app-supplier-form',
  templateUrl: './supplier-form.component.html',
  styleUrls: ['./supplier-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SupplierFormComponent implements OnInit {
  @ViewChild('supplierContent', { static: true }) private modal;
  @Output() result: EventEmitter<ContactModel[]> = new EventEmitter<ContactModel[]>();
  @Input() private suppliers: Array<ContactModel> = new Array<ContactModel>();
  @Input() centers: Array<CenterModel> = new Array<CenterModel>();
  @Input() zones: Array<ZoneModel> = new Array<ZoneModel>();
  @Input() deliveryGroups: Array<DeliveryGroupModel> = new Array<DeliveryGroupModel>();
  @Input() buyers: Array<UserModel> = new Array<UserModel>();


  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };
  private modalRef: NgbModalRef;

  availableSuppliers: Array<ContactModel> = new Array<ContactModel>();

  sending: boolean = false;

  supplier: ContactModel = new ContactModel();

  contactSettlementTypes = [{ name: 'Pre-facturacion', id: SettlementType.prebilling },
  { name: 'Resultado seco', id: SettlementType.dry_result }];

  contactPrebillingWeight = [{ name: 'Peso del receptor', id: ContactPrebillingWeight.receiver_weight },
  { name: 'Peso del suplidor', id: ContactPrebillingWeight.supplier_weight }];

  loadAvailableSuppliers: boolean = false;

  settlementType = SettlementType;

  constructor(private _modalService: NgbModal,
    private _contactService: ContactService,
    private _notification: NotificationService,
    public globals: GlobalsService) { }

  ngOnInit() {
  }

  onSubmitSupplier(supplier: ContactModel) {
    // if (!this.checkIfProffixExists(supplier)) {
    //   this.updateSupplier(supplier);
    // }
    if (this.formValid(supplier) && Number(supplier.performance) > 0) {
      this.updateSupplier(supplier);
    }
  }

  formValid(supplier: ContactModel): boolean {

    if (supplier.settlement_type_cd === SettlementType.prebilling) {
      if (typeof supplier.prebilling_weight_cd === 'number' && supplier.max_diff >= 0 && supplier.max_diff <= 100) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  initialize(supplier: ContactModel, options?: NgbModalOptions) {
    this.setSupplier(supplier, this.suppliers, options);
  }

  setSupplier(supplier: ContactModel, suppliers: Array<ContactModel>, options?: NgbModalOptions) {
    if (supplier) {
      this.supplier = _.cloneDeep(supplier);
    } else {
      this.supplier = new ContactModel();
      this.getAvailableSuppliers();
    }

    this.suppliers = suppliers;
    this.openModal(options);
  }

  closeModal(reason: string = "") {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  compareFn(a, b) {
    return a['id'] === b;
  }

  // checkIfProffixExists(supplier: ContactModel): boolean {
  //   return !!_.find(this.suppliers, s => {
  //     if (s['id']) {
  //       if (s['id'] !== supplier['id']) {
  //         return s['proffix'] === supplier['proffix'];
  //       }
  //     } else {
  //       return s['proffix'] === supplier['proffix'];
  //     }
  //   });
  // }

  getAvailableSuppliers() {
    this.loadAvailableSuppliers = true;
    this._contactService
      .getContactWillBeSupplier()
      .then(
        res => {
          this.availableSuppliers = res['contacts'];
          this.loadAvailableSuppliers = false;
        }
      )
      .catch(
        err => {
          console.error(err);
          this.loadAvailableSuppliers = false;
        }
      );
  }

  updateSupplier(supplier: ContactModel) {
    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    supplier = this.addAutomaticInfo(supplier);
    this._contactService
      .updateContact(supplier)
      .then(
        (res) => {
          supplier = res['contact'];
          supplier.zones = _.last(supplier.zones);
          const index = _.findIndex(this.suppliers, { id: supplier['id'] });
          this.suppliers[index] = supplier;
          this.result.emit(this.suppliers);

          this._notification.clear();
          this._notification.success('Actualizado correctamente');
          this.sending = false;
          this.closeModal();
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      );
  }

  private addAutomaticInfo(supplier: ContactModel): ContactModel {
    supplier['controller_name'] = 'suppliers';
    supplier['supplier'] = true;
    supplier['center'] && (supplier['center_id'] = supplier['center']['id']);
    supplier['zone_ids'] = supplier['zones'] ? [supplier['zones']['id']] : [];
    supplier['buyer'] && (supplier['buyer_id'] = supplier['buyer']['id']);
    supplier['delivery_groups_as_supplier'] && (supplier['delivery_group_ids_as_supplier'] = _.map(supplier['delivery_groups_as_supplier'], 'id'));

    return supplier;
  }

  private openModal(options: NgbModalOptions = null) {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }
}
