import { BigBagModel } from "./big-bag.model";
import { TicketModel } from './ticket.model';

export class UnloadModel {
  id: number;
  user_id: number;
  state_cd: number;
  type_cd: number;
  cocoa_type_id: number;
  cocoa_quality_id: number;
  certificate_id: number;
  unload_manager: {};
  cocoa_quality: {};
  certificate: {};
  center: {};
  big_bags: Array<BigBagModel>;
  provisionals: [];
  accepted: boolean;
  tickets: Array<any>;
  cocoa_type: any;
}
