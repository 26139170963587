import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';


import { UnloadModel } from '../models/unload.model';
@Injectable({
  providedIn: 'root'
})
export class UnloadService {
  private ROUTE = environment.routes.api + environment.routes.unloads;

  constructor(
    private _http: HttpClient
  ) { }

  getUnloads(page: number = 1, per_page: number = 25, parameters: {} = {}) {
    let params = {
      page: page.toString(),
      per_page: per_page.toString()
    };

    params = Object.assign(params, parameters);

    let options = {
      params: params,
      headers: this.getHeaders()
    };

    options = Object.assign(options, { responseType: params['format'] === 'json' ? 'json' : 'arraybuffer' });

    return new Promise(
      (resolve, reject) => {
        this._http.get(this.ROUTE, options)
          .toPromise()
          .then(
            res => {
              if (params.hasOwnProperty('format') && params['format'] === 'xlsx') {
                resolve(res);
              } else {
                res['success'] ? resolve(res) : reject(res);
              }
            }
          )
          .catch(
            err => {
              reject(err);
            }
          );
      }
    );
  }

  getUnload(id: number) {
    return new Promise((resolve, reject) => {
      this._http.get(`${this.ROUTE}/${id}`, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  getUnloadByCenter(center_id: number) {
    return new Promise((resolve, reject) => {
      this._http.get(`${this.ROUTE}`, { headers: this.getHeaders(), params: { 'q[center_id_eq]': center_id.toString() } })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  updateUnload(unload: UnloadModel) {
    return new Promise((resolve, reject) => {
      this._http.put(`${this.ROUTE}/${unload['id']}`, { unload: unload }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  getTicketCanUnload(unloadId: number) {
    return new Promise((resolve, reject) => {
      this._http.get(`${this.ROUTE}/${unloadId}/tickets`, { headers: this.getHeaders()})
        .toPromise()
          .then(
            res => {
              res['success'] ? resolve(res) : reject(res);
            }
          )
          .catch(
            err => {
              reject(err);
            }
          );
    });
  }

  deleteUnload(id: number) {
    return new Promise((resolve, reject) => {
      this._http.delete(`${this.ROUTE}/${id}`, { headers: this.getHeaders()})
        .toPromise()
          .then(
            res => {
              res['success'] ? resolve(res) : reject(res);
            }
          )
          .catch(
            err => {
              reject(err);
            }
          );
    });
  }

  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('yacao:app:token'),
        'user_id': localStorage.getItem('yacao:app:user_id')
      }
    );
  }
}
