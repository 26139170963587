import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DeliveryGroupModel } from "../models/delivery-group.model";

//Environment
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeliveryGroupService {
  deliveryGroupRoute = environment.routes.api + environment.routes.delivery_groups;

  constructor(private http: HttpClient) { }

  getDeliveryGroups(page: number = 1, per_page: number = 25, parameters: {} = {}): Promise<any> {
    let params = {
      page: page.toString(),
      per_page: per_page.toString()
    };

    params = Object.assign(params, parameters);

    let options = {
      params: params,
      headers: this.getHeaders()
    };

    options = Object.assign(options, { responseType: params['format'] === 'json' ? 'json' : 'arraybuffer' });

    return new Promise(
      (resolve, reject) => {
        this.http.get(
          this.deliveryGroupRoute, options)
          .toPromise()
          .then(
            res => {
              if (params.hasOwnProperty('format') && params['format'] === 'xlsx') {
                resolve(res);
              } else {
                res['success'] ? resolve(res) : reject(res);
              }
            }
          )
          .catch(
            err => {
              reject(err);
            }
          );
      }
    );
  }

  new() {
    return new Promise(
      (resolve, reject) => {
        this.http
          .get(`${this.deliveryGroupRoute}/new`, { headers: this.getHeaders() })
          .toPromise()
          .then(
            (res) => {
              res['success'] ? resolve(res) : reject(res);
            }
          )
          .catch(err => reject(err));
      }
    );
  }

  createDeliveryGroup(deliveryGroup: DeliveryGroupModel, current_delivery_groups: {}) {
    return new Promise((resolve, reject) => {
      this.http.post(this.deliveryGroupRoute, { delivery_group: deliveryGroup },
        { params: current_delivery_groups, headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  updateDeliveryGroup(deliveryGroup: DeliveryGroupModel, current_delivery_groups: {}) {
    return new Promise((resolve, reject) => {
      this.http.put(
        `${this.deliveryGroupRoute}/${deliveryGroup['id']}`, { delivery_group: deliveryGroup },
        { params: current_delivery_groups, headers: this.getHeaders() }
      ).toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  deleteDeliveryGroup(deliveryGroupId: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${this.deliveryGroupRoute}/${deliveryGroupId}`, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  getDeliveryGroup(id: number) {
    return new Promise(
      (resolve, reject) => {
        this.http
          .get(`${this.deliveryGroupRoute}/${id}`, { headers: this.getHeaders() })
          .toPromise()
          .then(
            res => {
              res['success'] ? resolve(res) : reject(res);
            }
          )
          .catch(
            err => {
              reject(err);
            }
          );
      }
    );
  }

  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('yacao:app:token'),
        'user_id': localStorage.getItem('yacao:app:user_id')
      }
    );
  }
}
