<app-root-modal>
  <div *ngIf="bigBag">
    <form #BigBagForm="ngForm" (ngSubmit)="BigBagForm.form.valid && onSubmit(bigBag)">
      <!-- Modal header -->
      <div class="modal-header">
        <h4 class="modal-title">{{ bigBag.id ? 'Actualizar lote' : 'Crear lote'}} {{ bigBag.barcode_number }} (Todos los
          pesos están en kg)</h4>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <div class="row">
          <div class="col-3 d-inline-block">
            <div class="form-group">
              Información del lote
            </div>
            <!-- Barcode Number-->
            <div class="form-group">
              <label for="BigBagBarCodeNumber">Código de barras</label>
              <input type="text" [(ngModel)]="bigBag.barcode_number" #bigBagBarcode="ngModel" class="form-control"
                [ngClass]="{ 'is-invalid': BigBagForm.submitted && bigBagBarcode.invalid}" id="BigBagBarCodeNumber"
                name="barCode" placeholder="Código de barras" (input)="unsaveChanges= true;" required
                [disabled]="unload.accepted || (bigBag.process_cd === bigBagProcess.refund)">

              <div *ngIf="BigBagForm.submitted && bigBagBarcode.invalid" class="invalid-tooltip">
                <div *ngIf="bigBagBarcode.errors.required">El código de barras es obligatorio</div>
              </div>

            </div>

            <!-- Certificate -->
            <div class="form-group">
              <label for="biBagCertificateId">Certificado</label>
              <ng-select id="biBagCertificateId" [items]="certificates" [clearable]="false" [searchable]
                [clearSearchOnAdd] notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name"
                bindValue="id" [(ngModel)]="bigBag.certificate_id" name="bigBagCertificate"
                (change)="unsaveChanges= true" placeholder="Selecciona el certificado" [compareWith]="compareFn"
                [disabled]="unload.accepted">
              </ng-select>
            </div>

            <!-- Cocoa type -->
            <div class="form-group">
              <label for="bigBagCocoaTypeId">Cacao</label>
              <ng-select id="bigBagCocoaTypeId" [items]="cocoaTypes" [clearable]="false" [searchable] [clearSearchOnAdd]
                notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name" bindValue="id"
                [(ngModel)]="bigBag.cocoa_type_id" name="bigBagCocoaType" placeholder="Selecciona el cacao"
                (change)="removeCocoaQualityIfBabaSelected($event, 'bigBag'); unsaveChanges= true"
                [compareWith]="compareFn" disabled>
              </ng-select>
            </div>

            <!-- Cocoa Quality -->
            <div class="form-group" *ngIf="drySelected(bigBag)">
              <label for="bigBagCocoaQualityId">Calidad del cacao</label>
              <ng-select id="bigBagCocoaQualityId" [items]="cocoaQualities" [clearable]="false" [searchable]
                [clearSearchOnAdd] notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name"
                bindValue="id" [(ngModel)]="bigBag.cocoa_quality_id" name="bigBagCocoaQuality"
                (change)="unsaveChanges= true" placeholder="Selecciona calidad del cacao" [compareWith]="compareFn"
                [disabled]="unload.accepted">
              </ng-select>
            </div>

            <div class="form-group">
              <label for="bigBagWeight">Peso</label>
              <input type="number" class="form-control" id="bigBagWeight" [(ngModel)]="bigBag.weight" placeholder="Peso"
                name="bigBagWeight" disabled>
            </div>
          </div>

          <!-- Table with tickets-->
          <div class="col-5 d-inline-block">
            <div class="form-group">
              <div class="row">
                <div class="d-inline-block col-2">
                  Recibos del lote
                </div>
                <div class="d-inline-block col-10">
                  <div class="row">
                    <div class="d-inline-block col-6 float-right">
                      <ng-select id="bigBagTicketToAddId" [items]="tickets" [clearable]="true" [searchable]
                        [clearSearchOnAdd] notFoundText="No hay coincidencias" [(ngModel)]="ticket"
                        loadingText="Buscando" bindLabel="talbook_sheet" name="bigBagTicketToAdd"
                        placeholder="Selecciona el recibo" [compareWith]="compareFn" [disabled]="unload.accepted">
                      </ng-select>
                    </div>

                    <div class="d-inline-block col-3 float-right">
                      <button type="button" class="btn btn-primary btn-sm" (click)="addTicketToCurrentBigBag(ticket)"
                        [disabled]="unload.accepted || !ticket">Añadir recibo</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped table-hover table-sm">
                <thead class="thead-success">
                  <tr>
                    <th>Recibo</th>
                    <th>Descargado</th>
                    <th>Certificado</th>
                    <th>Acciones</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let ticket of bigBag.tickets; let i= index; trackBy:trackByFn;">
                    <td>{{ticket.talbook_sheet}} </td>
                    <td class="w-25">
                      <!-- <input type="number" min="0" class="form-control" [ngClass]="{'is-invalid' : (BigBagForm.submitted && !ticket.weight)
                            || (BigBagForm.submitted && ticket.weight <= 0) }"
                            (input)="setTicketWeightInBigBag($event.target.value, bigBag.id, ticket.ticket_id)"
                            [value]="getWeightToFixed(ticket.weight)" required [disabled]="unload.accepted"> -->
                      <input type="number" min="0" class="form-control" [ngClass]="{'is-invalid' : (BigBagForm.submitted && !ticket.weight)
                            || (BigBagForm.submitted && ticket.weight <= 0) }"
                        (input)="setTicketWeightInBigBag($event.target.value, ticket.ticket_id)"
                        [value]="ticket.fixed_weight" required [disabled]="unload.accepted">
                    </td>
                    <td>{{ticket.certificate?.name}}</td>
                    <td>

                      <mat-icon *ngIf="!unload.accepted" class="position-relative top-5 m-0 cursor-pointer text-danger"
                        (click)="openAlertModal(ticket)">delete
                      </mat-icon>

                    </td>
                    <td>

                      <div class="col">
                        <div class="row">
                          <button type="button" class="btn btn-sm btn-light"
                            (click)="changeTicketPosition(i, -1)" [disabled]="false">
                            <mat-icon class="">keyboard_arrow_up</mat-icon>
                          </button>
                        </div>
                        <div class="row">
                          <button type="button" class="btn btn-sm btn-light"
                            (click)="changeTicketPosition(i, 1)" [disabled]="false">
                            <mat-icon class="">keyboard_arrow_down</mat-icon>
                          </button>
                        </div>
                      </div>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-4">
            <div class="col-12" appNotRightClick>

              <div class="col-12 mt-2  pl-0 pr-0">
                <button [disabled]="bigBagImages.length < 1" type="button"  class="btn btn-danger btn-sm btn-block" (click)="removeImage(bigBag)">
                  Quitar imagen
                </button>
              </div>

              <div class="col-12 mt-2 mb-2 pl-0 pr-0">
                <button type="button" class="btn btn-success btn-sm btn-block">
                  Subir imagen
                  <input type="file" name="lotImage" class="upload-image-input" accept="image/*"
                    (change)="addImage(bigBag, $event)">
                </button>
              </div>

              <angular-image-viewer *ngIf="bigBagImages.length > 0" [src]="[bigBagImages[imageIndex].lot_content]" [(index)]="imageIndex"
                [config]="{ wheelZoom: true }"></angular-image-viewer>
            </div>
          </div>
        </div>
      </div>


      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" name="submitTradePrice" [disabled]="sending || !bigBag.tickets
              || bigBag.tickets.length <1"
          [disabled]="unload.accepted || unload.state_cd !== unloadState.finished">Guardar</button>
        <button type="button" class="btn btn-danger" (click)="closeModal()" name="closeModal"
          [disabled]="sending">Cancelar</button>
      </div>
    </form>
  </div>
</app-root-modal>

<!-- Modal alert -->
<ng-template #alertModal let-modal let-d="dismiss">
  <!-- Modal header -->
  <div class="modal-header">
    <h4 class="modal-title">¿Seguro qué desea eliminar este recibo?</h4>
  </div>
  <!-- Modal body -->
  <div class="modal-body">
    <h4>Esta a punto de eliminar un recibo del lote. ¿Seguro que desea continuar?</h4>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeAlertModal('accepted')"
      [disabled]="sending">Aceptar</button>
    <button type="button" class="btn btn-primary" (click)="closeAlertModal('cancel')" name="closeModal"
      [disabled]="sending">Cancelar</button>
  </div>
</ng-template>