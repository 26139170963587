import { Component, OnInit, ViewChild, ViewEncapsulation, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
// Components

// Models
import { TicketModel } from 'src/app/models/ticket.model';
import { CertificateModel } from 'src/app/models/certificate.model';
import { CocoaTypeModel } from 'src/app/models/cocoa-type.model';
import { UserModel } from 'src/app/models/user.model';
import { ContactModel } from 'src/app/models/contact.model';
import { CocoaQualityModel } from 'src/app/models/cocoa_quality.model';
import { BagModel } from 'src/app/models/bag.model';

// Services
import { TicketService } from 'src/app/services/ticket.service';
import { NotificationService } from 'src/app/services/notification.service';
import { GlobalsService } from 'src/app/services/globals.service';

// Constants
import { SettlementType, TicketType, TicketUnloadState, ContactPrebillingWeight } from 'src/app/app.enum';
import { PackageFormComponent } from './package-form/package-form.component';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import { TranslateService } from '@ngx-translate/core';

export interface ReceiptInterface {
  id: number;
  receipt_content: string;
}

@Component({
  selector: 'app-ticket-form',
  templateUrl: './ticket-form.component.html',
  styleUrls: ['./ticket-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TicketFormComponent implements OnInit {
  @ViewChild('updateTicketModal', { static: true }) private modal: TemplateRef<any>;
  @ViewChild(PackageFormComponent, { static: true }) private packageModal: PackageFormComponent;
  @ViewChild(AlertModalComponent, { static: true }) private alertModal: AlertModalComponent;

  @Output() result: EventEmitter<TicketModel[]> = new EventEmitter<TicketModel[]>();

  @Input() certificates: CertificateModel[] = new Array<CertificateModel>();
  @Input() cocoaTypes: CocoaTypeModel[] = new Array<CocoaTypeModel>();
  @Input() receivers: UserModel[] = new Array<UserModel>();
  @Input() suppliers: ContactModel[] = new Array<ContactModel>();
  @Input() cocoaQualities: CocoaQualityModel[] = new Array<CocoaQualityModel>();
  @Input() tickets: TicketModel[] = new Array<TicketModel>();

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'full-modal'
  };

  settlementTypes = [{ name: 'Pre-facturacion', id: SettlementType.prebilling }, { name: 'Resultado seco', id: SettlementType.dry_result }];
  ticketSettlementTypes = SettlementType;
  ticket: TicketModel = new TicketModel();
  ticketType = TicketType;
  ticketUnloadState = TicketUnloadState;
  sending: boolean = false;
  receiptIndex: number = 0;

  contactPrebillingWeight = [{ name: 'Peso del receptor', id: ContactPrebillingWeight.receiver_weight },
  { name: 'Peso del suplidor', id: ContactPrebillingWeight.supplier_weight }];



  constructor(
    private _modalService: NgbModal,
    private _ticketService: TicketService,
    private _notification: NotificationService,
    public globals: GlobalsService,
    private translate: TranslateService
    ) { 
      translate.setDefaultLang('es');
    }

  ngOnInit() {
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  setTicket(ticket: TicketModel) {
    this.ticket = ticket;
  }

  async openForm(ticket: TicketModel) {
    await this.getTicket(ticket.id);

    const collectedDate = moment(ticket['collected_date']).format('DD-MM-YYYY').split('-');

    const startedDate = moment(ticket['contract_starts']).format('DD-MM-YYYY').split('-');

    if (this.ticket.ticket_type_cd === TicketType.direct_purchase) {
      this.ticket['temp_contract_starts'] = {
        day: Number(startedDate[0]),
        month: Number(startedDate[1]),
        year: Number(startedDate[2])
      };

      this.ticket['temp_contract_ends'] = this.ticket['temp_contract_starts'];
    }

    this.ticket['temp_collected_date'] = {
      day: Number(collectedDate[0]),
      month: Number(collectedDate[1]),
      year: Number(collectedDate[2])
    };


    this.ticket['bags_info'] = this.ticket['bags'];
    _.each(this.suppliers, supplier => {
      supplier['full_name_and_diceros_id'] = `(${supplier.diceros_id}) ${supplier.full_name}`;
    });

    await this.openModal();
  }

  openPackageForm(ticket: TicketModel) {
    this.packageModal.openForm(ticket);
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  /**
   * 
   * @param o1 Es el array con todos los valores
   * @param o2 Es el valor que tiene actualmente el selectable
   */
  compareFn(o1, o2): boolean {
    if (_.isNumber(o2)) {
      return o1.id === o2;
    } else {
     return o1.id === o2.id
    }
     // return o1 && o2 ? o1.id === o2.id : o1 === o2;
   };

  updateTicket(ticket: TicketModel) {

    if (ticket.billed || !this.dateIsInSeasonRange(ticket)) {
      return;
    }

    let receipt_image;
    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    ticket = this.updateDates(ticket);
    ticket.hasOwnProperty('remove_receipt') && this.deleteReceiptImage(ticket, 'receipt');
    if (ticket.hasOwnProperty('provenance_sheet_attributes')) {
      // const t = JSON.parse(JSON.stringify(ticket));
      delete ticket['provenance_sheet_attributes'];
    }

    if (ticket['supplier'] && ticket['supplier']['id'] !== 0) {
      ticket['supplier_not_found'] = false;
    }

    if (ticket['receipt_content'] && ticket['receipt_content'].id) {
      receipt_image = _.cloneDeep(ticket['receipt_content']);
    }

    ticket['certificate_id'] = ticket['certificate'] && ticket['certificate']['id'];
    ticket['cocoa_type_id'] = ticket['cocoa_type'] && ticket['cocoa_type']['id'];
    ticket['original_receiver_id'] = ticket['original_receiver'] && ticket['original_receiver']['id'];
    ticket['contact_id'] = ticket['supplier'] && ticket['supplier']['id'];
    ticket['talbook_id'] = ticket['talbook'] && ticket['talbook']['id'];
    ticket['cocoa_quality_id'] = this.isDrySelected(ticket) ? ticket['cocoa_quality_id'] : null;

    console.log('El recibo enviado', ticket);
    this._ticketService
      .updateTicket(ticket)
      .then(
        async res => {
          const index = _.findIndex(this.tickets, { id: ticket['id'] });
          this.tickets[index] = res['ticket'];
          this.result.emit(this.tickets);

          if (receipt_image) {
            await this.uploadImage(receipt_image);
          }
 
          this.sending = false;
          this._notification.clear();
          this._notification.success('Actualizado correctamente');

          this.closeModal();
        }
      )
      .catch(
        err => {
          if (err.hasOwnProperty('ticket')) {
            const index = _.findIndex(this.tickets, { id: err['ticket']['id'] });
            this.tickets[index] = err['ticket'];
            this.closeModal();
          }

          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      );
  }

  removeReceipt(ticket: TicketModel) {
    if (ticket.receipt) {
      this.ticket['remove_receipt'] = ticket['receipt']['blob_id'];
      this.ticket.receipt = null;
    } else if (ticket['receipt_content']) {
      delete ticket['receipt_content'];
    }
  
  }

  isDrySelected(ticket: TicketModel) {
    if (ticket['cocoa_type']) {
      return ticket['cocoa_type']['name'].toLowerCase().includes('seco');
    } else {
      return false;
    }
  }

  changeCompleteState(event, ticket: TicketModel) {
    ticket['force_completed_state_to'] = event.checked;
  }

  setReviewedAndSend(ticket: TicketModel): void {
    if (this.canReviewed(ticket)) {
      ticket.reviewed = !ticket.reviewed;
      
      if (ticket.reviewed) {
        // if (!ticket.supplier.intermediary) {
        //   ticket.provenance_sheet_reviewed = ticket.reviewed;
        //   ticket.processed = true;
        // }
  
        this.updateTicket(ticket);

      } else  {
        if (ticket.supplier.intermediary && ticket.provenance_sheet_reviewed) {
          this.alertModal.openModal('Recuerde que', 'Al desrevisar este recibo tambien pasara a estado no revisado su hoja de procedencia.', ticket);
        } else  {
          ticket.provenance_sheet_reviewed = ticket.reviewed;
          ticket.processed = false;
          this.updateTicket(ticket);
        }
      }

    } else {
      if (ticket.billed) {
        this._notification.error('El recibo ya ha sido facturado.')
      } else {
        this._notification.error('Hay campos obligatorios sin rellenar.')
      }
    }
  }

  canReviewed(ticket: TicketModel): boolean {
    let required_fields = {
      supplier: false,
      certificate_id: false,
      cocoa_type_id: false,
      receiver_weight: false,
      receipt: false,
      original_receiver_id: false,
      current_receiver_id: false,
      collected_date: false
    };

    if (ticket.cocoa_type && ticket.cocoa_type.name.toLowerCase() === 'seco') {
      required_fields = Object.assign(required_fields, {
        cocoa_quality_id: false
      });
    }

    if (ticket.settlement_state_cd === SettlementType.prebilling && ticket.cocoa_type && ticket.cocoa_type.name.toLowerCase() === 'baba') {
      required_fields = Object.assign(required_fields, {
        contact_prebilling_weight_cd: false,
        contact_max_diff: false,
        supplier_performance: false
      });
    }

    _.each(required_fields, (value, key) => {
      if (key === 'contact_prebilling_weight_cd') {
        required_fields[key] = _.isNumber(ticket[key]);
      } else  {
        required_fields[key] = !!ticket[key];
      }
    });

    if (!ticket.billed && !ticket.finished && !ticket.unload_accepted) {
      if (!ticket.reviewed) {
        return Object.values(required_fields).every(Boolean);
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  setTicketBillingDatas() {
    if (this.ticket.settlement_state_cd === SettlementType.dry_result) {
      this.ticket = Object.assign(this.ticket, {
        contact_prebilling_weight_cd: null,
        contact_max_diff: null,
        supplier_performance: null
      });
    } else if (this.ticket.settlement_state_cd === SettlementType.prebilling && !!this.ticket.supplier) {
      this.ticket = Object.assign(this.ticket, {
        contact_prebilling_weight_cd: this.ticket.supplier.prebilling_weight_cd,
        contact_max_diff: this.ticket.supplier.max_diff,
        supplier_performance: this.ticket.supplier.performance
      });
    }
  }

  alertModalResult(result: any): void {
    if (result['reason'] === 'alert-accepted') {
      const ticket = result['returnValue'];
      ticket.provenance_sheet_reviewed = ticket.reviewed;
      ticket.processed = false;
      this.updateTicket(ticket);
    }
  }

  setReceipt(event: any): void {
    if(event && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.ticket.receipt = null;
        this.ticket['receipt_content'] = {
          id: this.ticket.id,
          receipt_content: reader.result
        }
      }
    }
  }

  private deleteReceiptImage(ticket: TicketModel, type: string) {
    this._ticketService.deleteReceiptImage(ticket, 'receipt')
      .then(
        res => {
          delete ticket['remove_receipt'];
          const index = _.findIndex(this.tickets, { id: ticket['id'] });
          this.tickets[index] = ticket;
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al eliminar la imagen');
          }
        }
      );
  }

  dateIsInSeasonRange(ticket: TicketModel): boolean {
    const season_start = moment(ticket.season.starts_at).startOf('day').toDate();
    const season_ends = moment(ticket.season.ends_at).startOf('day').toDate();
    const temp_collected_date = new Date(ticket['temp_collected_date']['year'], (ticket['temp_collected_date']['month'] -1), ticket['temp_collected_date']['day']);
    const current_season = moment(temp_collected_date).startOf('day').toDate();

    return moment(current_season).isSameOrAfter(season_start) && moment(current_season).isSameOrBefore(season_ends);
  }

  private updateDates(ticket: TicketModel): TicketModel {
    console.log('año', ticket['temp_collected_date']['year']);
    console.log('mes', ticket['temp_collected_date']['month']);
    console.log('dia', ticket['temp_collected_date']['day']);
    const date: Date = new Date(ticket['temp_collected_date']['year'], (ticket['temp_collected_date']['month'] -1), ticket['temp_collected_date']['day']);
    const collectedDate = moment(date, moment.ISO_8601, true).startOf('day').format('YYYY-MM-DD');

    // const displayEndDate = new Date();

    if (ticket['temp_collected_date']) {
      ticket['collected_date'] = collectedDate;

      if (ticket['ticket_type_cd'] === TicketType.direct_purchase) {
        ticket['contract_starts'] = ticket['collected_date'];
        ticket['contract_ends'] = ticket['collected_date'];
      }
    }


    if (ticket.ticket_type_cd === TicketType.direct_purchase) {
      const contract_start: Date = new Date(ticket['temp_contract_starts']['year'], (ticket['temp_contract_starts']['month'] - 1), ticket['temp_contract_starts']['day']);
      const displayStartDate =  moment(contract_start, moment.ISO_8601, true).startOf('day').format('YYYY-MM-DD');
      if (ticket['temp_contract_starts']) {

        ticket['contract_starts'] = displayStartDate;
        ticket['collected_date'] = displayStartDate;
        ticket['contract_ends'] = displayStartDate;
      }
    }

    return ticket;
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }

  private async getTicket(ticket_id: number) {
    await this._ticketService.getTicket(ticket_id)
      .then(
        res => {
          this.ticket = res['ticket'];
        }
      ).catch(err => {
        console.error(err);
        if (err.hasOwnProperty('errors')) {
          this._notification.error(err['errors'][0]);
          this.ticket = err['ticket'];
        } else {
          this._notification.error('Se ha producido un error.');
        }
      });
  }

  private async uploadImage(image: any): Promise<void> {
    await this._ticketService.uploadImages(image, 'receipt')
      .then(
        res => {
          const index = _.findIndex(this.tickets, { id: image['id'] });
          this.tickets[index] = res['ticket'];
          this.result.emit(this.tickets);
        }
      ).catch(err => console.error(err));
  }
}
