import { Component, OnInit, ViewChild, ViewEncapsulation, Output, TemplateRef, EventEmitter } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

// Models
import { TicketModel } from 'src/app/models/ticket.model';
import { BagModel } from 'src/app/models/bag.model';

// Constants
import { TicketUnloadState } from 'src/app/app.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-package-form',
  templateUrl: './package-form.component.html',
  styleUrls: ['./package-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PackageFormComponent implements OnInit {
  @ViewChild('content', { static: true }) modal: TemplateRef<any>;
  @Output() outputTicket: EventEmitter<TicketModel> = new EventEmitter<TicketModel>();
  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };

  ticket: TicketModel = new TicketModel();
  ticketUnloadState = TicketUnloadState;
  numberOfBagsChange: boolean = false;

  constructor(
    private _modalService: NgbModal,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('es');
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  ngOnInit() {
  }

  openForm(ticket: TicketModel) {
    this.ticket = _.cloneDeep(ticket);
    _.each(this.ticket['bags_info'], (bag, key) => {
      bag['fixed_weight'] = Number(this.fixedNumber(Number(bag['weight'])));
    });

    this.openModal();
  }

  fixedNumber(number: number, decimals: number = 2): string {
    return number.toFixed(decimals);
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  deleteBag(index: number) {
    let resultAux: number = 0;
    _.pullAt(this.ticket['bags_info'], index);

    _.each(this.ticket['bags_info'], (bag) => {
      resultAux += bag.weight;

    });

    this.ticket['receiver_weight'] = Number(resultAux.toFixed(1));
    this.ticket['contract_weight'] = this.ticket['receiver_weight'];
  }

  updateWeight(index: number, $value: number) {
    this.numberOfBagsChange = true;
    let resultAux = 0;
    this.ticket['bags_info'][index]['fixed_weight'] = Number($value);
    this.ticket['bags_info'][index].weight = Number($value);

    _.each(this.ticket['bags_info'], (bag) => {
      resultAux += bag.weight;
    });

    this.ticket['receiver_weight'] = Number(resultAux.toFixed(1));
    this.ticket['contract_weight'] = this.ticket['receiver_weight'];
  }

  changeNumberOfPackages(numberOfPackages: number): void {
    this.numberOfBagsChange = true;
    const totalWeight: number = Number(this.ticket.receiver_weight);
    const divider: number = Number(numberOfPackages);
    const onePackageWeight: number = totalWeight / divider;

    if (divider <= 0) {
      return;
    }

    this.ticket.bags_info = [];

    for (let i = 0; i < divider; i++) {
      this.addNewBag(onePackageWeight);
    }

  }

  addNewBag(weight: number = 0) {
    let bagInfo: BagModel = new BagModel;
    let code: string;
    if (!this.ticket['bags_info']) {
      this.ticket['bags_info'] = [];
    }

    // if (this.ticket['bags_info'].length === 0) {
    //   bagInfo.code = '1';
    //   bagInfo.weight = weight;
    //   bagInfo.ticket_id = this.ticket.id;
    // } else {
    //   bagInfo.code = (Number(_.last(this.ticket['bags_info'])['code']) + 1).toString();
    //   bagInfo.weight = weight;
    //   bagInfo.ticket_id = this.ticket.id;
    // }

    if (this.ticket['bags_info'].length === 0) {
      code = '1';
    } else {
      code = (Number(_.last(this.ticket['bags_info'])['code']) + 1).toString();
    }

    bagInfo = Object.assign(bagInfo, {
      code: code,
      weight: weight,
      fixed_weight: weight.toFixed(1),
      ticket_id: this.ticket.id
    });

    this.ticket['bags_info'].push(bagInfo);
  }

  emitTicket(ticket: TicketModel) {
    if (ticket.finished) {
      return;
    }
    
    if (!ticket.billed) {
      this.outputTicket.emit(ticket);
    }

    this.closeModal();
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }
}
