

<app-navbar></app-navbar>
<div class="row">
  <div class="col">
    <div class="filtersContainer">
      <div class="row">
        <div class="col-3">
          <button type="button" class="btn btn-outline-dark btn-block" (click)="isCollapsed= !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="collapsibleFilters">
            <!-- <mat-icon class="position-relative top-5 m-0">{{ isCollapsed ? 'visibility_off' : 'visibility' }}</mat-icon> -->
            <i class="{{ isCollapsed ? 'fa fa-eye-slash' : 'fa fa-eye' }}"></i>
            {{ isCollapsed ? 'Ocultar Filtros' : 'Mostrar Filtros' }}
          </button>
        </div>

        <div class="col-3 offset-6">
          <button class="btn btn-info btn-block" (click)="openForm(null)">
            <i class="fa fa-plus"></i>
            Nueva exportación
          </button>
        </div>
      </div>

      <!-- Filters -->
      <div class="" id="collapsibleFilters" [ngbCollapse]="!isCollapsed">
        <div class="row">
          <!-- Start date -->
          <div class="col-3">
            <label>Fecha inicio</label>

            <div class="input-group">
              <!-- input box -->
              <input class="form-control" name="mydate" placeholder="dd/mm/aaaa" angular-mydatepicker
                #dp="angular-mydatepicker" [options]="myDpOptions" (dateChanged)="onDateChanged($event, 'q[exportation_date_gteq]')"
                [locale]="'es'" (click)="dp.toggleCalendar()"/>

              <!-- clear date button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="filters['q[exportation_date_gteq]']" (click)="dp.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>

              <!-- toggle calendar button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>

            </div>
            <!-- <input class="form-control" type="date" [(ngModel)]="filters['q[exportation_date_gteq]']" /> -->
          </div>

          <!-- End date-->
          <div class="col-3">
            <label>Fecha final</label>
            <!-- <input class="form-control" type="date" [(ngModel)]="filters['q[exportation_date_lteq]']" /> -->

            <div class="input-group">
              <!-- input box -->
              <input class="form-control" name="mydate" placeholder="dd/mm/aaaa" angular-mydatepicker
                #dp1="angular-mydatepicker" [options]="myDpOptions" (dateChanged)="onDateChanged($event, 'q[exportation_date_lteq]')"
                [locale]="'es'" (click)="dp1.toggleCalendar()"/>

              <!-- clear date button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="filters['q[exportation_date_lteq]']" (click)="dp1.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>

              <!-- toggle calendar button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp1.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>

            </div>
          </div>

          <!-- Ticket -->
          <div class="col-3">
            <label>Rec. prov.</label>

            <ng-select [items]="tickets" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="talbook_sheet" bindValue="id" name="exportationTicket" placeholder="Recibo provisional"
              [multiple]="false" [(ngModel)]="filters['q[exportations_tickets_ticket_id_eq]']">
            </ng-select>
          </div>

          <!-- Container name -->
          <div class="col-3">
            <label>Nombre del contenedor</label>
            <input class="form-control" type="text" [(ngModel)]="filters['q[container_name_cont]']"
              placeholder="Nombre del contenedor" />
          </div>

          <!-- Transfer name-->
          <div class="col-3">
            <label>Traslado</label>
            <input class="form-control" type="text" [(ngModel)]="filters['q[exportations_tickets_transfer_cont]']"
              placeholder="Traslado" />
          </div>

          <!-- Entry code -->
          <div class="col-3">
            <label>Entrada al almacen</label>
            <input class="form-control" type="text" [(ngModel)]="filters['q[exportations_tickets_entry_code_cont]']"
              placeholder="Entrada al almacen" />
          </div>
        </div>

        <div class="row mt-3 align-bottom">
          <div class="col-3 offset-9 d-flex align-items-end">
            <button type="button" class="btn btn-success btn-block" (click)="getExportations(1, itemsPerPage, filters)">
              Filtrar
              <i class="fa fa-filter"></i>
            </button>
          </div>
        </div>

        <!-- <div class="col-3 d-flex align-items-end">
            <button type="button" class="btn btn-outline-dark btn-block" (click)="exportToExcel()" disabled>
              <div class="load-container" *ngIf="loadingExcel">
                <div class="loader-green d-inline-block"></div>
              </div>
              <i class="fa fa-download" *ngIf="!loadingExcel"></i>
              Descargar excel
            </button>
          </div> -->

      </div>
    </div>
  </div>
</div>

<!-- Table with exportation list -->
<div *ngIf="!loading">
  <table class="table sticky-yacao green table-striped table-hover table-sm">
    <thead>
      <tr>
        <th>Fecha de exportacion</th>
        <th>Nombre del contenedor</th>
        <th>Información</th>
        <th>Acciones</th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let exportation of exportations | paginate: { id:'exportationPagination', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; index as i;">
        <td>
          {{ exportation.exportation_date | date: 'dd/MM/yyyy'}}
        </td>
        <td>
          {{ exportation.container_name }}
        </td>
        <td>
          <button class="btn btn-sm btn-info"
            (click)="exportationShowModal.show(exportation)">
            Ver detalles
          </button>
        </td>
        <td>
          <div class="nav-item dropdown" ngbDropdown>
            <a class="btn btn-success btn-sm dropdown-toggle white" ngbDropdownToggle>
              Selecciona acción
            </a>

            <div class="dropdown-menu" aria-labelledby="admin" ngbDropdownMenu>
              <button class="btn btn-sm dropdown-item"
                (click)="openForm(exportation)" [disabled]="!exportation.active">
                <i class="fa fa-edit text-primary" *ngIf="!loadingExcel"></i>
                Editar
              </button>

              <button class="btn btn-sm dropdown-item" (click)="getExportation(exportation, 'xlsx')">
                <div class="load-container" *ngIf="loadingExcel">
                  <div class="loader-green d-inline-block"></div>
                </div>
                <i class="fa fa-download text-secundary" *ngIf="!loadingExcel"></i>
                Descargar excel
              </button>

              <button class="btn btn-sm dropdown-item" (click)="exportationForm.setExportationActiveValueTo(exportation, false);"
              [disabled]="!exportation.active">
                <!-- <mat-icon class="position-relative top-5 font-weight-bold">delete</mat-icon> -->
                <i class="fa fa-trash text-danger" *ngIf="!loadingExcel"></i>
                {{ exportation.active ? 'Desactivar' : 'Desactivada'}}
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="loading" *ngIf="loading">
  <ng-template #loadingTemplate></ng-template>
  <ngx-loading [show]="loading" [template]="loadingTemplate"></ngx-loading>
</div>

<div class="paginationContainer" *ngIf="!loading">
  <pagination-controls autoHide="true" id="exportationPagination" responsive="true" previousLabel="Anterior"
    nextLabel="Siguiente" (pageChange)="getExportations($event, itemsPerPage, filters)">
  </pagination-controls>
</div>



<app-exportation-forms [cocoaQualities]="cocoaQualities" [tickets]="tickets" (result)="setExportations($event); changePageAt(1)"></app-exportation-forms>
<app-exportation-details></app-exportation-details>