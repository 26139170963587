import { Injectable } from '@angular/core';
import { ToastrService, TOAST_CONFIG } from 'ngx-toastr';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private info_ref: any;
  constructor(private _notification: ToastrService) { }

  success(message: string, title: string = '', options?: {}): void {
    const opt = {
      timeOut: 5000,
      closeButton: true
    };

    this._notification.success(message, title, options || opt);
  }

  info(message: string, title: string = '', options?: {}): void {
    const opt = { enableHtml: true, disableTimeOut: true };
    this.info_ref = this._notification.info(message, title, options || opt);
  }

  error(message: string, title: string = '', options?: {}): void {
    const opt = { closeButton: true, disableTimeOut: true };

    this._notification.error(message, title, options || opt);
  }

  warning(message: string, title: string = '', options?: {}): void {
    const opt = {};
    this._notification.warning(message, title, options || opt);
  }

  clear(id?: number) {
    this._notification.clear(id);
    this.info_ref = null;
  }

  changeInfoMessage(message: string): void {
    if (this.info_ref) {
      const _componentInstance = this.info_ref.toastRef.componentInstance;
      _componentInstance.message = message;
    }
  }

  createOrUpdateInfoMessage(message: string, opt?: {}): void {
    if (this.info_ref) {
      this.changeInfoMessage(message);
    } else {
      this.info(message, '', opt);
    }
  }

  changeModalZIndexTo(zIndex: number): void {
    _.each(document.getElementsByClassName('modal'), (element, index) => {
      document.getElementsByClassName('modal')[index]['style']['z-index'] = zIndex;
    });
  }
}
