<app-navbar></app-navbar>
<div class="row">
  <div class="col">
    <div class="filtersContainer">
      <div class="row">
        <div class="col-3">
          <button type="button" class="btn btn-outline-dark btn-block" (click)="isCollapsed= !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="collapsibleFilters">
            <!-- <mat-icon class="position-relative top-5 m-0">{{ isCollapsed ? 'visibility_off' : 'visibility' }}</mat-icon> -->
            <i class="{{ isCollapsed ? 'fa fa-eye-slash' : 'fa fa-eye' }}"></i>
            {{ isCollapsed ? 'Ocultar Filtros' : 'Mostrar Filtros' }}
          </button>
        </div>

        <div class="col-3 offset-6">
          <button class="btn btn-info btn-block" (click)="openForm(null)">
            <!-- <mat-icon class="position-relative top-5 font-weight-bold">add</mat-icon> -->
            <i class="fa fa-plus"></i>
            Nuevo usuario
          </button>
        </div>
      </div>

      <div class="" id="collapsibleFilters" [ngbCollapse]="!isCollapsed">
        <div class="row">
          <div class="col-3">
            <label>Nombre</label>

            <ng-select [items]="allUsers" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="name" bindValue="id" name="userName" placeholder="Nombre de usuario"
              [multiple]="false" [(ngModel)]="filters['q[id_eq]']"></ng-select>
          </div>

          <div class="col-3">
            <label>Email</label>

            <ng-select [items]="allUsers" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="email" bindValue="email" name="userEmail" placeholder="Email de usuario"
              [multiple]="false" [(ngModel)]="filters['q[email_eq]']"></ng-select>
          </div>

          <div class="col-3">
            <label>Tipo de usuario</label>

            <ng-select [items]="filterRoles" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="name" bindValue="id" name="userRoles" placeholder="Rol de usuario"
              [multiple]="false" [(ngModel)]="filters['q[roles_id_eq]']"></ng-select>
          </div>

          <div class="col-3">
            <label>Centro</label>

            <ng-select [items]="centers" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="name" bindValue="id" name="userCenter" placeholder="Centro del usuario"
              [multiple]="false" [(ngModel)]="filters['q[center_id_eq]']"></ng-select>
          </div>

          <div class="col-3">
            <label>Zona</label>

            <ng-select [items]="zones" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="name" bindValue="id" name="userZones" placeholder="Zona de usuario"
              [multiple]="false" [(ngModel)]="filters['q[zones_id_eq]']"></ng-select>
          </div>

          <div class="col-3">
            <label>Activo</label>

            <ng-select [items]="yesOrNot" bindLabel="name" bindValue="value" [searchable] [hideSelected]="true"
              notFoundText="No hay coincidencias" loadingText="Buscando" name="userIsActive" placeholder="Activo"
              [multiple]="false" [(ngModel)]="filters['q[active_eq]']"></ng-select>
          </div>
        </div>
        <div class="row mt-3 align-bottom">
          <div class="col-3 offset-6 d-flex align-items-end">
            <button type="button" class="btn btn-outline-dark btn-block"
              (click)="getUsers(1, itemsPerPage, filters, 'xlsx')">
              <div class="load-container" *ngIf="loadingExcel">
                <div class="loader-green d-inline-block"></div>
              </div>
              <i class="fa fa-download" *ngIf="!loadingExcel"></i>
              Descargar excel
            </button>
          </div>
          <div class="col-3 d-flex align-items-end">
            <button type="button" class="btn btn-success btn-block" (click)="getUsers(1, itemsPerPage, filters)">
              Filtrar
              <i class="fa fa-filter"></i>
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<app-users-form [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" [currentPage]="currentPage" [users]="users"
  [roles]="roles" [zones]="zones" [centers]="centers" [allUsers]="allUsers" [suppliers]="suppliers"
  (result)="setUsers($event)"></app-users-form>

<div *ngIf="!loading">
  <table class="table sticky-yacao green table-striped table-hover table-sm">
    <thead>
      <tr>
        <th>#</th>
        <th>Nombre</th>
        <th>Email</th>
        <th>Roles</th>
        <th>Centro</th>
        <th>Zona</th>
        <th>Activo</th>
        <th>Acciones</th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let user of users | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i= index;">
        <td>
          {{globals.getAbsoluteIndex(itemsPerPage, currentPage, i) + 1}}
        </td>

        <td>
          {{user.name}} {{user.surname}}
        </td>

        <td>
          {{user.email}}
        </td>

        <td>
          <div class="col-12" *ngFor="let role of user.roles">
            {{role.name}}
          </div>
        </td>

        <td>
          {{ user.center?.name }}
        </td>

        <td>
          <div class="col-12" *ngFor="let zone of user.zones">
            {{zone.name}}
          </div>
        </td>

        <td>
          <mat-icon class="font-weight-bold"> {{ user.active ? 'check' : 'close' }}</mat-icon>
        </td>

        <td>
          <div class="nav-item dropdown" ngbDropdown>
            <a class="btn btn-success btn-sm dropdown-toggle white" ngbDropdownToggle>
              Selecciona acción
            </a>

            <div class="dropdown-menu" aria-labelledby="admin" ngbDropdownMenu>
              <button class="btn btn-sm dropdown-item" (click)="openForm(user)"
                [disabled]="(!globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor) || (roles.length <1 || zones.length <1)">
                <i class="fa fa-edit text-primary"></i>
                Editar
              </button>

              <button type="button" class="btn btn-sm dropdown-item" (click)="generatePincode(user)"
                [disabled]="!userIsUnloadManager(user)">
                <i class="fa fa-exclamation-triangle text-warning"></i>
                Generar pin
              </button>

              <button class="btn btn-sm dropdown-item" (click)="deactivateActivateUser(user)"
                [disabled]="(!globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor)">
                <i class="fa fa-trash text-danger"></i>
                {{ user.active ? 'Desactivar acceso' : 'Activar acceso'}}
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="loading" *ngIf="loading">
  <ng-template #loadingTemplate></ng-template>
  <ngx-loading [show]="loading" [template]="loadingTemplate"></ngx-loading>
</div>

<div class="paginationContainer" *ngIf="!loading">
  <pagination-controls autoHide="true" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
    (pageChange)="getUsers($event, itemsPerPage, filters)"></pagination-controls>
</div>