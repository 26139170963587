import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-root-modal',
  templateUrl: './root-modal.component.html',
  styleUrls: ['./root-modal.component.scss']
})
export class RootModalComponent implements OnInit {
  @ViewChild('modal') modalTemplate: TemplateRef<any>;
  private modalRef: NgbModalRef;
  @Input() private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'custom-modal'
  };

  constructor(private _modalService: NgbModal) { }

  ngOnInit() {
  }

  show(options?: NgbModalOptions) {
    console.log('Entra');
    this.modalRef = this._modalService.open(this.modalTemplate, options ? options : this.modalOptions);
  }

  hide() {
    this.modalRef.close();
   this.modalRef.dismiss();
  }
}
