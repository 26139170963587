import { TicketType } from '../app.enum';

export class FilterModel {
  // Delivery groups
  'q[id_eq]': number;
  'q[active_eq]': boolean;
  'q[delivery_groups_bool_certificates_certificate_id_eq]': number;
  'q[delivery_groups_list_certificates_certificate_id_eq]': number;
  'q[coordinator_id_eq]': number;
  'q[producers_id_eq]': number;

  // Exportations
  'q[exportation_date_gteq]': string;
  'q[exportation_date_lteq]': string;
  'q[exportations_tickets_ticket_id_eq]': number;
  'q[container_name_cont]': string;
  'q[exportations_tickets_transfer_cont]': string;
  'q[exportations_tickets_entry_code_cont]': string;

  // Contacts & Suppliers
  'q[supplier_eq]': boolean;
  'q[proffix_eq]': string;
  'q[buyer_id_eq]': number;
  'q[suppliers_delivery_groups_delivery_group_id_eq]': number;
  'q[contacts_zones_zone_id_eq]': number;
  'q[center_id_eq]': number;
  'q[suppliers_list_certificates_certificate_id_eq]': number;
  'q[active_as_supplier_eq]': boolean;
  'q[corporate_eq]': boolean;
  'q[processor_eq]': boolean;

  /* Contracts and Tickets */
  'q[used_eq]': boolean;
  'q[cocoa_type_id_eq]': number;
  'q[completed_eq]': boolean;
  'q[contact_id_eq]': number;
  'q[contract_starts_gteq]': string;
  'q[contract_ends_lteq]': string;
  'q[certificate_id_eq]': number;
  'q[finished_eq]': boolean;
  'q[nulled_eq]': boolean;
  'q[original_receiver_id_eq]': number;
  'q[processed_eq]': boolean;
  'q[talbook_sheet_cont]': string;
  'q[ticket_type_cd_eq]': number;
  'q[ticket_type_cd_not_eq]': number;
  'q[unload_center_id_eq]': number;
  'q[unloading_state_cd_eq]': number;
  'q[unloading_state_cd_not_eq]': number;
  'q[validated_eq]': boolean;
  g: any = [
    {
      m: 'or',
      collected_date_gteq: null,
      contract_starts_gteq: null
    },
    {
      m: 'or',
      collected_date_lteq: null,
      contract_starts_lteq: null
    }
  ];

  // Talbooks
  'q[talbook_type_id_eq]': number;
  'q[receiver_id_eq]': number;

  // Unloads
  'q[created_at_gteq]': string;
  'q[created_at_lteq]': string;
  'q[type_cd_eq]': number;
  'q[cocoa_quality_id_eq]': number;
  'q[user_id_eq]': number;
  'q[accepted_eq]': boolean;

  // Users
  'q[zones_id_eq]': number;
  'q[roles_id_eq]': number;
}
