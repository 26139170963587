import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

// Services
import { GlobalsService } from 'src/app/services/globals.service';
import { AuthService } from 'src/app/services/auth.service';

// Models
import { UserModel } from 'src/app/models/user.model';

// Constants
import { CONSTANTS } from 'src/app/app.consts';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  user: UserModel = new UserModel();
  mode = new FormControl('over');
  constructor(
    private _notification: ToastrService,
    public globals: GlobalsService,
    private router: Router,
    private auth: AuthService,
    private translate: TranslateService) {
    // translate.setDefaultLang('es');
  }

  ngOnInit(): void {
    this.user = new UserModel();
    const token: string = localStorage.getItem('yacao:app:token');
    const helper = new JwtHelperService();
    if (token && !helper.isTokenExpired(token)) {
      this._navigateToDefaultUserPage();
    }
  }

  login() {
    this._notification.clear();
    this._notification.info('Autenticando usuario...', null, {
      disableTimeOut: true
    });

    this.user['email'] = this.user['alias'] + '@yacao.com';
    const email = this.user.email.replace(/\s/g, "").toLowerCase();
    const password = this.user.password.replace(/\s/g, "");


    this.auth.login(email, password)
      .then(
        res => {
          this._notification.clear();
          this.setGlobalsCurrentUserDatas(res['user']);

          if (!this.canEnter()) {
            this._notification.error('No está autorizado a usar la aplicación', null, {
              closeButton: true,
              disableTimeOut: true
            });

            this.auth.logout();
          } else {
            this._notification.clear();
            this._notification.success('Autenticado correctamente', null, {
              timeOut: 5000,
              closeButton: true
            });

            this._navigateToDefaultUserPage();
          }
        }
      ).catch(
        err => {
          const options = {
            closeButton: true,
            disableTimeOut: true
          };

          this._notification.clear();
          if (err.errors.hasOwnProperty('user_password_invalid')) {
            this._notification.error('La contraseña es incorrecta o el usuario no tiene una contraseña válida.', null, options);
          } else if (err.errors.hasOwnProperty('user_not_found')) {
            this._notification.error('No existe usuario con ese identificador', null, options);
          } else {
            this._notification.error('Error al loguear', null, options);
          }
        }
      );
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  private setGlobalsCurrentUserDatas(user: UserModel) {
    user['center'] && (this.globals.currentUserCenterId = user['center_id']);
    this.globals.currentUserWorkCentersIds = _.map(user.work_centers, 'id');

    this.globals.currentUserRoles = [];
    this.globals.currentUserRolesAsBooleanObject = {};
    this.globals.currentUserCanTranslate = user.is_a_translator;

    _.each(user['roles'], role => {
      this.globals.currentUserRoles.push(role);

      this.globals.currentUserRolesAsBooleanObject[role['alias']] = true;
    });

    CONSTANTS.CURRENT_USER_ROLES = _.map(this.globals.currentUserRoles, 'alias');
  }

  private canEnter(): boolean {
    let canEnter = false;
    if (this.globals.currentUserRolesAsBooleanObject['admin']
      || this.globals.currentUserRolesAsBooleanObject['manager']
      || this.globals.currentUserRolesAsBooleanObject['certification_manager']
      || this.globals.currentUserRolesAsBooleanObject['supervisor']
      || this.globals.currentUserRolesAsBooleanObject['buyer']) {
      canEnter = true;
    }
    return canEnter;
  }

  private _navigateToDefaultUserPage() {
    let page: string;

    if (this.globals.currentUserRolesAsBooleanObject['manager']
      || this.globals.currentUserRolesAsBooleanObject['admin']
      || this.globals.currentUserRolesAsBooleanObject['supervisor']) {
      page = '/tickets';
    } else if (this.globals.currentUserRolesAsBooleanObject['buyer']) {
      page = '/contracts';
    } else if (this.globals.currentUserRolesAsBooleanObject['certification_manager']) {
      page = '/contacts';
    }

    if (page && (this.router.url === '/' || this.router.url === '/login')) {
      this.router.navigate([page]);
    }
  }
}
