<app-navbar></app-navbar>
<div class="row">
  <!-- FILTROS -->
  <div class="col">
    <div class="filtersContainer">
      <div class="row">
        <div class="col-3">
          <button *ngIf="!isInTranslateMode" type="button" class="btn btn-outline-dark btn-block"
            (click)="isCollapsed= !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapsibleFilters">
            <!-- <mat-icon class="position-relative top-5 m-0">{{ isCollapsed ? 'visibility_off' : 'visibility' }}</mat-icon> -->
            <i class="{{ isCollapsed ? 'fa fa-eye-slash' : 'fa fa-eye' }}"></i>
            {{ (isCollapsed ? 'buttons.filter_button_hidde.current_value' : 'buttons.filter_button_show.current_value') | translate }}
          </button>

          <button *ngIf="isInTranslateMode" type="button" class="btn btn-outline-dark btn-block"
            (click)="isCollapsed= !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapsibleFilters">
            <!-- <mat-icon class="position-relative top-5 m-0">{{ isCollapsed ? 'visibility_off' : 'visibility' }}</mat-icon> -->
            <i class="{{ isCollapsed ? 'fa fa-eye-slash' : 'fa fa-eye' }}"></i>
            {{ (isCollapsed ? 'buttons.filter_button_hidde' : 'buttons.filter_button_show') }}
          </button>
        </div>
      </div>

      <div class="" id="collapsibleFilters" [ngbCollapse]="!isCollapsed">
        <div class="row">
          <div class="col-3">
            <label *ngIf="!isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.creation_date_search_start.current_value' | translate}}
            </label>

            <label *ngIf="isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.creation_date_search_start' }}
            </label>
            <!--
            <input class="form-control" type="date" [(ngModel)]="filters.g[0].collected_date_gteq"
              [(ngModel)]="filters.g[0].contract_starts_gteq" placeholder="ajksdad"/>
     -->


            <div class="input-group">
              <!-- input box -->
              <input class="form-control" name="mydate"
                placeholder="{{ 'commons.filters.placeholders.date_format' | translate}}" angular-mydatepicker
                #dp="angular-mydatepicker" [options]="myDpOptions" (dateChanged)="onDateChanged($event, 'startDate')"
                [locale]="'es'" (click)="dp.toggleCalendar()" />

              <!-- clear date button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="filters.g[0].contract_starts_gteq"
                  (click)="dp.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>

              <!-- toggle calendar button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>

            </div>
          </div>

          <div class="col-3">
            <label *ngIf="!isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.supplier.current_value' | translate}}
            </label>

            <label *ngIf="isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.supplier.current_value' }}
            </label>

            <ng-select [items]="suppliers" [searchable] [hideSelected]="true"
              notFoundText="{{ 'commons.filters.not_found_text_search' | translate}}"
              loadingText="{{ 'commons.filters.loading_text_search' | translate}}" bindLabel="full_name" bindValue="id"
              name="producer" placeholder="{{ 'commons.filters.placeholders.supplier' | translate}}" [multiple]="false"
              [(ngModel)]="filters['q[contact_id_eq]']">
            </ng-select>
          </div>


          <div class="col-3">
            <label *ngIf="!isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.completed.current_value' | translate}}
            </label>

            <label *ngIf="isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.completed'}}
            </label>

            <ng-select [items]="yesOrNot" bindValue="value" bindLabel="name" [searchable] [hideSelected]="true"
              notFoundText="{{ 'commons.filters.not_found_text_search' | translate}}" loadingText="Buscando"
              name="isComplete" placeholder="{{ 'commons.filters.placeholders.completed' | translate}}"
              [multiple]="false" [(ngModel)]="filters['q[completed_eq]']">
            </ng-select>
          </div>


          <div class="col-3">
            <label *ngIf="!isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.ticket_number.current_value' | translate}}
            </label>

            <label *ngIf="isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.ticket_number'}}
            </label>

            <input class="form-control" type="text" [(ngModel)]="filters['q[talbook_sheet_cont]']"
              placeholder="{{ 'commons.filters.placeholders.ticket_number' | translate}}" />
          </div>

          <div class="col-3">
            <label *ngIf="!isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.creation_date_search_finish.current_value' | translate}}
            </label>

            <label *ngIf="isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.creation_date_search_finish' }}
            </label>


            <!-- <input class="form-control" type="date" [(ngModel)]="filters.g[1].collected_date_lteq"
              [(ngModel)]="filters.g[1].contract_starts_lteq" /> -->


            <div class="input-group">
              <!-- input box -->
              <input class="form-control" name="mydate"
                placeholder="{{ 'commons.filters.placeholders.date_format' | translate}}" angular-mydatepicker
                #dp1="angular-mydatepicker" [options]="myDpOptions" (dateChanged)="onDateChanged($event, 'endDate')"
                [locale]="'es'" (click)="dp1.toggleCalendar()" />

              <!-- clear date button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="filters.g[1].collected_date_lteq"
                  (click)="dp1.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>

              <!-- toggle calendar button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp1.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>

            </div>
          </div>

          <div class="col-3">
            <label *ngIf="!isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.certificate.current_value' | translate}}
            </label>

            <label *ngIf="isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.certificate'}}
            </label>

            <ng-select [items]="certificates" [searchable] [hideSelected]="true"
              notFoundText="{{ 'commons.filters.not_found_text_search' | translate}}" loadingText="Buscando"
              bindLabel="name" bindValue="id" name="certificates"
              placeholder="{{ 'commons.filters.placeholders.certificate' | translate}}" [multiple]="false"
              [(ngModel)]="filters['q[certificate_id_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label *ngIf="!isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.unloaded.current_value' | translate}}
            </label>

            <label *ngIf="isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.unloaded'}}
            </label>
            <!--
            <ng-select [items]="ticketUnloadStateFilter" bindValue="value" bindLabel="name" [searchable]
              [hideSelected]="true" notFoundText="No hay coincidencias" loadingText="Buscando" name="isNull"
              placeholder="Descargado" [multiple]="false" [(ngModel)]="filters['q[unloading_state_cd_eq]']">
            </ng-select> -->

            <ng-select [items]="ticketUnloadStateFilter" bindValue="value" bindLabel="name" [searchable]
              [hideSelected]="true" notFoundText="{{ 'commons.filters.not_found_text_search' | translate}}"
              loadingText="Buscando" name="isNull"
              placeholder="{{ 'commons.filters.placeholders.unloaded' | translate}}" [multiple]="false"
              (change)="setUnloadingStateFilter($event)">
            </ng-select>
          </div>


          <div class="col-3">
            <label *ngIf="!isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.unload_id.current_value' | translate}}
            </label>

            <label *ngIf="isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.unload_id' }}
            </label>

            <ng-select [items]="unloadIds" bindValue="value" bindLabel="value" [searchable] [hideSelected]="true"
              notFoundText="{{ 'commons.filters.not_found_text_search' | translate}}" loadingText="Buscando"
              name="isNull" placeholder="{{ 'commons.filters.placeholders.unload_id' | translate}}" [multiple]="false"
              [(ngModel)]="filters['q[unloads_id_eq]']">
            </ng-select>
          </div>

          <div class="col-3"
            *ngIf="globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor || globals.currentUserRolesAsBooleanObject.buyer">
            <label *ngIf="!isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.unload_center.current_value' | translate}}
            </label>

            <label *ngIf="isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.unload_center'}}
            </label>

            <ng-select [items]="centers" [searchable] [hideSelected]="true"
              notFoundText="{{ 'commons.filters.not_found_text_search' | translate}}" loadingText="Buscando"
              bindLabel="name" bindValue="id" name="centers"
              placeholder="{{ 'commons.filters.placeholders.unload_center' | translate}}" [multiple]="false"
              [(ngModel)]="filters['q[unload_center_id_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label *ngIf="!isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.cocoa_type.current_value' | translate}}
            </label>

            <label *ngIf="isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.cocoa_type'}}
            </label>

            <ng-select [items]="cocoaTypes" [searchable] [hideSelected]="true"
              notFoundText="{{ 'commons.filters.not_found_text_search' | translate}}" loadingText="Buscando"
              bindLabel="name" bindValue="id" name="cocoatype"
              placeholder="{{ 'commons.filters.placeholders.cocoa_type' | translate}}" [multiple]="false"
              [(ngModel)]="filters['q[cocoa_type_id_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label *ngIf="!isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.nulled.current_value' | translate}}
            </label>

            <label *ngIf="isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.nulled'}}
            </label>

            <ng-select [items]="yesOrNot" bindValue="value" bindLabel="name" [searchable] [hideSelected]="true"
              notFoundText="{{ 'commons.filters.not_found_text_search' | translate}}" loadingText="Buscando"
              name="isNull" placeholder="{{ 'commons.filters.placeholders.nulled' | translate}}" [multiple]="false"
              [(ngModel)]="filters['q[nulled_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label *ngIf="!isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.revised_ticket.current_value' | translate}}
            </label>

            <label *ngIf="isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.revised_ticket' }}
            </label>

            <ng-select [items]="yesOrNot" bindValue="value" bindLabel="name" [searchable] [hideSelected]="true"
              notFoundText="{{ 'commons.filters.not_found_text_search' | translate}}" loadingText="Buscando"
              name="reviewed" placeholder="{{ 'commons.filters.placeholders.revised_ticket' | translate}}"
              [multiple]="false" [(ngModel)]="filters['q[reviewed_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label *ngIf="!isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.receiver.current_value' | translate}}
            </label>

            <label *ngIf="isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.receiver' }}
            </label>

            <ng-select [items]="receivers" [searchable] [hideSelected]="true"
              notFoundText="{{ 'commons.filters.not_found_text_search' | translate}}" loadingText="Buscando"
              bindLabel="name" bindValue="id" name="receiver"
              placeholder="{{ 'commons.filters.placeholders.receiver' | translate}}" [multiple]="false"
              [(ngModel)]="filters['q[original_receiver_id_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label *ngIf="!isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.ticket_type.current_value' | translate}}
            </label>

            <label *ngIf="isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.ticket_type' }}
            </label>

            <ng-select [items]="ticketTypeFilter" bindLabel="name" bindValue="value" [searchable] [hideSelected]="true"
              notFoundText="{{ 'commons.filters.not_found_text_search' | translate}}" loadingText="Buscando"
              name="isNull" placeholder="{{ 'commons.filters.placeholders.ticket_type' | translate}}" [multiple]="false"
              [(ngModel)]="filters['q[ticket_type_cd_eq]']">
            </ng-select>
          </div>


          <div class="col-3">
            <label *ngIf="!isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.finished.current_value' | translate}}
            </label>

            <label *ngIf="isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.finished' }}
            </label>

            <ng-select [items]="yesOrNot" bindValue="value" bindLabel="name" [searchable] [hideSelected]="true"
              notFoundText="{{ 'commons.filters.not_found_text_search' | translate}}" loadingText="Buscando"
              name="isFinished" placeholder="{{ 'commons.filters.placeholders.finished' | translate}}"
              [multiple]="false" [(ngModel)]="filters['q[finished_eq]']">
            </ng-select>
          </div>


          <!-- <div class="col-3">
            <label>
              Procesado
            </label>

            <ng-select [items]="yesOrNot" bindValue="value" bindLabel="name" [searchable] [hideSelected]="true"
              notFoundText="No hay coincidencias" loadingText="Buscando" name="processed" placeholder="Procesado"
              [multiple]="false" [(ngModel)]="filters['q[processed_eq]']">
            </ng-select>
          </div> -->

          <div class="col-3">
            <label *ngIf="!isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.revised_provenance.current_value' | translate}}
            </label>

            <label *ngIf="isInTranslateMode">
              {{ 'cocoa_receipt_filters_labels.revised_provenance'}}
            </label>

            <ng-select [items]="yesOrNot" bindValue="value" bindLabel="name" [searchable] [hideSelected]="true"
              notFoundText="{{ 'commons.filters.not_found_text_search' | translate}}" loadingText="Buscando"
              name="provenance_sheet_reviewed"
              placeholder="{{ 'commons.filters.placeholders.revised_provenance' | translate}}" [multiple]="false"
              [(ngModel)]="filters['q[provenance_sheet_reviewed_eq]']" (change)="setContactIntermediaryFiter($event)">
            </ng-select>
          </div>
        </div>

        <div class="row mt-3 align-bottom">
          <div class="col-3 offset-6 d-flex align-items-end">
            <button *ngIf="!isInTranslateMode" type="button" class="btn btn-outline-dark btn-block"
              (click)="getTickets(1, itemsPerPage, filters, 'xlsx')">
              <div class="load-container" *ngIf="loadingExcel">
                <div class="loader-green d-inline-block"></div>
              </div>
              <i class="fa fa-download" *ngIf="!loadingExcel"></i>
              {{ 'buttons.excel_download_button.current_value' | translate}}
            </button>

            <button *ngIf="isInTranslateMode" type="button" class="btn btn-outline-dark btn-block"
              (click)="getTickets(1, itemsPerPage, filters, 'xlsx')">
              <div class="load-container" *ngIf="loadingExcel">
                <div class="loader-green d-inline-block"></div>
              </div>
              <i class="fa fa-download" *ngIf="!loadingExcel"></i>
              {{ 'buttons.excel_download_button' }}
            </button>
          </div>

          <div class="col-3 d-flex align-items-end">
            <button *ngIf="!isInTranslateMode" type="button" class="btn btn-success btn-block"
              (click)="getTickets(1, itemsPerPage, filters)">
              <i class="fa fa-filter"></i>
              {{ 'buttons.filter_button.current_value' | translate}}
            </button>

            <button *ngIf="isInTranslateMode" type="button" class="btn btn-success btn-block"
              (click)="getTickets(1, itemsPerPage, filters)">
              <i class="fa fa-filter"></i>
              {{ 'buttons.filter_button' }}
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- LISTADO DE RECIBOS -->
<div *ngIf="!loading">
  <table class="table sticky-yacao green table-striped table-hover table-sm">
    <thead>
      <tr>
        <th>
          {{ isInTranslateMode ? 'cocoa_receipt_table_headers.collected_date' : ('cocoa_receipt_table_headers.collected_date.current_value' | translate)}}
        </th>

        <th>
          {{ isInTranslateMode ? 'cocoa_receipt_table_headers.state': ('cocoa_receipt_table_headers.state.current_value' | translate)}}
        </th>

        <th>
          {{ isInTranslateMode ? 'cocoa_receipt_table_headers.ticket_number' : ('cocoa_receipt_table_headers.ticket_number.current_value' | translate)}}
        </th>

        <th>
          {{ isInTranslateMode ? 'cocoa_receipt_table_headers.ticket_type' : ('cocoa_receipt_table_headers.ticket_type.current_value' | translate)}}
        </th>

        <th>
          {{ isInTranslateMode ? 'cocoa_receipt_table_headers.ticket_photo' : ('cocoa_receipt_table_headers.ticket_photo.current_value' | translate)}}
        </th>

        <th>
          {{  isInTranslateMode ? 'cocoa_receipt_table_headers.receiver' : ('cocoa_receipt_table_headers.receiver.current_value' | translate)}}
        </th>

        <th>
          {{ isInTranslateMode ? 'cocoa_receipt_table_headers.supplier' : ('cocoa_receipt_table_headers.supplier.current_value' | translate)}}
        </th>

        <th>
          {{ isInTranslateMode ? 'cocoa_receipt_table_headers.cocoa_type' : ('cocoa_receipt_table_headers.cocoa_type.current_value' | translate)}}
        </th>

        <th>
          {{ isInTranslateMode ? 'cocoa_receipt_table_headers.certificate' : ('cocoa_receipt_table_headers.certificate.current_value' | translate) }}
        </th>

        <th>
          {{ isInTranslateMode ? 'cocoa_receipt_table_headers.cocoa_quality' : ('cocoa_receipt_table_headers.cocoa_quality.current_value' | translate)}}
        </th>

        <th>
          {{ isInTranslateMode ? 'cocoa_receipt_table_headers.supplier_weighing' : ('cocoa_receipt_table_headers.supplier_weighing.current_value' | translate)}}
        </th>

        <th>
          {{ isInTranslateMode ? 'cocoa_receipt_table_headers.receiver_weighing' : ('cocoa_receipt_table_headers.receiver_weighing.current_value' | translate)}}
        </th>

        <th>
          {{ isInTranslateMode ? 'cocoa_receipt_table_headers.provenance' : ('cocoa_receipt_table_headers.provenance.current_value' | translate)}}
        </th>

        <th>
          {{ isInTranslateMode ? 'cocoa_receipt_table_headers.location' : ('cocoa_receipt_table_headers.location.current_value' | translate)}}
        </th>

        <!--
        <th>
          Tipo
          <br>
          facturación
        </th> -->

        <th>
          {{ isInTranslateMode ? 'cocoa_receipt_table_headers.unload_id' : ('cocoa_receipt_table_headers.unload_id.current_value' | translate)}}
        </th>

        <!-- <th>
          Descargado
        </th> -->

        <th>
          {{ isInTranslateMode ? 'cocoa_receipt_table_headers.revised_ticket' : ('cocoa_receipt_table_headers.revised_ticket.current_value' | translate)}}
        </th>

        <th>
          {{ isInTranslateMode ? 'cocoa_receipt_table_headers.revised_provenance' : ('cocoa_receipt_table_headers.revised_provenance.current_value' | translate)}}
        </th>

        <!-- <th>Facturado</th>  Descomentar para saber si el recibo esta facturado no -->

        <th>
          {{isInTranslateMode ? 'cocoa_receipt_table_headers.actions' : ('cocoa_receipt_table_headers.actions.current_value' | translate)}}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let ticket of tickets | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; index as i;">
        <td>
          <span *ngIf="ticket.ticket_type_cd === ticketType.direct_purchase">
            {{ ticket.contract_starts | date:'dd/MM/yy' }}
          </span>
          <span *ngIf="ticket.ticket_type_cd === ticketType.cocoa_receipt">
            {{ ticket.collected_date | date:'dd/MM/yy' }}
          </span>
        </td>

        <td>
          {{ getStateOf(ticket) }}
        </td>

        <td>
          {{ ticket.talbook_sheet }}
        </td>

        <td>
          {{ getTicketTypeOf(ticket.ticket_type_cd) }}
        </td>

        <td>
          <mat-icon class="pointer" *ngIf="ticket.receipt"
            (click)="openGallery(('Recibo ' + ticket.talbook_sheet), [ticket.receipt.url])">
            image
          </mat-icon>

          <mat-icon *ngIf="!ticket.receipt">
            close
          </mat-icon>
        </td>

        <td>
          {{ ticket.original_receiver?.name }}
        </td>

        <td [className]="ticket.supplier_not_found ? 'text-danger' : 'text-dark'">
          {{ ticket.supplier_not_found ? 'Suplidor no encontrado' : ticket.supplier?.full_name }}
        </td>


        <td>
          {{ ticket.cocoa_type?.name }}
        </td>

        <td>
          {{ ticket.certificate?.name }}
        </td>

        <td>
          {{ ticket.cocoa_quality?.name }}
        </td>

        <td>
          {{ ticket.supplier_weight }}
        </td>

        <td>
          {{ ticket.receiver_weight }}
        </td>

        <td>
          <mat-icon class="pointer"
            *ngIf="ticket.provenance_sheet && ticket.provenance_sheet?.sheet_images.length !== 0"
            (click)="openProvenanceSheetForm(ticket)">
            collections
          </mat-icon>

          <mat-icon (click)="openProvenanceSheetForm(ticket)" class="pointer"
            *ngIf="(ticket.supplier?.intermediary && !ticket.provenance_sheet) || (ticket.supplier?.intermediary && ticket.provenance_sheet?.sheet_images.length === 0)">
            close
          </mat-icon>
        </td>

        <td>
          {{ ticket.unload_center?.name || ticket.current_receiver?.name }}
        </td>
        <!--
        <td>
          {{ ticket.settlement_state_cd === enumSettlementState.prebilling ? 'Pre negociado' :
          ticket.settlement_state_cd === enumSettlementState.dry_result ? 'Resultado seco' : '' }}
        </td> -->

        <td>
          <span>
            <div *ngFor="let unload_id of ticket.unload_ids">
              {{ unload_id }}
            </div>
          </span>
          <i *ngIf="ticket.unload_ids && ticket.unload_ids.length < 1"
            class="{{ ticket.unloading_state_cd === ticketUnloadState.unloaded ? '' : 'fa fa-times' }}"></i>
        </td>

        <!-- <td>
            <div *ngFor="let unload_id of ticket.unload_id">
              {{unload_id}}
            </div>
          </td> -->


        <!-- <td>
          <i class="{{ ticket.unloading_state_cd === ticketUnloadState.unloaded ? 'fa fa-check' : 'fa fa-times' }}"></i>
        </td> -->

        <td>
          <i class="{{ ticket.reviewed ? 'fa fa-check' : 'fa fa-times' }}"></i>
        </td>

        <td>
          <i *ngIf="ticket.supplier && ticket.supplier.intermediary"
            class="{{ ticket.provenance_sheet_reviewed ? 'fa fa-check' : 'fa fa-times' }}"></i>
        </td>
        <!-- Descomentar si se quiere saber si el recibo esta facturado o no
        <td>
          <i class="{{ ticket.billed ? 'fa fa-check' : 'fa fa-times' }}"></i>
        </td> -->

        <!--
          Dropdown with options
        -->
        <td>
          <div class="nav-item dropdown" ngbDropdown>
            <a class="btn btn-success btn-sm dropdown-toggle white" ngbDropdownToggle>
              {{ 'commons.actions_dropdown.title' | translate}}
            </a>

            <div class="dropdown-menu" aria-labelledby="admin" ngbDropdownMenu>
              <button class="btn btn-sm dropdown-item" (click)="openTicketForm(ticket)"
                [disabled]="disabledIfCurrent()">
                <mat-icon class="position-relative top-5 text-primary">
                  edit
                </mat-icon>
                {{ 'commons.actions_dropdown.options.edit' | translate}}
              </button>

              <!-- <button class="btn btn-sm dropdown-item" (click)="ticketProcessedOut(ticket)"
                [disabled]="ticket.nulled || !ticket.completed || ticket.finished || disabledIfCurrent() || sending">
                <mat-icon class="position-relative top-5 text-danger" *ngIf="ticket.processed">
                  close
                </mat-icon>
                <mat-icon class="position-relative top-5 text-warning" *ngIf="!ticket.processed">
                  settings
                </mat-icon>
                {{ ticket.processed ? 'No procesado' : 'Procesado' }}
              </button> -->

              <button *ngIf="ticket.provenance_sheet && ticket.provenance_sheet?.sheet_images.length"
                class="btn btn-sm dropdown-item" (click)="openProvenanceSheetForm(ticket)"
                [disabled]="ticket.nulled || sending">
                <mat-icon class="position-relative top-5 text-info">description</mat-icon>
                {{ 'commons.actions_dropdown.options.provenance_form' | translate}}
              </button>

              <button class="btn btn-sm dropdown-item" (click)="ticketFinished(ticket)"
                [disabled]="ticket.billed || ticket.nulled || !ticket.reviewed || !ticket.provenance_sheet_reviewed || (!globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor) || sending">
                <mat-icon class="position-relative top-5 text-danger" *ngIf="ticket.finished">close</mat-icon>
                <mat-icon class="position-relative top-5 text-success" *ngIf="!ticket.finished">check</mat-icon>
                {{ (ticket.finished ? 'commons.actions_dropdown.options.not_finished' : 'commons.actions_dropdown.options.finished') | translate}}
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!-- Loading div-->
<div class="loading" *ngIf="loading">
  <ng-template #loadingTemplate></ng-template>
  <ngx-loading [show]="loading" [template]="loadingTemplate"></ngx-loading>
</div>

<!-- PAGINADOR -->
<div class="paginationContainer" *ngIf="!loading">
  <pagination-controls autoHide="true" responsive="true"
    previousLabel="{{ 'commons.pagination_labels.prev' | translate}}"
    nextLabel="{{ 'commons.pagination_labels.next' | translate}}"
    (pageChange)="getTickets($event, itemsPerPage, filters)">
  </pagination-controls>
</div>


<app-ticket-form [certificates]="certificates" [cocoaTypes]="cocoaTypes" [receivers]="receivers" [suppliers]="suppliers"
  [cocoaQualities]="cocoaQualities" [tickets]="tickets" (result)="setTickets($event)"></app-ticket-form>

<app-provenance-sheet-form [tickets]="tickets" [contacts]="contacts" (result)="setTickets($event)">
</app-provenance-sheet-form>
<app-images-modal></app-images-modal>