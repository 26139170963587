<app-navbar></app-navbar>
<!-- Container with filters and create button and expor to excel buttons -->
<div class="row">
  <div class="col">
    <div class="filtersContainer">
      <div class="row">
        <div class="col-3">
          <button type="button" class="btn btn-outline-dark btn-block" (click)="isCollapsed= !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="collapsibleFilters">
            <!-- <mat-icon class="position-relative top-5 m-0">{{ isCollapsed ? 'visibility_off' : 'visibility' }}</mat-icon> -->
            <i class="{{ isCollapsed ? 'fa fa-eye-slash' : 'fa fa-eye' }}"></i>
            {{ (isCollapsed ? 'commons.filters.filter_button_hidde' : 'commons.filters.filter_button_show') | translate}}
          </button>
        </div>
      </div>

      <div class="" id="collapsibleFilters" [ngbCollapse]="!isCollapsed">
        <div class="row">
          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.creation_date_search_start' | translate}}
            </label>

            <div class="input-group">
              <!-- input box -->
              <input class="form-control" name="mydate" placeholder="{{ 'commons.filters.placeholders.date_format' | translate}}" angular-mydatepicker
                #dp="angular-mydatepicker" [options]="myDpOptions"
                (dateChanged)="onDateChanged($event, 'q[created_at_gteq]')" [locale]="'es'"
                (click)="dp.toggleCalendar()" />

              <!-- clear date button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="filters['q[created_at_gteq]']"
                  (click)="dp.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>

              <!-- toggle calendar button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>

            </div>
            <!-- <input class="form-control" type="date" [(ngModel)]="filters['q[created_at_gteq]']" /> -->
          </div>


          <!-- Unload manager filter -->
          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.unload_manager' | translate}}
            </label>

            <ng-select [items]="unloadManagers" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="name" bindValue="id" name="unloadManager" placeholder="Encargado"
              [multiple]="false" [(ngModel)]="filters['q[user_id_eq]']">
            </ng-select>
          </div>

          <!-- Cocoa quality filter -->
          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.cocoa_quality' | translate}}
            </label>

            <ng-select [items]="cocoaQualities" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindValue="id" bindLabel="name" name="cocoaQuality" placeholder="Calidad del cacao"
              [multiple]="false" [(ngModel)]="filters['q[cocoa_quality_id_eq]']">
            </ng-select>
          </div>


          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.ticket_number' | translate}}
            </label>

            <input class="form-control" type="text" [(ngModel)]="filters.g[0].tickets_talbook_sheet_cont"
              [(ngModel)]="filters.g[0].provisional_unloads_ticket_talbook_sheet_cont"
              placeholder="Número del recibo" />
            <!-- <ng-select [items]="allTickets" bindValue="id" bindLabel="talbook_sheet" [searchable] [hideSelected]="true"
              notFoundText="No hay coincidencias" loadingText="Buscando" name="unloadTickets" placeholder="Recibo"
              [multiple]="false" [(ngModel)]="filters['q[tickets_id_eq]']">
            </ng-select> -->
          </div>


          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.creation_date_search_finish' | translate}}
            </label>

            <div class="input-group">
              <!-- input box -->
              <input class="form-control" name="mydate" placeholder="{{ 'commons.filters.placeholders.date_format' | translate}}" angular-mydatepicker
                #dp1="angular-mydatepicker" [options]="myDpOptions"
                (dateChanged)="onDateChanged($event, 'q[created_at_lteq]')" [locale]="'es'"
                (click)="dp1.toggleCalendar()" />

              <!-- clear date button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="filters['q[created_at_lteq]']"
                  (click)="dp1.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>

              <!-- toggle calendar button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp1.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>

            </div>
            <!-- <input class="form-control" type="date" [(ngModel)]="filters['q[created_at_lteq]']" /> -->
          </div>

          <!-- Certificates filter -->
          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.certificate' | translate}}
            </label>

            <ng-select [items]="certificates" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindValue="id" bindLabel="name" name="certificates" placeholder="Certificados"
              [multiple]="false" [(ngModel)]="filters['q[certificate_id_eq]']">
            </ng-select>
          </div>


          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.validated' | translate}}
            </label>

            <ng-select [items]="yesOrNot" bindValue="value" bindLabel="name" [searchable] [hideSelected]="true"
              notFoundText="No hay coincidencias" loadingText="Buscando" name="isAccepted" placeholder="Validada"
              [multiple]="false" [(ngModel)]="filters['q[accepted_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.unload_id' | translate}}
            </label>

            <ng-select [items]="allIds" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" name="unloadIds" placeholder="Identificador" [multiple]="false"
              [(ngModel)]="filters['q[id_eq]']">
            </ng-select>
          </div>

          <!-- Center filter -->
          <div class="col-3"
            *ngIf="globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor">
            <label>
              {{ 'commons.filters.labels.unload_center' | translate}}
            </label>

            <ng-select [items]="centers" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindValue="id" bindLabel="name" name="center" placeholder="Centro"
              [multiple]="false" [(ngModel)]="filters['q[center_id_eq]']">
            </ng-select>
          </div>

          <!-- Cocoa type filter -->
          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.cocoa_type' | translate}}
            </label>

            <ng-select [items]="cocoaTypes" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindValue="id" bindLabel="name" name="cocoaType" placeholder="Tipo de cacao"
              [multiple]="false" [(ngModel)]="filters['q[cocoa_type_id_eq]']">
            </ng-select>
          </div>


          <!-- Unload Type filter -->
          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.unload_type' | translate}}
            </label>

            <ng-select [items]="unloadTypeFilter" bindValue="value" bindLabel="name" [searchable] [hideSelected]="true"
              notFoundText="No hay coincidencias" loadingText="Buscando" name="unloadType"
              placeholder="Tipo de descarga" [multiple]="false" [(ngModel)]="filters['q[type_cd_eq]']">
            </ng-select>
          </div>


          <div class="col-3">
            <label>
              {{ 'commons.filters.labels.lot' | translate}}
            </label>

            <ng-select [items]="allBarcodes" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" name="unloadLots" placeholder="Lote" [multiple]="false"
              [(ngModel)]="filters['q[big_bags_barcode_number_eq]']">
            </ng-select>
          </div>

        </div>

        <div class="row mt-3 align-bottom">
          <div class="col-3 offset-6 align-items-end">
            <button type="button" class="btn btn-outline-dark btn-block"
              (click)="getUnloads(1, itemsPerPage, filters, 'xlsx')">
              <div class="load-container" *ngIf="loadingExcel">
                <div class="loader-green d-inline-block"></div>
              </div>
              <i class="fa fa-download" *ngIf="!loadingExcel"></i>
              {{ 'commons.filters.excel_download_button' | translate}}
            </button>
          </div>

          <div class="col-3 align-items-end">
            <button type="button" class="btn btn-success btn-block" (click)="getUnloads(1, itemsPerPage, filters)">
              <i class="fa fa-filter"></i>
              {{ 'commons.filters.filter_button' | translate}}
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- Table with datas -->
<div *ngIf="!loading">
  <table class="table sticky-yacao green table-striped table-hover table-sm">
    <thead>
      <tr>
        <th>
          {{ 'pages.table_headers.#' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.id' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.unload_date' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.unload_hour' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.unload_manager' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.certificate' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.cocoa_type' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.cocoa_quality' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.center' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.unload_type' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.status' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.unload_validated' | translate}}
        </th>
        <th>
          {{ 'pages.table_headers.actions' | translate}}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let unload of unloads | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i= index;">
        <td>{{globals.getAbsoluteIndex(itemsPerPage, currentPage, i) + 1}}</td>
        <td>{{unload.id}}</td>
        <td>{{unload.created_at | date: 'dd/MM/yyyy'}}</td>
        <td>{{unload.created_at | date: 'HH:mm'}}</td>
        <td>{{unload.unload_manager_name}}</td>
        <td>{{unload.certificate_name}}</td>
        <td>{{unload.cocoa_type_name}}</td>
        <td>{{unload.cocoa_quality_name}}</td>
        <td>{{unload.center_name}}</td>
        <td>{{ (unload.type_cd === unloadType.final ? 'pages.unload_forms.unload_type_final' : 'pages.unload_forms.unload_type_provisional') | translate}}</td>
        <td *ngIf="unload.type_cd === unloadType.final">
          {{ (unload.state_cd === unloadState.pending ? 'pages.unload_forms.unload_state.paused' : unload.state_cd === unloadState.in_progress ? 'pages.unload_forms.unload_state.inprogress' : 'pages.unload_forms.unload_state.finished') | translate}}
        </td>
        <td *ngIf="unload.type_cd === unloadType.provisional">
          {{ allTicketsAreUnloaded(unload) ? 'pages.unload_forms.unload_state.loaded' : 'pages.unload_forms.unload_state.pending' }}
        </td>
        <td>
          <mat-icon class="position-relative top-5" *ngIf="unload.type_cd === unloadType.final">
            {{ unload.accepted ? 'checkmark' : 'close' }}</mat-icon>
        </td>
        <td>
          <div class="col-12">
            <div class="nav-item dropdown" ngbDropdown>
              <a class="btn btn-success btn-sm dropdown-toggle white" ngbDropdownToggle>
                {{ 'commons.actions_dropdown.title' | translate}}
              </a>

              <div class="dropdown-menu" aria-labelledby="admin" ngbDropdownMenu>
                <!-- (click)="setUnload(unload, unload.type_cd === unloadType.final ? finalUnloadModal : provisionalUnloadModal, 'full-modal')" -->
                <button class="btn btn-sm dropdown-item" (click)="openForm(unload)">
                  <mat-icon class="position-relative top-5 text-primary">edit</mat-icon>
                  {{ 'commons.actions_dropdown.options.edit' | translate}}
                </button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="loading" *ngIf="loading">
  <ng-template #loadingTemplate></ng-template>
  <ngx-loading [show]="loading" [template]="loadingTemplate"></ngx-loading>
</div>

<div class="paginationContainer" *ngIf="!loading">
  <pagination-controls autoHide="true" responsive="true" previousLabel="{{ 'commons.pagination_labels.prev' | translate}}"  
    nextLabel="{{ 'commons.pagination_labels.next' | translate}}"
    (pageChange)="getUnloads($event, itemsPerPage, filters)"></pagination-controls>
</div>


<app-final-unload-form [certificates]="certificates" [unloadManagers]="unloadManagers" [cocoaTypes]="cocoaTypes"
  [cocoaQualities]="cocoaQualities" [unloads]="unloads" (result)="getUnloads(currentPage, itemsPerPage, filters)">
</app-final-unload-form>

<app-provisional-unload-form [certificates]="certificates" [unloadManagers]="unloadManagers" [cocoaTypes]="cocoaTypes"
  [cocoaQualities]="cocoaQualities"></app-provisional-unload-form>