import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';

import * as _ from 'lodash';

// Models
import { PaymentModel } from 'src/app/models/payment.model';
import { ConceptModel } from 'src/app/models/concept.model';
import { UserModel } from 'src/app/models/user.model';
import { ContactModel } from 'src/app/models/contact.model';

// Services
import { NotificationService } from 'src/app/services/notification.service';
import { TicketService } from 'src/app/services/ticket.service';
import { GlobalsService } from 'src/app/services/globals.service';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent implements OnInit {
  @ViewChild('paymentForm', { static: true }) private modal: TemplateRef<any>;

  @Output() result: EventEmitter<PaymentModel> = new EventEmitter<PaymentModel>();

  @Input() concepts: ConceptModel[] = [];
  @Input() buyers: UserModel[] = [];
  @Input() suppliers: ContactModel[] = [];

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'payment-modal'
  };

  form: FormGroup;

  payment: PaymentModel;

  sending: boolean = false;
  receiptIndex: number = 0;

  constructor(private _modalService: NgbModal,
    private _notification: NotificationService,
    private _paymentService: TicketService,
    private _formBuilder: FormBuilder,
    public globals: GlobalsService) { }

  ngOnInit(): void {
  }

  onSubmit(payment: PaymentModel): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this._update(payment);
  }

  async openForm(payment: PaymentModel): Promise<void> {
    _.each(this.suppliers, (supplier: any) => {
      supplier['full_name_and_diceros_id'] = `(${supplier.diceros_id}) ${supplier.full_name}`;
    });

    await this._getPayment(payment.id);

    this._buildForm();
    this.openModal();
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  compareFn(o1, o2): boolean {
    if (_.isNumber(o2)) {
      return o1.id === o2;
    } else {
      return o1.id === o2.id
    }
    // return o1 && o2 ? o1.id === o2.id : o1 === o2;
  };

  changeCompleteState(event, payment: PaymentModel) {
    payment['force_completed_state_to'] = event.checked;
  }

  removeReceipt(payment: PaymentModel) {
    this.payment['remove_receipt'] = payment['receipt']['blob_id'];
    this.payment.receipt = null;
  }

  setReceipt(event: any): void {
    if(event && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.payment['receipt_content'] = {
          id: this.payment.id,
          receipt_content: reader.result
        }
      }
    }
  }


  private async _getPayment(id: number): Promise<void> {
    await this._paymentService.getTicket(id)
      .then(
        (res: any) => {
          this.payment = res['ticket'];
        }
      ).catch(err => console.error(err));
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }

  private _buildForm(): void {
    const collected_date: any = this.globals.serverDateToPluginDate(this.payment.collected_date);
    let concept: ConceptModel;
    if (this.payment.concepts) {
      concept = _.last(this.payment.concepts);
    }

    this.form = this._formBuilder.group({
      collected_date: [collected_date, Validators.required],
      supplier: [this.payment.supplier, [Validators.required]],
      comments: [this.payment.comments],
      concept: [concept, Validators.required],
      contract_price: [this.payment.contract_price, [Validators.required, Validators.min(0)]],
      nulled: [this.payment.nulled],
      original_receiver: [this.payment.original_receiver, Validators.required],
      completed: [this.payment.completed]
    });
  }

  private async _update(payment: PaymentModel): Promise<void> {
    const form_collected_date: any = this.form.get('collected_date').value;
    const collected_date: any = this.globals.pluginDateToServerFormat(form_collected_date);
    let receipt_image;

    let concept_ids: number[] = [];
    payment.hasOwnProperty('remove_receipt') && this._deleteReceiptImage(payment, 'receipt');

    const form_concept: any = this.form.get('concept');
    if (form_concept) {
      const concept: ConceptModel = form_concept.value;
      concept_ids = [concept.id];
    }

    payment = Object.assign(payment, this.form.getRawValue());
    payment = Object.assign(payment, {
      concept_ids: concept_ids,
      collected_date: collected_date,
      contact_id: (payment.supplier ? payment.supplier.id : null),
    });


    if (payment['receipt_content'] && payment['receipt_content'].id) {
      receipt_image = _.cloneDeep(payment['receipt_content']);
    }

    delete payment.supplier;

    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    await this._paymentService.updateTicket(payment)
      .then(
        async (res: any) => {

          this.result.emit(res['ticket']);


          if (receipt_image) {
            await this._uploadImage(receipt_image);
          }
           
          this.sending = false;
          this._notification.clear();
          this._notification.success('Actualizado correctamente');

          this.closeModal();
        }
      ).catch(
        (err: any) => {
          if (err.hasOwnProperty('ticket')) {
            this.result.emit(err['ticket']);
            this.closeModal();
          }

          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      )
  }

  private async _uploadImage(image: any): Promise<void> {
    await this._paymentService.uploadImages(image, 'receipt')
      .then(
        res => {
          this.result.emit(res['ticket']);
        }
      ).catch(err => console.error(err));
  }

  private _deleteReceiptImage(payment: PaymentModel, type: string) {
    this._paymentService.deleteReceiptImage(payment, 'receipt')
      .then(
        res => {

        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al eliminar la imagen');
          }
        }
      );
  }
}
