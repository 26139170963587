import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';

// Models
import { AddonModel } from 'src/app/models/addon.model';

// Services
import { AddonService } from 'src/app/services/addon.service';

// Components
import { AddonFormComponent } from '../modals/addon-form/addon-form.component';

@Component({
  selector: 'app-addons-index',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss']
})
export class AddonsPage implements OnInit {
  @ViewChild('sendingNotification', { static: true }) customNotificationTmpl;
  @ViewChild(AddonFormComponent, { static: true }) private formModal: AddonFormComponent;

  addons: Array<AddonModel> = new Array<AddonModel>();
  loading = true;
  p = 1;

  constructor(
    private _addonService: AddonService
  ) { }

  ngOnInit() {
    this.getAddons();
  }

  openForm(addon: AddonModel) {
    this.formModal.openForm(addon);
  }

  deactivateActivateAddon(addon: AddonModel) {
    addon['active'] = !addon['active'];
    this.formModal.updateAddon(addon);
  }

  setAddons(addons: AddonModel[]): void {
    this.addons = addons;
  }

  private getAddons() {
    this.loading = true;
    this._addonService.getAddons()
      .then(
        res => {
          this.addons = res['addons'];
          this.loading = false;
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }
}
