import { Component, OnInit, ViewChild, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';

// Components
import { AlertModalComponent } from '../../../../components/modals/alert-modal/alert-modal.component';
import { ContractPackageFormComponent } from '../package-form/package-form.component';

// Models
import { TicketModel } from 'src/app/models/ticket.model';
import { AddonModel } from 'src/app/models/addon.model';

// Services
import { TicketService } from 'src/app/services/ticket.service';
import { NotificationService } from 'src/app/services/notification.service';

// Globals
import { TicketType, TicketUnloadState } from 'src/app/app.enum';
import { BagModel } from 'src/app/models/bag.model';
import { CertificateModel } from 'src/app/models/certificate.model';
import { CocoaTypeModel } from 'src/app/models/cocoa-type.model';
import { CocoaQualityModel } from 'src/app/models/cocoa_quality.model';
import { UserModel } from 'src/app/models/user.model';
import { ContactModel } from 'src/app/models/contact.model';
import { GlobalsService } from 'src/app/services/globals.service';
import { TICKET_FIELDS_DICCIONARY } from 'src/app/app.consts';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contract-form',
  templateUrl: './contract-form.component.html',
  styleUrls: ['./contract-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContractFormComponent implements OnInit {
  @ViewChild('updateTicketModal', { static: true }) private modal;
  @ViewChild(AlertModalComponent, { static: true }) private alertModal: AlertModalComponent;
  @ViewChild(ContractPackageFormComponent, { static: true }) private packageModal: ContractPackageFormComponent;

  @Output() result: EventEmitter<TicketModel[]> = new EventEmitter<TicketModel[]>();
  @Input() private addons: AddonModel[] = new Array<AddonModel>();
  @Input() tickets: TicketModel[] = new Array<TicketModel>();
  @Input() certificates: CertificateModel[] = new Array<CertificateModel>();
  @Input() cocoaTypes: CocoaTypeModel[] = new Array<CocoaTypeModel>();
  @Input() cocoaQualities: CocoaQualityModel[] = new Array<CocoaQualityModel>();
  @Input() buyers: UserModel[] = new Array<UserModel>();
  @Input() suppliers: ContactModel[] = new Array<ContactModel>();

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'full-modal'
  };

  private changeCompleteStateValue: boolean = false;
  private addon: AddonModel = new AddonModel();


  contractType = TicketType;

  ticket: TicketModel = new TicketModel();
  sending: boolean = false;

  ticketUnloadState = TicketUnloadState;
  receiptIndex: number = 0;
  authorizationIndex: number = 0;
  isNulledChanged: boolean = false;

  constructor(private _modalService: NgbModal,
    private _ticketService: TicketService,
    private _notification: NotificationService,
    public globals: GlobalsService,
    private translate: TranslateService
  ) {
    // translate.setDefaultLang('es');
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }
  
  ngOnInit() {
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  /**
   * 
   * @param o1 Es el array con todos los valores
   * @param o2 Es el valor que tiene actualmente el selectable
   */
  compareFn(o1, o2): boolean {
    if (_.isNumber(o2)) {
      return o1.id === o2;
    } else {
      return o1.id === o2.id
    }
    // return o1 && o2 ? o1.id === o2.id : o1 === o2;
  };

  openForm(ticket: TicketModel, addon: AddonModel) {
    this.ticket = _.cloneDeep(ticket);
    this.addon = addon;

    _.each(this.suppliers, supplier => {
      supplier['full_name_and_diceros_id'] = `(${supplier.diceros_id}) ${supplier.full_name}`;
    });

    this.openModal();
  }

  updateContractStateValue(ticket: TicketModel) {
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);
    this.sending = true;
    this._ticketService.updateTicket(ticket)
      .then(
        res => {
          const index = _.findIndex(this.tickets, { id: ticket['id'] });
          this.tickets[index] = res['ticket'];
          this.result.emit(this.tickets);

          this._notification.clear();
          this._notification.success('Actualizado correctamente');
          this.sending = false;
          this.changeCompleteStateValue = false;
        }
      )
      .catch(
        err => {
          if (err.hasOwnProperty('ticket')) {
            const index = _.findIndex(this.tickets, { id: err['ticket']['id'] });
            this['tickets'][index] = err['ticket'];
          }

          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
          this.changeCompleteStateValue = false;
        }
      );
  }

  addNewBag() {
    const bagInfo: BagModel = new BagModel;

    if (!this.ticket['bags_info']) {
      this.ticket['bags_info'] = [];
    }

    if (this.ticket['bags_info'].length === 0) {
      bagInfo.code = '1';
      bagInfo.weight = 0;
      bagInfo.ticket_id = this.ticket.id;
    } else {
      bagInfo.code = (Number(_.last(this.ticket['bags_info'])['code']) + 1).toString();
      bagInfo.weight = 0;
      bagInfo.ticket_id = this.ticket.id;
    }

    this.ticket['bags_info'].push(bagInfo);
  }


  updateWeight(index: number, $value: number) {
    let resultAux = 0;
    this.ticket['bags_info'][index].weight = Number($value);

    _.each(this.ticket['bags_info'], (bag) => {
      resultAux += bag.weight;
    });

    this.ticket['receiver_weight'] = Number(resultAux.toFixed(1));
    this.ticket['contract_weight'] = this.ticket['receiver_weight'];
  }

  deleteBag(index: number) {
    let resultAux: number = 0;
    _.pullAt(this.ticket['bags_info'], index);

    _.each(this.ticket['bags_info'], (bag) => {
      resultAux += bag.weight;

    });

    this.ticket['receiver_weight'] = Number(resultAux.toFixed(1));
    this.ticket['contract_weight'] = this.ticket['receiver_weight'];
  }

  removeImage = (ticket, type: string) => {
    switch (type) {
      case 'ticket':
        if (ticket.receipt) {
          this.ticket['remove_receipt'] = ticket['receipt']['blob_id'];
          ticket['receipt'] = null;
        } else if (ticket['receipt_content']) {
          delete ticket['receipt_content'];
        }

        break;
      case 'authorization':
        if (ticket.authorization) {
          this.ticket['remove_authorization'] = ticket['authorization']['blob_id'];
          ticket['authorization'] = null;
        } else if (ticket['authorization_content']) {
          delete ticket['authorization_content'];
        }

        break;
    }
  }

  setImage(event: any, type: string): void {
    if (event && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        switch (type) {
          case 'receipt':
            this.ticket.receipt = null;
            this.ticket['receipt_content'] = {
              id: this.ticket.id,
              receipt_content: reader.result
            }
            break;
          case 'authorization':
            this.ticket.authorization = null;
            this.ticket['authorization_content'] = {
              id: this.ticket.id,
              authorization_content: reader.result
            }
            break;
        }

      }
    }
  }

  isDrySelected(ticket: TicketModel) {
    return ticket['cocoa_type'] && ticket['cocoa_type']['name'].toLowerCase().includes('seco');
  }

  setAllPriceOptions(ticket: TicketModel) {
    ticket['base_price'] = ticket['contract_price'];
    if (ticket.ticket_type_cd === TicketType.contracts) {
      // ticket['base_price'] = ticket['contract_price'];
      // ticket['addon_price'] = 0;

      // ticket.addon_price = this.addon ? this.addon.amount : 0;

      // if (ticket['addon_toggle'] && this.addon) {
      //   ticket.base_price = Number(ticket.base_price) - Number(ticket.addon_price );
      //   // if (!this.addon) {
      //   //   ticket['base_price'] = ticket['base_price'] - 0;
      //   //   ticket['addon_price'] = 0;
      //   // } else {
      //   //   ticket['base_price'] = ticket['base_price'] - this.addon['amount'];
      //   //   ticket['addon_price'] = this.addon['amount'];
      //   // }
      // }

      if (ticket.addon_toggle) {
        ticket.base_price = (Number(ticket.base_price) - Number(ticket.addon_price));
      }

      if (ticket['commission_toggle']) {
        // ticket['base_price'] = ticket['base_price'] - Number(ticket.supplier.current_commission_value);
        // ticket['commission_price'] = Number(ticket.supplier.current_commission_value);

        ticket['base_price'] = Number(ticket['base_price']) - Number(ticket['commission_price']);
      }
  
      if (Number(ticket['base_price']) > Number(ticket['max_price']) && !ticket['special_price']) {
        this.openAlertModal('Recuerda', null, { 'type': 'priceToHigh' });
      } else if (Number(ticket['base_price']) <= Number(ticket['max_price']) && ticket['special_price']) {
        ticket['special_price'] = false;
        this.openAlertModal('Recuerda', null, { 'type': 'specialPriceToggleWithoutNeed' });
      } else {
        this.updateTicket(ticket);
      }
    } else {
      if (ticket.ticket_type_cd === TicketType.direct_purchase && ticket.finished) {
        return;
      }
      
      this.updateTicket(ticket);
    }

  }

  setAddon($event: any, ticket: TicketModel): void {
    this.addon = _.find(this.addons, { 'certificate_id': $event['id'] }) as AddonModel;
    ticket.addon_price = this.addon ? this.addon.amount : 0;

    if (ticket['addon_toggle'] && this.addon) {
      ticket.base_price = Number(ticket.base_price) - Number(ticket.addon_price);
    }
  }

  changeAddonPrice(event: number, ticket: TicketModel): void {
    const addon: number = Number(event);
    if (addon < 0) {
      return;
    }

    ticket.addon_price = addon;

    if (ticket['addon_toggle']) {
      ticket.base_price = Number(ticket.base_price) - addon;
    }
  }

  recalculatePrice(checked: boolean, ticket: TicketModel): void {
    if (checked) {
      ticket.base_price = (Number(ticket.base_price) - Number(ticket.addon_price));
    } else {
      ticket.base_price = (Number(ticket.base_price) + Number(ticket.addon_price));
    }
  }

  alertModalReason(reason: any): void {

  }

  validateTicket(ticket: TicketModel) {

    if (ticket.ticket_type_cd === TicketType.direct_purchase && !ticket.provenance_sheet_reviewed && ticket.supplier.intermediary) {
      this._notification.error('No se puede validar esta compra directa, porque la procedencia no ha sido revisada.');
      return;
    }

    let required_fields = {
      contact_id: false,
      original_receiver_id: false,
      cocoa_type_id: false,
      certificate_id: false,
      contract_price: false,
      contract_weight: false,
      contract_starts: false,
      contract_ends: false,
      receiver_weight: false,
      collected_date: false,
      receipt: false,
      temp_contract_ends: false,
      temp_contract_starts: false
    }

    if (ticket.cocoa_type && ticket.cocoa_type.name.toLocaleLowerCase() === 'seco') {
      required_fields = Object.assign(required_fields, { cocoa_quality_id: false });
    }

    if (ticket.ticket_type_cd !== TicketType.contracts && ticket.contact && ticket.contact.intermediary) {
      required_fields = Object.assign(required_fields, { provenance_sheet: false });
    }

    if (ticket.special_price) {
      required_fields = Object.assign(required_fields, { authorization: false });
    }

    if (ticket.ticket_type_cd === TicketType.contracts) {
      required_fields = Object.assign(required_fields, { temp_contract_collected_date: false });
    }

    _.each(required_fields, (value: boolean, key: string) => {
      if (key === 'provenance_sheet') {
        required_fields[key] = (ticket.provenance_sheet && ticket.provenance_sheet.sheet_images.length > 0);
      } else if (key === 'authorization') {
        required_fields[key] = (ticket.authorization && ticket.authorization.url);
      } else if (key === 'receipt') {
        required_fields[key] = (ticket.receipt && ticket.receipt.url);
      } else if (key === 'receiver_weight' || key === 'contract_weight') {
        required_fields[key] = (ticket[key] && Number(ticket[key]) > 0);
      } else {
        required_fields[key] = !!ticket[key];
      }
    });

    if (!Object.values(required_fields).every(Boolean)) {
      let message: string = '';

      _.each(required_fields, (value: boolean, key: string) => {
        if (!value) {
          message += `${TICKET_FIELDS_DICCIONARY[key]}::`;
        }
      });

      this._notification.error(`Los siguientes campos son requeridos: ${message}`);
      return;
    }

    ticket['validated'] = !ticket['validated'];
    const t = {
      id: ticket['id'],
      validated: ticket['validated']
    };

    // this.updateContractStateValue(t as TicketModel);
    this.updateTicket(ticket);
  }

  updateTicket(ticket: TicketModel) {
    let receip_image;
    let authorization_image;
    if (!this.dateIsInSeasonRange(ticket)) {
      return;
    }

    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    ticket = this.updateDates(ticket);
    ticket.hasOwnProperty('remove_receipt') && this.deleteReceiptImage(ticket, 'receipt');
    ticket.hasOwnProperty('remove_authorization') && this.deleteReceiptImage(ticket, 'authorization');

    if (ticket['supplier'] && ticket['supplier']['id'] !== 0) {
      ticket['supplier_not_found'] = false;
    }

    ticket['certificate_id'] = ticket['certificate'] && ticket['certificate']['id'];
    ticket['cocoa_type_id'] = ticket['cocoa_type'] && ticket['cocoa_type']['id'];
    ticket['original_receiver_id'] = ticket['original_receiver'] && ticket['original_receiver']['id'];
    ticket['contact_id'] = ticket['supplier'] && ticket['supplier']['id'];
    ticket['talbook_id'] = ticket['talbook'] && ticket['talbook']['id'];
    ticket['cocoa_quality_id'] = this.isDrySelected(ticket) ? ticket['cocoa_quality_id'] : null;

    if (ticket['receipt_content'] && ticket['receipt_content'].id) {
      receip_image = _.cloneDeep(ticket['receipt_content']);
    }

    if (ticket['authorization_content'] && ticket['authorization_content'].id) {
      authorization_image = _.cloneDeep(ticket['authorization_content']);
    }


    if (!this.changeCompleteStateValue) {
      if (ticket['supplier']
        && ticket['certificate_id']
        && ticket['cocoa_type_id']
        && ticket['receiver_weight']
        && ticket['contract_starts']
        && ticket['contract_ends']
        && ticket['contract_price']
        && ticket['receipt']) {
        if (ticket['special_price']) {
          if (ticket['authorization']) {
            ticket['completed'] = true;
          } else {
            ticket['completed'] = false;
          }
        } else {
          ticket['completed'] = true;
        }
        if (ticket['supplier']['intermediary']) {
          if (ticket['provenance_sheet']
            && ticket['provenance_sheet']['sheet_images']
            && ticket['provenance_sheet']['sheet_images'].length > 0) {
            ticket['completed'] = true;
          } else {
            ticket['completed'] = false;
          }
        } else {
          ticket['completed'] = true;
        }
      } else {
        ticket['completed'] = false;
      }
    }

    this._ticketService
      .updateTicket(ticket)
      .then(
        async res => {
          const index = _.findIndex(this.tickets, { id: ticket['id'] });
          this.tickets[index] = res['ticket'];

          if (receip_image) {
            await this.uploadImage(receip_image, 'receipt');
          }

          if (authorization_image) {
            await this.uploadImage(authorization_image, 'authorization');
          }

          this._notification.clear();
          this._notification.success('Actualizado correctamente');
          this.result.emit(this.tickets);
          this.closeModal();
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      );
  }

  setTicket(ticket: TicketModel) {
    this.ticket = ticket;
  }


  openPackageForm(ticket: TicketModel) {
    this.packageModal.openForm(ticket);
  }

  dateIsInSeasonRange(ticket: TicketModel): boolean {
    const season_start = moment(ticket.season.starts_at).startOf('day').toDate();
    const season_ends = moment(ticket.season.ends_at).startOf('day').toDate();
    const contract_starts = moment(`${ticket['temp_contract_starts']['year']}-${ticket['temp_contract_starts']['month']}-${ticket['temp_contract_starts']['day']}`).startOf('day').toDate();
    const contracts_ends = moment(`${ticket['temp_contract_ends']['year']}-${ticket['temp_contract_ends']['month']}-${ticket['temp_contract_ends']['day']}`).startOf('day').toDate();

    return moment(contract_starts).isSameOrAfter(season_start) && moment(contracts_ends).isSameOrBefore(season_ends);
  }

  startsDateIsInSeason(ticket: TicketModel): boolean {
    const season_start = moment(ticket.season.starts_at).startOf('day').toDate();
    const contract_starts = moment(`${ticket['temp_contract_starts']['year']}-${ticket['temp_contract_starts']['month']}-${ticket['temp_contract_starts']['day']}`).startOf('day').toDate();

    return moment(contract_starts).isSameOrAfter(season_start);
  }

  endsDateIsInSeason(ticket: TicketModel): boolean {
    const season_ends = moment(ticket.season.ends_at).startOf('day').toDate();
    const contracts_ends = moment(`${ticket['temp_contract_ends']['year']}-${ticket['temp_contract_ends']['month']}-${ticket['temp_contract_ends']['day']}`).startOf('day').toDate();

    console.log(contracts_ends);
    console.log('temp',season_ends);
    return moment(contracts_ends).isSameOrBefore(season_ends);
  }

  nulledChanged(): void {
    this.isNulledChanged = true;
  }

  private updateDates(ticket: TicketModel): TicketModel {

    if (ticket['temp_contract_starts']) {
      const contract_starts: Date = new Date(ticket['temp_contract_starts']['year'], (ticket['temp_contract_starts']['month'] - 1), ticket['temp_contract_starts']['day']);
      const displayStartDate = moment(contract_starts, moment.ISO_8601, true).startOf('day').format('YYYY-MM-DD');
      ticket['contract_starts'] = displayStartDate;
      if (ticket['ticket_type_cd'] === TicketType.direct_purchase) {
        ticket['contract_starts'] = displayStartDate;
        ticket['contract_ends'] = displayStartDate;
        ticket['collected_date'] = displayStartDate;
      }
    }

    if (ticket['temp_contract_collected_date'] && ticket['ticket_type_cd'] === TicketType.contracts) {
      const collected_date: Date = new Date(ticket['temp_contract_collected_date']['year'], (ticket['temp_contract_collected_date']['month'] - 1), ticket['temp_contract_collected_date']['day']);
      const collectedDate = moment(collected_date, moment.ISO_8601, true).startOf('day').format('YYYY-MM-DD');
      ticket['collected_date'] = collectedDate;
    }

    if (ticket['temp_contract_ends'] && ticket['ticket_type_cd'] !== TicketType.direct_purchase) {
      const contract_ends: Date = new Date(ticket['temp_contract_ends']['year'], (ticket['temp_contract_ends']['month'] - 1), ticket['temp_contract_ends']['day']);
      const displayEndDate = moment(contract_ends, moment.ISO_8601, true).startOf('day').format('YYYY-MM-DD');


      ticket['contract_ends'] = displayEndDate;
      // if (ticket['ticket_type_cd'] === TicketType.direct_purchase) {
      //   ticket['contract_starts'] = displayEndDate;
      //   ticket['contract_ends'] = displayEndDate;
      // }
    }


    return ticket;
  }


  private deleteReceiptImage(ticket: TicketModel, type: string) {
    this._ticketService.deleteReceiptImage(ticket, type)
      .then(
        res => {
          switch (type) {
            case 'authorization': delete ticket['remove_authorization'];
              break;
            case 'receipt': delete ticket['remove_receipt'];
              break;
          }

          const index = _.findIndex(this.tickets, { id: ticket['id'] });
          this['tickets'][index] = ticket;
        }
      )
      .catch(
        err => {
          console.error(err);
          this._notification.error(`Se ha producido un error al eliminar la imagen ${type === 'receipt' ? 'del recibo' : 'de la autorizacion'}`);
        }
      );
  }


  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }

  private openAlertModal(modal_header: string, modal_body: string, params: {}) {

    switch (params['type']) {
      case 'priceToHigh':
        modal_body = `El precio introducido es mayor que el máximo establecido, pulsa precio especial y que el encargado de compras añada la foto`;
        break;
      case 'specialPriceToggleWithoutNeed':
        modal_body = `Si el precio es inferior o igual al establecido, no debes pulsar precio especial y realizar la foto, `;
        break;
    }

    this.alertModal.openModal(modal_header, modal_body);
    this.alertModal.alertModalRef.result
      .then(
        result => {
          console.log('result', result);
        },
        reason => {
          switch (reason) {
            case 'alert-accepted':
              // this.changeModalsZIndexTo(1050);
              switch (params['type']) {
                case 'priceToHigh':
                  break;
                case 'specialPriceToggleWithoutNeed':
                  this.updateTicket(this.ticket);
                  break;
              }
              break;
          }
        }
      );
  }

  private async uploadImage(image: any, type: string): Promise<void> {
    await this._ticketService.uploadImages(image, type)
      .then(
        res => {
          const index = _.findIndex(this.tickets, { id: image['id'] });
          this.tickets[index] = res['ticket'];
          this.result.emit(this.tickets);
        }
      ).catch(err => console.error(err));
  }

}
