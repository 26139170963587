import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Models
import { AddonModel } from '../models/addon.model';

import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AddonService {
  ROUTE = environment.routes.api + environment.routes.addons;

  constructor(
    private _http: HttpClient
  ) { }

  getAddons() {
    return new Promise((resolve, reject) => {
      this._http.get(this.ROUTE, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        )
    });
  }

  getAddon(addon_id: number) {
    return new Promise((resolve, reject) => {
      this._http
        .get(`${this.ROUTE}/${addon_id}`, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  createAddon(addon: AddonModel) {
    return new Promise((resolve, reject) => {
      this._http
        .post(this.ROUTE, { addon: addon }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  updateAddon(addon: AddonModel) {
    return new Promise((resolve, reject) => {
      this._http.put(`${this.ROUTE}/${addon['id']}`, { addon: addon }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('yacao:app:token'),
        'user_id': localStorage.getItem('yacao:app:user_id')
      }
    );
  }
}
