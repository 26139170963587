import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-translation-edit-modal',
  templateUrl: './translation-edit-modal.component.html',
  styleUrls: ['./translation-edit-modal.component.scss']
})
export class TranslationEditModalComponent implements OnInit {
  @ViewChild('content') private modal;

  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg',
    windowClass: 'translation-edit-modal-component'
  };

  private modalRef: NgbModalRef;

  modalTitle: string = '';
  translateString: string= '';

  constructor(
    private _modalService: NgbModal,
    private translate: TranslateService
  ) {
    // translate.setDefaultLang('es');
  }

  ngOnInit() {
  }

  openLEM() {
    console.log(this.translate);
    this.modalTitle = "Bienvenido";
    this.openModal();
  }

  closeModal() {
    this.modalRef && this.modalRef.dismiss();
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }

}
