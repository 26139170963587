import { CertificateTypeModel } from './certificate-type.model';

export class CertificateModel {
  id: number;
  certificate_type: CertificateTypeModel;
  certificate_type_id: number;
  name: string;
  alias: string;
  active: boolean;
  description: string;
}

