import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

// Models
import { CommissionModel } from 'src/app/models/commission.model';

// Services
import { CommissionService } from 'src/app/services/commission.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-commission-form',
  templateUrl: './commission-form.component.html',
  styleUrls: ['./commission-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommissionFormComponent implements OnInit {
  @ViewChild('content', { static: true }) private modal;
  @Input() private commissions: Array<CommissionModel> = new Array<CommissionModel>();
  @Output() result: EventEmitter<CommissionModel[]> = new EventEmitter<CommissionModel[]>();

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };

  commission: CommissionModel = new CommissionModel();
  sending: boolean = false;

  constructor(private _modalService: NgbModal,
    private _commissionService: CommissionService,
    private _notification: NotificationService) { }

  ngOnInit() {
  }

  onSubmit(commission: CommissionModel) {
    commission.id ? this.updateCommision(commission) : this.createCommision(commission);
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  openForm(commission: CommissionModel) {
    this.commission = commission ? _.cloneDeep(commission) : new CommissionModel();
    this.openModal();
  }

  updateCommision(commission: CommissionModel) {
    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);
    this._commissionService.updateCommision(commission)
      .then(
        res => {
          const index = _.findIndex(this.commissions, { id: res['commission']['id'] });
          this.commissions[index] = res['commission'];
          this.result.emit(this.commissions);
          this._notification.clear();
          this._notification.success('Actualizado correctamente');
          this.closeModal();
          this.sending = false;
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      );
  }


  private createCommision(commission: CommissionModel) {
    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);
    this._commissionService.createCommission(commission)
      .then(
        res => {
          this.commissions.push(res['commission']);
          this.result.emit(this.commissions);

          this._notification.clear();
          this._notification.success('Creado correctamente');

          this.sending = false;
          this.closeModal();
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al crear');
          }
        }
      );
  }


  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }
}
