import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

//Models
import { TradePriceModel } from '../models/trade-price';

import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TradePriceService {
  private ROUTE = environment.routes.api + environment.routes.trade_prices;
  constructor(
    private _http: HttpClient
  ) { }

  getTradePrices(page: number = 1, per_page: number = 25, parameters: {} = {}) {
    let params = {
      page: page.toString(),
      per_page: per_page.toString()
    };

    params = Object.assign(params, parameters);

    let options = {
      params: params,
      headers: this.getHeaders()
    };

    options = Object.assign(options, { responseType: params['format'] === 'json' ? 'json' : 'arraybuffer' });

    return new Promise(
      (resolve, reject) => {
        this._http.get(this.ROUTE, options)
          .toPromise()
          .then(
            res => {
              if (params.hasOwnProperty('format') && params['format'] === 'xlsx') {
                resolve(res);
              } else {
                console.log(res);
                res['success'] ? resolve(res) : reject(res);
              }
            }
          )
          .catch(
            err => {
              reject(err);
            }
          );
      }
    );
  }

  getTradePrice(id: number) {
    return new Promise((resolve, reject) => {
      this._http.get(`${this.ROUTE}/${id}`, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        )
    });
  }

  createTradePrice(tradePrice: TradePriceModel) {
    return new Promise((resolve, reject) => {
      this._http.post(this.ROUTE, { trade_price: tradePrice }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        )
    });
  }

  updateTradePirce(tradePrice: TradePriceModel) {
    return new Promise((resolve, reject) => {
      this._http.put(`${this.ROUTE}/${tradePrice['id']}`, { trade_price: tradePrice }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        )
    });
  }

  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('yacao:app:token'),
        'user_id': localStorage.getItem('yacao:app:user_id')
      }
    );
  }
}
