import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

// Models
import { UserModel } from 'src/app/models/user.model';
import { CenterModel } from 'src/app/models/center.model';
import { TalbookTypeModel } from 'src/app/models/talbook-type.model';
import { TalBookModel } from 'src/app/models/talbook.model';

// Services
import { TalbookService } from 'src/app/services/talbook.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { TypeOfTalonary } from 'src/app/app.enum';
import { NotificationService } from 'src/app/services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-talbook-form',
  templateUrl: './talbook-form.component.html',
  styleUrls: ['./talbook-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TalbookFormComponent implements OnInit {
  @ViewChild('content', { static: true }) private modal;
  @Output() result: EventEmitter<TalBookModel[]> = new EventEmitter<TalBookModel[]>();

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };

  @Input() private users: UserModel[] = new Array<UserModel>();
  @Input() private talbooks: TalBookModel[] = new Array<TalBookModel>();
  @Input() private centers: CenterModel[] = new Array<CenterModel>();
  @Input() private currentPage: number;
  @Input() private itemsPerPage: number;
  @Input() private totalItems: number;
  @Input() private talonaries: TalbookTypeModel[] = new Array<TalbookTypeModel>();

  @Input() receivers: UserModel[] = new Array<UserModel>();
  @Input() buyers: UserModel[] = new Array<UserModel>();
  @Input() payers: UserModel[] = [];

  allowedUsers: UserModel[] = new Array<UserModel>();
  allowedCenters: CenterModel[] = new Array<CenterModel>();
  activeTalonaries: TalbookTypeModel[] = new Array<TalbookTypeModel>();
  talbook: TalBookModel = new TalBookModel();
  generateLabel: boolean = false;
  sending: boolean = false;

  constructor(
    private _modalService: NgbModal,
    private _talbookService: TalbookService,
    public globals: GlobalsService,
    private _notification: NotificationService,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('es');
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  ngOnInit() {
  }

  onSubmit(talbook: TalBookModel): void {
    if (this.firstPageValid(talbook)) {
      talbook['id'] ? this.updateTalBook(talbook) : this.createTalBook(talbook);
    }
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  openForm(talbook: TalBookModel): void {
    this.talbook = _.cloneDeep(talbook) || new TalBookModel();
    this.allowedCenters = _.cloneDeep(this.centers);
    this.activeTalonaries = _.filter(this.talonaries, { active: true});
    this.allowedUsers = _.cloneDeep(this.users);
    if (talbook) {
      this.getAllowedUsers(talbook);
      this.getAllowedCenters(talbook);
    }

    this.openModal();
  }

  compareFn(a: any, b: any): boolean {
    return a['id'] === b;
  }

  getAllowedUsers(talbook: TalBookModel): void {
    if (talbook.center) {
      if (talbook.talbook_type.type_cd === TypeOfTalonary.contracts) {
        this.allowedUsers = _.filter(this.buyers, { center_id: talbook.center.id });
      } else if (talbook.talbook_type.type_cd === TypeOfTalonary.cocoa_receipt) {
        this.allowedUsers = _.filter(this.receivers, { center_id: talbook.center.id });
      } else {
        this.allowedUsers = _.filter(this.users, { center_id: talbook.center.id });
      }
    } else {
      if (talbook.talbook_type.type_cd === TypeOfTalonary.contracts) {
        this.allowedUsers = _.cloneDeep(this.buyers);
      } else if (talbook.talbook_type.type_cd === TypeOfTalonary.cocoa_receipt) {
        this.allowedUsers = _.cloneDeep(this.receivers);
      } else if (talbook.talbook_type.type_cd === TypeOfTalonary.income || talbook.talbook_type.type_cd === TypeOfTalonary.outcome) {
        this.allowedUsers = _.cloneDeep(this.payers);
      } else {
        this.allowedUsers = _.cloneDeep(this.users);
      }
    }
    // if (center) {
    //   if (talbookType.label.includes('01')) {
    //     return _.filter(this.buyers, {center_id: center['id']});
    //   } else if (talbookType.label.includes('04')) {
    //     return _.filter(this.receivers, { center_id: center['id']});
    //   }
    // } else {
    //   if (talbookType.label.includes('01')) {
    //     return this.buyers;
    //   } else if (talbookType.label.includes('04')) {
    //     return this.receivers;
    //   }
    // }
  }

  clearTalbookCenterIfNecesary(event: any, talbook: TalBookModel): void {
    if (!event) {
      if (talbook.talbook_type_id === 2 && !talbook.receiver) {
        talbook.center_id = null;
        talbook.center = null;
        this.getAllowedUsers(talbook);
      }
    }
  }


  getAllowedCenters(talbook: TalBookModel): void {
    if (talbook.receiver) {
      const user = _.find(this.users, { id: talbook.receiver.id });
      this.allowedCenters = _.filter(this.centers, { id: user.center_id });
      this.talbook.center = _.cloneDeep(_.first(this.allowedCenters));
      if (talbook.talbook_type.type_cd === TypeOfTalonary.contracts) {
        if (talbook.center) {
          this.allowedUsers = _.filter(this.buyers, { center_id: talbook.center.id });
        } else {
          this.allowedUsers = _.cloneDeep(this.buyers);
        }

      } else if (talbook.talbook_type.type_cd === TypeOfTalonary.cocoa_receipt) {
        if (talbook.center) {
          this.allowedUsers = _.filter(this.receivers, { center_id: talbook.center.id });
        } else {
          this.allowedUsers = _.cloneDeep(this.receivers);
        }

      } else if (talbook.talbook_type.type_cd === TypeOfTalonary.income || talbook.talbook_type.type_cd === TypeOfTalonary.outcome) {
        this.allowedUsers = _.cloneDeep(this.payers);
      }else {
        if (talbook.center) {
          this.allowedUsers = _.filter(this.users, { center_id: talbook.center.id });
        } else {
          this.allowedUsers = _.cloneDeep(this.users);
        }
      }
    } else {
      this.allowedCenters = _.cloneDeep(this.centers);
    }
  }

  generateSheetsAndLabel(event: TalbookTypeModel): void {
    this.generateLabel = true;
    this._talbookService.generateLabel(event['id'])
      .then(
        res => {
          this.generateLabel = false;
          this.talbook = res['talbook'];
        }
      )
      .catch(
        err => {
          this.generateLabel = false;
          console.error(err);
        }
      );
  }

  updateTalBook(talbook: TalBookModel): void {
    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    talbook['receiver'] && (talbook['receiver_id'] = talbook['receiver']['id']);
    talbook['center'] && (talbook['center_id'] = talbook['center']['id']);
    talbook['talbook_type'] && (talbook['talbook_type_id'] = talbook['talbook_type']['id']);
    if (!talbook.receiver) talbook.receiver_id = null;
    if (talbook.talbook_type_id === 2 && !talbook.receiver) {
      talbook.center = null;
      talbook.center_id = null;
    }

    this._talbookService
      .updateTalBook(talbook)
      .then(
        res => {
          const index = _.findIndex(this.talbooks, { id: talbook['id'] });
          this.talbooks[index] = res['talbook'];
          this.result.emit(this.talbooks);

          this._notification.clear();
          this._notification.success('Actualizado correctamente');
          this.closeModal();
          this.sending = false;
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      );
  }

  setCenterOfBuyer(buyer: UserModel): void {
    if (buyer) {
      this.talbook['center'] = _.cloneDeep(_.find(this.centers, { id: buyer['center_id'] }));
    } else {
      this.talbook['center'] = null;
    }
  }

  calculateLastPage(talbook: TalBookModel): void {
    const pages = talbook.first_page.split('-');
    const lastPage = `${Number(pages[1]) + 49}`;
    talbook.last_page = `${pages[0]}-`;

    for (let i = 0; i < (this.talbook.talbook_type.number_of_digits - lastPage.length); i++) {
      talbook.last_page += '0';
    }

    talbook.last_page += lastPage;
  }

  firstPageValid(talbook: TalBookModel): boolean {
    const start = talbook.label.split('E')[1].split('N')[0];
    const pattern = `^${start}-[0-9]{${talbook.talbook_type.number_of_digits}}$`;
    const regex = new RegExp(pattern);
    return regex.test(talbook.first_page);
  }

  private createTalBook(talbook: TalBookModel): void {
    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    talbook['current_page'] = talbook['first_page'];
    talbook['receiver'] && (talbook['receiver_id'] = talbook['receiver']['id']);
    talbook['center'] && (talbook['center_id'] = talbook['center']['id']);
    talbook['talbook_type'] && (talbook['talbook_type_id'] = talbook['talbook_type']['id']);
    talbook['active'] = true;

    this._talbookService
      .createTalBook(talbook)
      .then(
        res => {
          this.totalItems += 1;
          if (this.currentPage === _.ceil(this.totalItems / this.itemsPerPage)) {
            this.talbooks.push(res['talbook']);
          }

          this.result.emit(this.talbooks);

          this._notification.clear();
          this._notification.success('Creado correctamente');
          this.closeModal();
          this.sending = false;
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al crear');
          }
        }
      );
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }
}
