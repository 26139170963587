import { Component, OnInit, ViewChild } from '@angular/core';

import * as _ from 'lodash';

// Models
import { TalBookModel } from '../../../models/talbook.model';
import { UserModel } from '../../../models/user.model';
import { CenterModel } from '../../../models/center.model';
import { FilterModel } from 'src/app/models/filter.model';

// Service
import { TalbookService } from '../../../services/talbook.service';
import { DownloadFileService } from 'src/app/services/download-file.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { TalbookTypeModel } from 'src/app/models/talbook-type.model';

// Components
import { TalbookFormComponent } from '../modals/talbook-form/talbook-form.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-talbooks',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss']
})
export class TalbooksPage implements OnInit {
  @ViewChild(TalbookFormComponent, { static: true }) private talbookForm: TalbookFormComponent;

  receivers: UserModel[] = new Array<UserModel>();
  buyers: UserModel[] = new Array<UserModel>();
  payers: UserModel[] = [];

  talbooks: Array<TalBookModel> = new Array<TalBookModel>();
  users: Array<UserModel> = new Array<UserModel>();
  centers: Array<CenterModel> = new Array<CenterModel>();
  talonaries: Array<TalbookTypeModel> = new Array<TalbookTypeModel>();

  filters: FilterModel = new FilterModel();
  yesOrNot = [{ name: 'Si', value: true }, { name: 'No', value: false }];


  currentPage: number = 1;

  loading: boolean;
  loadingExcel = false;
  isCollapsed = false;

  itemsPerPage: number = 25;
  totalItems: number = 0;

  constructor(
    private _downloadFileService: DownloadFileService,
    private talbookService: TalbookService,
    public globals: GlobalsService,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('es');
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }
  

  async ngOnInit() {
    delete this.filters['g'];
    this.getTalBooks(1, this.itemsPerPage, this.filters);
  }

  getTalBooks(page: number = 1, per_page: number = 25, params: {} = {}, format: string = 'json') {
    let parameters = _.cloneDeep(params);

    if (format === 'json') {
      this.loading = true;
    }

    if (format === 'xlsx') {
      this.loadingExcel = true;
    }

    parameters = Object.assign(parameters, { format: format });

    _.each(parameters, (value, key) => {
      value == null && delete parameters[key];
    });

    this.talbookService
      .getTalBooks(page, per_page, parameters)
      .then(
        (res) => {
          if (format === 'json') {
            this.talbooks = res['talbooks'];
            this.receivers = res['receivers'];
            this.buyers = res['buyers'];
            this.payers = res['payers'];
            this.centers = res['centers'];
            this.talonaries = res['talbook_types'];
            this.users = _.uniqBy([...this.receivers, ...this.buyers], 'id');

            this.currentPage = res['current_page'];
            this.totalItems = res['total_items'];
            this.loading = false;
          } else {
            this.loadingExcel = false;
            this._downloadFileService.downloadFile(res, 'talonarios', 'application/vnd.ms-excel');
          }
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }

  deactivateActivateTalBook(talbook: TalBookModel) {
    talbook['active'] = !talbook['active'];
    this.talbookForm.updateTalBook(talbook);
  }

  openForm(talbook: TalBookModel): void {
    this.talbookForm.openForm(talbook);
  }

  setTalbooks(talbooks: TalBookModel[]): void {
    this.talbooks = talbooks;
  }
}
