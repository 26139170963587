import { Component, OnInit, ViewChild } from '@angular/core';

import * as _ from 'lodash';

// Models
import { CocoaTypeModel } from '../../../models/cocoa-type.model';

// Services
import { CocoaTypesService } from '../../../services/cocoatype.service';

// Components
import { CocoaTypeFormComponent } from '../modals/cocoa-type-form/cocoa-type-form.component';

@Component({
  selector: 'app-cocoa-types',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss']
})
export class CocoaTypesPage implements OnInit {
  @ViewChild(CocoaTypeFormComponent, {static: true}) cocoaTypeForm: CocoaTypeFormComponent;
  cocoaTypes: Array<CocoaTypeModel> = new Array<CocoaTypeModel>();

  p = 1;

  loading: boolean;
  isCollapsed = false;

  constructor(private cocoaTypesService: CocoaTypesService) { }

  ngOnInit() {
    this.getCocoaType();
  }

  openForm(cocoaType: CocoaTypeModel) {
    this.cocoaTypeForm.openForm(cocoaType);
  }

  getCocoaType() {
    this.loading = true;
    this.cocoaTypesService.getAll()
      .then(
        res => {
          this.cocoaTypes = res['cocoa_types'];
          this.loading = false;
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }

  deactivateActivateCococaType(cocoaType: CocoaTypeModel) {
    cocoaType['active'] = !cocoaType['active'];
    this.cocoaTypeForm.updateCocoaType(cocoaType);
  }

  setCocoaTypes(cocoaTypes: CocoaTypeModel[]): void {
    this.cocoaTypes = cocoaTypes;
  }
}
