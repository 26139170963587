export const environment = {
  production: false,
  routes: {
    api: 'https://api.translations.yacao.sirokko.es/api/',
    centers: 'centers',
    certificate_types: 'certificate_types',
    certificates: 'certificates',
    cocoa_types: 'cocoa_types',
    contacts: 'contacts',
    delivery_groups: 'delivery_groups',
    roles: 'roles',
    suppliers: 'suppliers',
    talbook_types: 'talbook_types',
    talbooks: 'talbooks',
    tickets: 'tickets',
    users: 'users',
    zone_groups: 'zone_groups',
    zones: 'zones',
    login: 'login',
    logout: 'logout',
    provenance_sheet_info: 'update_provenance_sheet',
    exportations: 'exportations',
    products: 'products',
    cocoa_qualities: 'cocoa_qualities',
    addons: 'addons',
    commissions: 'commissions',
    trade_prices: 'trade_prices',
    unloads: 'unloads',
    big_bags: 'big_bags',
    pincode: 'pincode',
    seasons: 'seasons',
    translations: 'translations',
    concepts: 'concepts',
    settings: 'settings'
  }
}