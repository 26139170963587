
<!-- Molda template -->
<ng-template #content let-modal let-d="dismiss">
  <form #Form="ngForm" (ngSubmit)="Form.form.valid && onSubmit(season)">
    <div class="modal-header">
      <h4 class="modal-title">{{ season.id ? 'Actualizar temporada' : 'Crear temporada'}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="form-group">
        <label for="seasonNameId">Nombre para la temporada</label>
        <input type="text" #seasonName="ngModel" class="form-control" id="seasonNameId" [(ngModel)]="season.name"
          placeholder="Name" name="seasonName" [ngClass]="{ 'is-invalid': Form.submitted && seasonName.invalid}"
          required>

        <div *ngIf="Form.submitted && seasonName.invalid" class="invalid-tooltip">
          <div *ngIf="seasonName.errors.required">El nombre es obligatorio</div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <label for="startDateId">
            Inicio de temporada
          </label>

          <input id="seasonStartDateId" class="form-control" [(ngModel)]="season.temp_season_starts_at"
            placeholder="Inicio de temporada" name="startedDate" ngbDatepicker #startedDate="ngbDatepicker"
            (click)="startedDate.toggle()"
            [ngClass]="{ 'is-invalid': Form.submitted && !season.temp_season_starts_at
            || (Form.submitted && season.temp_season_ends_at && season.temp_season_starts_at && !dateComparing(season))}" required />

          <div *ngIf="Form.submitted && !season.temp_season_starts_at" class="invalid-tooltip">
            <div>La fecha de inicio es obligatoria</div>
          </div>
        </div>

        <div class="form-group col-md-6">
          <label for="endDateId">
            Final de temporada
          </label>

          <input id="finishedDateId" class="form-control" [(ngModel)]="season.temp_season_ends_at"
            placeholder="Fin de temporada" name="finishedDate" ngbDatepicker #finishedDate="ngbDatepicker"
            (click)="finishedDate.toggle()"
            [ngClass]="{ 'is-invalid': (Form.submitted && !season.temp_season_ends_at)
            || (Form.submitted && season.temp_season_ends_at && season.temp_season_starts_at && !dateComparing(season))}" required />

          <div *ngIf="Form.submitted && !season.temp_season_ends_at" class="invalid-tooltip">
            <div>La fecha final es obligatoria</div>
          </div>

          <div *ngIf="Form.submitted && !dateComparing(season)" class="invalid-tooltip">
            <div>La fecha final no puede ser antes que la inicial</div>
          </div>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" name="submitSeason"
        [disabled]="sending">{{ season.id ? 'Guardar' : 'Crear'}}</button>
      <button type="button" class="btn btn-danger" (click)="d('cancel-click')" name="closeModal"
        [disabled]="sending">Cancelar</button>
    </div>
  </form>
</ng-template>
