import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/* Guards */
import { AuthGuard } from './guards/auth.guard';

/* Pages */
import { CentersPage } from './pages/centers/index/index.page';
import { CertificatesPage } from './pages/certificates/index/index.page';
import { CertificateTypesPage } from './pages/certificate-types/index/index.page';
import { CocoaTypesPage } from './pages/cocoa-types/index/index.page';
import { RolesPage } from './pages/roles/index/index.page';
import { TalbooksPage } from './pages/talbooks/index/index.page';
import { TalonariesPage } from './pages/talonaries/index/index.page';
import { UsersPage } from './pages/users/index/index.page';
import { ZoneGroupsPage } from './pages/zone-groups/index/index.page';
import { ZonesPage } from './pages/zones/index/index.page';
import { ContactsPage } from './pages/contacts/index/index.page';
import { TicketsPage } from './pages/tickets/index/index.page';
import { DeliveryGroupsPage } from './pages/delivery-groups/index/index.page';
import { SuppliersPage } from './pages/suppliers/index/index.page';
import { ExportationsPage } from './pages/exportations/index/index.page';
import { AddonsPage } from './pages/addons/index/index.page';
import { CommissionsPage } from './pages/commissions/index/index.page';
import { TradePricesPage } from './pages/trade-prices/index/index.page';
import { ContractsPage } from './pages/contracts/index/index.page';
import { UnloadsPage } from './pages/unloads/index/unloads.page';
import { SeasonsPage } from './pages/seasons/index/index.page';
import { Roles } from './app.enum';
import { AppComponent } from './components/app.component';
import { LoginComponent } from './pages/login/login.component';
import { TranslateComponent } from './pages/translate/translate.component';
import { PaymentsComponent } from './pages/payments/payments.component';
import { ConceptsComponent } from './pages/concepts/concepts.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'centers',
    canActivate: [AuthGuard],
    component: CentersPage,
    data: { roles: [Roles.admin] }
  },
  {
    path: 'certificates',
    canActivate: [AuthGuard],
    component: CertificatesPage,
    data: { roles: [Roles.admin] }
  },
  {
    path: 'certificate-types',
    canActivate: [AuthGuard],
    component: CertificateTypesPage,
    data: { roles: [Roles.admin, Roles.supervisor] }
  },
  {
    path: 'cocoa-types',
    canActivate: [AuthGuard],
    component: CocoaTypesPage,
    data: { roles: [Roles.admin] }
  },
  {
    path: 'roles',
    canActivate: [AuthGuard],
    component: RolesPage,
    data: { roles: [Roles.admin] }
  },
  {
    path: 'talonary-books',
    canActivate: [AuthGuard],
    component: TalbooksPage,
    data: { roles: [Roles.admin, Roles.supervisor, Roles.manager] }
  },
  {
    path: 'talonaries',
    canActivate: [AuthGuard],
    component: TalonariesPage,
    data: { roles: [Roles.admin] }
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    component: UsersPage,
    data: { roles: [Roles.admin, Roles.supervisor] }
  },
  {
    path: 'zone-groups',
    canActivate: [AuthGuard],
    component: ZoneGroupsPage,
    data: { roles: [Roles.admin, Roles.supervisor] }
  },
  {
    path: 'zones',
    canActivate: [AuthGuard],
    component: ZonesPage,
    data: { roles: [Roles.admin, Roles.supervisor] }
  },
  {
    path: 'contacts',
    canActivate: [AuthGuard],
    component: ContactsPage,
    data: { roles: [Roles.admin, Roles.supervisor, Roles.certification_manager] }
  },
  {
    path: 'tickets',
    canActivate: [AuthGuard],
    component: TicketsPage,
    data: { roles: [Roles.admin, Roles.supervisor, Roles.manager, Roles.buyer] }
  },
  {
    path: 'delivery-groups',
    canActivate: [AuthGuard],
    component: DeliveryGroupsPage,
    data: { roles: [Roles.admin, Roles.supervisor, Roles.certification_manager] }
  },
  {
    path: 'suppliers',
    canActivate: [AuthGuard],
    component: SuppliersPage,
    data: { roles: [Roles.admin, Roles.supervisor] }
  },
  {
    path: 'exportations',
    canActivate: [AuthGuard],
    component: ExportationsPage,
    data: { roles: [Roles.admin, Roles.supervisor] }
  },
  {
    path: 'addons',
    canActivate: [AuthGuard],
    component: AddonsPage,
    data: { roles: [Roles.admin] }
  },
  {
    path: 'commissions',
    canActivate: [AuthGuard],
    component: CommissionsPage,
    data: { roles: [Roles.admin, Roles.supervisor] }
  },
  {
    path: 'trade-prices',
    canActivate: [AuthGuard],
    component: TradePricesPage,
    data: { roles: [Roles.admin, Roles.supervisor] }
  },
  {
    path: 'contracts',
    canActivate: [AuthGuard],
    component: ContractsPage,
    data: { roles: [Roles.admin, Roles.supervisor, Roles.buyer] }
  },
  {
    path: 'unloads',
    canActivate: [AuthGuard],
    component: UnloadsPage,
    data: { roles: [Roles.admin, Roles.supervisor, Roles.manager] }
  },
  {
    path: 'seasons',
    canActivate: [AuthGuard],
    component: SeasonsPage,
    data: { roles: [Roles.admin] }
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'translate',
    canActivate: [AuthGuard],
    component: TranslateComponent,
    data: { roles: [Roles.admin]}
  },
  {
    path: 'payments',
    canActivate: [AuthGuard],
    component: PaymentsComponent,
    data: { roles: [Roles.admin, Roles.supervisor] }
  },
  {
    path: 'concepts',
    canActivate: [AuthGuard],
    component: ConceptsComponent,
    data: { roles: [Roles.admin] }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }
