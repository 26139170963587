import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CertificateTypeModel } from '../models/certificate-type.model';


//Environment
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CertificateTypeService {
  certificateTypeRoute = environment.routes.api + environment.routes.certificate_types;

  constructor(private http: HttpClient) {
  }

  getCertificateTypes() {
    return new Promise((resolve, reject) => {
      this.http.get(this.certificateTypeRoute, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  createCertificateType(certificateType: CertificateTypeModel) {
    return new Promise((resolve, reject) => {
      this.http.post(this.certificateTypeRoute, { certificate_type: certificateType }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  updateCertificateType(certificateType: CertificateTypeModel) {
    return new Promise((resolve, reject) => {
      this.http.put(
        `${this.certificateTypeRoute}/${certificateType['id']}`, { certificate_type: certificateType }, { headers: this.getHeaders() }
      ).toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  deleteCertificateType(certificateTypeId: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${this.certificateTypeRoute}/${certificateTypeId}`, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('yacao:app:token'),
        'user_id': localStorage.getItem('yacao:app:user_id')
      }
    );
  }
}
