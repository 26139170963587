import { TicketModel } from './ticket.model';

export class BigBagModel {
  id: number;
  weight: number;
  certificate_id: number;
  cocoa_quality_id: number;
  barcode_number: string;
  process_cd: number;
  certificate: any;
  cocoa_type_id: number;
  cocoa_quality: any;
  tickets: Array<any>;
}
