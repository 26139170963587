import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
  NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import * as _ from 'lodash';

// Models
import { SeasonModel } from 'src/app/models/season.model';

// Services
import { SeasonService } from 'src/app/services/season.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-season-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class SeasonFormComponent implements OnInit {
  @ViewChild('content', { static: true }) private modal;

  @Input() private seasons: SeasonModel[] = new Array<SeasonModel>();
  @Output() result: EventEmitter<SeasonModel[]> = new  EventEmitter<SeasonModel[]>();

  season: SeasonModel = new SeasonModel();
  modalRef: NgbModalRef;

  alertModalHeader: string = '';
  sending: boolean = false;

  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };

  constructor(
    private _modalService: NgbModal,
    private _seasonService: SeasonService,
    private _notification: NotificationService
  ) { }

  ngOnInit() {

  }

  openForm(season: SeasonModel) {
    season = season || new SeasonModel();

    if (season['id']) {
      const startedDate = new Date(season['season_starts_at']);
      const finishedDate = new Date(season['season_ends_at']);
      season['temp_season_starts_at'] = {
        day: Number(startedDate.getDate()),
        month: Number(startedDate.getMonth() + 1),
        year: Number(startedDate.getFullYear())
      };

      season['temp_season_ends_at'] = {
        day: Number(finishedDate.getDate()),
        month: Number(finishedDate.getMonth() + 1),
        year: Number(finishedDate.getFullYear())
      };
    }

    this.season = season;
    this.openModal();
  }

  closeModal(reason: string = '', options?: NgbModalOptions) {
    this.modalRef && this.modalRef.dismiss();
    // this.modalRef = this._modalService.open(this.modalRef, options ? options : this.modalOptions);
  }

  onSubmit(season: SeasonModel) {
    season.id ? this.updateSeason(season) : this.createSeason(season);
  }


  dateComparing(season: SeasonModel): boolean {
    const startedDate = new Date();
    const finishedDate = new Date();

    if (season['temp_season_starts_at']) {
      startedDate.setDate(season['temp_season_starts_at']['day']);
      startedDate.setMonth(season['temp_season_starts_at']['month'] - 1);
      startedDate.setFullYear(season['temp_season_starts_at']['year']);
    }

    if (season['temp_season_ends_at']) {
      finishedDate.setDate(season['temp_season_ends_at']['day']);
      finishedDate.setMonth(season['temp_season_ends_at']['month'] - 1);
      finishedDate.setFullYear(season['temp_season_ends_at']['year']);
    }
    return (startedDate < finishedDate);
  }

  updateSeason(season: SeasonModel) {
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);
    season = this.updateDates(season);
    this._seasonService.updateSeason(season)
      .then(
        res => {
          this.result.emit(res['seasons']);

          this._notification.clear();
          this._notification.success('Actualizado correctamente');
          this.closeModal();
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
            if (_.includes(err['errors'], 'diceros_error')) {
              this.closeModal();
              this.result.emit(err['seasons']);
            }
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      );
  }

  private openModal(options: NgbModalOptions = null) {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }

  private createSeason(season: SeasonModel) {
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);
    season = this.updateDates(season);
    this._seasonService.createSeason(season)
      .then(
        res => {

          this._notification.clear();
          this._notification.success('Creado correctamente');

          this.seasons.push(res['season']);
          this.result.emit(this.seasons);
          this.closeModal();
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
            if (_.includes(err['errors'], 'diceros_error')) {
              this.closeModal();
              this.seasons.push(err['season']);
              this.result.emit(this.seasons);
            }
          } else {
            this._notification.error('Se ha producido un error al crear');
          }
        }
      );
  }


  private updateDates(season: SeasonModel): SeasonModel {
    const displayStartDate = new Date();
    const displayEndDate = new Date();
    if (season['temp_season_starts_at']) {
      displayStartDate.setDate(season['temp_season_starts_at']['day']);
      displayStartDate.setMonth(season['temp_season_starts_at']['month'] - 1);
      displayStartDate.setFullYear(season['temp_season_starts_at']['year']);
      season['season_starts_at'] = displayStartDate;
    }

    if (season['temp_season_ends_at']) {
      displayEndDate.setDate(season['temp_season_ends_at']['day']);
      displayEndDate.setMonth(season['temp_season_ends_at']['month'] - 1);
      displayEndDate.setFullYear(season['temp_season_ends_at']['year']);
      season['season_ends_at'] = displayEndDate;
    }

    return season;
  }

}
