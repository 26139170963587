<app-navbar></app-navbar>

<div *ngFor="let category of wordCategories" class="col-12 mb-3">
  <h6><strong>{{  category.name }}</strong></h6>
  <div class="col-2 d-inline-block" *ngFor="let word of category.words">
    <div>{{ word.original_value }}</div>
    <div>{{ word.key }}</div>
    <input type="text" (input)="changeWordValue($event.target.value, category, word)" class="form-control"
      [value]="word.current_value" [disabled]="!globals.currentUserCanTranslate">
  </div>
</div>

<div class="mt-3 ml-3">
  <button type="button" class="btn btn-lg btn-dark" (click)="saveTranslate()" [disabled]="!globals.currentUserCanTranslate">Guardar cambios</button>
</div>