

<app-navbar></app-navbar>
<!-- Container with create button and expor to excel buttons -->
<div class="row">
  <div class="col">
    <div class="filtersContainer">
      <div class="row">
        <div class="col-3">
          <h5 class="text-dark font-weight-light">
            Precios máximos del cacao
          </h5>
        </div>
        <div class="col-3 offset-3">
          <button type="button" class="btn btn-info btn-block" (click)="openForm(null)">
            <i class="fa fa-plus"></i>
            Nuevo precio
          </button>
        </div>
        <div class="col-3">
          <button type="button" class="btn btn-outline-dark btn-block"
            (click)="getTradePrices(1, itemsPerPage, filters, 'xlsx')">
            <div class="load-container" *ngIf="loadingExcel">
              <div class="loader-green d-inline-block"></div>
            </div>
            <i class="fa fa-download" *ngIf="!loadingExcel"></i>
            Descargar Excel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-trade-prices-form [tradePrices]="tradePrices" (result)="setTradePrices($event)"></app-trade-prices-form>

<!-- Table with datas -->
<div *ngIf="!loading">
  <table class="table sticky-yacao green table-striped table-hover table-sm">
    <thead>
      <tr>
        <th>#</th>
        <th>Nombre</th>
        <th>Precio por QQ</th>
        <th>Precio por KG</th>
        <th>Tipo de cacao</th>
        <th>Calidad del cacao</th>
        <th>Certificado</th>
        <th>Activo</th>
        <th>Acciones</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let tradePrice of tradePrices | paginate: { itemsPerPage: 15, currentPage: p }; let i= index;">
        <td>{{i + 1}}</td>
        <td>{{ tradePrice.name }}</td>
        <td>{{ tradePrice.amount_per_qq }}</td>
        <td>{{ tradePrice.amount }}</td>
        <td>{{ tradePrice.cocoa_type?.name }}</td>
        <td>{{ tradePrice.cocoa_quality?.name }}</td>
        <td>{{ tradePrice.certificate?.name }}</td>
        <td>
          <mat-icon class="font-weight-bold"> {{ tradePrice.active ? 'check' : 'close' }}</mat-icon>
        </td>
        <td>
          <div class="col-12">
            <div class="nav-item dropdown" ngbDropdown>
              <a class="btn btn-success btn-sm dropdown-toggle white" ngbDropdownToggle>
                Selecciona acción
              </a>

              <div class="dropdown-menu" aria-labelledby="admin" ngbDropdownMenu>
                <button class="btn btn-sm dropdown-item" (click)="openForm(tradePrice)">
                  <!-- <mat-icon class="position-relative top-5 font-weight-bold">edit</mat-icon> -->
                  <i class="fa fa-edit text-primary"></i>
                  Editar
                </button>

                <button class="btn btn-sm dropdown-item" (click)="deactivateActivateTradePrice(tradePrice)">
                  <!-- <mat-icon class="position-relative top-5">delete</mat-icon> -->
                  <i class="fa fa-trash text-danger"></i>
                  {{ tradePrice.active ? 'Desactivar' : 'Activar' }}
                </button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="loading" *ngIf="loading">
  <ng-template #loadingTemplate></ng-template>
  <ngx-loading [show]="loading" [template]="loadingTemplate"></ngx-loading>
</div>

<div class="paginationContainer" *ngIf="!loading">
  <pagination-controls autoHide="true" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
    (pageChange)="p= $event"></pagination-controls>
</div>
