import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

// Models
import { FilterModel } from 'src/app/models/filter.model';
import { TicketModel } from '../../../models/ticket.model';
import { CertificateModel } from '../../../models/certificate.model';
import { CocoaTypeModel } from '../../../models/cocoa-type.model';
import { UserModel } from '../../../models/user.model';
import { ContactModel } from '../../../models/contact.model';
import { DeliveryGroupModel } from '../../../models/delivery-group.model';
import { CocoaQualityModel } from 'src/app/models/cocoa_quality.model';
import { CenterModel } from 'src/app/models/center.model';

// Services
import { TicketService } from '../../../services/ticket.service';
import { DownloadFileService } from 'src/app/services/download-file.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TranslationService } from 'src/app/services/translation.service';
// Constants
import { TicketType, TicketUnloadState, SettlementType } from 'src/app/app.enum';
import { GlobalsService } from 'src/app/services/globals.service';

// Components
import { TicketFormComponent } from '../modals/ticket-form/ticket-form.component';
import { ProvenanceSheetFormComponent } from '../modals/provenance-sheet-form/provenance-sheet-form.component';
import { ImagesModalComponent } from 'src/app/components/modals/images-modal/images-modal.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-index',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TicketsPage implements OnInit, OnDestroy {
  private translate_mode$: Subject<void> = new Subject<void>();
  @ViewChild(TicketFormComponent, { static: true }) ticketForm: TicketFormComponent;
  @ViewChild(ProvenanceSheetFormComponent) provenanceSheetForm: ProvenanceSheetFormComponent;
  @ViewChild(ImagesModalComponent, { static: true }) private gallery: ImagesModalComponent;
  // Yes or Not filters
  yesOrNot = [{ name: 'Si', value: true }, { name: 'No', value: false }];
  // Ticket types filter
  ticketTypeFilter = [
    { name: 'Compra directa', value: TicketType.direct_purchase },
    { name: 'Entregas de cacao', value: TicketType.cocoa_receipt }
  ];

  enumSettlementState = SettlementType;

  ticketUnloadStateFilter = [
    { name: 'Si', value: TicketUnloadState.unloaded },
    { name: 'No', value: TicketUnloadState.unloaded }
  ];

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy'
    // other options are here...
  };

  // Objects
  ticketType = TicketType;
  supplier: ContactModel;

  // Collections
  tickets: Array<TicketModel> = new Array<TicketModel>();
  // talbooks: Array<TalBookModel> = new Array<TalBookModel>();
  certificates: Array<CertificateModel> = new Array<CertificateModel>();
  cocoaTypes: Array<CocoaTypeModel> = new Array<CocoaTypeModel>();
  // creators: Array<UserModel> = new Array<UserModel>();
  receivers: Array<UserModel> = new Array<UserModel>();
  contacts: Array<ContactModel> = new Array<ContactModel>();
  suppliers: Array<ContactModel> = new Array<ContactModel>();
  centers: Array<CenterModel> = new Array<CenterModel>();
  // provenanceSheetImages: Array<string>;
  // provenanceSheetImageSelectedIndex = 0;

  // provenanceSheetKgBaba: Array<number> = new Array<number>();
  // provenanceSheetKgDry: Array<number> = new Array<number>();

  cocoaQualities: Array<CocoaQualityModel> = new Array<CocoaQualityModel>();

  // provenanceSheetProducers: Array<ContactModel> = new Array<ContactModel>();
  deliveryGroups: Array<DeliveryGroupModel> = new Array<DeliveryGroupModel>();
  talbook_sheets: [] = [];

  // selectImage = 0;


  filters: FilterModel = new FilterModel();

  // startDate: Date;
  // endDate: Date;

  // p = 1;

  loading: boolean;
  sending: boolean = false;

  isCollapsed = false;

  // contactsLoad = false;
  // changeCompleteStateValue = false;
  // timeout: any;
  // activeFilter = 'Si';

  ticketUnloadState = TicketUnloadState;
  // resultAux: number;

  itemsPerPage = 25;
  totalItems = 0;
  currentPage = 1;

  loadingExcel = false;
  isInTranslateMode: boolean = false;

  currentUserRoles = {};
  unloadIds = [];

  constructor(
    private _downloadFileService: DownloadFileService,
    private ticketService: TicketService,
    public globals: GlobalsService,
    private _notification: NotificationService,
    private translate: TranslateService,
    private translationService: TranslationService
  ) { 
    // translate.setDefaultLang('es');
  }

  ngOnInit() {
    this.filters['q[ticket_type_cd_in]'] = JSON.stringify([TicketType.direct_purchase, TicketType.cocoa_receipt]);
    this.getTickets(this.currentPage, this.itemsPerPage, this.filters);
    this.currentUserRoles = {};

    _.each(this.globals.currentUserRoles, role => {
      this.currentUserRoles[role['alias']] = true;
    });

    this.isInTranslateMode = this.translationService.isInTranslateMode;
    
    this.translationService.translate_mode$
    .pipe(takeUntil(this.translate_mode$))
    .subscribe(
      (isTranslateMode: boolean) => {
        this.isInTranslateMode = isTranslateMode;
      }
    )
  }

  ngOnDestroy(): void {
    this.translate_mode$.next();
    this.translate_mode$.complete();
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  getTickets(page: number = 1, per_page: number = 25, params: {} = {}, format: string = 'json') {
    let parameters = _.cloneDeep(params);

    if (format === 'json') {
      this.loading = true;
    }

    if (format === 'xlsx') {
      this.loadingExcel = true;
    }


    parameters = Object.assign(parameters, { format: format });

    _.each(parameters, (value, key) => {
      if (typeof parameters[key] === 'string') {
        parameters[key] = parameters[key].trim();
      }
      
      (value == null) && delete parameters[key];
    });

    if (parameters['g']) {
      parameters = Object.assign(parameters, { g: JSON.stringify({ g: parameters['g'] }) });
    }

    this.ticketService
      .getTickets(page, per_page, parameters)
      .then(
        (response) => {
          if (format === 'json') {
            this.tickets = response['tickets'];
            this.getSuppliers(response['suppliers'])
            this.certificates = response['certificates'];
            this.cocoaTypes = response['cocoa_types'];
            this.cocoaQualities = response['cocoa_qualities'];
            const receivers: any[] = _.uniqBy([...response['receivers'], ...response['buyers']], 'id');
            this.getReceivers(receivers);
            this.contacts = response['producers'];
            this.deliveryGroups = response['delivery_groups'];
            this.talbook_sheets = response['talbook_sheets'];
            this.unloadIds = response['unload_ids'];
            this.getCenters(response['centers']);

            this.totalItems = response['total_items'];
            this.currentPage = response['current_page'];
            this.loading = false;
            _.each(this.contacts, (contact) => {
              const groups = _.filter(this.deliveryGroups, (deliveryGroup) => {
                if (_.includes(deliveryGroup['producer_ids'], contact['id'])) {
                  return deliveryGroup;
                }
              });

              contact['producer_in_delivery_groups'] = groups;
            });
          } else {
            this.loadingExcel = false;
            this._downloadFileService.downloadFile(response, 'recibos', 'application/vnd.ms-excel');
          }
        }
      )
      .catch(
        (error) => console.error(error)
      );
  }

  getTicketTypeOf(ticket: number = 0) {
    const tt = TicketType[ticket];
    let name = '';

    switch (tt) {
      case 'cocoa_receipt':
        name = 'Recibo';
        break;
      case 'direct_purchase':
        name = 'Compra directa';
        break;
      case 'contracts':
        name = 'Acuerdo de compra';
        break;
    }

    return name;
  }

  onDateChanged(event, filter: string) {
    console.log(event)
    if (filter === 'startDate') {
      this.filters.g[0].contract_starts_gteq = event.singleDate.jsDate;
      this.filters.g[0].collected_date_gteq = event.singleDate.jsDate;
    } else if (filter === 'endDate') {
      this.filters.g[1].collected_date_lteq = event.singleDate.jsDate;
      this.filters.g[1].contract_starts_lteq = event.singleDate.jsDate;
    }
  }

  setContactIntermediaryFiter(event: any) {
    if (event) {
      this.filters = Object.assign(this.filters, {
        'q[contact_intermediary_eq]': true
      });
    } else {
      delete this.filters['q[contact_intermediary_eq]'];
    }

  }

  openGallery(title: string, images: string[]) {
    this.gallery.openGallery(title, images);
  }

  setUnloadingStateFilter(event) {
    if (event) {
      if (event.name === 'Si') {
        this.filters['q[unloads_id_not_null]'] = 1;
        // delete this.filters['q[unloading_state_cd_not_eq]'];
        // this.filters['q[unloading_state_cd_eq]'] = event.value;
      } else {
        this.filters['q[unloads_id_not_null]'] = 0;
        // delete this.filters['q[unloading_state_cd_eq]'];
        // this.filters['q[unloading_state_cd_not_eq]'] = event.value;
      }
    } else {
      delete this.filters['q[unloads_id_not_null]'];
      // delete this.filters['q[unloading_state_cd_eq]'];
      // delete this.filters['q[unloading_state_cd_not_eq]'];
    }
  }

  // removeImage = (ticket, type: string) => {
  //   switch (type) {
  //     case 'ticket':
  //       this.ticket['remove_receipt'] = ticket['receipt']['blob_id'];
  //       ticket['receipt'] = '';
  //       break;
  //     case 'sheet':
  //       const toRemove = this.ticket['provenance_sheet']['sheet_images'][this.provenanceSheetIndex];

  //       if (!this.ticket['provenance_sheet_attributes']) {
  //         this.ticket['provenance_sheet_attributes'] = new ProvenanceSheetModel();
  //       }

  //       toRemove['to_remove'] = true;
  //       this.ticket['provenance_sheet_attributes']['sheet_images'].push(toRemove);

  //       _.pullAt(this.ticket['provenance_sheet']['sheet_images'], this.provenanceSheetImageSelectedIndex);
  //       _.pullAt(this.provenanceSheetImages, this.provenanceSheetImageSelectedIndex);
  //       this.provenanceSheetImageSelectedIndex = 0;

  //       break;
  //   }
  // }



  // setImageModal(content, img: Array<any>, type: string) {
  //   this.modalImgs = [];
  //   _.each(img, i => {
  //     i.hasOwnProperty('sheet_content') ? this.modalImgs.push(i['sheet_content']) : this.modalImgs.push(i);
  //   });

  //   this.isSheet = type === 'sheet' ? true : false;

  //   // this.openModal(content, 'custom-modal');

  // }


  ticketProcessedOut(ticket: TicketModel) {
    ticket['processed'] = !ticket['processed'];

    const t = {
      id: ticket['id'],
      processed: ticket['processed']
    };

    this.updateTicketStateValue(t as TicketModel);
  }

  ticketFinished(ticket: TicketModel) {
    if (!ticket.billed) {
      ticket['finished'] = !ticket['finished'];

      const t = {
        id: ticket['id'],
        finished: ticket['finished']
      };
  
      this.updateTicketStateValue(t as TicketModel);
    } else  {
      this._notification.error('El recibo está facturado');
    }

  }

  openTicketForm(ticket: TicketModel) {
    this.ticketForm.openForm(ticket);
  }

  openProvenanceSheetForm(ticket: TicketModel) {
    this.provenanceSheetForm.openForm(ticket);
  }

  // setTicket(ticket, content, modalclass: string) {
  //   const collectedDate = new Date(ticket['collected_date']);

  //   const startedDate = new Date(ticket['contract_starts']);
  //   const finishedDate = new Date(ticket['contract_ends']);

  //   if (ticket.ticket_type_cd === TicketType.direct_purchase) {
  //     ticket['temp_contract_starts'] = {
  //       day: Number(startedDate.getDate()),
  //       month: Number(startedDate.getMonth() + 1),
  //       year: Number(startedDate.getFullYear())
  //     };

  //     ticket['temp_contract_ends'] = ticket['temp_contract_starts'];
  //   }


  //   this.provenanceSheetImages = [];

  //   ticket['temp_collected_date'] = {
  //     day: Number(collectedDate.getDate()),
  //     month: Number(collectedDate.getMonth() + 1),
  //     year: Number(collectedDate.getFullYear())
  //   };

  //   this.ticket = ticket;

  //   if (ticket['provenance_sheet']
  //     && ticket['provenance_sheet']['sheet_images']
  //     && ticket['provenance_sheet']['sheet_images'].length > 0) {
  //     this.provenanceSheetImages = _.map(ticket['provenance_sheet']['sheet_images'], 'sheet_content');
  //   }

  //   ticket['bags_info'] = ticket.bags;
  //   this.openModal(content, modalclass);
  // }

  compareFn(a, b) {
    return a['id'] === b;
  }

  // changeProvenanceSheetImage(n: number) {
  //   if (n === -1) {
  //     if (this.provenanceSheetImageSelectedIndex === 0) {
  //       this.provenanceSheetImageSelectedIndex = (this.provenanceSheetImages.length - 1);
  //     } else {
  //       this.provenanceSheetImageSelectedIndex += n;
  //     }
  //   } else {
  //     if (this.provenanceSheetImageSelectedIndex === (this.provenanceSheetImages.length - 1)) {
  //       this.provenanceSheetImageSelectedIndex = 0;
  //     } else {
  //       this.provenanceSheetImageSelectedIndex += n;
  //     }
  //   }
  // }




  // currentIsAdminOrSupervisor(): boolean {
  //   if (!!_.find(this.currentUserRoles, { alias: 'admin' })) {
  //     return true;
  //   } else if (!!_.find(this.currentUserRoles, { alias: 'supervisor' })) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // currentIs(alias: string) {
  //   return !!_.find(this.globals.currentUserRoles, { alias: alias });
  // }

  disabledIfCurrent(): boolean {
    let returnVal = true;
    _.each(this.globals.currentUserRoles, (role) => {
      if (role['alias'] === 'admin' || role['alias'] === 'supervisor' || role['alias'] === 'manager') {
        returnVal = false;
        return false;
      }
    });

    return returnVal;
  }


  // getTicketSupplier(ticket: TicketModel): ContactModel {
  //   return _.find(this.contacts, { id: ticket.supplier_id });
  // }

  getStateOf(ticket: TicketModel) {
    let state = 'Incompleto';
    
    if (ticket.nulled) {
      state = 'Anulado';
      return state;
    }

    if (ticket.finished) {
      state = 'Finalizado';
      return state;
    }

    if (ticket.completed) {
      state = 'Completado';
    } else {
      state = 'Incompleto';
    }

    return state;
  }

  setTickets(tickets: TicketModel[]): void {
    this.tickets = tickets;
  }

  private updateTicketStateValue(ticket: TicketModel) {
    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    this.ticketService.updateTicket(ticket)
      .then(
        res => {
          const index = _.findIndex(this.tickets, { id: ticket['id'] });
          this.tickets[index] = res['ticket'];


          this._notification.clear();
          this._notification.success('Actualizado correctamente');

          this.sending = false;

        }
      )
      .catch(
        err => {
          if (err.hasOwnProperty('ticket')) {
            const index = _.findIndex(this.tickets, { id: err['ticket']['id'] });
            this.tickets[index] = err['ticket'];
            // this.getTickets();
            // this.filterTickets();
          }

          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      );
  }


  private getReceivers(receivers: Array<UserModel>) {
    const roles = {};
    _.each(this.globals.currentUserRoles, role => {
      roles[role['alias']] = true;
    });

    if (roles['manager'] && (!roles['admin'] || !roles['supervisor'])) {
      console.log(receivers.length);
      this.receivers = _.filter(receivers, receiver => {
        if (receiver.center_id === this.globals.currentUserCenterId || _.includes(this.globals.currentUserWorkCentersIds, receiver.center_id)) {
          return receiver;
        }
      });
      console.log(this.globals.currentUserWorkCentersIds);
      // this.receivers = _.filter(receivers, { center_id: this.globals.currentUserCenterId });
    } else {
      this.receivers = receivers;
    }
  }

  private getCenters(centers: Array<CenterModel>) {
    const roles = {};
    _.each(this.globals.currentUserRoles, role => {
      roles[role['alias']] = true;
    });

    if (roles['manager'] && (!roles['admin'] || !roles['supervisor'])) {
      this.centers = [_.find(centers, { id: this.globals.currentUserCenterId })];
    } else {
      this.centers = centers;
    }
  }

  private getSuppliers(suppliers: Array<ContactModel>) {
    if (this.globals.currentUserRolesAsBooleanObject['admin']
      || this.globals.currentUserRolesAsBooleanObject['supervisor']
      || this.globals.currentUserRolesAsBooleanObject['manager']) {
      this.suppliers = suppliers;
    } else if (this.globals.currentUserRolesAsBooleanObject['buyer']) {
      this.suppliers = _.filter(suppliers, { buyer_id: +localStorage.getItem('yacao:app:user_id') });
    }
  }
}
