
<!-- Molda template -->
<ng-template #content let-modal let-d="dismiss">
  <form #Form="ngForm" (ngSubmit)="Form.form.valid && onSubmit(addon)">
    <div class="modal-header">
      <h4 class="modal-title">{{ addon.id ? 'Actualizar' : 'Crear'}} incentivo</h4>

      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="form-group">
        <label for="addonNameId">Nombre del incentivo</label>

        <input type="text" #addonName="ngModel" class="form-control" id="addonNameId" [(ngModel)]="addon.name"
          placeholder="Name" name="addonName" [ngClass]="{ 'is-invalid': Form.submitted && addonName.invalid}" required>

        <div *ngIf="Form.submitted && addonName.invalid" class="invalid-tooltip">
          <div *ngIf="addonName.errors.required">El nombre es obligatorio</div>
        </div>
      </div>

      <div class="form-group">
        <label for="addonDescriptionId">Descripcion del incentivo</label>

        <textarea name="addonDescription" type="text" id="addonDescriptionId" class="md-textarea form-control"
          mdbInputDirective [(ngModel)]="addon.description" aria-describedby="addonDescription"></textarea>
        <small id="addonDescription" class="form-text text-muted">Descripción del incentivo</small>
      </div>

      <div class="form-group">
        <label for="certificateId">Certificado</label>

        <ng-select id="certificateId" [items]="certificates" [searchable] [clearSearchOnAdd]
          notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name" bindValue="id"
          [(ngModel)]="addon.certificate_id" name="certificate" placeholder="Selecciona el certificado"
          [compareWith]="compareFn" [ngClass]="{'select-invalid': Form.submitted && !addon.certificate_id }"
          [disabled]="addon.certificate?.id" required></ng-select>
      </div>

      <div class="row">
        <div class="col-6 form-group">
          <label for="addonAmountId">Pesos por KG</label>

          <input type="number" #addonAmount="ngModel" class="form-control" id="addonAmountId" [(ngModel)]="addon.amount"
            placeholder="Cantidad" name="addonAmount" [ngClass]="{ 'is-invalid': Form.submitted && addonAmount.invalid}"
            min="0" required>

          <div *ngIf="Form.submitted && addonAmount.invalid" class="invalid-tooltip">
            <div *ngIf="addonAmount.errors.required">La cantidad es obligatoria</div>
          </div>
        </div>

        <div class="col-6">
          <label for="addonAmountInKG">Pesos por QQ</label>

          <div>
            {{addon.amount ? (addon.amount * 50) : 0}} $RD por QQ
          </div>
        </div>
      </div>

      <div class="form-group">
        <mat-checkbox [(ngModel)]="addon.active" name="addonActive">Activo</mat-checkbox>
      </div>
    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" name="submitAddon"
        [disabled]="sending">{{ addon.id ? 'Guardar' : 'Crear'}}</button>
      <button type="button" class="btn btn-danger" (click)="d('button click')" name="closeModal"
        [disabled]="sending">Cancelar</button>
    </div>
  </form>
</ng-template>