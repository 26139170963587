<ng-template #exportationModal let-modal let-d="dismiss">
  <div class="modal-header" *ngIf="exportation">
    <h4 class="modal-title">{{ exportation.id ? 'Actualizar' : 'Crear' }} exportación</h4>

    <button type="button" class="close" aria-label="Close" (click)="d('Cross click'); resetSubmitedValue()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">

    <!-- Exportation fields -->
    <!-- Container Name-->
    <div class="form-group col-4 d-inline-block">
      <label for="exportationContainerId">
        Nombre del contenedor
      </label>

      <input type="text" #exportationContainerName="ngModel" class="form-control col-xs-4" id="exportationContainerId"
        [(ngModel)]="exportation.container_name" placeholder="Name" name="exportationContainerMame"
        [ngClass]="{ 'is-invalid': submitted && exportationContainerName.invalid}" required>

      <div *ngIf="submitted && exportationContainerName.invalid" class="invalid-tooltip">
        <div *ngIf="exportationContainerName.errors.required">El contenedor es obligatorio</div>
      </div>
    </div>

    <!-- Date of exportation-->
    <div class="form-group col-md-4 d-inline-block">
      <label for="exportationDate">
        Fecha de exportación
      </label>

      <input id="exportationDate" class="form-control" [(ngModel)]="exportation.temp_exportation_date"
        placeholder="Fecha de exportación" name="exportationDate" ngbDatepicker #exportationDate="ngbDatepicker"
        [ngClass]="{ 'is-invalid': submitted && !exportation.temp_exportation_date }" (click)="exportationDate.toggle()"
        required />

      <div *ngIf="submitted && !exportation.temp_exportation_date" class="invalid-tooltip">
        <div>La fecha es obligatoria</div>
      </div>
    </div>

    <!-- Quality of product exportation-->
    <div class="form-group col-md-4 d-inline-block">
      <label for="exportationQuality">
        Calidad
      </label>

      <!-- <input type="text" class="form-control" id="exportationQuality" 
            placeholder="Calidad del producto" name="exportationQuality" [(ngModel)]="exportation.quality"
            [ngClass]="{ 'is-invalid': submitted && !exportation.quality}" required> -->
      <ng-select [items]="cocoaQualities" [searchable]="true" notFoundText="No hay coincidencias" loadingText="Buscando"
        bindValue="id" bindLabel="name" name="exportationCocoaQuality" [clearable]="false" [compareWith]="compareFn"
        [ngClass]="{'select-invalid': submitted && !exportation.quality_id }" placeholder="Calidad del cacao"
        [multiple]="false" [(ngModel)]="exportation.cocoa_quality_id">
      </ng-select>

      <div *ngIf="submitted && !exportation.quality_id" class="invalid-tooltip">
        <div>La calidad es obligatoria</div>
      </div>
    </div>

    <!-- Exportations tickets datas -->
    <div class="col-12">
      <div class="table-responsive table-container">
        <table class="table table-striped table-hover table-sm">
          <thead>
            <tr class="green-success">
              <th>Recibo a exportar</th>
              <th>Nº de entrada</th>
              <th>Traslado</th>
              <th>Sublote</th>
              <th>Kilos</th>
              <th>
                <button class="btn btn-primary" (click)="addNewRow()">
                  <mat-icon class="position-relative top-5 font-weight-bold">add</mat-icon>
                </button>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              *ngFor="let t of exportation.exportation_tickets | paginate: { itemsPerPage: 9, currentPage: exportationTicketPage, id: 'modal-paginator' }; let i= index; trackBy:trackByFn;">
              <!-- Column 1 Ticket-->
              <td>
                <div class="col-12 d-inline-block">
                  <ng-select [items]="tickets" [searchable]="true" notFoundText="No hay coincidencias"
                    loadingText="Buscando" bindValue="id" bindLabel="talbook_sheet" name="exportationTicket"
                    [clearable]="false" placeholder="Seleccione el recibo" [multiple]="false" [compareWith]="compareFn"
                    [(ngModel)]="exportation.exportation_tickets[globals.getAbsoluteIndex(9, exportationTicketPage, i)].ticket_id"
                    [ngClass]="{'select-invalid': submitted && !exportation.exportation_tickets[globals.getAbsoluteIndex(9, exportationTicketPage, i)]?.ticket_id }">
                  </ng-select>
                </div>
              </td>

              <!-- Column 2 Entry number -->
              <td>
                <div class="col-12 d-inline-block">
                  <input type="text" class="form-control col-xs-4"
                    [(ngModel)]="exportation.exportation_tickets[globals.getAbsoluteIndex(9, exportationTicketPage, i)].entry_code" placeholder="Numero de entrada"
                    name="exportationEntryNumber" [disabled]="!exportation.exportation_tickets[globals.getAbsoluteIndex(9, exportationTicketPage, i)].ticket_id" required>

                  <!-- <div *ngIf="submitted && !validateWeight(i)" class="invalid-tooltip">
                    <div>El peso es obligatorio</div>
                  </div> -->
                </div>
              </td>

              <!-- Column 3 Transfer -->
              <td>
                <div class="col-12 d-inline-block">
                  <input type="text" class="form-control col-xs-4" placeholder="Traslado"
                    name="exportationTransfer" [disabled]="!exportation.exportation_tickets[globals.getAbsoluteIndex(9, exportationTicketPage, i)].ticket_id"
                    [(ngModel)]="exportation.exportation_tickets[globals.getAbsoluteIndex(9, exportationTicketPage, i)].transfer">
                </div>
              </td>

              <!-- Column 4 Sublot -->
              <td>
                <div class="col-12 d-inline-block">
                  <input type="text" class="form-control col-xs-4" placeholder="Sublote"
                    name="exportationSublot" [disabled]="!exportation.exportation_tickets[globals.getAbsoluteIndex(9, exportationTicketPage, i)].ticket_id"
                    [(ngModel)]="exportation.exportation_tickets[globals.getAbsoluteIndex(9, exportationTicketPage, i)].sublot">
                </div>
              </td>

              <!-- Column 5 Weight-->
              <td>
                <div class="col-12 d-inline-block">
                  <div class="col-9 d-inline-block p-0">
                    <input type="number" class="form-control col-xs-4"
                      placeholder="Kg a exportar" name="exportationProduct"
                      [ngClass]="{ 'is-invalid': submitted && !validateWeight(globals.getAbsoluteIndex(9, exportationTicketPage, i))}" min="0"
                      [(ngModel)]="exportation.exportation_tickets[globals.getAbsoluteIndex(9, exportationTicketPage, i)].weight"
                      [disabled]="!exportation.exportation_tickets[globals.getAbsoluteIndex(9, exportationTicketPage, i)].ticket_id" required>

                    <div *ngIf="submitted && !validateWeight(globals.getAbsoluteIndex(9, exportationTicketPage, i))" class="invalid-tooltip">
                      <div>El peso es obligatorio</div>
                    </div>
                  </div>
                </div>
              </td>

              <!-- Column 6 Remove button -->
              <td>
                <div class="col-12 d-inline-block p-0" *ngIf="exportation.exportation_tickets.length > 1">
                  <button type="button" class="btn btn-danger" (click)="removeTicket(globals.getAbsoluteIndex(9, exportationTicketPage, i))">
                    <mat-icon class="position-relative top-5">delete</mat-icon>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-12">
      <div class="paginationContainer">
        <pagination-controls autoHide="true" id="modal-paginator" responsive="true" previousLabel="Anterior"
          nextLabel="Siguiente" (pageChange)="exportationTicketPage = $event">
        </pagination-controls>
      </div>
    </div>
  </div>

  <!-- Modal Footer -->
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" name="submitExportation" (click)="onSubmit(exportation)"
      [disabled]="sending">
      {{ exportation.id ? 'Guardar' : 'Crear' }}
    </button>

    <button class="btn btn-danger" (click)="d('button click'); resetSubmitedValue()" name="closeModal" [disabled]="sending">
      Cancelar
    </button>
  </div>
</ng-template>
