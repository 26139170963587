<ng-template #content let-modal let-update="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'pages.tickets_forms.package_form' | translate }}{{ ticket.talbook_sheet }}
    </h4>

    <button type="button" class="close" aria-label="Close" (click)="update('Cross click')">
      <span aria-hidden="true">
        &times;
      </span>
    </button>
  </div>

  <div class="modal-body edit-ticket-modal">
    <form #Form="ngForm">
      <div class="row">
        <div class="col">
          <label class="col" for="bagArrayLenght">
            {{ 'pages.tickets_forms.bags_number' | translate }}
          </label>

          <input id="bagArrayLenght" class="form-control" placeholder="Nº bultos" name="bagArrayLenght" (input)="changeNumberOfPackages($event.target.value)"
            [disabled]="!ticket.completed || ticket.reviewed || ticket.billed" [value]="ticket['bags_info']?.length" />
        </div>

        <div class="col">
          <label class="col" for="bagArrayWeight">
            {{ 'pages.tickets_forms.total_weight' | translate }}
          </label>

          <input id="bagArrayWeight" class="form-control" placeholder="Peso total" name="bagArrayWeight"
            [(ngModel)]="ticket.receiver_weight" disabled />
        </div>


        <button class="col btn" (click)="addNewBag()"
          [disabled]="ticket.reviewed || ticket.billed || (ticket.unloading_state_cd === ticketUnloadState.unloaded)">
          <i class="fa fa-plus"></i>
        </button>
      </div>

      <div class="row d-flex justify-content-center package-table">
        <table>
          <tr class="row">
            <th class="col-4">{{ 'pages.tickets_forms.code' | translate }}</th>
            <th class="col-6">{{ 'pages.tickets_forms.weight' | translate }}</th>
            <th class="col-2">{{ 'pages.tickets_forms.action' | translate }}</th>
          </tr>
          <tr class="row" *ngFor="let bag of ticket.bags_info; let i= index">
            <th class="col-4"><label>{{bag.code}}</label></th>
            <th class="col-6"><input type="number" class="form-control" [value]="bag.fixed_weight" name="bagWeight"
                (input)="updateWeight(i, $event.target.value)"
                [disabled]="ticket.reviewed || ticket.billed || (ticket.unloading_state_cd === ticketUnloadState.unloaded)">
            </th>
            <th class="col-2">
              <button class="btn" (click)="deleteBag(i)"
                [disabled]="ticket.reviewed || ticket.billed || (ticket.unloading_state_cd === ticketUnloadState.unloaded)">
                <i class="fa fa-trash"></i>
              </button></th>
          </tr>
        </table>
      </div>
    </form>
  </div>

  <div class="modal-footer" *ngIf="!ticket.finished">
    <button class="btn btn-primary" (click)="emitTicket(ticket)"
      [disabled]="(ticket.finished || ticket.unload_accepted || ticket.reviewed || ticket.billed || (!numberOfBagsChange || (ticket.unloading_state_cd === ticketUnloadState.unloaded)))">
      Guardar
    </button>

    <button class="btn btn-danger" (click)="closeModal('cancel click')" name="closeModal">
      {{ 'pages.tickets_forms.button_cancel' | translate }}
    </button>
  </div>
</ng-template>
