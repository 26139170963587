
<ng-template #content let-modal let-d="dismiss">
  <form #Form="ngForm" (ngSubmit)="Form.form.valid && onSubmit(center)">
    <div class="modal-header">
      <h4 class="modal-title">{{center.id ? 'Actualizar' : 'Crear'}} centro</h4>

      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="form-group">
        <label for="centerNameId">Nombre del centro</label>
        <input type="text" #centerName="ngModel" class="form-control" id="centerNameId" [(ngModel)]="center.name"
          placeholder="Name" name="centerName" [ngClass]="{ 'is-invalid': Form.submitted && centerName.invalid}"
          required>

        <div *ngIf="Form.submitted && centerName.invalid" class="invalid-tooltip">
          <div *ngIf="centerName.errors.required">El nombre es obligatorio</div>
        </div>
      </div>

      <div class="form-group">
        <mat-checkbox [(ngModel)]="center.active" name="centerActive">Activo</mat-checkbox>
      </div>

    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" name="submitCenter"
        [disabled]="sending">{{ center.id ? 'Guardar' : 'Crear'}}</button>
      <button type="button" class="btn btn-danger" (click)="d('button click')" name="closeModal"
        [disabled]="sending">Cancelar</button>
    </div>
  </form>
</ng-template>