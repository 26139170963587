<ng-template #modal let-modal let-update="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
      Configuración
    </h4>

    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">
        &times;
      </span>
    </button>
  </div>

  <form [formGroup]="form" novalidate (ngSubmit)="onSubmit()">
    <div class="modal-body">

      <div class="row form-gorup" *ngIf="!loading">
        <div class="form-gorup col-6">
          <label for="client_name">
            Nombre de la compañia
          </label>
          <input id="client_name" type="text" class="form-control" formControlName="client_name">
        </div>

        <div class="form-gorup col-6">
          <label for="primary_language">
            Lenguaje base
          </label>

          <ng-select id="primary_language" [clearable]="false" [items]="primariesLanguages" bindLabel="name"
            bindValue="name" [compareWith]="compareFn" [multiple]="false" formControlName="primary_language">
          </ng-select>
        </div>
      </div>

      <div class="loading" *ngIf="loading">
        <ng-template #loadingTemplate></ng-template>
        <ngx-loading [show]="loading" [template]="loadingTemplate"></ngx-loading>
      </div>

    </div>

    <div class="modal-footer">
      <button class="btn btn-primary" type="submit">
        Guardar
      </button>

      <button type="button" class="btn btn-danger" (click)="closeModal()" name="closeModal">
        Cancelar
      </button>
    </div>
  </form>
</ng-template>