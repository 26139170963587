import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

// Models
import { ZoneGroupModel } from 'src/app/models/zone-group.model';

// Services
import { ZoneGroupService } from 'src/app/services/zone-group.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-zone-groups-form',
  templateUrl: './zone-groups-form.component.html',
  styleUrls: ['./zone-groups-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZoneGroupsFormComponent implements OnInit {
  @ViewChild('content', { static: true }) private modal;
  @Output() result: EventEmitter<ZoneGroupModel[]> = new EventEmitter<ZoneGroupModel[]>();

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };

  @Input() private zonegroups: ZoneGroupModel[] = new Array<ZoneGroupModel>();
  zonegroup: ZoneGroupModel = new ZoneGroupModel();
  sending: boolean = false;

  constructor(private _modalService: NgbModal,
    private _zoneGroupService: ZoneGroupService,
    private _notification: NotificationService) { }

  ngOnInit() {
  }

  onSubmit(zoneGroup: ZoneGroupModel) {
    zoneGroup['id'] ? this.updateZoneGroup(zoneGroup) : this.createZoneGroup(zoneGroup);
  }

  openForm(zonegroup: ZoneGroupModel) {
    this.zonegroup = _.cloneDeep(zonegroup) || new ZoneGroupModel();
    this.openModal();
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  updateZoneGroup(zonegroup: ZoneGroupModel) {
    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    this._zoneGroupService
      .updateZoneGroup(zonegroup)
      .then(
        res => {
          const index = _.findIndex(this.zonegroups, { id: zonegroup['id'] });
          this.zonegroups[index] = res['zone_group'];
          this.result.emit(this.zonegroups);

          this._notification.clear();
          this._notification.success('Actualizado correctamente');
          this.closeModal();
          this.sending = false;
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      );
  }

  private createZoneGroup(zonegroup: ZoneGroupModel) {
    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    this._zoneGroupService.createZoneGroup(zonegroup)
      .then(
        res => {
          this.zonegroups.push(res['zone_group']);
          this.result.emit(this.zonegroups);

          this._notification.clear();
          this._notification.success('Creado correctamente');
          this.closeModal();
          this.sending = false;
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al crear');
          }
        }
      );
  }



  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }
}
