
<ng-template #content let-modal let-d="dismiss">
  <form #talbookTypeForm="ngForm" (ngSubmit)="talbookTypeForm.form.valid && onSubmit(talbookType)">
    <div class="modal-header">
      <h4 class="modal-title">{{talbookType.id ? 'Actualizar tipo de talonario' : 'Crear tipo de talonario' }} </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="form-group d-inline-block col-6">
        <label for="talbookTypeTypeId">Nombre del tipo de talonario</label>
        <input type="text" #talbookTypeName="ngModel" class="form-control" id="talbookTypeTypeId" [(ngModel)]="talbookType.name"
          placeholder="Name" name="talbookTypeType"
          [ngClass]="{ 'is-invalid': talbookTypeForm.submitted && talbookTypeName.invalid}" required>

        <div *ngIf="talbookTypeForm.submitted && talbookTypeName.invalid" class="invalid-tooltip">
          <div *ngIf="talbookTypeName.errors.required">El nombre es obligatorio</div>
        </div>
      </div>


      <div class="form-group d-inline-block col-3" *ngIf="!talbookType.id">
        <label for="talbookTypeTypeDigigtsId">Número de dígitos</label>
        <input type="number" class="form-control" id="talbookTypeTypeDigigtsId" min="1"
          [(ngModel)]="talbookType.number_of_digits" placeholder="1" name="talbookTypeDigitsPage" required>
      </div>

      <div class="form-group d-inline-block col-3" *ngIf="!talbookType.id">
        <label for="talbookTypeLabelId">Etiqueta del talonario</label>
        <input type="text" #talbookTypeLabel="ngModel" class="form-control" id="talbookTypeLabelId"
          [(ngModel)]="talbookType.label" placeholder="Etiqueta" name="talbookTypeLabel" pattern="[0-9]{2}"
          [ngClass]="{ 'is-invalid': talbookTypeForm.submitted && talbookTypeLabel.invalid}" required>

        <div *ngIf="talbookTypeForm.submitted && talbookTypeLabel.invalid" class="invalid-tooltip">
          <div *ngIf="talbookTypeLabel.errors.required">La etiqueta es obligatoria</div>
          <div *ngIf="talbookTypeLabel.errors.pattern">El patron debe ser valido Ej: 04</div>
        </div>
      </div>
      <!-- 
      <div class="form-group">
        <label for="talbookTypeTypePatternId">Patrón</label>
        <input type="text" class="form-control" id="talbookTypeLabelId"
        [(ngModel)]="talbookType.pattern" placeholder="Etiqueta" name="talbookTypeTypePatternId" readonly>
      </div> -->

      <div class="form-group d-inline-block col-3" *ngIf="!talbookType.id">
        <label for="talbookTypeTypeInitialPageId">Página inicial</label>
        <input type="text" #talbookTypeFirstPage="ngModel" class="form-control" id="talbookTypeTypeInitialPageId"
          [(ngModel)]="talbookType.first_page" placeholder="000000" name="talbookTypeFirstPage"
          [ngClass]="{ 'is-invalid': talbookTypeForm.submitted && (talbookTypeFirstPage.invalid || !formValid(talbookType))}"
          required>
        <div *ngIf="talbookTypeForm.submitted && (talbookTypeFirstPage.invalid || !formValid(talbookType))"
          class="invalid-tooltip">
          <div *ngIf="talbookTypeFirstPage.errors && talbookTypeFirstPage.errors.required">La página inicial es obligatoria
          </div>
          <div *ngIf="!formValid(talbookType)">El patron debe ser valido Ej: 000000</div>
        </div>
      </div>

      <div class="form-group d-inline-block col-9" *ngIf="!talbookType.id">
        <label for="typeId">
          Tipo de talonario
        </label>


        <ng-select id="typeId" [items]="types" [searchable] notFoundText="No hay coincidencias" loadingText="Buscando"
          [(ngModel)]="talbookType.type_cd" bindValue="value" bindLabel="name" name="TalonaryType"
          placeholder="Tipo de talonario" [compareWith]="compareFn" [multiple]="false" required></ng-select>
      </div>

      <div class="form-group col-12">
        <label for="talbookTypeTypeDescriptionId">Descripcion</label>
        <textarea name="descriptionTalonary" type="text" id="talbookTypeTypeDescriptionId" class="md-textarea form-control"
          mdbInputDirective [(ngModel)]="talbookType.description" aria-describedby="talbookTypeTypeHelp"></textarea>
        <small id="talbookTypeTypeHelp" class="form-text text-muted">Descripcion del tipo de talonario</small>
      </div>

      <div class="form-group">
        <mat-checkbox [(ngModel)]="talbookType.active" name="talbookTypeActive">Activo</mat-checkbox>
      </div>

    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" name="submitTalonary"
        [disabled]="sending"> {{talbookType.id ? 'Guardar' : 'Crear' }}</button>

      <button type="button" class="btn btn-danger" (click)="d('button click')" name="closeModal"
        [disabled]="sending">Cancelar</button>
    </div>
  </form>
</ng-template>