import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

// Models
import { RoleModel } from 'src/app/models/role.model';

// Services
import { RoleService } from 'src/app/services/role.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RoleFormComponent implements OnInit {
  @ViewChild('content', { static: true }) private modal;
  @Output() result: EventEmitter<RoleModel[]> = new EventEmitter<RoleModel[]>();

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };

  @Input() private roles: RoleModel[] = new Array<RoleModel>();

  role: RoleModel = new RoleModel();
  sending: boolean = false;

  constructor(private _modalService: NgbModal,
    private _roleService: RoleService,
    private _notification: NotificationService) { }

  ngOnInit() {
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  onSubmit(role: RoleModel) {
    role['id'] ? this.updateRole(role) : this.createRole(role);
  }

  openForm(role: RoleModel) {
    this.role = _.cloneDeep(role) || new RoleModel();
    this.openModal();
  }

  updateRole(role: RoleModel) {
    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    this._roleService
      .updateRole(role)
      .then(
        res => {
          const index = _.findIndex(this.roles, { id: role['id'] });
          this.roles[index] = res['role'];
          this.result.emit(this.roles);

          this._notification.clear();
          this._notification.success('Actualizado correctamente');
          this.closeModal();
          this.sending = false;
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
            if (_.includes(err['errors'], 'diceros_error')) {
              this.closeModal();
              const index = _.findIndex(this.roles, { id: role['id'] });
              this.roles[index] = err['role'];
              this.result.emit(err['role']);
            }
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      );
  }

  private createRole(role: RoleModel) {
    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);
    this._roleService.createRole(role)
      .then(
        res => {
          this.roles.push(res['role']);
          this.result.emit(this.roles);

          this._notification.clear();
          this._notification.success('Creado correctamente');
          this.closeModal();
          this.sending = false;
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
            if (_.includes(err['errors'], 'diceros_error')) {
              this.closeModal();
              this.roles.push(err['role']);
              this.result.emit(this.roles);
            }
          } else {
            this._notification.error('Se ha producido un error al crear');
          }
        }
      );
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }
}
