<!-- PRODUCERS MODAL -->
<ng-template #producerModal let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Productores de {{deliveryGroup.name}}</h4>

    <button type="button" class="close" aria-label="Close" (click)="d('Cross click'); producerPage = 1">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div>
      <table class="table table-striped table-hover table-sm">
        <thead>
          <tr class="green-success">
            <th>#</th>
            <th>Código</th>
            <th>Cédula</th>
            <th>Productor</th>
            <th>Suplidor</th>
            <th>Coordinador</th>
            <th>Nombre</th>
            <th>Apodo</th>
            <th>Teléfono</th>
            <th>Activo</th>
            <th>BSS</th>
            <th>UE/NOP</th>
            <th>Trans.</th>
            <th>FFL</th>
            <th>FLO</th>
            <th>UTZ</th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="let contact of deliveryGroup.producers | paginate: { id:'producersPagination', itemsPerPage: 15, currentPage: producerPage }; let i= index;">
            <td>
              {{globals.getAbsoluteIndex(15, producerPage, i) + 1}}
            </td>

            <td>
              {{contact.code}}
            </td>

            <td>
              {{contact.cedula}}
            </td>

            <td>
              {{contact.producer ? 'Si' : 'No'}}
            </td>

            <td>
              {{contact.supplier ? 'Si' : 'No'}}
            </td>

            <td>
              {{contact.coordinator ? 'Si' : 'No'}}
            </td>

            <td>
              {{contact.name}} {{contact.surname}}
            </td>

            <td>
              {{contact.nickname}}
            </td>

            <td>
              {{contact.phone}}
            </td>


            <td>
              <mat-icon class="font-weight-bold">{{ contact.active_as_contact ? 'check' : 'close '}}</mat-icon>
            </td>

            <td>
              {{getQuotaOfContact(contact, 'biosuisse')}}
            </td>

            <td>
              {{getQuotaOfContact(contact, 'ue/nop')}}
            </td>

            <td>
              {{getQuotaOfContact(contact, 'trans')}}
            </td>

            <td>
              <mat-icon class="font-weight-bold">{{ haveCertificate(contact, 'ffl') ? 'check' : 'close '}}</mat-icon>
            </td>
            <td>
              <mat-icon class="font-weight-bold">{{ haveCertificate(contact, 'flo') ? 'check' : 'close '}}</mat-icon>
            </td>
            <td>
              <mat-icon class="font-weight-bold">{{ haveCertificate(contact, 'utz') ? 'check' : 'close '}}</mat-icon>
            </td>

          </tr>
        </tbody>
      </table>
    </div>

    <div class="paginationContainer">
      <pagination-controls id="producersPagination" autoHide="true" responsive="true" previousLabel="Anterior"
        nextLabel="Siguiente" (pageChange)="producerPage= $event"></pagination-controls>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-danger" (click)="d('button click'); producerPage = 1;" name="closeModal">
      Cancelar
    </button>
  </div>
</ng-template>