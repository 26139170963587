import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

//Environment
import { environment } from '../../environments/environment';

// Models
import { CocoaTypeModel } from '../models/cocoa-type.model';

@Injectable({
  providedIn: 'root'
})
export class CocoaTypesService {
  cocoaRoute = environment.routes.api + environment.routes.cocoa_types;

  constructor(
    private http: HttpClient
  ) {
  }

  createCocoaType(cocoa: CocoaTypeModel) {
    return new Promise((resolve, reject) => {
      this.http.post(this.cocoaRoute, { cocoa_type: cocoa }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  getAll(params: {} = {}) {
    const options = {
      params: params,
      headers: this.getHeaders()
    }

    return new Promise((resolve, reject) => {
      this.http.get(this.cocoaRoute, options)
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  updateCocoaType(cocoaType: CocoaTypeModel) {
    return new Promise((resolve, reject) => {
      this.http.put(
        `${this.cocoaRoute}/${cocoaType['id']}`, { cocoa_type: cocoaType }, { headers: this.getHeaders() }
      ).toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  deleteCocoaType(cocoaTypeId: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${this.cocoaRoute}/${cocoaTypeId}`, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('yacao:app:token'),
        'user_id': localStorage.getItem('yacao:app:user_id')
      }
    );
  }

}
