import { CertificateModel } from './certificate.model';

export class AddonModel {
  id: number;
  season_id: number;
  name: string;
  amount: number;
  description: Text;
  active: boolean = true;
  certificate_id: number;
  certificate: CertificateModel;
}
