

<app-navbar></app-navbar>
<div class="row">
  <div class="col">
    <div class="filtersContainer">
      <div class="row">
        <div class="col-3">
          <button type="button" class="btn btn-outline-dark btn-block" (click)="isCollapsed= !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="collapsibleFilters">
            <!-- <mat-icon class="position-relative top-5 m-0">{{ isCollapsed ? 'visibility_off' : 'visibility' }}</mat-icon> -->
            <i class="{{ isCollapsed ? 'fa fa-eye-slash' : 'fa fa-eye' }}"></i>
            {{ isCollapsed ? 'Ocultar Filtros' : 'Mostrar Filtros' }}
          </button>
        </div>

        <div class="col-3 offset-6">
          <button class="btn btn-info btn-block" (click)="openForm(null)">
            <!-- <mat-icon class="position-relative top-5 font-weight-bold">add</mat-icon> -->
            <i class="fa fa-plus"></i>
            Nuevo grupo de entrega
          </button>
        </div>
      </div>

      <div class="" id="collapsibleFilters" [ngbCollapse]="!isCollapsed">
        <div class="row">

          <div class="col-3">
            <label>
              Certificado Orgánico
            </label>

            <ng-select [items]="listCertificates" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="name" bindValue="id" name="deliveryGroupCertificate"
              placeholder="Certificado" [multiple]="false"
              [(ngModel)]="filters['q[delivery_groups_list_certificates_certificate_id_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              Productor
            </label>

            <ng-select [items]="filterProducers" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="full_name" bindValue="id" name="producersName"
              placeholder="Nombre del productor" [multiple]="false" [(ngModel)]="filters['q[producers_id_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              Coordinador
            </label>

            <ng-select [items]="filterCoordinators" [searchable] [hideSelected]="true"
              notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="full_name" bindValue="id"
              name="coordinatorName" placeholder="Nombre del coordinador" [multiple]="false"
              [(ngModel)]="filters['q[coordinator_id_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              Grupo
            </label>

            <ng-select [items]="deliveryGroupNames" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" name="deliveryGroupName" placeholder="Nombre"
              [multiple]="false" [(ngModel)]="filters['q[name_cont]']"></ng-select>
          </div>

          <div class="col-3">
            <label>
              Certificado Social
            </label>

            <ng-select [items]="boolCertificates" [searchable] [hideSelected]="true" notFoundText="No hay coincidencias"
              loadingText="Buscando" bindLabel="name" bindValue="id" name="deliveryGroupBoolCertificate"
              placeholder="Certificado Social" [multiple]="false"
              [(ngModel)]="filters['q[delivery_groups_bool_certificates_certificate_id_eq]']">
            </ng-select>
          </div>

          <div class="col-3">
            <label>
              Activo
            </label>

            <ng-select [items]="yesOrNot" bindLabel="name" bindValue="value" [searchable] [hideSelected]="true"
              notFoundText="No hay coincidencias" loadingText="Buscando" name="deliveryGroupIsActive"
              placeholder="Activo" [multiple]="false" [(ngModel)]="filters['q[active_eq]']"></ng-select>
          </div>
        </div>

        <div class="row mt-3 align-bottom">
          <div class="col-3 offset-6 d-flex align-items-end">
            <button type="button" class="btn btn-outline-dark btn-block"
              (click)="getDeliveryGroups(1, itemsPerPage, filters, 'xlsx')">
              <div class="load-container" *ngIf="loadingExcel">
                <div class="loader-green d-inline-block"></div>
              </div>
              <i class="fa fa-download" *ngIf="!loadingExcel"></i>
              Descargar excel
            </button>
          </div>
          <div class="col-3 d-flex align-items-end">
            <button type="button" class="btn btn-success btn-block"
              (click)="getDeliveryGroups(1, itemsPerPage, filters)">
              <i class="fa fa-filter"></i>
              Filtrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<app-delivery-group-form 
[deliveryGroups]="deliveryGroups" 
[totalItems]="totalItems" 
[itemsPerPage]="itemsPerPage" 
[currentPage]="currentPage"
(saveDeliveryGroup)="setDeliveryGroups($event)"></app-delivery-group-form>
<app-delivery-group-producers></app-delivery-group-producers>


<!-- Table with deliveryGroup datas -->
<div *ngIf="!loading">
  <table class="table sticky-yacao green table-striped table-hover table-sm">
    <thead>
      <tr>
        <th>#</th>
        <th>Nombre</th>
        <th>Coordinador</th>
        <th>Productores</th>
        <th>Activo</th>
        <th>BSS</th>
        <th>UE/NOP</th>
        <th>Trans.</th>
        <th>FFL</th>
        <th>FLO</th>
        <th>UTZ</th>
        <th>Acciones</th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let deliveryGroup of deliveryGroups | paginate: { id:'deliveryGroupsPagination', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i= index;">
        <td>
          {{globals.getAbsoluteIndex(itemsPerPage, currentPage, i) + 1}}
        </td>

        <td>
          {{deliveryGroup.name}}
        </td>

        <td>
          {{deliveryGroup.coordinator?.name}} {{deliveryGroup.coordinator?.surname}}
        </td>

        <td>
          <button class="btn btn-sm btn-info" (click)="openProducerModal(deliveryGroup)">
            Ver detalles
          </button>
        </td>

        <td>
          <mat-icon class="font-weight-bold">{{ deliveryGroup.active ? 'check' : 'close' }}</mat-icon>
        </td>

        <td>
          {{getQuotaOf(deliveryGroup, 'biosuisse')}}
        </td>

        <td>
          {{getQuotaOf(deliveryGroup, 'ue/nop')}}
        </td>

        <td>
          {{getQuotaOf(deliveryGroup, 'trans')}}
        </td>

        <td>
          <mat-icon class="font-weight-bold">{{ haveCertificate(deliveryGroup, 'ffl') ? 'check' : 'close' }}
          </mat-icon>
        </td>
        <td>
          <mat-icon class="font-weight-bold">{{ haveCertificate(deliveryGroup, 'flo') ? 'check' : 'close' }}
          </mat-icon>
        </td>
        <td>
          <mat-icon class="font-weight-bold">{{ haveCertificate(deliveryGroup, 'utz') ? 'check' : 'close' }}
          </mat-icon>
        </td>

        <td>
          <div class="nav-item dropdown" ngbDropdown>
            <a class="btn btn-success btn-sm dropdown-toggle white" ngbDropdownToggle>
              Selecciona acción
            </a>

            <div class="dropdown-menu" aria-labelledby="admin" ngbDropdownMenu>
              <button class="btn btn-primary btn-sm dropdown-item"
                (click)="openForm(deliveryGroup)">
                <mat-icon class="position-relative top-5 text-primary">edit</mat-icon>
                Editar
              </button>

              <button class="btn btn-sm dropdown-item"
                (click)="deactivateActivateDeliveryGroup(deliveryGroup)">
                <mat-icon class="position-relative top-5 text-danger">delete</mat-icon>
                {{deliveryGroup.active ? 'Desactivar' : 'Activar'}}
              </button>

              <button class="btn btn-sm dropdown-item" (click)="generatePDF(deliveryGroup.id)">
                <mat-icon class="position-relative top-5 text-secundary">picture_as_pdf</mat-icon>
                Ver PDF
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="loading" *ngIf="loading">
  <ng-template #loadingTemplate></ng-template>
  <ngx-loading [show]="loading" [template]="loadingTemplate"></ngx-loading>
</div>

<div class="paginationContainer" *ngIf="!loading">
  <pagination-controls id="deliveryGroupsPagination" autoHide="true" responsive="true" previousLabel="Anterior"
    nextLabel="Siguiente" (pageChange)="getDeliveryGroups($event, itemsPerPage, filters)"></pagination-controls>
</div>