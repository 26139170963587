import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

//Environment
import { environment } from '../../environments/environment';

// Models
import { ExportationModel } from '../models/exportation.model';

@Injectable({
  providedIn: 'root'
})
export class ExportationService {
  exportationRoute = environment.routes.api + environment.routes.exportations;

  constructor(private http: HttpClient) { }

  getExportations(page: number = 1, per_page: number = 25, parameters: {} = {}) {
    let params = {
      'q[used_eq]': 'true',
      page: page.toString(),
      per_page: per_page.toString()
    };

    params = Object.assign(params, parameters);

    let options = {
      headers: this.getHeaders(),
      params: params
    };

    options = Object.assign(options, { responseType: params['format'] === 'json' ? 'json' : 'arraybuffer' });

    return new Promise((resolve, reject) => {
      this.http
        .get(this.exportationRoute, options)
        .toPromise()
        .then(
          res => {
            if (params.hasOwnProperty('format') && params['format'] === 'xlsx') {
              resolve(res);
            } else {
              res['success'] ? resolve(res) : reject(res);
            }
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  getExportation(id: number, format: string = 'json') {
    let options = {
      headers: this.getHeaders(),
      params: { format: format }
    };

    options = Object.assign(options, { responseType: (format === 'xlsx' ? 'arraybuffer' : format) });

    return new Promise(
      (resolve, reject) => {
        this.http
          .get(`${this.exportationRoute}/${id}`, options)
          .toPromise()
          .then(
            res => {
              if (format === 'xlsx') {
                resolve(res);
              } else {
                res['success'] ? resolve(res) : reject(res);
              }
            }
          )
          .catch(
            err => {
              reject(err);
            }
          );
      });
  }

  createExportation(exportation: ExportationModel) {
    return new Promise((resolve, reject) => {
      this.http.post(this.exportationRoute, { exportation: exportation }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  updateExportation(exportation: ExportationModel) {
    return new Promise((resolve, reject) => {
      this.http.put(`${this.exportationRoute}/${exportation['id']}`, { exportation: exportation }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  deleteExportation(id: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${this.exportationRoute}/${id}`, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  getTickets() {
    return new Promise(
      (resolve, reject) => {
        this.http
          .get(`${this.exportationRoute}/tickets`, { headers: this.getHeaders() })
          .toPromise()
          .then(
            (response) => {
              response['success'] ? resolve(response) : reject(response);
            }
          )
          .catch(error => reject(error));
      }
    );
  }

  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('yacao:app:token'),
        'user_id': localStorage.getItem('yacao:app:user_id')
      }
    );
  }
}
