<!-- MODAL WITH FORM TO UPDATE CONTRACT -->
<ng-template #updateTicketModal let-modal let-update="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'pages.tickets_forms.contracts_edit_form' | translate}}{{ ticket.talbook_sheet }}
    </h4>

    <button type="button" class="close" aria-label="Close" (click)="update('Cross click')">
      <span aria-hidden="true">
        &times;
      </span>
    </button>
  </div>

  <div class="modal-body">
    <form #Form="ngForm">
      <!-- FIRST ROW OF THE MODAL --- FOR THE IMAGES -->
      <div class="row">
        <div class="col-4">
          <div class="col-md-12">
            <h3>{{ 'pages.tickets_forms.contract_image' | translate}}</h3>

            <div class="col-12 mt-2">
              <button class="btn btn-danger btn-sm btn-block" (click)="removeImage(ticket, 'ticket')"
                [disabled]="((globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor) && (ticket.billed && ticket.finished))
              || (!globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor && (ticket.billed || ticket.validated || ticket.finished))">
                Quitar imagen
              </button>
            </div>

            <div class="col-12 mt-2">
              <button class="btn btn-success btn-sm btn-block"
                [disabled]="((globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor) && ticket.billed)
              || (!globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor && (ticket.billed || ticket.reviewed))">
                Subir imagen
                <input type="file" name="receiptImageUpload" class="upload-image-input" accept="image/*"
                  (change)="setImage($event, 'receipt')">
              </button>
            </div>

            <div class="col-12 mt-2 mb-2" appNotRightClick>
              <!-- <img [src]="ticket.receipt.url" style="max-width: 100%;" /> -->
              <angular-image-viewer *ngIf="ticket.receipt && ticket.receipt.url" [src]="[ticket.receipt.url]"
                [(index)]="receiptIndex" [config]="{ wheelZoom: true }"></angular-image-viewer>

              <angular-image-viewer *ngIf="!ticket.receipt && ticket.receipt_content"
                [src]="[ticket.receipt_content.receipt_content]" [(index)]="receiptIndex"
                [config]="{ wheelZoom: true }"></angular-image-viewer>
            </div>
          </div>
        </div>

        <div class="col-4">
          <!-- SECOND ROW OF THE MODAL --- FOR THE DATES -->
          <div class="row" *ngIf="ticket.ticket_type_cd === contractType.contracts">
            <div class="form-group col-md-4">
              <label for="startDateId">
                {{ 'pages.table_headers.start_date' | translate}}
              </label>

              <input id="startDateId" class="form-control" [(ngModel)]="ticket.temp_contract_starts"
                placeholder="Fecha de recogida" name="startedDate" ngbDatepicker #startedDate="ngbDatepicker"
                (click)="startedDate.toggle()"
                [disabled]="ticket.validated ||
                  (!ticket.validated && !globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor)" />

              <div *ngIf="!startsDateIsInSeason(ticket)" class="invalid-tooltip">
                <div>La fecha está fuera de la temporada.</div>
              </div>
            </div>

            <div class="form-group col-md-4">
              <label for="finishedDateId">
                {{ 'pages.table_headers.end_date' | translate}}
              </label>

              <input id="finishedDateId" class="form-control" [(ngModel)]="ticket.temp_contract_ends"
                placeholder="Fecha de recogida" name="finishedDate" ngbDatepicker #finishedDate="ngbDatepicker"
                (click)="finishedDate.toggle()" [class.is-invalid]="!endsDateIsInSeason(ticket)"
                [disabled]="ticket.validated || 
                  (!ticket.validated && !globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor)" />

              <div *ngIf="!endsDateIsInSeason(ticket)" class="invalid-tooltip">
                <div>La fecha está fuera de la temporada.</div>
              </div>
            </div>

            <div class="form-group col-md-4">
              <label for="collectDateId">
                {{ 'pages.table_headers.creation_date' | translate}}
              </label>

              <input id="collectDateId" class="form-control" [(ngModel)]="ticket.temp_contract_collected_date"
                placeholder="Fecha de recogida" name="collectedDate" ngbDatepicker #collectedDate="ngbDatepicker"
                (click)="collectedDate.toggle()"
                [disabled]="ticket.validated ||
                  (!ticket.validated && !globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor)" />
            </div>
          </div>

          <div class="row" *ngIf="ticket.ticket_type_cd === contractType.direct_purchase">
            <div class="form-group col-md-12">
              <label for="startDateId">
                {{ 'pages.table_headers.creation_date' | translate}}
              </label>

              <input id="startDateId" class="form-control" [(ngModel)]="ticket.temp_contract_starts"
                placeholder="Fecha de recogida" name="startedDate" ngbDatepicker #startedDate="ngbDatepicker"
                (click)="startedDate.toggle()"
                [disabled]="ticket.validated || (ticket.finished) ||
                  (!ticket.validated && !globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor)" />

              <div *ngIf="!dateIsInSeasonRange(ticket)" class="invalid-tooltip">
                <div>La fecha está fuera de la temporada.</div>
              </div>
            </div>
          </div>

          <!-- THIRD ROW OF THE MODAL --- FOR THE COCOA DATA -->
          <div class="row">
            <div class="form-group col-md-4">
              <label for="ticketCertificateId">
                {{ 'pages.tickets_forms.cocoa_type' | translate}}
              </label>

              <ng-select id="ticketCertificateId" [items]="certificates" [searchable] [clearSearchOnAdd]
                notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name"
                [(ngModel)]="ticket.certificate" name="certificateOfList"
                placeholder="Selecciona el tipo de certificado" [compareWith]="compareFn"
                (change)="setAddon($event, ticket)" [clearable]=false [multiple]="false"
                [disabled]="ticket.reviewed || ticket.billed || (ticket.validated) || (ticket.ticket_type_cd === contractType.direct_purchase && ticket.finished)">
              </ng-select>
            </div>

            <div class="form-group col-md-4" *ngIf="globals.currentUserRolesAsBooleanObject.admin">
              <label for="ticketCocoaTypeId">
                {{ 'pages.tickets_forms.cocoa_type' | translate}}
              </label>

              <ng-select id="ticketCocoaTypeId" [items]="cocoaTypes" [searchable] [clearSearchOnAdd]
                [disabled]="ticket.reviewed || ticket.billed || (ticket.validated)" notFoundText="No hay coincidencias"
                loadingText="Buscando" bindLabel="name" [(ngModel)]="ticket.cocoa_type" name="cocoaType"
                placeholder="Selecciona el tipo de cacao" [compareWith]="compareFn" [multiple]="false" [clearable]=false
                [disabled]="(ticket.ticket_type_cd === contractType.direct_purchase && ticket.finished)">
              </ng-select>
            </div>


            <div class="form-group col-md-4" *ngIf="!globals.currentUserRolesAsBooleanObject.admin">
              <label for="ticketCocoaTypeId">
                {{ 'pages.tickets_forms.cocoa_type' | translate}}
              </label>

              <ng-select id="ticketCocoaTypeId" [items]="cocoaTypes" [searchable] [clearSearchOnAdd] disabled
                notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name"
                [(ngModel)]="ticket.cocoa_type" name="cocoaType" placeholder="Selecciona el tipo de cacao"
                [compareWith]="compareFn" [multiple]="false" [clearable]="false"
                [disabled]="(ticket.ticket_type_cd === contractType.direct_purchase && ticket.finished)">
              </ng-select>
            </div>

            <div class="form-group col-md-4">
              <label for="ticketCocoaQualityId">
                {{ 'pages.tickets_forms.cocoa_quality' | translate}}
              </label>

              <ng-select id="ticketCocoaQualityId" [items]="cocoaQualities" [searchable] [clearSearchOnAdd]
                [disabled]="ticket.reviewed || ticket.billed || !isDrySelected(ticket) || (ticket.validated)"
                notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name" bindValue="id"
                [(ngModel)]="ticket.cocoa_quality_id" name="cocoaQuality" placeholder="Calidad del cacao"
                [compareWith]="compareFn" [multiple]="false"
                [disabled]="(ticket.ticket_type_cd === contractType.direct_purchase && ticket.finished)">
              </ng-select>
            </div>
          </div>

          <!-- FOURTH ROW OF THE MODAL --- DATA OF BUYER AND SELLER -->
          <div class="row">
            <div class="form-group col-md-6" *ngIf="globals.currentUserRolesAsBooleanObject.admin">
              <label for="ticketReceiverId">
                {{ 'pages.table_headers.buyer' | translate}}
              </label>

              <ng-select id="ticketReceiverId" [items]="buyers" [searchable] [clearSearchOnAdd]
                [disabled]="ticket.reviewed || ticket.billed || (ticket.validated || ticket.unload_accepted) || (ticket.ticket_type_cd === contractType.direct_purchase && ticket.finished)"
                notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name"
                [(ngModel)]="ticket.original_receiver" name="receiver" placeholder="Selecciona el comprador"
                [compareWith]="compareFn" [multiple]="false">
              </ng-select>
            </div>

            <div class="form-group col-md-6" *ngIf="!globals.currentUserRolesAsBooleanObject.admin">
              <label for="ticketReceiverId">
                {{ 'pages.table_headers.buyer' | translate}}
              </label>

              <ng-select id="ticketReceiverId" [items]="buyers" [searchable] [clearSearchOnAdd] disabled
                notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name"
                [(ngModel)]="ticket.original_receiver" name="receiver" placeholder="Selecciona el comprador"
                [compareWith]="compareFn" [multiple]="false"
                [disabled]="(ticket.ticket_type_cd === contractType.direct_purchase && ticket.finished)">
              </ng-select>
            </div>

            <div class="form-group col-md-6">
              <label for="ticketSupplierId">
                {{ 'pages.table_headers.supplier' | translate}}
              </label>

              <ng-select id="ticketSupplierId" [items]="suppliers" [searchable] [clearSearchOnAdd]
                [disabled]="ticket.reviewed || ticket.billed || (ticket.validated || ticket.unload_accepted)"
                notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="full_name_and_diceros_id"
                [(ngModel)]="ticket.supplier" name="supplier" placeholder="Selecciona el suplidor"
                [compareWith]="compareFn" [multiple]="false"
                [disabled]="(ticket.ticket_type_cd === contractType.direct_purchase && ticket.finished)">
              </ng-select>
            </div>
          </div>

          <!-- FIFTH ROW --- WEIGHT -->
          <div class="row">
            <div class="form-group col-md-3" *ngIf="ticket.ticket_type_cd === contractType.contracts">
              <label for="contractWeight">
                {{ 'pages.tickets_forms.weight_in_kgs' | translate}}
              </label>

              <input type="number" class="form-control" id="contractWeight" [(ngModel)]="ticket.contract_weight"
                placeholder="ej: 2000" name="weightPerBuyer"
                [disabled]="ticket.nulled || !ticket.completed" />
            </div>

            <div class="form-group col-md-3" *ngIf="ticket.ticket_type_cd === contractType.contracts">
              <label for="contractWeightQQ">
                {{ 'pages.tickets_forms.weight_in_qqs' | translate}}
              </label>
              <input type="number" id="contractWeightQQ" class="form-control" [value]="(ticket.contract_weight / 50)"
                readonly>
            </div>

            <div class="form-group col-md-12" *ngIf="ticket.ticket_type_cd === contractType.direct_purchase">
              <div class="row">
                <div class="form-group col-6 d-inline-block">
                  <label for="weightPerSupplierInput">
                    {{ 'pages.tickets_forms.supplier_weight' | translate}}
                  </label>

                  <input type="number" class="form-control" id="weightPerSupplierInput"
                    [(ngModel)]="ticket.supplier_weight" placeholder="ej: 2000" name="weightPerSupplier"
                    [disabled]="!ticket.reviewed || ticket.supplier.intermediary || ticket.billed || ticket.finished" />
                </div>

                <div class="form-group col-md-6 d-inline-block">
                  <label class="d-block" for="bagArrayLenght">
                    {{ 'pages.tickets_forms.bags' | translate}}{{ticket['bags_info']?.length}}
                  </label>
                  <button type="button" class="btn btn-info" (click)="openPackageForm(ticket)">Detalle de
                    bultos</button>
                </div>
              </div>

              <!-- <div class="row">
                <label class="col-4" for="bagArrayLenght">
                  Bultos: {{ticket['bags_info']?.length}}
                </label>
                <label class="col-6" for="bagArrayWeight">
                  Peso total: {{ticket['receiver_weight']}} kg
                </label>
                <button class="col-2 btn" (click)="addNewBag()"
                  [disabled]="ticket.reviewed || ticket.billed
                    || (ticket.unloading_state_cd !== ticketUnloadState.not_unloaded && ticket.unloading_state_cd !== ticketUnloadState.in_center)">
                  <i class="fa fa-plus"></i>
                </button>
                <div>
                  <table>
                    <tr class="row">
                      <th class="col-4">Codigo</th>
                      <th class="col-6">Peso</th>
                      <th class="col-2"></th>
                    </tr>
                    <tr class="row" *ngFor="let bag of ticket.bags_info; let i= index">
                      <th class="col-4"><label>{{bag.code}}</label></th>
                      <th class="col-6"><input type="number" class="form-control" [value]="bag.weight" name="bagWeight"
                          (input)="updateWeight(i, $event.target.value)"
                          [disabled]="ticket.reviewed || ticket.billed
                    || (ticket.unloading_state_cd !== ticketUnloadState.not_unloaded && ticket.unloading_state_cd !== ticketUnloadState.in_center)">
                      </th>
                      <th class="col-2"><button class="btn" (click)="deleteBag(i)"
                        [disabled]="ticket.reviewed || ticket.billed
                        || (ticket.unloading_state_cd !== ticketUnloadState.not_unloaded && ticket.unloading_state_cd !== ticketUnloadState.in_center)">
                          <i class="fa fa-trash"></i>
                        </button></th>
                    </tr>
                  </table>
                </div>
              </div> -->

            </div>
          </div>

          <div class="row">
            <div class="form-group col-md-4">
              <label for="contractPrice">
                {{ 'pages.tickets_forms.price_per_kg' | translate}}
              </label>

              <input type="number" class="form-control" id="contractPrice" [(ngModel)]="ticket.contract_price"
                placeholder="ej: 1000" name="contractPrice"
                [disabled]="(!globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor) ||
                (ticket.nulled || !ticket.certificate_id || !ticket.cocoa_type_id || ticket.validated) || (ticket.ticket_type_cd === contractType.direct_purchase && ticket.finished)" />
            </div>

            <div class="form-group col-md-4">
              <label for="contractPriceQQ">
                {{ 'pages.tickets_forms.price_per_qq' | translate}}

              </label>

              <input type="number" class="form-control" id="contractPriceQQ" [value]="(ticket.contract_price * 50)"
                readonly />
            </div>

            <div class="col-4 d-inline-block" *ngIf="ticket.ticket_type_cd === contractType.direct_purchase">
              <div class="form-gorup">
                <label for="estimateValue">Valor estimado</label>

                <input id="estimateValue" type="text" class="form-control" readonly
                  [value]="globals.truncateDecimals(ticket.estimated_value, 2)">

              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-4 d-inline-block">
              <label for="contractCommissionKg">
                {{ 'pages.tickets_forms.commission_per_kg' | translate}}
              </label>

              <input type="number" class="form-control" id="contractCommissionKg" [(ngModel)]="ticket.commission_price"
                placeholder="Comision" name="contractCommision"
                [disabled]="ticket.nulled || !ticket.certificate_id || !ticket.cocoa_type_id || ticket.validated || (ticket.ticket_type_cd === contractType.direct_purchase && ticket.finished)" />
            </div>
            <div class="col-4 d-inline-block">
              <label for="contractCommissionQq">
                {{ 'pages.tickets_forms.commission_per_qq' | translate}}
              </label>

              <input type="number" class="form-control" id="contractCommissionQq" placeholder="Comision"
                name="contractCommision" [value]="(ticket.commission_price * 50)" readonly />
            </div>

            <div class="col-4 d-inline-block">
              <label for="addonPrice">
                {{ 'pages.tickets_forms.incentive' | translate}}
              </label>

              <input type="number" class="form-control" id="addonPrice"
                (input)="changeAddonPrice($event.target.value, ticket)" placeholder="Incentivo" name="addonPrice"
                [disabled]="ticket.nulled || !ticket.certificate_id || ticket.validated ||  (ticket.ticket_type_cd === contractType.direct_purchase && ticket.finished)"
                [value]="ticket.addon_price" />
            </div>
          </div>

          <!-- SIXTH ROW --- CHECKBOX -->
          <div class="row">
            <div class="form-group col-3">
              <mat-checkbox [(ngModel)]="ticket.nulled" name="ticketNull"
                [disabled]="ticket.reviewed || ticket.billed || (ticket.validated || ticket.unload_accepted) || (ticket.ticket_type_cd === contractType.direct_purchase && ticket.finished)"
                (change)="nulledChanged()">
                Nulo
              </mat-checkbox>
            </div>
            <div class="form-group col-3">
              <mat-checkbox [(ngModel)]="ticket.addon_toggle" name="ticketAddon"
                [disabled]="!ticket.supplier?.trusted || (ticket.validated) || (ticket.ticket_type_cd === contractType.direct_purchase && ticket.finished)"
                (change)="recalculatePrice(ticket.addon_toggle, ticket)">
                Incentivo
              </mat-checkbox>
            </div>
            <div class="form-group col-3">
              <mat-checkbox [(ngModel)]="ticket.commission_toggle" name="ticketCommission"
              [disabled]="(ticket.validated) ||  (ticket.ticket_type_cd === contractType.direct_purchase && ticket.finished)">
                {{ 'pages.tickets_forms.commission_checkbox' | translate}}
              </mat-checkbox>
            </div>
            <div class="form-group col-3">
              <mat-checkbox [(ngModel)]="ticket.special_price" name="ticketSpecialPrice"
              [disabled]="(ticket.validated) ||  (ticket.ticket_type_cd === contractType.direct_purchase && ticket.finished)">
                {{ 'pages.tickets_forms.special_price_checkbox' | translate}}
              </mat-checkbox>
            </div>
          </div>

          <!-- SEVENTH ROW --- COMMENTS -->
          <div class="row">
            <div class="form-group col-md-12">
              <label for="ticketComments">
                {{ 'pages.tickets_forms.comments' | translate}}
              </label>

              <textarea class="form-control" id="ticketComments" [(ngModel)]="ticket.comments" rows="5"
                name="ticketComments"
                [disabled]="ticket.reviewed || ticket.billed || (ticket.validated || ticket.unload_accepted) || (ticket.ticket_type_cd === contractType.direct_purchase && ticket.finished)"></textarea>
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="col-md-12">
            <h3>{{ 'pages.tickets_forms.authorization_image' | translate}}</h3>

            <div class="col-12 mt-2">
              <button class="btn btn-danger btn-sm btn-block" (click)="removeImage(ticket, 'authorization')"
                [disabled]="(ticket.ticket_type_cd === contractType.direct_purchase && ticket.finished) || ((globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor) && ticket.billed)
              || (!globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor && (ticket.billed || ticket.validated))">
              {{ 'pages.tickets_forms.erase_image' | translate}}
              </button>
            </div>

            <div class="col-12 mt-2">
              <button class="btn btn-success btn-sm btn-block"
                [disabled]="((globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor) && ticket.billed)
              || (!globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor && (ticket.billed || ticket.validated))">
                Subir imagen
                <input type="file" name="authImageUpload" class="upload-image-input" accept="image/*"
                  (change)="setImage($event, 'authorization')">
              </button>
            </div>

            <!-- <div class="col-12 mt-2 mb-2">
              <img [src]="ticket.authorization.url" style="max-width: 100%;" />
            </div> -->
            <div class="col-12 mt-2 mb-2">
              <!-- <img [src]="ticket.receipt.url" style="max-width: 100%;" /> -->
              <angular-image-viewer *ngIf="ticket.authorization && ticket.authorization.url"
                [src]="[ticket.authorization.url]" [(index)]="authorizationIndex" [config]="{ wheelZoom: true }">
              </angular-image-viewer>

              <angular-image-viewer *ngIf="!ticket.authorization && ticket.authorization_content"
                [src]="[ticket.authorization_content.authorization_content]" [(index)]="authorizationIndex"
                [config]="{ wheelZoom: true }">
              </angular-image-viewer>

            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button class="btn btn-warning" [disabled]="sending || ticket.contract_have_invoices || (ticket.ticket_type_cd === contractType.direct_purchase && !ticket.unload_accepted)
    || (ticket.ticket_type_cd === contractType.direct_purchase && !ticket.finished) " (click)="validateTicket(ticket)">
      {{ ticket.validated ? 'Desvalidar' : 'Validar'}}
    </button>

    <button class="btn btn-primary" (click)="setAllPriceOptions(ticket)" [disabled]="sending || ticket.billed || (isNulledChanged && ticket.agreemen_diceros_id) ||
    (ticket.ticket_type_cd === contractType.direct_purchase && ticket.finished)">
      Guardar
    </button>

    <button class="btn btn-danger" (click)="update('button click')" name="closeModal">
      {{ 'pages.tickets_forms.button_cancel' | translate}}
    </button>
  </div>
</ng-template>

<app-alert-modal (reason)="alertModalReason($event)"></app-alert-modal>
<app-contract-package-form (outputTicket)="setTicket($event)"></app-contract-package-form>