import { Component, OnInit, ViewChild } from '@angular/core';

// Services
import { GlobalsService } from 'src/app/services/globals.service';
import { AuthService } from 'src/app/services/auth.service';
import { TicketService } from 'src/app/services/ticket.service';
import { DownloadFileService } from 'src/app/services/download-file.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { TranslationEditModalComponent } from '../modals/translation-edit-modal/translation-edit-modal.component';
import { Subscription } from 'rxjs';
import { TranslationService } from 'src/app/services/translation.service';

import { SettingFormComponent } from 'src/app/pages/settings/setting-form/setting-form.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @ViewChild(TranslationEditModalComponent) private translation: TranslationEditModalComponent;
  @ViewChild(SettingFormComponent) private _settingComponent: SettingFormComponent;

  isNavbarCollapsed: boolean = false;
  public isVisible: boolean = false;

  constructor(
    public globals: GlobalsService,
    private auth: AuthService,
    private _ticketService: TicketService,
    private _downloadFileService: DownloadFileService,
    private _translationService: TranslationService,
    public location: Location,
    public translate: TranslateService) { 
    }

  ngOnInit(): void {
  }

  logout() {
    this.auth.logout().catch(err => console.error(err));
  }


  getReportOf(kind: string = 'addons') {
    this._ticketService.getReports(kind)
      .then(
        res => {
          this._downloadFileService.downloadFile(res, kind, 'application/vnd.ms-excel');
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }

  isCurrentPath(path: string): boolean {
    return this.location.isCurrentPathEqualTo(path);
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  enterLanguageEditMode() {
    this.isVisible = !this.isVisible;
  }

  openTranslationForm() {
    // Esto funciona pero solamente cambia la traducción localmente, no modifica el archivo i18n, cuando está false a true aparentemente no hace nada.
    

    // this.subscription = this.translate.get('commons.navbar.purchase_agreements').subscribe(res => {
    //   console.log(res, "ANTES");
    // });
    // this.subscription.unsubscribe();

    // this.translate.set('commons.navbar.purchase_agreements', 'Nuevo Valor', 'es');

    // this.translate.setTranslation('es', {'commons.navbar.purchase_agreements':'Nuevo Valor'}, false);
    // this.subscription = this.translate.get('commons.navbar.purchase_agreements').subscribe( res => {
    //   console.log(res);
    // });
    // this.subscription.unsubscribe();

    // P.commons.navbar.purchase_agreements = 'Nuevo Valor';
    // fs.writeFile("es.json", JSON.stringify(P, null, 4), (err) => {
    //     err && console.log(err);
    //     console.log('cambiando el es.json');
    // })
    this.translation.openLEM();
  }

  translateMode(): void {
    this._translationService.changeToTranslateMode();
  }

  editSettings(): void {
    this._settingComponent.openForm();
  }
}
