import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ConceptModel } from 'src/app/models/concept.model';
import { ConceptsService } from 'src/app/services/concepts.service';
import { NgbModalRef, NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-concept-form',
  templateUrl: './concept-form.component.html',
  styleUrls: ['./concept-form.component.scss']
})
export class ConceptFormComponent implements OnInit {
  @ViewChild('conceptForm', { static: true }) private modal: TemplateRef<any>;

  @Output() result: EventEmitter<ConceptModel> = new EventEmitter<ConceptModel>();

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };


  concept: ConceptModel = new ConceptModel();
  form: FormGroup;
  sending: boolean = false;

  constructor(
    private _conceptService: ConceptsService,
    private _modalService: NgbModal,
    private _notification: NotificationService,
    private _formBuilder: FormBuilder
  ) {

  }

  ngOnInit(): void {
  }

  onSubmit(concept: ConceptModel): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    concept = Object.assign(concept, this.form.getRawValue());
    concept.id ? this.update(concept) : this._create(concept);
  }

  openForm(concept: ConceptModel): void {
    this.concept = concept || new ConceptModel();
    this._buildForm(this.concept);
    this.openModal();
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  async update(concept: ConceptModel): Promise<void> {
    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    this._conceptService.updateConcept(concept)
      .then(
        (res: any) => {

          this.result.emit(res['concept']);
          this.sending = false;
          this._notification.clear();
          this._notification.success('Actualizado correctamente');

          this.closeModal();
        }
      ).catch(
        (err: any) => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al Actualizar.');
          }
        }
      )
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }

  private _buildForm(concept: ConceptModel): void {
    this.form = this._formBuilder.group({
      title: [concept.title, Validators.required],
      active: [concept.active]
    });
  }

  private async _create(concept: ConceptModel): Promise<void> {
    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    this._conceptService.createConcept(concept)
      .then(
        (res: any) => {

          this.result.emit(res['concept']);

          this.sending = false;
          this._notification.clear();
          this._notification.success('Creado correctamente');

          this.closeModal();
        }
      ).catch(
        (err: any) => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al crear.');
          }
        }
      )
  }

}
