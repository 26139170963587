import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

// Models
import { UserModel } from 'src/app/models/user.model';
import { ContactModel } from 'src/app/models/contact.model';
import { RoleModel } from 'src/app/models/role.model';
import { ZoneModel } from 'src/app/models/zone.model';
import { CenterModel } from 'src/app/models/center.model';

// Services
import { UserService } from 'src/app/services/user.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
  selector: 'app-users-form',
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsersFormComponent implements OnInit {
  @ViewChild('content', { static: true }) private modal;
  @Output() result: EventEmitter<UserModel[]> = new EventEmitter<UserModel[]>();

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };


  @Input() private currentPage: number;
  @Input() private itemsPerPage: number;
  @Input() private totalItems: number;
  @Input() private users: UserModel[] = new Array<UserModel>();
  @Input() private allUsers: UserModel[] = new Array<UserModel>();
  @Input() suppliers: ContactModel[] = new Array<ContactModel>();
  @Input() roles: RoleModel[] = new Array<RoleModel>();
  @Input() centers: CenterModel[] = new Array<CenterModel>();
  @Input() zones: ZoneModel[] = new Array<ZoneModel>();

  user: UserModel = new UserModel();
  sending: boolean = false;
  centersCanManager: CenterModel[];

  constructor(
    private _modalService: NgbModal,
    private _userService: UserService,
    public globals: GlobalsService,
    private _notification: NotificationService) { }

  ngOnInit() {
  }

  onSubmit(user: UserModel) {
    if (this.formValid(user)) {
      user['id'] ? this.updateUser(user) : this.createUser(user);
    }
  }

  openForm(user: UserModel) {
    this.user = _.cloneDeep(user) || new UserModel();
    if (this.user.center) {
      this.setCentersCanManager(this.user.center);
    }

    this.openModal();
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  compareFn(a, b) {
    if (a['diceros_id']) {
      return a['diceros_id'] === b;
    } else {
      return a['id'] === b;
    }
  }

  userHaveOnwPropertyRole(user, role): boolean {
    return !!_.find(user['roles'], { id: role['id'] });
  }

  userHaveOnwPropertyZone(user, zone) {
    return !!_.find(user['zones'], { id: zone['id'] });
  }

  userHaveOnwPropertyWorkCenter(user: UserModel, center: CenterModel): boolean {
    return !!_.find(user['work_centers'], { id: center.id});
  }

  addRemoveRoleToUser($event, role: RoleModel) {
    if ($event.checked) {
      this.user['roles'].push(role);
      // this.selectedRoles[role['alias']] = true;
    } else {
      const index = _.findIndex(this.user['roles'], { id: role['id'] });
      _.pullAt(this.user['roles'], index);
      // this.selectedRoles[role['alias']] = false;
    }
  }

  addRemoveWorkCenterToUser($event, center: CenterModel): void {
    if (!this.user.work_centers)  {
      this.user.work_centers = [];
    }

    if ($event.checked) {
      this.user.work_centers.push(center);
    } else {
      const index = _.findIndex(this.user.work_centers, { id: center.id});
      _.pullAt(this.user.work_centers, index);
    }

    this.user.work_center_ids = _.map(this.user.work_centers, 'id');
  }

  setCentersCanManager(event) {
    if (event) {
      this.centersCanManager = _.cloneDeep(this.centers);
      const index = _.findIndex(this.centersCanManager, { id: event.id});
      _.pullAt(this.centersCanManager, index);
    } else {
      this.centersCanManager = [];
    }

  }
  // removeUserZonesFromSelectable() {
  //   _.each(this.user['zones'], zone => {
  //     _.pullAt(this.enableZones, _.findIndex(this.enableZones, { id: zone['id'] }));
  //   });
  // }

  addRemoveZoneToUser($event, zone: ZoneModel) {
    if ($event.checked) {
      this.user['zones'].push(zone);
    } else {
      const index = _.findIndex(this.user['zones'], { id: zone['id'] });
      _.pullAt(this.user['zones'], index);
    }
  }

  emailValid(email: string): boolean {
    const rexpression = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return rexpression.test(email);
  }

  userHaveRole(user: UserModel, alias: string) {
    return _.find(user['roles'], { alias: alias });
  }

  updateUser(user: UserModel) {
    user = this.deleteUserIncorrectPropertiesAndAddIds(user);
    user['user_password'] = user['password'];

    if (this.checkIfUserCanCreate(user)) {
      this.sending = true;
      this._notification.info('Enviando datos...');
      this._notification.changeModalZIndexTo(1001);

      this._userService
        .updateUser(user)
        .then(
          res => {
            const index = _.findIndex(this.users, { id: user['id'] });
            this.users[index] = res['user'];
            this.result.emit(this.users);

            this._notification.clear();
            this._notification.success('Actualizado correctamente');
            this.closeModal();
            this.sending = false;
          }
        )
        .catch(
          err => {
            this.sending = false;
            console.error(err);
            this._notification.clear();
            this._notification.changeModalZIndexTo(1050);
            if (err.hasOwnProperty('errors')) {
              this._notification.error(err['errors'][0]);
            } else {
              this._notification.error('Se ha producido un error al actualizar');
            }
          }
        );
    } else {
      this._notification.error('No puedes modificar este usuario');
    }

  }

  private createUser(user: UserModel) {

    if (!_.find(this.allUsers, ['email', user['email']])) {
      this.sending = true;
      this._notification.info('Enviando datos...');
      this._notification.changeModalZIndexTo(1001);

      user = this.deleteUserIncorrectPropertiesAndAddIds(user);
      user['alias'] = user['email'].split('@')[0];
      user['user_password'] = user['password'];
      if (this.checkIfUserCanCreate(user)) {

        this._userService.createUser(user)
          .then(
            res => {

              this.totalItems += 1;
              if (this.currentPage === _.ceil(this.totalItems / this.itemsPerPage)) {
                this.users.push(res['user']);
              }
              this.result.emit(this.users);

              this._notification.clear();
              this._notification.success('Creado correctamente');
              this.closeModal();
              this.sending = false;
            }
          )
          .catch(
            err => {
              this.sending = false;
              console.error(err);
              this._notification.clear();
              this._notification.changeModalZIndexTo(1050);
              if (err.hasOwnProperty('errors')) {
                this._notification.error(err['errors'][0]);
                if (_.includes(err['errors'], 'diceros_error')) {
                  this.closeModal();
                }
              } else {
                this._notification.error('Se ha producido un error al crear');
              }
            }
          );

      } else {
        this._notification.error('No puedes crear este tipo de usuario');
      }

    } else {
      this._notification.error('Ya existe usuario con ese correo');
    }
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }

  private formValid(user: UserModel): boolean {
    let requiredFields = {
      email: false,
      emailValid: false,
      roles: false
    };

    if (!user.id) {
      requiredFields = Object.assign(requiredFields, {
        password: false
      });
    }

    if (this.userHaveRole(user, 'driver') || this.userHaveRole(user, 'unload_manager') || this.userHaveRole(user, 'manager')) {
      requiredFields = Object.assign(requiredFields, { center: false});
    }

    if (this.userHaveRole(user, 'driver') ||  this.userHaveRole(user, 'buyer')) {
      requiredFields = Object.assign(requiredFields, { zones: false });
    }

    requiredFields['emailValid'] = this.emailValid(user.email);

    _.each(requiredFields, (value, key) => {
      if (key !== 'emailValid') {
        requiredFields[key] = !!user[key];
      }

    });

    return Object.values(requiredFields).every(Boolean);

    // const emailValid = user['email'] ? this.emailValid(user['email']) : false;
    // if (!user['name']
    //   || (!user['id'] && !user['password'])
    //   || !emailValid
    //   || user['roles'].length < 1) {
    //   return false;
    // } else {
    //   return true;
    // }
  }

  private deleteUserIncorrectPropertiesAndAddIds(user: UserModel): UserModel {
    user['role_ids'] = [];
    const roles = { diver: false, manager: false, buyer: false, unload_manager: false };
    _.each(user['roles'], role => {
      roles[role['alias']] = true;
      user['role_ids'].push(role['id']);
    });

    if (!roles['driver'] && !roles['manager'] && !roles['unload_manager']) {
      delete user['center'];
      user['center_id'] = null;
    }

    if (!roles['driver'] && !roles['buyer']) {
      delete user['zones'];
      user['zone_ids'] = null;
    }

    if (user['center']) {
      user['center_id'] = user['center']['id'];
    } else {
      user['center_id'] = null;
    }

    user['zone_ids'] = _.map(user['zones'], 'id');

    return user;
  }

  private checkIfUserCanCreate(user: UserModel): boolean {
    if (this.globals.currentUserRolesAsBooleanObject.admin) {
      return true;
    } else {
      return !_.find(user['roles'], { alias: 'admin' });
    }
    // if (!!_.find(this.globals.currentUserRoles, { alias: 'admin' })) {
    //   return true;
    // } else if (!!_.find(user['roles'], { alias: 'admin' })) {
    //   return false;
    // } else {
    //   return true;
    // }
  }

}
