import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Models
import { ZoneModel } from "../models/zone.model";

// Environment
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ZoneService {
  zoneRoute = environment.routes.api + environment.routes.zones;

  constructor(private http: HttpClient) {
  }

  getZones(params: {} = {}) {
    const options = {
      params: params,
      headers: this.getHeaders()
    };

    return new Promise((resolve, reject) => {
      this.http.get(this.zoneRoute, options)
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  createZone(zone: ZoneModel) {
    return new Promise((resolve, reject) => {
      this.http.post(this.zoneRoute, { zone: zone }, { headers: this.getHeaders() })
        .subscribe(
          res => {
            res['success'] ? resolve(res) : reject(res);
          },
          err => {
            reject(err);
          }
        )
    });
    // return new Promise((resolve, reject) => {
    //   this.http.post(this.zoneRoute, { zone: zone }, { headers: this.getHeaders() })
    //     .toPromise()
    //     .then(
    //       res => {
    //         console.log(res);
    //         res['success'] ? resolve(res) : reject(res);
    //       }
    //     )
    //     .catch(
    //       err => {
    //         reject(err);
    //       }
    //     );
    // });
  }

  updateZone(zone: ZoneModel) {
    return new Promise(
      (resolve, reject) => {
        this.http
          .put(`${this.zoneRoute}/${zone['id']}`, { zone: zone }, { headers: this.getHeaders() })
          .toPromise()
          .then(
            res => {
              res['success'] ? resolve(res) : reject(res);
            }
          )
          .catch(
            err => {
              reject(err);
            }
          );
      }
    );
  }

  deleteZone(zoneId: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${this.zoneRoute}/${zoneId}`, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  getZoneBy(id: number) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.zoneRoute}/${id}`, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('yacao:app:token'),
        'user_id': localStorage.getItem('yacao:app:user_id')
      }
    );
  }
}
