import { TalbookTypeModel } from "./talbook-type.model";
import { UserModel } from "./user.model";
import { CenterModel } from "./center.model";

export class TalBookModel {
  id: number;
  talbook_type: TalbookTypeModel;
  talbook_type_id: number;
  created_by: UserModel;
  created_by_id: number;
  label: string;
  current_page: string;
  first_page: string;
  last_page: string;
  active = true;
  completed = false;
  receiver_id: number;
  receiver: UserModel;
  center_id: number;
  center: CenterModel;
  create_at: Date;
}
