<app-navbar></app-navbar>
<div class="row">
  <div class="col">
    <div class="filtersContainer">
      <div class="row">
        <div class="col-3">
          <h5 class="text-dark font-weight-light">
            Listado de roles
          </h5>
        </div>
        <div class="col-3 offset-3">
          <button type="button" class="btn btn-info btn-block" (click)="openForm(null);">
            <!-- <mat-icon class="position-relative top-5 font-weight-bold">add</mat-icon> -->
            <i class="fa fa-plus"></i>
            Nuevo rol
          </button>
        </div>
        <div class="col-3">
          <button type="button" class="btn btn-outline-dark btn-block"
            [disabled]="true">
            <i class="fa fa-download"></i>
            Descargar Excel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-role-form [roles]="roles" (result)="setRoles($event)"></app-role-form>

<div *ngIf="!loading">
  <table class="table sticky-yacao green table-striped table-hover table-sm">
    <thead>
      <tr>
        <th>#</th>
        <th>Nombre</th>
        <th>Activo</th>
        <th>Acciones</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let role of roles | paginate: { itemsPerPage: 15, currentPage: p }; let i= index;">
        <td>{{i + 1}}</td>
        <td>{{role.name}}</td>
        <td>
          <mat-icon class="font-weight-bold"> {{ role.active ? 'check' : 'close' }}</mat-icon>
        </td>
        <td>
          <div class="col-12">
            <div class="nav-item dropdown" ngbDropdown>
              <a class="btn btn-success btn-sm dropdown-toggle white" ngbDropdownToggle>
                Selecciona acción
              </a>

              <div class="dropdown-menu" aria-labelledby="admin" ngbDropdownMenu>
                <button class="btn btn-primary btn-sm dropdown-item" (click)="openForm(role)"
                  [disabled]="!disableEditAdminAliasIfCurrentNotAreAdmin(role)">
                  <!-- <mat-icon class="position-relative top-5 font-weight-bold">edit</mat-icon> -->
                  <i class="fa fa-download text-secundary"></i>
                  Editar
                </button>

                <button class="btn btn-danger btn-sm dropdown-item" (click)="deactivateActivateRole(role)"
                  [disabled]="!disableEditAdminAliasIfCurrentNotAreAdmin(role)">
                  <!-- <mat-icon class="position-relative top-5">delete</mat-icon> -->
                  <i class="fa fa-trash text-danger"></i>
                  {{ role.active? 'Desactivar' : 'Activar'}}
                </button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="loading" *ngIf="loading">
  <ng-template #loadingTemplate></ng-template>
  <ngx-loading [show]="loading" [template]="loadingTemplate"></ngx-loading>
</div>

<div class="paginationContainer" *ngIf="!loading">
  <pagination-controls autoHide="true" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
    (pageChange)="p= $event"></pagination-controls>
</div>