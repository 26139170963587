
<ng-template #content let-modal let-d="dismiss">
  <form #Form="ngForm" (ngSubmit)="Form.form.valid && onSubmit(certificate)">
    <div class="modal-header">
      <h4 *ngIf="!certificate.id" class="modal-title">Crear certificado</h4>
      <h4 *ngIf="certificate.id" class="modal-title">Actualizar certificado</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">

      <div class="form-group">
        <label for="certificateNameId">Nombre del certificado</label>
        <input type="text" #certificateName="ngModel" class="form-control" id="certificateNameId"
          [(ngModel)]="certificate.name" placeholder="Name" name="certificateName"
          [ngClass]="{ 'is-invalid': Form.submitted && certificateName.invalid}" required>

        <div *ngIf="Form.submitted && certificateName.invalid" class="invalid-tooltip">
          <div *ngIf="certificateName.errors.required">El nombre es obligatorio</div>
        </div>
      </div>

      <div class="form-group">
        <label for="certificateDescriptionId">Descripción</label>
        <textarea name="certificateDescription" type="text" id="certificateDescriptionId"
          class="md-textarea form-control" mdbInputDirective [(ngModel)]="certificate.description"
          aria-describedby="certificateDescription"></textarea>
        <small id="certificateDescription" class="form-text text-muted">Descripción del certificado</small>
      </div>

      <div class="form-group">
        <label for="certificateTypeId">Tipo de certificado</label>
        <ng-select id="certificateTypeId" [items]="certificateTypes" [searchable] [clearSearchOnAdd]
          notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name"
          [(ngModel)]="certificate.certificate_type" name="certificateTypes" placeholder="Selecciona el tipo"
          [compareWith]="compareFn" (change)="setCertificateTypeId($event)"
          [ngClass]="{'select-invalid': Form.submitted && !certificate.certificate_type }" required></ng-select>
      </div>

      <div class="form-group">
        <mat-checkbox [(ngModel)]="certificate.active" name="certificateActive">Activo</mat-checkbox>
      </div>

    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" name="submitCertificate"
        [disabled]="sending">
        {{ certificate.id ? 'Guardar' : 'Crear' }}
      </button>

      <button type="button" class="btn btn-danger" (click)="d('button click')" name="closeModal" [disabled]="sending">
        Cancelar
      </button>
    </div>
  </form>
</ng-template>
