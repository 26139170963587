import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';

// Models
import { ZoneGroupModel } from '../../../models/zone-group.model';

// Services
import { ZoneGroupService } from '../../../services/zone-group.service';

// Components
import { ZoneGroupsFormComponent } from '../modals/zone-groups-form/zone-groups-form.component';

@Component({
  selector: 'app-zone-groups',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss']
})
export class ZoneGroupsPage implements OnInit {
  @ViewChild(ZoneGroupsFormComponent, { static: true }) zoneGroupForm: ZoneGroupsFormComponent;

  zoneGroups: Array<ZoneGroupModel> = new Array<ZoneGroupModel>();
  p: number = 0;

  loading: boolean;
  isCollapsed = false;

  constructor(private zoneGroupService: ZoneGroupService) { }

  ngOnInit() {
    this.getZoneGroups();
  }

  getZoneGroups() {
    this.loading = true;
    this.zoneGroupService.getZoneGroups()
      .then(
        res => {
          this.zoneGroups = res['zone_groups'];
          this.loading = false;
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }


  deactivateActivateZoneGroup(zonegroup: ZoneGroupModel) {
    zonegroup['active'] = !zonegroup['active'];
    this.zoneGroupForm.updateZoneGroup(zonegroup);
  }

  openForm(zonegroup: ZoneGroupModel) {
    this.zoneGroupForm.openForm(zonegroup);
  }

  setZoneGroups(zoneGroups: ZoneGroupModel[]): void {
    this.zoneGroups = zoneGroups;
  }
}
