import { Component, OnInit, ViewChild } from '@angular/core';

import * as _ from 'lodash';

// Models
import { UserModel } from '../../../models/user.model';
import { RoleModel } from '../../../models/role.model';
import { CenterModel } from '../../../models/center.model';
import { ZoneModel } from '../../../models/zone.model';
import { FilterModel } from 'src/app/models/filter.model';
import { ContactModel } from 'src/app/models/contact.model';

// Services
import { UserService } from '../../../services/user.service';
import { RoleService } from '../../../services/role.service';
import { CenterService } from '../../../services/center.service';
import { ZoneService } from '../../../services/zone.service';
import { DownloadFileService } from 'src/app/services/download-file.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { NotificationService } from 'src/app/services/notification.service';

// Components
import { UsersFormComponent } from '../modals/users-form/users-form.component';


@Component({
  selector: 'app-index-users',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss']
})
export class UsersPage implements OnInit {
  @ViewChild(UsersFormComponent, { static: true }) userForm: UsersFormComponent;

  users: Array<UserModel> = new Array<UserModel>();
  allUsers: Array<UserModel> = new Array<UserModel>();

  userBeforeUpdate: UserModel = new UserModel();

  centers: Array<CenterModel> = new Array<CenterModel>();
  zones: Array<ZoneModel> = new Array<ZoneModel>();

  suppliers: ContactModel[] = new Array<ContactModel>();

  roles: Array<RoleModel> = new Array<RoleModel>();
  filterRoles: Array<RoleModel> = new Array<RoleModel>();
  // activeValue = 'Si';

  filters: FilterModel = new FilterModel();
  yesOrNot = [{ name: 'Si', value: true }, { name: 'No', value: false }];

  currentPage: number = 1;
  itemsPerPage: number = 25;
  totalItems: number = 0;

  loading: boolean;
  loadingExcel: boolean = false;
  isCollapsed: boolean = false;
  sending: boolean = false;

  constructor(
    private _downloadFileService: DownloadFileService,
    private userService: UserService,
    private roleService: RoleService,
    private centerService: CenterService,
    private zoneService: ZoneService,
    public globals: GlobalsService,
    private _notification: NotificationService
  ) {
  }

  ngOnInit() {
    delete this.filters['g'];
    this.filters['q[active_eq]'] = true;
    this.getUsers(1, this.itemsPerPage, this.filters);
    this.getRoles();
    this.getCenters();
    this.getZones();
  }

  getUsers(page: number = 1, per_page: number = 25, params: {} = {}, format: string = 'json') {
    const admin = !!_.find(this.globals.currentUserRoles, { alias: 'admin' });
    if (!admin) {
      params = Object.assign(params, { 'q[roles_alias_not_eq]': 'admin' });
    }

    let parameters = _.cloneDeep(params);

    if (format === 'json') {
      this.loading = true;
    }

    if (format === 'xlsx') {
      this.loadingExcel = true;
    }

    parameters = Object.assign(parameters, { format: format });

    _.each(parameters, (value, key) => {
      value == null && delete parameters[key];
    });

    this.userService
      .getUsers(page, per_page, parameters)
      .then(
        res => {
          if (format === 'json') {
            this.users = res['users'];
            this.currentPage = res['current_page'];
            this.totalItems = res['total_items'];
            this.allUsers = res['all_users'];
            this.suppliers = res['suppliers'];
            this.loading = false;
          } else {
            this.loadingExcel = false;
            this._downloadFileService.downloadFile(res, 'usuarios', 'application/vnd.ms-excel');
          }
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }


  deactivateActivateUser(user: UserModel) {
    user['active'] = !user['active'];
    this.userForm.updateUser(user);
  }

  openForm(user: UserModel) {
    this.getRoles();
    this.userForm.openForm(user);
  }

  getCenters() {
    this.centerService.getCenters()
      .then(
        res => {
          this.centers = res['centers'];
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }

  getZones() {
    const params = {
      'q[active_eq]': true
    };

    this.zoneService.getZones(params)
      .then(
        res => {
          this.zones = res['zones'];
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }

  generatePincode(user: UserModel) {
    this.sending = true;
    this._notification.info('Enviando datos...');

    this.userService.generatePincode(`${user['id']}`)
      .then(
        res => {
          const index = _.findIndex(this.users, { id: user['id'] });
          this['users'][index] = res['user'];

          this._notification.clear();
          this._notification.warning(`Nuevo pin: ${res['user']['pincode']}`, 'Este mensaje sera eliminado', {
            timeOut: (1000 * 20),
            tapToDismiss: false,
            progressBar: true,
            progressAnimation: 'decreasing',
          });

          this.sending = false;
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al generar el pin');
          }
        }
      );
  }

  userIsUnloadManager(user: UserModel) {
    return _.includes(_.map(user['roles'], 'alias'), 'unload_manager');
  }

  setUsers(users: UserModel[]): void {
    this.users = users;
  }

  // private initializeRoles() {
  //   return { buyer: null, driver: null, manager: null, unload_manager: null };
  // }

  private getRoles() {
    const supervisor = _.find(this.globals.currentUserRoles, { alias: 'supervisor' });
    const admin = _.find(this.globals.currentUserRoles, { alias: 'admin' });
    let params = {};

    if (supervisor && !admin) {
      params = { 'q[alias_not_in][]': ['admin', 'supervisor', 'certification_manager'] };
    }

    this.roleService.getRoles(params)
      .then(
        res => {
          this.roles = _.filter(res['roles'], { active: true });
          this.filterRoles = res['roles'];
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }
}
