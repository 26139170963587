
<!-- Modal for Delivery groups table -->
<ng-template #deliveryGroupModal let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
      Grupos de {{supplier.name }} {{supplier.surname}}
    </h4>

    <button type="button" class="close" aria-label="Close" (click)="d('close-delivery-group-modal')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div>
      <table class="table table-striped table-hover table-sm">
        <thead>
          <tr class="green-success">
            <th>#</th>
            <th>Nombre</th>
            <th>Coordinador</th>
            <th>Productores</th>
            <th>Activo</th>
            <th>BSS</th>
            <th>UE/NOP</th>
            <th>Trans.</th>
            <th>FFL</th>
            <th>FLO</th>
            <th>UTZ</th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="let deliveryGroup of deliveryGroups | paginate: { id:'deliveryGroupsPagination', itemsPerPage: 15, currentPage: deliveryGroupPage }; let i= index;">
            <td>
              {{i + 1}}
            </td>

            <td>
              {{ deliveryGroup.name }}
            </td>

            <td>
              {{deliveryGroup.coordinator?.name}}
              {{deliveryGroup.coordinator?.surname}}
            </td>

            <td>
              <button class="btn btn-sm btn-info"
                (click)="openProducerModal(deliveryGroup)">
                Ver detalles
              </button>
            </td>

            <td>
              <mat-icon class="font-weight-bold"> {{ deliveryGroup.active ? 'check' : 'close' }}</mat-icon>
            </td>

            <td>
              {{getQuotaOfDeliveryGroup(deliveryGroup, 'biosuisse')}}
            </td>

            <td>
              {{getQuotaOfDeliveryGroup(deliveryGroup, 'ue/nop')}}
            </td>

            <td>
              {{getQuotaOfDeliveryGroup(deliveryGroup, 'trans')}}
            </td>

            <td>
              <mat-icon class="font-weight-bold"> {{ haveCertificate(deliveryGroup, 'ffl') ? 'check' : 'close' }}
              </mat-icon>
            </td>
            <td>
              <mat-icon class="font-weight-bold"> {{ haveCertificate(deliveryGroup, 'flo') ? 'check' : 'close' }}
              </mat-icon>
            </td>
            <td>
              <mat-icon class="font-weight-bold"> {{ haveCertificate(deliveryGroup, 'utz') ? 'check' : 'close' }}
              </mat-icon>
            </td>

          </tr>
        </tbody>
      </table>
    </div>

    <div class="paginationContainer">
      <pagination-controls id="deliveryGroupsPagination" autoHide="true" responsive="true" previousLabel="Anterior"
        nextLabel="Siguiente" (pageChange)="deliveryGroupPage= $event"></pagination-controls>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-danger" (click)="d('close-delivery-group-modal')" name="closeModal">
      Cancelar
    </button>
  </div>
</ng-template>
<delivery-groups-producers-component></delivery-groups-producers-component>