import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';

// Models
import { ConceptModel } from 'src/app/models/concept.model';

// Service
import { ConceptsService } from 'src/app/services/concepts.service';

// Components
import { ConceptFormComponent } from './concept-form/concept-form.component';

@Component({
  selector: 'app-concepts',
  templateUrl: './concepts.component.html',
  styleUrls: ['./concepts.component.scss']
})
export class ConceptsComponent implements OnInit {
  @ViewChild(ConceptFormComponent, { static: true }) conceptForm: ConceptFormComponent;
  concepts: ConceptModel[] = [];

  loading: boolean = true;
  p: number = 1;

  constructor(private _concetpService: ConceptsService) { }

  ngOnInit(): void {
    this._getConcepts();
  }

  openForm(concept: ConceptModel): void {
    this.conceptForm.openForm(concept);
  }

  setConcept(concept: ConceptModel): void {
    const index: number = _.findIndex(this.concepts, { id: concept.id });
    if (index !== -1) {
      this.concepts[index] = concept;
    } else {
      this.concepts.push(concept);
    }
  }

  changeActiveState(concept: ConceptModel): void {
    concept.active = !concept.active;
    this.conceptForm.update(concept);
  }

  private async _getConcepts(): Promise<void> {
    this.loading = true;
    await this._concetpService.getConcepts()
      .then(
        res => {
          this.concepts = res['concepts'];
        }
      ).catch(err => console.error(err));
    
    this.loading = false;
  }

}
