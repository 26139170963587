import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import * as _ from 'lodash';

// Models
import { DeliveryGroupModel } from 'src/app/models/delivery-group.model';
import { ContactModel } from 'src/app/models/contact.model';

// Services
import { DeliveryGroupService } from 'src/app/services/delivery-group.service';

@Component({
  selector: 'delivery-groups-producers-component',
  templateUrl: './delivery-groups-producers.component.html',
  styleUrls: ['./delivery-groups-producers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeliveryGroupsProducersComponent implements OnInit {
  @ViewChild('producerModal', { static: true }) private modal;

  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass : "producer-of-delivery-group-modal"
  };

  private modalRef: NgbModalRef;

  deliveryGroup: DeliveryGroupModel = new DeliveryGroupModel();
  producerPage: number = 1;

  constructor(private _deliveryGroupService: DeliveryGroupService, private _modalService: NgbModal) { }

  ngOnInit() {
  }

  setDeliveryGroupValue(deliveryGroup: DeliveryGroupModel) {
    this._deliveryGroupService
      .getDeliveryGroup(deliveryGroup.id)
      .then(
        (response) => {
          this.deliveryGroup = response['delivery_group'];
          this.openModal(this.modal, this.modalOptions);
        }
      )
      .catch(error => console.error(error));
  }

  closeModal() {
    this.modalRef && this.modalRef.dismiss();
  }

  getQuotaOfContact(contact: ContactModel, alias: string) {
    const cert = _.find(contact['list_certificates'], { alias: alias });
    return cert ? cert['quota'] : 0;
  }

  haveCertificate(element: any, alias: string) {
    return !!_.find(element['bool_certificates'], { alias: alias });
  }

  private openModal(modal, modalOptions: NgbModalOptions) {
    this._modalService.open(modal, modalOptions);
  }

}
