export class TalbookTypeModel {
  id: number;
  name: string;
  description: string;
  label: string;
  pattern: string = 'N';
  first_page: string = '000000';
  number_of_digits: number = 6;
  type_cd: number;
  active: boolean;
}
