import { ProvenanceSheetModel } from "./provenance-sheet.model";
import { CertificateModel } from "./certificate.model";
import { UserModel } from "./user.model";
import { CocoaTypeModel } from "./cocoa-type.model";
import { ContactModel } from "./contact.model";
import { CenterModel } from './center.model';
import { BagModel } from './bag.model';
import { ReceiptModel } from './receipt.model';

export class TicketModel extends ReceiptModel {
  receiver: UserModel;
  receiver_id: number;
  cocoa_type: CocoaTypeModel;
  cocoa_type_id: number;
  contact: ContactModel;
  certificate: CertificateModel;
  certificate_id: number;
  receiver_weight: number;
  supplier_weight: number;
  contract_weight: number;
  provenance_sheet: ProvenanceSheetModel;
  active: boolean;
  finished: boolean;
  processed: boolean;
  created_at: Date;
  weight_available: number;
  supplier_not_found = false;
  cocoa_quality_id: number;
  contract_starts: any;
  contract_ends: any;
  contract_price: number;
  base_price: number;
  max_price: number;
  addon_price: number;
  commission_price: number;
  addon_toggle: boolean;
  commission_toggle: boolean;
  special_price: boolean;
  validated: boolean;
  unload_center_id: number;
  unload_center: CenterModel;
  temp_collected_date: any;
  temp_contract_collected_date: any;
  unloading_state_cd: number;
  bags_info: Array<BagModel>;
  provenance_sheet_info: any;
  temp_contract_starts: any;
  temp_contract_ends: any;
  trusted: boolean;
  authorization: any;
  contact_id: number;
  settlement_state_cd: number;
  unload_accepted: boolean;
  reviewed: boolean;
  provenance_sheet_reviewed: boolean;
  billed: boolean;
  supplier_performance: number;
  season_name: string;
  contact_max_diff: number;
  contact_prebilling_weight_cd: number;
  estimated_value: number;
  supplier_name: string;
  contract_have_invoices: boolean;
  receipt_content: any;
  authorization_content: any;
  season:any;
  agreemen_diceros_id: number;
}
