import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';

// Models
import { ContactModel } from '../../../models/contact.model';
import { CertificateModel } from '../../../models/certificate.model';
import { ZoneModel } from '../../../models/zone.model';
import { DeliveryGroupModel } from '../../../models/delivery-group.model';

// Services
import { ContactService } from '../../../services/contact.service';
import { DownloadFileService } from 'src/app/services/download-file.service';
import { GlobalsService } from 'src/app/services/globals.service';

// Component
import { ContactFormComponent } from '../modals/contact-form/contact-form.component';

export class ContactFilter {
  'q[code_eq]': string;
  'q[nickname_eq]': string;
  'q[id_eq]': number;
  'q[contacts_list_certificates_certificate_id_eq]': number;
  'q[contacts_bool_certificates_certificate_id_eq]': number;
  'q[delivery_groups_contacts_delivery_group_id_eq]': number;
  'q[supplier_eq]': number;
  'q[producer_eq]': number;
  'q[active_as_contact_eq]': boolean;
  'q[fundopo_eq]': boolean;
  'q[processor_eq]': boolean;
}


@Component({
  selector: 'app-contact',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss']
})
export class ContactsPage implements OnInit {
  @ViewChild(ContactFormComponent, { static: true }) contactForm: ContactFormComponent;

  contacts: Array<ContactModel> = new Array<ContactModel>();
  allContacts: Array<ContactModel> = new Array<ContactModel>();

  nicknames: Array<string> = new Array<string>();

  deliveryGroups: Array<DeliveryGroupModel> = new Array<DeliveryGroupModel>();

  listCertificates: Array<CertificateModel> = new Array<CertificateModel>();
  boolCertificates: Array<CertificateModel> = new Array<CertificateModel>();

  zones: Array<ZoneModel> = new Array<ZoneModel>();

  loading: boolean;
  loadingExcel = false;
  isCollapsed = false;

  totalItems = 0;
  currentPage = 1;
  itemsPerPage = 25;

  yesOrNot = [{ name: 'Si', value: true }, { name: 'No', value: false }];
  filters: ContactFilter = new ContactFilter();

  constructor(
    private _downloadFileService: DownloadFileService,
    private contactService: ContactService,
    public globals: GlobalsService
  ) { }

  ngOnInit() {
    delete this.filters['g'];
    this.filters = Object.assign(this.filters, { 'q[active_as_contact]': true });
    this.getContacts(1, this.itemsPerPage, this.filters);
  }

  getContacts(page: Number = 1, per_page: Number = 25, params: {} = {}, format: string = 'json') {
    let parameters = _.cloneDeep(params);

    if (format === 'json') {
      this.loading = true;
    }

    if (format === 'xlsx') {
      this.loadingExcel = true;
    }

    parameters = Object.assign(parameters, { format: format });

    _.each(parameters, (value, key) => {
      if (typeof parameters[key] === 'string') {
        parameters[key] = parameters[key].trim();
      }
      
      value == null && delete parameters[key];
    });

    this.contactService
      .getContacts(page, per_page, parameters)
      .then(
        (response) => {
          if (format === 'json') {
            this.contacts = response['contacts'];
            this.allContacts = response['all_contacts'];

            this.listCertificates = response['list_certificates'];
            this.boolCertificates = response['bool_certificates'];
            this.zones = response['zones'];
            this.deliveryGroups = response['delivery_groups'];
            this.nicknames = _.map(response['all_contacts'], 'nickname').filter(x => x);

            this.totalItems = response['total_items'];
            this.currentPage = response['current_page'];
            this.loading = false;
          } else {
            this.loadingExcel = false;
            this._downloadFileService.downloadFile(response, 'contactos', 'application/vnd.ms-excel');
          }
        }
      )
      .catch(
        (error) => console.error(error)
      );
  }


  deactivateActivateContact(contact: ContactModel) {
    if (contact['producer_in_delivery_groups'] && contact['producer_in_delivery_groups'].length > 0) {
      contact['producer_in'] = _.first(contact['producer_in_delivery_groups']);
    }

    contact['active_as_contact'] = !contact['active_as_contact'];
    this.contactForm.updateContact(contact);
  }


  openForm(contact: ContactModel) {
    if (contact) {
      contact['producer_in'] = _.last(contact['producer_in_delivery_groups']);
      contact['zones'] = _.last(contact['zones']);
    }

    this.contactForm.openForm(contact);
  }



  haveCertificate(contact: ContactModel, alias: string) {
    return !!_.find(contact['bool_certificates'], { alias: alias });
  }


  getQuotaOf = (contact: ContactModel, alias: string) => {
    const cert = _.find(contact['list_certificates'], { alias: alias });
    return cert ? cert['quota'] : 0;
  }

  setContacts(contacts: ContactModel[]): void {
    this.contacts = contacts;
  }
}
