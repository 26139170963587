import { Component, OnInit } from '@angular/core';

import { TranslationService } from 'src/app/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

// Models
import { WordModel } from 'src/app/models/word.model';

// Services
import { NotificationService } from 'src/app/services/notification.service';
import { WordCategoryModel } from 'src/app/models/word-category';
import { CONSTANTS } from 'src/app/app.consts';
import { GlobalsService } from 'src/app/services/globals.service';
import { SettingService } from 'src/app/services/setting.service';

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss']
})
export class TranslateComponent implements OnInit {

  wordCategories: WordCategoryModel[] = [];
  company: string = 'yacao';
  have_setting: boolean = false;

  constructor(private _translationService: TranslationService,
    private _translateService: TranslateService,
    private _notification: NotificationService,
    public globals: GlobalsService,
    private _settingService: SettingService) { }

  ngOnInit(): void {
    this._initialize();
  }

  changeWordValue(value: string, category: WordCategoryModel, word: WordModel): void {
    const current_category_index: number  = _.findIndex(this.wordCategories, { name: category.name });
    if (current_category_index !== -1) {
      word.current_value = value;
      const index: number = _.findIndex(this.wordCategories[current_category_index].words, { key: word.key });
      this.wordCategories[current_category_index].words[index] = word;
    }
  }

  saveTranslate(): void {
    if (!this.have_setting) {
      this._notification.info('Primero crea una configuración.');
      return;
    }
    // this._translateService.currentLang.includes(this.company) ? this._updateTranslate() : this._createTranslate();
    this._updateTranslate();
  }

  private async _updateTranslate(): Promise<void> {
    this._notification.info('Actualizando...');

    const json: any = this._formatForSave('update');
    await this._translationService.updateTranslation(this._translateService.currentLang, JSON.stringify(json))
      .then(
        res => {
          this._notification.clear();
          this._translateService.use('en');
          this._translateService.reloadLang(res['locale']);
          this._translateService.use(res['locale']);
          this._notification.success('Actualizado correctamente');
        }
      ).catch(err => {
        console.error(err);
        this._notification.clear();
        this._notification.error('Error al actualizar');
      });
  }

  // private async _createTranslate(): Promise<void> {
  //   this._notification.info('Creando...');
  //   const json: any = this._formatForSave('create');
  //   await this._translationService.createTranslation(this.company, CONSTANTS.APP_LANGUAGE, JSON.stringify(json))
  //     .then(
  //       res => {
  //         CONSTANTS.APP_LANGUAGE = res['locale'];
  //         this._notification.clear();
  //         this._translateService.use(res['locale']);
  //         this._notification.success('Creado correctamente');
  //       }
  //     ).catch(err => {
  //       console.error(err);
  //       this._notification.clear();
  //       this._notification.error('Error al crear');
  //     });
  // }

  private _formatForSave(from: string): any {
    let json = {};
    _.each(this.wordCategories, (category: WordCategoryModel) => {
      json[category.name] = {};
      _.each(category.words, (word: WordModel) => {
        json[category.name][word.key] = {
          original_value: word.original_value,
          current_value: word.current_value
        }
      });
    });

    return json;
  }

  private async _initialize(): Promise<void> {
    await this._getSetting();

    this._translateService.getTranslation(CONSTANTS.APP_LANGUAGE)
    .subscribe((json: any) => {
      _.each(json, (value: string, key: string) => {
        const wordCategory: WordCategoryModel = new WordCategoryModel();
        wordCategory.name = key;
        _.each(json[key], (value: { original_value: string, current_value: string}, key: string) => {
          if (!wordCategory.words) {
            wordCategory.words = [];
          }

          const word: WordModel = { original_value: value.original_value, key: key, current_value:  value.current_value };
          wordCategory.words.push(word);
        });
        
        this.wordCategories.push(wordCategory);
      });
    });
  }

  private async _getSetting(): Promise<void> {
    await this._settingService.getAppSetting()
      .then(
        (res: any) => {
          this.have_setting = !!res['setting'];
        }
      ).catch((err: any) => console.error(err));
  }
}
