import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { TranslateLoader } from '@ngx-translate/core';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class TranslationService implements TranslateLoader {
  translate_mode$: Subject<boolean> = new Subject<boolean>();

  isInTranslateMode: boolean = false;
  private readonly ROUTE = environment.routes.api + environment.routes.translations;
  private readonly SETTINGS_ROUTE = environment.routes.api + environment.routes.settings;

  constructor(
    private _http: HttpClient
  ) { }

  /**
   * TODO quitar el default se puso para las pruebas mas facilmente
   * @param locale 
   */
  getTranslation(lang: string): Observable<any> {
    console.log('hago get de', lang);
    return this._http.get(`${this.ROUTE}`, { params: { locale: lang } }).pipe(
      map(x => x['file']),
      catchError(_ => this._http.get(`/assets/i18n/${lang}.json`))
    );
  }

  createTranslation(company: string, lang: string, dictionary: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(this.ROUTE, { translation: { company_name: company, locale: lang, dictionary: dictionary }})
        .subscribe(
          (res: any) => {
            res['success'] ? resolve(res) : reject(res);
          },
          (err: any) => {
            reject(err);
          }
        )
    });
  }

  updateTranslation(lang: string, dictionary: string): Promise<any> {
    return new Promise((resolve, reject) => {
        this._http.put(`${this.ROUTE}/${lang}`, { translation: { dictionary: dictionary }})
          .subscribe(
            (res: any) => {
              res['success'] ? resolve(res) : reject(res);
            },
            (err: any) => {
              reject(err);
            }
          )
    });
  }

  changeToTranslateMode(): void {
    this.isInTranslateMode = !this.isInTranslateMode;

    this.translate_mode$.next(this.isInTranslateMode);
  }
}
