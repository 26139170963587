
<!-- Modal alert -->
<ng-template #alertModal let-modal let-d="dismiss">
  <!-- Modal header -->
  <div class="modal-header">
    <h4 class="modal-title">{{alertModalHeader}}</h4>
  </div>
  <!-- Modal body -->
  <div class="modal-body">
    <h4>{{alertModalMessage}}</h4>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeModal('alert-accepted')">{{ 'commons.alert_modal.button_accept' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="closeModal('alert-cancel')" name="closeModal">{{ 'commons.alert_modal.button_cancel' | translate }}</button>
  </div>
</ng-template>