import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as _ from 'lodash';
import { AuthService } from '../services/auth.service';
import { Roles } from '../app.enum';
import { CONSTANTS } from '../app.consts';
import { UserService } from '../services/user.service';
import { UserModel } from '../models/user.model';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private userService: UserService
  ) { }

  // canActivate() {
  //   // this.router.navigate([this.auth.authenticated ? '/tickets' : '']);
  //   // return this.auth.authenticated;

  //   return localStorage.getItem('yacao:app:token') ? true : false;
  //   // if (this.auth.authenticated) {
  //   //   return true;
  //   // } else {
  //   //   this.router.navigate(['']);
  //   //   return false;
  //   // }
  // }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = localStorage.getItem('yacao:app:token');
    const user_id: string = localStorage.getItem('yacao:app:user_id');
    const helper = new JwtHelperService();
    
    if (token && !helper.isTokenExpired(token)) {
      console.log('Entra para navegar', route.data.roles);
      return await this.userService.getUserBy(user_id)
        .then(
          async (res) => {
            const user: UserModel = res['user'];
            const user_roles: string[] = _.map(user.roles, 'alias');
            const permit_roles_indexes: number[] = route.data.roles;
            const permit_roles_aliases: string[] = [];
            _.each(permit_roles_indexes, (index: number) => {
              permit_roles_aliases.push(Roles[index]);
            });

            // return permit_roles_aliases.some(alias => user_roles.includes(alias)) && user.logged_in; habilitar esta linea cuando la rama develop del backend este en master
            return permit_roles_aliases.some(alias => user_roles.includes(alias)) 
          }
        ).catch(
          async (err) => {
            console.error(err);
            return false;
          }
        )
      // await this.userService.getUserBy()
      //   .then(
      //     async (res) => {
      //       const roles = _.map(res['user']['roles'], 'alias');
      //       _.each(route.data.roles, (value) => {
      //         const alias = Roles[value];
      //         canEnter = roles.includes(alias);

      //         // Si puede entrar salimos del iterador
      //         if (canEnter) {
      //           return false;
      //         }
      //       });
      //     }).catch(
      //       err => {
      //         console.error(err);
      //         canEnter = false;
      //       }
      //     )
      // // let canEnter = false;
      // // console.log(CONSTANTS.CURRENT_USER_ROLES)
      // // _.each(route.data.roles, (value) => {
      // //   const alias = Roles[value];
      // //   canEnter = CONSTANTS.CURRENT_USER_ROLES.includes(alias);

      // //   // Si puede entrar salimos del iterador
      // //   if (canEnter) {
      // //     return false;
      // //   }
      // // });

      // console.log(canEnter);
      // return canEnter;
    } else {
      console.log('no puedes entrar')
      token && this.authService.logout().catch(err => console.error(err));
      return false;
    }
    // console.log(route.data.roles);
    // console.log(Roles['admin'])
    // return true;
  }
}
