
<!-- Modal for exportation -->
<ng-template #showModal let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title ml-2">
      Contenedor: {{ exportation.container_name }}<br />
      Fecha exportación: {{ exportation.exportation_date | date: 'dd/MM/yyyy'}}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('close-information-modal')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <table class="table sticky-yacao green table-striped table-hover table-sm">
      <thead>
        <tr>
          <th>Recibos</th>
          <th>Kilos exportados</th>
        </tr>
      </thead>
      <tbody *ngFor="let ticket of exportation.exportation_tickets | paginate: { id:'informationPagination', itemsPerPage: 15, currentPage: page } ; index as i;">
        <tr>
          <td>
            <div>
              {{ ticket.talbook_sheet }}
            </div>
          </td>
          <td>
            <div>
              {{ ticket.weight }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="paginationContainer">
      <pagination-controls id="informationPagination" autoHide="true" responsive="true" previousLabel="Anterior"
        nextLabel="Siguiente" (pageChange)="page = $event"></pagination-controls>
    </div>
  </div>
</ng-template>