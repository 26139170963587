import { Component, OnInit, ViewChild, ViewEncapsulation, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ExportationModel } from 'src/app/models/exportation.model';
import { CocoaQualityModel } from 'src/app/models/cocoa_quality.model';
import { TicketModel } from 'src/app/models/ticket.model';
import { ExportationTicketModel } from 'src/app/models/ticket-to-export.model';
import { ExportationService } from 'src/app/services/exportation.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-exportation-forms',
  templateUrl: './exportation-forms.component.html',
  styleUrls: ['./exportation-forms.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExportationFormsComponent implements OnInit {
  @ViewChild('exportationModal', { static: true }) private modal;

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'full-modal'
  };

  @Output() result: EventEmitter<ExportationModel[]> = new EventEmitter<ExportationModel[]>();
  @Input() cocoaQualities: Array<CocoaQualityModel> = new Array<CocoaQualityModel>();
  @Input() tickets: Array<TicketModel> = new Array<TicketModel>();


  exportations: ExportationModel[] = new Array<ExportationModel>();
  exportation: ExportationModel;
  sending: boolean = false;
  submitted: boolean = false;
  loadingExcel: boolean = false;
  exportationTicketPage: number = 1;

  constructor(
    private _modalService: NgbModal,
    public globals: GlobalsService,
    private _notification: NotificationService,
    private exportationService: ExportationService,
    private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  onSubmit(exportation: ExportationModel) {
    this.submitted = true;
    if (this.validateForm()) {
      exportation = this.formatingDate(exportation);
      exportation['id'] ? this.updateExportation(exportation) : this.createExportation(exportation);
    }
  }

  openForm(exportation: ExportationModel): void {
    this.exportation = exportation || new ExportationModel();

    if (this.exportation.id) {
      const exportDate = new Date(exportation['exportation_date']);
      _.each(this.exportation.exportation_tickets, (t, index) => {
        this.exportation.exportation_tickets[index]['weight'] = Number(this.exportation.exportation_tickets[index]['weight']);
      });


      this.exportation['temp_exportation_date'] = {
        day: Number(exportDate.getDate()),
        month: Number(exportDate.getMonth() + 1),
        year: Number(exportDate.getFullYear())
      };
    } else {
      this.addNewRow();
    }

    this.openModal();

    this.changeDetectorRef.detectChanges();
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  compareFn(a: any, b: any): boolean {
    return a['id'] === b;
  }

  removeTicket(index: number) {
    _.pullAt(this.exportation.exportation_tickets, index);

    this.changePageIfNumberOfItemsChanges();
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  validateWeight(index): boolean {
    if (this.exportation.exportation_tickets[index]) {
      return ((typeof this.exportation.exportation_tickets[index]['weight'] === 'number') && (this.exportation.exportation_tickets[index]['weight'] > 0));
    } else {
      return false;
    }
  }

  updateExportation(exportation: ExportationModel) {
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);
    this.sending = true;

    this.exportationService.updateExportation(exportation)
      .then(
        res => {
          this.result.emit(res['exportations']);

          this._notification.clear();
          this._notification.success('Actualizado correctamente');
          this.sending = false;
          this.submitted = false;
          this.closeModal();
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      );
  }

  addNewRow() {
    const expTicket: ExportationTicketModel = new ExportationTicketModel();
    this.exportation.exportation_tickets.push(expTicket);

    this.changePageIfNumberOfItemsChanges();
  }

  resetSubmitedValue() {
    this.submitted = false;
  }

  setExportationActiveValueTo(exportation: ExportationModel, value: boolean) {
    exportation['active'] = value;
    exportation = this.formatingDate(exportation);
    this.updateExportation(exportation);
  }

  private validateForm(): boolean {
    let allTicketValid = false;
    _.each(this.exportation.exportation_tickets, expTicket => {
      if (!expTicket['ticket_id'] || (!_.isNumber(expTicket['weight']) || expTicket['weight'] <= 0) || expTicket['entry_code'].trim() === '') {
        allTicketValid = false;
        return false;
      } else {
        allTicketValid = true;
      }
    });

    if (this.exportation.container_name &&
      this.exportation.container_name.trim() !== "" &&
      this.exportation['temp_exportation_date']
      && allTicketValid
      && this.exportation['cocoa_quality_id']) {
      return true;
    } else {
      return false;
    }
  }

  private formatingDate(exportation: ExportationModel): ExportationModel {
    const date = new Date();

    if (exportation['temp_exportation_date']) {
      date.setDate(exportation['temp_exportation_date']['day']);
      date.setMonth(exportation['temp_exportation_date']['month'] - 1);
      date.setFullYear(exportation['temp_exportation_date']['year']);

      exportation['exportation_date'] = date;
    }

    return exportation;
  }

  private createExportation(exportation: ExportationModel) {
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);
    this.sending = true;

    this.exportationService
      .createExportation(exportation)
      .then(
        res => {
          this.result.emit(res['exportations']);

          this._notification.clear();
          this._notification.success('Creado correctamente');
          this.submitted = false;
          this.sending = false;

          this.closeModal();
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al crear');
          }
        }
      );
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }

  private changePageIfNumberOfItemsChanges() {
    // 9 es el numero de items por paginacion con Math.ceil redondeamos hacia arriba para obtener el entero de la pagina que toca
    const newPage: number = Math.ceil(this.exportation.exportation_tickets.length / 9);

    if (this.exportationTicketPage < newPage) {
      this.exportationTicketPage = newPage;
    }
  }

}
