import { ExportationTicketModel } from "./ticket-to-export.model";

export class ExportationModel {
  id: number;
  container_name: string;
  exportation_date: Date;
  // quality: string;
  cocoa_quality_id: number;
  product: string;
  exportation_tickets: Array<ExportationTicketModel> = new Array<ExportationTicketModel>();
  exportation_weight: number;
  active = true;
  temp_exportation_date: any;
  quality_id: number;
}
