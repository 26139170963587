<!-- IMAGE MODAL TO SEE EACH PICTURE -->
<ng-template #content let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title"> {{modalTitle}} </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div appNotRightClick>
      <!-- <ngx-image-viewer [src]="images" [(index)]="imagesIndex"></ngx-image-viewer> -->
      <angular-image-viewer *ngIf="images.length > 0" [src]="images" [(index)]="imagesIndex"
        [config]="{ wheelZoom: true }"></angular-image-viewer>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="d('button click')" name="closeModal">Cerrar</button>
  </div>
</ng-template>