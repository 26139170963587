<app-root-modal>
  <div *ngIf="unload.id">
    <form #Form="ngForm">
      <!-- Modal header -->
      <div class="modal-header">
        <h4 class="modal-title">Detalle de la descarga</h4>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <!-- Unload form -->
        <div class="row">
          <!-- Unload manager-->
          <div class="form-group d-inline-block col-3">
            <label for="unloadManagerId">Encargado de la descarga</label>
            <ng-select id="unloadManagerId" [items]="unloadManagers" [clearable]="false" [searchable] [clearSearchOnAdd]
              notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name" bindValue="id"
              [(ngModel)]="unload.user_id" name="unloadUser" placeholder="Selecciona el certificado"
              [compareWith]="compareFn" disabled>
            </ng-select>
          </div>

          <!-- Cocoa type -->
          <div class="orm-group d-inline-block col-3">
            <label for="unloadCocoaTypeId">Cacao</label>
            <ng-select id="unloadCocoaTypeId" [items]="cocoaTypes" [clearable]="false" [searchable] [clearSearchOnAdd]
              notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name" bindValue="id"
              [(ngModel)]="unload.cocoa_type_id" name="unloadCocoaType" placeholder="Selecciona el cacao"
               [compareWith]="compareFn" disabled>
            </ng-select>
          </div>

          <!-- Certificate -->
          <div class="form-group d-inline-block col-3">
            <label for="unloadCertificateId">Certificado</label>
            <ng-select id="unloadCertificateId" [items]="certificates" [clearable]="false" [searchable]
              [clearSearchOnAdd] notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name"
              bindValue="id" [(ngModel)]="unload.certificate_id" name="unloadCertificate"
              placeholder="Selecciona el certificado" [compareWith]="compareFn" disabled>
            </ng-select>
          </div>

          <!-- Cocoa Quality -->
          <div class="orm-group d-inline-block col-3" *ngIf="drySelected(unload)">
            <label for="unloadCocoaQualityId">Calidad del cacao</label>
            <ng-select id="unloadCocoaQualityId" [items]="cocoaQualities" [clearable]="false" [searchable]
              [clearSearchOnAdd] notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name"
              bindValue="id" [(ngModel)]="unload.cocoa_quality_id" name="unloadCocoaQuality"
              placeholder="Selecciona calidad del cacao" [compareWith]="compareFn" disabled>
            </ng-select>
          </div>
        </div>

        <!-- Datas container -->
        <div class="row">
          <!-- Tickets table-->
          <div class="col-12 d-inline-block">
            <div class="table-responsive" #bigBagsData>
              <table class="table table-striped table-hover table-sm">
                <thead class="thead-success">
                  <tr>
                    <th>Recibo</th>
                    <th>Recibido</th>
                    <th>Nº bultos</th>
                    <th>Certificado</th>
                    <th>Cargado</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    *ngFor="let ticket of unload.provisionals | paginate: { itemsPerPage: 9, currentPage: bigBagPage, id: 'modal-big-bag-paginator' }; let i= index; trackBy:trackByFn;">
                    <td>
                      {{ticket.talbook_sheet}}
                    </td>
                    <td>
                      {{ ticket.receiver_weight }}
                    </td>
                    <td>{{ticket.number_of_packages}}</td>
                    <td>{{ticket.certificate?.name}}</td>
                    <td>
                      <mat-icon class="position-relative top-5">
                        {{ ticket.unloading_state_cd !== ticketUnloadState.provisional_unloaded ? 'check' : 'close'}}
                      </mat-icon>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="paginationContainer">
              <pagination-controls id="modal-big-bag-paginator" autoHide="true" responsive="true"
                previousLabel="Anterior" nextLabel="Siguiente" (pageChange)="bigBagPage= $event"></pagination-controls>
            </div>
          </div>
        </div>
        <!-- FOOTER -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="closeModal()" name="closeModal">Cerrar</button>
        </div>
      </div>
    </form>
  </div>
</app-root-modal>