import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-images-modal',
  templateUrl: './images-modal.component.html',
  styleUrls: ['./images-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImagesModalComponent implements OnInit {
  @ViewChild('content', { static: true }) private modal;

  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg',
    windowClass: 'images-modal-component'
  };

  private modalRef: NgbModalRef;

  modalTitle: string = '';
  images: string[] = [];
  imagesIndex: number = 0;

  constructor(private _modalService: NgbModal) { }

  ngOnInit() {
  }

  openGallery(title: string, images: string[]) {
    this.modalTitle = title;
    this.images = images;
    this.openModal();
  }

  closeModal() {
    this.modalRef && this.modalRef.dismiss();
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }
}
