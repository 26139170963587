<ng-template #content let-modal let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title"> {{modalTitle}} </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div appNotRightClick>
        <div class="row">
            <div class="col">
                <label for="name">
                    NAME: 
                </label>
                <!-- <input class="form-control" type="text" [(ngModel)]=""/> -->
            </div>
        </div>
      </div>
  
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="d('button click')" name="closeModal">Cerrar</button>
    </div>
  </ng-template>
