import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Model
import { TalbookTypeModel } from '../models/talbook-type.model';

//Environment
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TalonaryService {
  talonaryRoute = environment.routes.api + environment.routes.talbook_types;
  constructor(
    private http: HttpClient
  ) {
  }

  getTalonaries() {
    return new Promise((resolve, reject) => {
      this.http.get(this.talonaryRoute, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  createTalonary(talbookType: TalbookTypeModel) {
    return new Promise((resolve, reject) => {
      this.http.post(this.talonaryRoute, { talbook_type: talbookType }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  updateTalonary(talbookType: TalbookTypeModel) {
    return new Promise((resolve, reject) => {
      this.http.put(
        `${this.talonaryRoute}/${talbookType['id']}`, { talbook_type: talbookType }, { headers: this.getHeaders() }
      ).toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  deleteTalonary(talonaryId: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${this.talonaryRoute}/${talonaryId}`, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('yacao:app:token'),
        'user_id': localStorage.getItem('yacao:app:user_id')
      }
    );
  }
}

