import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// models
import { CenterModel } from '../models/center.model';

//Environment
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CenterService {
  centerRoute = environment.routes.api + environment.routes.centers;

  constructor(
    private http: HttpClient
  ) {

  }

  getCenters() {
    return new Promise((resolve, reject) => {
      this.http.get(this.centerRoute, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  createCenter(center: CenterModel) {
    return new Promise((resolve, reject) => {
      this.http.post(this.centerRoute, { center: center }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  updateCenter(center: CenterModel) {
    return new Promise((resolve, reject) => {
      this.http.put(`${this.centerRoute}/${center['id']}`, { center: center }, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  deleteCenter(centerId: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${this.centerRoute}/${centerId}`, { headers: this.getHeaders() })
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });

  }

  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('yacao:app:token'),
        'user_id': localStorage.getItem('yacao:app:user_id')
      }
    );
  }
}
