import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import * as _ from 'lodash';

// Models
import { TicketModel } from 'src/app/models/ticket.model';
import { ContactModel } from 'src/app/models/contact.model';
import { CertificateModel } from 'src/app/models/certificate.model';

// Services
import { TicketService } from 'src/app/services/ticket.service';
import { NotificationService } from 'src/app/services/notification.service';
import { GlobalsService } from 'src/app/services/globals.service';

// Components
import { RootModalComponent } from 'src/app/components/modals/root-modal/root-modal.component';
import { ProvenanceSheetModel } from 'src/app/models/provenance-sheet.model';
import { AngularImageViewerComponent } from 'angular-x-image-viewer';
import { TranslateService } from '@ngx-translate/core';

export interface ProvenanceSheetInterface {
  sheet_images: [{
    sheet_content: string;
    to_remove: boolean
  }];
  ticket_id: number;
}
@Component({
  selector: 'app-provenance-sheet-form',
  templateUrl: './provenance-sheet-form.component.html',
  styleUrls: ['./provenance-sheet-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProvenanceSheetFormComponent implements OnInit {
  @ViewChild(RootModalComponent, { static: true }) private modal: RootModalComponent;
  @ViewChild(AngularImageViewerComponent) images: AngularImageViewerComponent;

  @Output() result: EventEmitter<TicketModel[]> = new EventEmitter<TicketModel[]>();

  @Input() tickets: TicketModel[] = new Array<TicketModel>();
  @Input() contacts: ContactModel[] = new Array<ContactModel>();

  provenanceSheetContacts: ContactModel[] = new Array<ContactModel>();
  ticket: TicketModel = new TicketModel();
  modalImgs: string[];

  producerPage: number = 1;
  sending: boolean = false;
  provenanceSheetImgs: string[] = new Array<string>();
  imageIndex: number = 0;

  newSheetsImages: any[] = [];
  readonly producersPerPage: number = 5;

  constructor(
    private _ticketService: TicketService,
    private _notification: NotificationService,
    public globals: GlobalsService,
    private translate: TranslateService
    ) { 
      translate.setDefaultLang('es');
    }

  ngOnInit() { }

  onSubmit(ticket: TicketModel) {
    if (ticket.billed) {
      return;
    }

    if (ticket.finished) {
      return;
    }
    
    const ticketCopy = _.cloneDeep(ticket);
    if (!ticket.provenance_sheet_info.id) {
      this.createProvenanceSheet(ticket)
    } else { 
      this.saveProvenanceSheetInfo(ticket);
    }

    if (ticketCopy.provenance_sheet_attributes && !!_.find(ticketCopy['provenance_sheet_attributes']['sheet_images'], { to_remove: true })) {
      this.deleteSheetImages(ticketCopy);
    }
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  closeModal(reason: string = ''): void {
    // this.modalRef && this.modalRef.dismiss(reason);
    this.modal.hide();
  
  }

  async openForm(ticket: TicketModel) {
    this.newSheetsImages = [];
    await this.getTicket(ticket.id);
    this.imageIndex = 0;
    this.provenanceSheetContacts = _.cloneDeep(this.contacts);
    // this.ticket = _.cloneDeep(ticket);
    this.ticket['provenance_sheet_info'] = _.cloneDeep(this.ticket['provenance_sheet']);

    if (!this.ticket['provenance_sheet_info']) {
      this.ticket['provenance_sheet_info'] = new ProvenanceSheetModel();
    }

    if (this.ticket['provenance_sheet_info'] && !this.ticket['provenance_sheet_info']['producers_info']) {
      const contact = new ContactModel();
      contact['weight'] = 0;
      this.ticket['provenance_sheet_info']['producers_info'] = [];
      this.ticket['provenance_sheet_info']['producers_info'].push(contact);

      /* NOTE :: Esto se ha desactivado por peticion de Roman */
      /*
        this.provenanceSheetContacts = _.cloneDeep(_.filter(this.provenanceSheetContacts, contact => {
          return _.includes(_.map(contact['list_certificates'], 'id'), this.ticket['certificate_id']);
        }));
      */
    } else if (this.ticket['provenance_sheet_info'] && this.ticket['provenance_sheet_info']['producers_info']) {
      const producers = [];

      _.each(this.ticket['provenance_sheet_info']['producers_info'], (p) => {
        if (p['id']) {
          const current_producer = _.cloneDeep(_.find(this.provenanceSheetContacts, { id: p['id'] }));
          if (current_producer) {
            current_producer.weight = p.weight;
            producers.push(current_producer);
          }
       
          // _.last(producers)['weight'] = p['weight'];
        }
      });

      this.ticket['provenance_sheet_info']['producers_info'] = producers;
      this.hideSelectedContacts();
    }

    this.provenanceSheetImgs = _.map(this.ticket['provenance_sheet_info']['sheet_images'], 'sheet_content');
    this.calculateTotalWeight();

    await this.openModal();
  }

  getQuotaOf = (contact: ContactModel, certificate: CertificateModel) => {
    let cert;

    if (contact && certificate) {
      cert = _.find(contact['list_certificates'], { id: certificate['id'] });
    }

    return (cert && cert['quota']) ? cert['quota'] : 0;
  }


  addNewColumnToProvenanceSheetProducers() {
    let contact = new ContactModel();
    if (!this.ticket['provenance_sheet_info']['producers_info']) {
      this.ticket['provenance_sheet_info']['producers_info'] = [];
    }

    contact = Object.assign(contact, {
      weight: 0
    });

    this.ticket['provenance_sheet_info']['producers_info'].push(contact);
  }

  addProducerAndTotalWeight($event, index) {
    this.ticket['provenance_sheet_info']['producers_info'][index] =
      _.cloneDeep(this.ticket['provenance_sheet_info']['producers_info'][index]);
    this.ticket['provenance_sheet_info']['producers_info'][index]['weight'] = $event.target.value ? Number($event.target.value) : 0;
    this.calculateTotalWeight();
  }

  hideSelectedContacts() {
    this.provenanceSheetContacts = _.cloneDeep(this.contacts);
    if (this.ticket['provenance_sheet_info'] && this.ticket['provenance_sheet_info']['producers_info']) {
      _.each(this.ticket['provenance_sheet_info']['producers_info'], (producer, index) => {
        this.ticket['provenance_sheet_info']['producers_info'][index]['weight'] = producer['weight'] ? producer['weight'] : 0;
        const i =  _.findIndex(this.provenanceSheetContacts, { id: producer['id'] });
        if (i > -1) {
          producer && _.pullAt(this.provenanceSheetContacts, i);
        }
      });
    }
  }

  deleteFromProvenanceSheetProducers(index) {
    _.pullAt(this.ticket['provenance_sheet_info']['producers_info'], index);
    this.hideSelectedContacts();
    this.calculateTotalWeight();
  }

  fixedNumberTo(value: number, digits: number = 1): String {
    return Number(value).toFixed(digits);
  }

  saveProvenanceSheetInfo(ticket: TicketModel) {    
    let new_provenance_sheet_images;
    this.sending = true;
    this._notification.info('Guardando Formulario de procedencia...');
    this._notification.changeModalZIndexTo(1001);


    const current_ticket = Object.assign({}, {
      id: ticket.id,
      supplier_id: ticket.supplier_id,
      provenance_sheet_info: {
        id: ticket['provenance_sheet_info']['id'],
        ticket_id: ticket.id,
        total_weight: ticket['provenance_sheet_info']['total_weight'],
        producers_info: [],
        provenance_sheet_reviewed: ticket.provenance_sheet_reviewed,
        processed: ticket.processed
      }
    });

    _.each(ticket['provenance_sheet_info']['producers_info'], (producer, index) => {
      if (producer['id']) {
        const contact = {
          contact_id: producer['id'],
          weight: producer['weight'],
          delivery_group_id: _.first(producer['producer_in_delivery_groups']) ? _.first(producer['producer_in_delivery_groups'])['id'] : null,
          position: index
        };

        current_ticket['provenance_sheet_info']['producers_info'].push(contact);
      }
    });

    if (this.newSheetsImages.length > 0) {
      new_provenance_sheet_images = {
        id: ticket.id,
        provenance_sheet_attributes: {
          sheet_images: [],
          ticket_id: ticket.id
        },
      };
      _.each(this.newSheetsImages, image => {
        const sheet_image = {
          sheet_content: image,
          to_remove: false
        }

        new_provenance_sheet_images.provenance_sheet_attributes.sheet_images.push(sheet_image);
      });
    }

    this._ticketService
      .updateProvenanceSheet(current_ticket as TicketModel)
      .then(
        res => {
          const index = _.findIndex(this.tickets, { id: res['ticket']['id'] });
          this.tickets[index] = res['ticket'];
          this.result.emit(this.tickets);

          if (new_provenance_sheet_images) {
            this.uploadImages(new_provenance_sheet_images);
          }

          this._notification.clear();
          this._notification.success('Guardado correctamente');

          this.sending = false;
          this.closeModal();
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se provenance_sheetha producido un error al actualizar');
          }
        }
      );
  }

  removeImage(ticket: TicketModel) {
    const index = this.images.index;
    if (this.provenanceSheetImgs[index].includes('http')) {
      const toRemove = this.ticket['provenance_sheet']['sheet_images'][index];

      if (!this.ticket['provenance_sheet_attributes']) {
        this.ticket['provenance_sheet_attributes'] = new ProvenanceSheetModel();
      }
  
      toRemove['to_remove'] = true;
      this.ticket['provenance_sheet_attributes']['sheet_images'].push(toRemove);
  
      _.pullAt(this.ticket['provenance_sheet']['sheet_images'], index);
      _.pullAt(this.provenanceSheetImgs, index);
  
      if (this.imageIndex > 0) {
        this.imageIndex -= 1;
      }
    } else {
      _.pullAt(this.provenanceSheetImgs, index);
      if (this.imageIndex > 0) {
        this.imageIndex -= 1;
      }
    }
  }

  setProvenanceSheetReviewed(ticket: TicketModel): void {
    if (ticket.finished) {
      return;
    }

    if (ticket.reviewed) {
      ticket.provenance_sheet_reviewed = !ticket.provenance_sheet_reviewed;
      ticket.processed = true;

      if (!ticket.billed) {
        if (ticket.cocoa_type.name.toLowerCase() === 'seco') {
          if (((Number(ticket.provenance_sheet_info.total_weight * 1.05)) < Number(ticket.receiver_weight)) && ticket.provenance_sheet_reviewed) {
            ticket.provenance_sheet_reviewed = false;
            ticket.processed = false;
            this._notification.error('El peso de suplidor supera el limite permitido');
            return;
          }
        } else  {
          if ((Number(ticket.provenance_sheet_info.total_weight) < Number(ticket.receiver_weight)) && ticket.provenance_sheet_reviewed) {
            ticket.provenance_sheet_reviewed = false;
            ticket.processed = false;
            this._notification.error('El peso suplidor no puede ser menor que el del receptor');
            return;
          }
        }
       
        this.onSubmit(ticket);
      } else {
        this._notification.error('El recibo ya está facturado');
      }
    } else {
      this._notification.error('El recibo debe estar revisado previamente.')
    }
  }

  changeProducerPosition(ticket: TicketModel, from: number, to: number): void {
    if (ticket.provenance_sheet_info && ticket.provenance_sheet_info.producers_info) {
      let producers:[] = ticket.provenance_sheet_info.producers_info;
      const new_index = from + to;
      if (new_index >= 0 && new_index <= (producers.length - 1)) {
        producers.splice(new_index, 0, producers.splice(from, 1)[0]);
      }
    }
  }

  async setSheetsImages(event: any): Promise<void> {
   this.newSheetsImages = [];
    _.each(event.target.files, file => {
      let reader = new FileReader();
      reader.onload = () => {
        this.newSheetsImages.push(reader.result);
        this.provenanceSheetImgs.push(reader.result.toString());
      }

      reader.readAsDataURL(file);
    });
  }

  private calculateTotalWeight() {
    this.ticket['provenance_sheet_info']['total_weight'] = 0;
    _.each(this.ticket['provenance_sheet_info']['producers_info'], producer => {
      if (!!producer['weight']) {
        this.ticket['provenance_sheet_info']['total_weight'] += Number(producer['weight']);
        this.ticket.provenance_sheet.total_weight = this.ticket['provenance_sheet_info']['total_weight'];
      }
    });
  }


  private deleteSheetImages(ticket: TicketModel) {
    this._ticketService.uploadImages(ticket, 'sheet')
      .then(
        res => {
          const index = _.findIndex(this.tickets, { id: ticket['id'] });
          this.tickets[index] = ticket;
        }
      )
      .catch(
        err => {
          this._notification.error('Error al eliminar las imagenes del formulario de procedencia');
          console.error(err);
        }
      );
  }

  private openModal(): void {
    this.modal.show();
  }

  private async getTicket(ticket_id: number) {
    await this._ticketService.getTicket(ticket_id)
      .then(
        res => {
          this.ticket = res['ticket'];
        }
      ).catch(err => {
        console.error(err);
        if (err.hasOwnProperty('errors')) {
          this._notification.error(err['errors'][0]);
          // this.ticket = err['ticket'];
        } else {
          this._notification.error('Se ha producido un error.');
        }
      });
  }

  private async uploadImages(images: any): Promise<void> {
    await this._ticketService.uploadImages(images, 'sheet')
      .then(
        async res => {
          const index = _.findIndex(this.tickets, { id: res['ticket']['id'] });
          this.tickets[index] = res['ticket'];
          this.result.emit(this.tickets);
        }
      ).catch(err => console.error(err));
  }

  private async createProvenanceSheet(ticket: TicketModel): Promise<void> {
    let new_provenance_sheet_images;
    if (this.newSheetsImages.length > 0) {
      new_provenance_sheet_images = {
        id: this.ticket.id,
        provenance_sheet_attributes: {
          sheet_images: [],
          ticket_id: this.ticket.id
        },
      };

      _.each(this.newSheetsImages, image => {
        const sheet_image = {
          sheet_content: image,
          to_remove: false
        }

        new_provenance_sheet_images.provenance_sheet_attributes.sheet_images.push(sheet_image);
      });

      await this.uploadImages(new_provenance_sheet_images)  
        .then(
          async res => {
            this.newSheetsImages = [];
            this.saveProvenanceSheetInfo(ticket);
          }
        ).catch(err => console.error(err));
    }
  }

}
