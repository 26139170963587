import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

//Environment
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CocoaQualityService {
  cocoaQualityRoute = environment.routes.api + environment.routes.cocoa_qualities;
  constructor(
    private http: HttpClient
  ) { }

  getCocoaQualities(params: {} = {}) {
    const options = {
      params: params,
      headers: this.getHeaders()
    }

    return new Promise((resolve, reject) => {
      this.http.get(this.cocoaQualityRoute, options)
        .toPromise()
        .then(
          res => {
            res['success'] ? resolve(res) : reject(res);
          }
        )
        .catch(
          err => {
            reject(err);
          }
        );
    });
  }

  private getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('yacao:app:token'),
        'user_id': localStorage.getItem('yacao:app:user_id')
      }
    );
  }
}
