import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  private readonly SETTINGS_ROUTE = environment.routes.api + environment.routes.settings;
  constructor(private _http: HttpClient) { }

  getAppSetting(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(`${this.SETTINGS_ROUTE}`)
        .subscribe(
          (res: any) => {
            res['success'] ? resolve(res) : reject(res);
          },
          (err: any) => {
            reject(err);
          }
        )
    });
  }

  createSetting(setting: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post(this.SETTINGS_ROUTE, { setting: setting })
        .subscribe(
          (res: any) => {
            res['success'] ? resolve(res) : reject(res);
          },
          (err: any) => {
            reject(err);
          }
        )
    });
  }

  updateSetting(setting: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.put(`${this.SETTINGS_ROUTE}/${setting.id}`, { setting: setting })  
        .subscribe(
          (res: any) => {
            res['success'] ? resolve(res) : reject(res);
          },
          (err: any) => {
            reject(err);
          }
        )
    });
  }
}
