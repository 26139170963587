import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {

  constructor() { }

  downloadFile(file: any, kindOfReport: string, fileType: string) {
    const blob = new Blob([file], { type: fileType }),
      fileUrl = window.URL.createObjectURL(blob),
      link = document.createElement('a');

    link.href = fileUrl;
    link.target = '_blank';
    link.download = `${kindOfReport}_report.xlsx`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
}
