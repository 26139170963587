
<app-navbar></app-navbar>
<div class="row">
  <div class="col">
    <div class="filtersContainer">
      <div class="row">
        <div class="col-3">
        </div>
        <div class="col-3 offset-3">
          <button type="button" class="btn btn-info btn-block" (click)="openForm(null)">
            <!-- <mat-icon class="position-relative top-5 font-weight-bold">add</mat-icon> -->
            <i class="fa fa-plus"></i>
            Nuevo centro
          </button>
        </div>
        <!-- <div class="col-3">
          <button type="button" class="btn btn-outline-dark btn-block"
            [disabled]="true">
            <i class="fa fa-download"></i>
            Descargar Excel
          </button>
        </div> -->
      </div>
    </div>
  </div>
</div>

<app-center-form [centers]="centers" (result)="setCenters($event)"></app-center-form>

<div *ngIf="!loading">
  <table class="table sticky-yacao green table-striped table-hover table-sm">
    <thead>
      <tr>
        <th>#</th>
        <th>Nombre</th>
        <th>Activo</th>
        <th>Acciones</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let center of centers | paginate: { itemsPerPage: 15, currentPage: p }; let i= index;">
        <td>{{i + 1}}</td>
        <td>{{center.name}}</td>
        <td>
          <mat-icon class="font-weight-bold">{{ center.active ? 'check' : 'close' }}</mat-icon>
        </td>
        <td>
          <div class="col-12">
            <div class="nav-item dropdown" ngbDropdown>
              <a class="btn btn-sm btn-success dropdown-toggle white" ngbDropdownToggle>
                Selecciona acción
              </a>

              <div class="dropdown-menu" aria-labelledby="admin" ngbDropdownMenu>
                <button class="btn btn-sm dropdown-item" (click)="openForm(center)">
                  <!-- <mat-icon class="position-relative top-5 font-weight-bold">edit</mat-icon> -->
                  <i class="fa fa-edit text-primary"></i>
                  Editar
                </button>

                <button class="btn btn-sm dropdown-item" (click)="deactivateActivateCenter(center)">
                  <!-- <mat-icon class="position-relative top-5">delete</mat-icon> -->
                  <i class="fa fa-trash text-danger"></i>
                  {{ center.active ? 'Desactivar' : 'Activar' }}
                </button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="loading" *ngIf="loading">
  <ng-template #loadingTemplate></ng-template>
  <ngx-loading [show]="loading" [template]="loadingTemplate"></ngx-loading>
</div>

<div class="paginationContainer">
  <pagination-controls autoHide="true" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
    (pageChange)="p= $event"></pagination-controls>
</div>