<!-- MODAL WITH FORM TO UPDATE PAYMENT -->
<ng-template #conceptForm let-modal let-update="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ concept.id ? 'Actualizar' : 'Crear' }}
    </h4>

    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">
        &times;
      </span>
    </button>
  </div>

  <form [formGroup]="form" novalidate (ngSubmit)="onSubmit(concept)">
    <div class="modal-body edit-payment-modal">

      <div class="row">

        <div class="form-gorup col-md-6 d-inline-block">
          <label for="title">
            Titulo
          </label>

          <input id="title" type="text" class="form-control" formControlName="title">
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-6">
          <mat-checkbox formControlName="active">
            Activo
          </mat-checkbox>
        </div>
      </div>

    </div>

    <div class="modal-footer">
      <button class="btn btn-primary" type="submit">
        Guardar
      </button>

      <button class="btn btn-danger" (click)="closeModal()" name="closeModal">
        Cancelar
      </button>
    </div>
  </form>
</ng-template>