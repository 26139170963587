import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';

// Models
import { AddonModel } from 'src/app/models/addon.model';
import { CertificateModel } from 'src/app/models/certificate.model';
import { CocoaQualityModel } from 'src/app/models/cocoa_quality.model';
import { CocoaTypeModel } from 'src/app/models/cocoa-type.model';
import { ContactModel } from 'src/app/models/contact.model';
import { FilterModel } from 'src/app/models/filter.model';
import { TalBookModel } from 'src/app/models/talbook.model';
import { TicketModel } from 'src/app/models/ticket.model';
import { UserModel } from 'src/app/models/user.model';
import { BagModel } from 'src/app/models/bag.model';

// Services
import { DownloadFileService } from 'src/app/services/download-file.service';
import { CertificateService } from 'src/app/services/certificate.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { TicketService } from 'src/app/services/ticket.service';

import { TicketType, TicketUnloadState } from '../../../app.enum';
// Components
import { ContractFormComponent } from '../modals/contract-form/contract-form.component';
import { ImagesModalComponent } from 'src/app/components/modals/images-modal/images-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contracts-index',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss']
})
export class ContractsPage implements OnInit {
  @ViewChild(ContractFormComponent, { static: true }) private contractForm: ContractFormComponent;
  @ViewChild(ImagesModalComponent, { static: true }) private gallery: ImagesModalComponent;

  yesOrNot = [
    { name: 'Si', value: true },
    { name: 'No', value: false }
  ];

  ticketTypeFilter = [
    { name: 'Compra directa', value: TicketType.direct_purchase },
    { name: 'Contrato', value: TicketType.contracts }
  ];

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy'
    // other options are here...
  };

  tickets: Array<TicketModel> = new Array<TicketModel>();

  suppliers: Array<ContactModel> = new Array<ContactModel>();
  buyers: Array<UserModel> = new Array<UserModel>();
  cocoaTypes: Array<CocoaTypeModel> = new Array<CocoaTypeModel>();
  certificates: Array<CertificateModel> = new Array<CertificateModel>();
  cocoaQualities: Array<CocoaQualityModel> = new Array<CocoaQualityModel>();
  // contacts: Array<ContactModel> = new Array<ContactModel>();
  talbooks: Array<TalBookModel> = new Array<TalBookModel>();
  addons: Array<AddonModel> = new Array<AddonModel>();

  modalImg: string;
  contractType = TicketType;

  provenanceSheetImages: Array<string>;
  provenanceSheetImageSelectedIndex = 0;
  provenanceSheetContacts: Array<ContactModel> = new Array<ContactModel>();
  provenanceSheetKgBaba: Array<number> = new Array<number>();
  provenanceSheetKgDry: Array<number> = new Array<number>();
  talbook_sheets = [];

  modalRef: NgbModalRef;
  alertModalRef: NgbModalRef;
  closeResult = '';

  contactsLoad = false;

  filters: FilterModel = new FilterModel();

  isCollapsed = false;

  currentPage = 1;
  totalItems = 0;
  itemsPerPage = 25;

  loading = false;
  loadingExcel = false;

  changeCompleteStateValue = false;
  ticketUnloadState = TicketUnloadState;

  constructor(
    private _downloadFileService: DownloadFileService,
    private _ticketService: TicketService,
    public globals: GlobalsService,
    private translate: TranslateService
  ) { 
    // translate.setDefaultLang('es');
  }

  ngOnInit() {
    delete this.filters['g'];
    this.filters['q[ticket_type_cd_in]'] = JSON.stringify([TicketType.direct_purchase, TicketType.contracts]);
    this.filters['q[used_eq]'] = true;
    this.getTickets(this.currentPage, this.itemsPerPage, this.filters);
  }

  onDateChanged(event, filter: string) {
    this.filters[filter] = event.singleDate.jsDate;
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }
  
  openForm(ticket: TicketModel) {
    const startedDate = moment(ticket['contract_starts']).format('DD-MM-YYYY').split('-');
    const finishedDate = moment(ticket['contract_ends']).format('DD-MM-YYYY').split('-');
    const collectedDate = moment(ticket['collected_date']).format('DD-MM-YYYY').split('-');

    ticket['temp_contract_starts'] = {
      day: Number(startedDate[0]),
      month: Number(startedDate[1]),
      year: Number(startedDate[2])
    };

    ticket['temp_contract_ends'] = {
      day: Number(finishedDate[0]),
      month: Number(finishedDate[1]),
      year: Number(finishedDate[2])
    };

    if (ticket.ticket_type_cd === TicketType.contracts) {
      ticket['temp_contract_collected_date'] = {
        day: Number(collectedDate[0]),
        month: Number(collectedDate[1]),
        year: Number(collectedDate[2])
      };
    }

    const addon = _.find(this.addons, { certificate_id: ticket['certificate_id'] });
    ticket['bags_info'] = ticket['bags'];

    this.contractForm.openForm(ticket, addon);
  }

  openGallery(title: string, images: string[]) {
    this.gallery.openGallery(title, images);
  }

  validateTicket(ticket: TicketModel) {
    ticket['validated'] = !ticket['validated'];
    const t = {
      id: ticket['id'],
      validated: ticket['validated']
    };

    this.contractForm.updateContractStateValue(t as TicketModel);
  }

  toInteger(value: number): number {
    return _.toInteger(value);
  }

  getTickets(page: number = 1, per_page: number = 25, params: {} = {}, format: string = 'json') {
    let parameters = _.cloneDeep(params);

    if (format === 'json') {
      this.loading = true;
    }

    if (format === 'xlsx') {
      this.loadingExcel = true;
    }

    parameters = Object.assign(parameters, { format: format });

    _.each(parameters, (value, key) => {
      value == null && delete parameters[key];
    });

    if (parameters['g']) {
      parameters = Object.assign(parameters, { g: JSON.stringify({ g: parameters['g'] }) });
    }

    this._ticketService
      .getTickets(page, per_page, parameters)
      .then(
        (response) => {
          console.log('Response', response);

          if (format === 'json') {
            this.tickets = response['tickets'];
            this.suppliers = response['suppliers'];
            this.certificates = response['certificates'];
            this.addons = response['addons'];
            this.cocoaTypes = response['cocoa_types'];
            this.cocoaQualities = response['cocoa_qualities'];
            this.buyers = response['buyers'];
            this.talbook_sheets = response['talbook_sheets'];

            this.totalItems = response['total_items'];
            this.currentPage = response['current_page'];
            this.loading = false;
          } else {
            this.loadingExcel = false;
            this._downloadFileService.downloadFile(response, 'acuerdos', 'application/vnd.ms-excel');
          }
        }
      )
      .catch(
        (error) => console.error(error)
      );
  }

  setTickets(tickets: TicketModel[]): void {
    this.tickets = tickets;
  }


  getQuantity(ticket: TicketModel): string {
    const contract_weight = Number(ticket.contract_weight);
    let divider = 50;
    if (ticket.ticket_type_cd === TicketType.direct_purchase && ticket.cocoa_type && ticket.cocoa_type.name.toLocaleLowerCase() === 'baba') {
      divider = Number(ticket.supplier_performance);
    }

    return (divider === 0) ? '0' : (contract_weight/divider).toFixed(2);
  }
}
