<app-root-modal>
  <div *ngIf="!loading">
    <!-- Modal header -->
    <div class="modal-header">
      <h4 class="modal-title">Actualizar descarga {{unload.id}} (Todos los pesos están en kg)</h4>
    </div>
    <!-- Modal body -->
    <div class="modal-body">

      <div class="row p-3">
        <div class="alert alert-info alert-dismissible fade show col-12" role="alert" *ngIf="displayTicketInfo">
          <strong>Recibo {{ ticketToDisplay.talbook_sheet }} </strong>
          <br>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeTicketInfo()">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="d-inline-block mr-1">
            <p><strong> Certificado:</strong> {{ ticketToDisplay.certificate?.name }} </p>
          </div>

          <div *ngIf="ticketToDisplay.settlement_state_cd" class="d-inline-block mr-1">
            <p>
              <strong> Tipo de facturación:</strong>
              {{ ticketToDisplay.settlement_state_cd !== null ? ticketSettlementStates[ticketToDisplay.settlement_state_cd].name : ''}}
            </p>
          </div>

          <div class="d-inline-block mr-1" *ngIf="ticketToDisplay.supplier_name">
            <p>
              <strong> Suplidor: </strong> {{ ticketToDisplay.supplier_name}}
            </p>
          </div>

          <div class="d-inline-block mr-1" *ngIf="ticketToDisplay.supplier_weight">
            <p>
              <strong> Peso suplidor: </strong> {{ ticketToDisplay.supplier_weight}} Kg.
            </p>
          </div>


          <div>
            <p><strong> Comentarios:</strong> {{ ticketToDisplay.comments }}
            </p>
          </div>

        </div>

        <div class="alert alert-warning fade show col-12" role="alert" *ngIf="weightLossNotExistWarning">
          <p><strong>¡Atención!:</strong> {{ warningMessage }}</p>
        </div>
      </div>
      <!-- Unload form -->
      <div class="row">
        <!-- Unload manager-->
        <div class="form-group d-inline-block col-3">
          <label for="unloadManagerId">Encargado de la descarga</label>
          <ng-select id="unloadManagerId" [items]="unloadManagers" [clearable]="false" [searchable] [clearSearchOnAdd]
            notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name" bindValue="id"
            [(ngModel)]="unload.user_id" name="unloadUser" placeholder="Selecciona el certificado"
            [compareWith]="compareFn" disabled>
          </ng-select>
        </div>

        <!-- Cocoa type -->
        <div class="orm-group d-inline-block col-3">
          <label for="unloadCocoaTypeId">Cacao</label>
          <ng-select id="unloadCocoaTypeId" [items]="cocoaTypes" [clearable]="false" [searchable] [clearSearchOnAdd]
            notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name" bindValue="id"
            [(ngModel)]="unload.cocoa_type_id" name="unloadCocoaType" placeholder="Selecciona el cacao"
            (change)="removeCocoaQualityIfBabaSelected($event, 'unload')" [compareWith]="compareFn" disabled>
          </ng-select>
        </div>

        <!-- Certificate -->
        <div class="form-group d-inline-block col-3">
          <label for="unloadCertificateId">Certificado</label>
          <ng-select id="unloadCertificateId" [items]="certificates" [clearable]="false" [searchable] [clearSearchOnAdd]
            notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name" bindValue="id"
            [(ngModel)]="unload.certificate_id" name="unloadCertificate" placeholder="Selecciona el certificado"
            [compareWith]="compareFn" disabled>
          </ng-select>
        </div>

        <!-- Cocoa Quality -->
        <div class="orm-group d-inline-block col-3" *ngIf="drySelected(unload)">
          <label for="unloadCocoaQualityId">Calidad del cacao</label>
          <ng-select id="unloadCocoaQualityId" [items]="cocoaQualities" [clearable]="false" [searchable]
            [clearSearchOnAdd] notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name"
            bindValue="id" [(ngModel)]="unload.cocoa_quality_id" name="unloadCocoaQuality"
            placeholder="Selecciona calidad del cacao" [compareWith]="compareFn" disabled>
          </ng-select>
        </div>

        <!-- <div class="form-group">
        <mat-checkbox [(ngModel)]="unload.accepted" name="unloadAccepted">Valida</mat-checkbox>
      </div> -->
      </div>

      <!-- Datas container -->
      <div class="row pr-0">
        <!-- Tickets table-->
        <div class="col-5 d-inline-block">
          <div class="table-responsive" #bigBagsData>
            <table class="table table-striped table-hover table-sm">
              <thead class="thead-success">
                <tr>
                  <th>Recibo</th>
                  <th>Recibido Kg</th>
                  <th>Descargado Kg</th>
                  <th>Merma Kg</th>
                  <th>Merma %</th>
                  <th>Revisado</th>
                  <th>Facturado</th>
                  <th>Filtrar</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let ticket of unload.tickets; let i= index; trackBy:trackByFn;">
                  <!-- <td (click)="openTicketInfo(ticket)" class="mouse-over-hand"> -->
                  <td (click)="showTicketInfo(ticket)" class="receipt-col">
                    <i class="fa fa-search process-icon"></i>
                    {{ticket.talbook_sheet}}
                    <!-- <button type="button" class="btn btn-info btn-sm"
                      (click)="getTicketBigBags(ticket)">Detalle</button> -->
                  </td>
                  <td>{{getWeightToFixed(ticket.receiver_weight)}}</td>
                  <td>{{getWeightToFixed(ticket.weight)}}</td>
                  <td>{{getTicketWeigthLoss(ticket).toFixed(1)}}</td>
                  <td>{{calculatePercentajeOfWeightLoss([ticket])}}</td>
                  <td>
                    <mat-icon class="font-weight-bold"> {{ ticket.reviewed ? 'check' : 'close' }}</mat-icon>
                  </td>
                  <td>
                    <mat-icon class="font-weight-bold"> {{ isTicketBilled(ticket) ? 'check' : 'close' }}</mat-icon>
                  </td>
                  <td>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="{{ticket.talbook_sheet}}"
                        [checked]="ticket.selected" (change)="selectUnselectTickets($event.target.checked, [ticket])">
                      <label class="custom-control-label" for="{{ticket.talbook_sheet}}"> </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="8"></td>
                </tr>
                <tr>
                  <td class="table-title">Total recibos</td>
                  <td>{{fixedNumber(totalTicketsCollectedWeight, 1)}}</td>
                  <td>{{fixedNumber(totalTicketsUnloadWeight, 1)}}</td>
                  <td>{{fixedNumber(totalTicketsWeightLoss, 1)}}</td>
                  <td>{{calculatePercentajeOfWeightLoss(unload.tickets)}}</td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="checkboxFilterAllTickets"
                        [checked]="allTicketsSelected"
                        (change)="selectUnselectTickets($event.target.checked, unload.tickets)">
                      <label class="custom-control-label" for="checkboxFilterAllTickets"></label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="paginationContainer">
            <pagination-controls id="modal-big-bag-paginator" autoHide="true" responsive="true" previousLabel="Anterior"
              nextLabel="Siguiente" (pageChange)="bigBagPage= $event"></pagination-controls>
          </div>
        </div>

        <!-- Cards containers-->
        <div class="col-7 d-inline-block mb-3 p-0">
          <div
            [ngClass]="{'col-3': (process.process_cd === bigBagProcess.refund), 'col-2':  (process.process_cd !== bigBagProcess.refund)}"
            class="d-inline-block p-0 h-100 mr-1"
            *ngFor="let process of processes; let processIndex = index;trackBy: trackByFn;">


            <div class="card">
              <!-- Card Header -->
              <div class="card-header pr-1">
                {{ process.name }}
                <div class="d-inline-block cursor-pointer float-right"
                  (click)="process.process_cd !== bigBagProcess.refund ? addBigBagToProcess(process.process_cd) : addRefoundBag()">
                  <mat-icon class="position-relative top-5 font-weight-bold">add</mat-icon>
                </div>
              </div>

              <!-- Card content -->
              <ul class="list-group list-group-flush">

                <li class="list-group-item" *ngFor="let bigBag of selectedBigBags; let i= index;trackBy: trackByFn;"
                  [hidden]="bigBag.process_cd !== process.process_cd">
                  <!-- Not refound datas -->
                  <div class="row" *ngIf="bigBag.process_cd !== bigBagProcess.refund">
                    <!-- BigBag datas-->
                    <div class="d-inline-block col-9">
                      {{bigBag.barcode_number}}
                      <br>
                      Peso: {{getFilteredTicketsWeightInBag(bigBag)}}
                    </div>

                    <!-- BigBag icons -->
                    <div class="d-inline-block col-3 h-100">
                      <div class="row">
                        <mat-icon class="position-relative top-5 font-weight-bold process-icon"
                          (click)="openBigBagForm(bigBag)">search</mat-icon>
                      </div>

                      <div class="row">
                        <mat-icon *ngIf="!unload.accepted"
                          class="position-relative top-5 font-weight-bold cursor-pointer text-danger"
                          (click)="openAlertModal(i, 'bigBag')">delete</mat-icon>
                      </div>
                    </div>
                  </div>

                  <!-- Refound datas -->
                  <div class="row" *ngIf="bigBag.process_cd === bigBagProcess.refund">
                    <div class="d-inline-block col-9">
                      <div class="row">
                        <label class="col-4 mt-2 p-0">Recibo: </label>
                        <ng-select class="custom col-8 pl-0" [items]="refoundTickets" [searchable]
                          notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="talbook_sheet"
                          name="refoundTicket" [clearable]="false" placeholder="Recibos" [multiple]="false"
                          [(ngModel)]="bigBag.tickets[0]" (change)="setBarcodeTo($event, i)" [compareWith]="compareFn" [disabled]="unload.accepted">
                        </ng-select>
                      </div>
                      <div class="row">
                        <label class="col-4 mt-2 p-0">
                          Peso:
                        </label>
                        <input type="number" class="form-control-plaintext col-8 border-bottom-black"
                          [value]="bigBag.tickets[0].weight" placeholder="Peso a devolver"
                          (input)="setRefoundWeightTo(bigBag, $event.target.value)" [disabled]="unload.accepted">
                      </div>
                    </div>

                    <!-- BigBag icons -->
                    <div class=" col-3 d-flex align-items-center justify-content-center">
                      <mat-icon *ngIf="!unload.accepted"
                        class="position-relative top-5 font-weight-bold cursor-pointer text-danger"
                        (click)="openAlertModal(i, 'bigBag')">delete</mat-icon>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <!-- Footer with totals -->
            <div class="p-0 col-12">
              <div [ngClass]="{ 'opacity-0':  processIndex !== 0 }" class="d-block">
                Totales
              </div>
              <div class="card mt-1 d-block">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    {{ getTotalWieghtOfTicketsInProcess(process.process_cd)}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="modal-footer">
      <!-- <button type="button" class="btn btn-primary" name="submitTradePrice" [disabled]="sending" 
                              *ngIf="!FinalUnloadForm.form.errors && !unload.accepted" (click)="validateUnloadAndSave(unload)">Validar y enviar</button> -->
      <button type="button" class="btn btn-primary" name="submitTradePrice"
        (click)="openAlertModal(unload, 'accepted-unload'); closeTicketInfo();"
        [disabled]="sending || !canAcceptedUnload(unload) || unloadEmpty(unload)">{{ unload.accepted ? 'Invalidar' : 'Validar y enviar'}}</button>

      <button type="button" class="btn btn-primary" name="submitTradePrice" [disabled]="sending"
        *ngIf="!unload.accepted" (click)="onSubmit(unload)"
        [disabled]="unload.accepted || unload.state_cd !== unloadState.finished">Guardar</button>

      <button *ngIf="unsaveChanges === false && unloadEmpty(unload)" type="button" class="btn btn-danger"
        (click)="deleteUnload(unload);" name="closeModal"
        [disabled]="sending || !globals.currentUserRolesAsBooleanObject.admin">Eliminar</button>

      <button type="button" class="btn btn-danger" (click)="closeModal(); closeTicketInfo(); unsaveChanges = false"
        name="closeModal" [disabled]="sending">Cancelar</button>
    </div>

    <!-- </form> -->
  </div>
</app-root-modal>

<app-lot-form [certificates]="certificates" [cocoaTypes]="cocoaTypes" [cocoaQualities]="cocoaQualities"
  [tickets]="tickets" [unload]="unload" [unsaveChanges]="unsaveChanges" [lotsImages]="lotsImages" (bigBagsEmit)="setUnloadBigBags($event)" (lotImagesEmit)="setLotImages($event)">
</app-lot-form>

<app-alert-modal (result)="alertModalResult($event)"></app-alert-modal>