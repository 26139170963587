import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';

import * as _ from 'lodash';

// Services
import { CertificateService } from 'src/app/services/certificate.service';
import { DeliveryGroupService } from 'src/app/services/delivery-group.service';
import { ZoneService } from 'src/app/services/zone.service';

// Models
import { ContactModel } from 'src/app/models/contact.model';
import { CertificateModel } from 'src/app/models/certificate.model';
import { FilterModel } from 'src/app/models/filter.model';
import { DeliveryGroupModel } from '../../../models/delivery-group.model';
import { GlobalsService } from 'src/app/services/globals.service';

// Globals
import { DownloadFileService } from 'src/app/services/download-file.service';
import { PdfService } from 'src/app/services/pdf.service';

// Components
import { DeliveryGroupProducersComponent } from '../modals/delivery-group-producers/delivery-group-producers.component';
import { DeliveryGroupFormComponent } from '../modals/delivery-group-form/delivery-group-form.component';
import { SeasonService } from 'src/app/services/season.service';


@Component({
  selector: 'app-delivery-groups-page',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeliveryGroupsPage implements OnInit {
  @ViewChild(DeliveryGroupProducersComponent, { static: true }) private producersModal: DeliveryGroupProducersComponent;
  @ViewChild(DeliveryGroupFormComponent, { static: true }) private formModal: DeliveryGroupFormComponent

  deliveryGroups: Array<DeliveryGroupModel> = new Array<DeliveryGroupModel>();

  deliveryGroupNames: [] = [];

  contact: ContactModel = new ContactModel();
  filterCoordinators: Array<ContactModel> = new Array<ContactModel>();
  filterProducers: Array<ContactModel> = new Array<ContactModel>();

  listCertificates: Array<CertificateModel> = new Array<CertificateModel>();
  boolCertificates: Array<CertificateModel> = new Array<CertificateModel>();


  filters: FilterModel = new FilterModel();
  yesOrNot = [{ name: 'Si', value: true }, { name: 'No', value: false }];

  loading: boolean;
  loadingExcel = false;
  isCollapsed = false;

  disabledSeeDetails = true;

  itemsPerPage = 25;
  totalItems = 0;
  currentPage = 1;

  constructor(
    public globals: GlobalsService,
    private _downloadFileService: DownloadFileService,
    private certificateService: CertificateService,
    private deliveryGroupService: DeliveryGroupService,
    private pdfService: PdfService,
    private _seasonService: SeasonService
  ) { }

  async ngOnInit() {
    delete this.filters['g'];
    await this.getCertificates();
    await this.getDeliveryGroups(1, this.itemsPerPage, this.filters);
  }

  async getCertificates() {
    await this.certificateService
      .getCertificates()
      .then(
        res => {
          this.boolCertificates = _.filter(res['certificates'],
            certificate => {
              return certificate['certificate_type']['list'] === false;
            }
          );

          this.listCertificates = _.filter(res['certificates'],
            certificate => {
              return certificate['certificate_type']['list'] === true;
            }
          );
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }

  async getDeliveryGroups(page: number = 1, per_page: number = 25, params: {} = {}, format: string = 'json') {
    let parameters = _.cloneDeep(params);

    if (format === 'json') {
      this.loading = true;
    }

    if (format === 'xlsx') {
      this.loadingExcel = true;
    }

    parameters = Object.assign(parameters, { format: format });

    _.each(parameters, (value, key) => {
      value == null && delete parameters[key];
    });

    await this.deliveryGroupService
      .getDeliveryGroups(page, per_page, parameters)
      .then(
        res => {
          if (format === 'json') {
            this.deliveryGroups = res['delivery_groups'];
            this.deliveryGroupNames = res['names'];
            this.filterCoordinators = _.filter(res['contacts'], { coordinator: true });
            this.filterProducers = _.filter(res['contacts'], { producer: true });
            this.currentPage = res['current_page'];
            this.totalItems = res['total_items'];
            this.loading = false;
          } else {
            this.loadingExcel = false;
            this._downloadFileService.downloadFile(res, 'grupos', 'application/vnd.ms-excel');
          }
        }
      )
      .catch(
        err => {
          console.error(err);
        }
      );
  }


  // openModal(content, options: NgbModalOptions) {
  //   options['backdrop'] = false;
  //   options['backdrop'] = 'static';

  //   this.modalRef = this.modalService.open(content, options);
  //   this.modalRef.result.then(
  //     result => {
  //       this.closeResult = `Close with: ${result}`;
  //     },
  //     reason => {
  //       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //     }
  //   );
  // }

  compareFn(a, b) {
    return a['id'] === b;
  }

  deactivateActivateDeliveryGroup(deliveryGroup: DeliveryGroupModel) {
    deliveryGroup['active'] = !deliveryGroup['active'];
    this.formModal.updateDeliveryGroup(deliveryGroup);
  }

  openForm(deliveryGroup: DeliveryGroupModel) {
    this.formModal.openForm(deliveryGroup);
  }

  openProducerModal(deliveryGroup: DeliveryGroupModel) {
    this.producersModal.setDeliveryGroupAndOpenModal(deliveryGroup);
  }

  setDeliveryGroupValue(deliveryGroup: DeliveryGroupModel) {

    // this.deliveryGroupService
    //   .getDeliveryGroup(deliveryGroup.id)
    //   .then(
    //     (response) => {
    //       this.deliveryGroup = response['delivery_group'];
    //       this.contacts = response['contacts'];
    //       this.producers = _.filter(this.contacts, { producer: true });

    //     }
    //   )
    //   .catch(error => console.error(error));
  }

  generatePDF(deliveryGroupId: number) {
    const utzId = _.find(this.boolCertificates, { alias: 'utz' })['id'];

    this._seasonService
      .getSeasons()
      .then(
        (seasonRes) => {
          const currentSeason = _.last(_.filter(seasonRes['seasons'], { current: true }));

          this.deliveryGroupService
            .getDeliveryGroup(deliveryGroupId)
            .then(
              (res) => {
                this.pdfService.downloadPDF(res['delivery_group'] as DeliveryGroupModel, utzId, currentSeason);
              }
            )
            .catch((error) => console.error(error));
        }
      ).catch((error) => console.error(error));
  }

  getQuotaOf(deliveryGroup: DeliveryGroupModel, alias: string): number {
    const cert = _.find(this.listCertificates, { alias: alias });
    const groupCert = _.find(deliveryGroup['max_quotas'], { certificate_id: cert['id'] });

    return groupCert ? groupCert['quota'] : 0;
  }

  // getQuotaOfContact(contact: ContactModel, alias: string) {
  //   const cert = _.find(contact['list_certificates'], { alias: alias })
  //   return cert ? cert['quota'] : 0;
  // }

  haveCertificate(element: any, alias: string) {
    return !!_.find(element['bool_certificates'], { alias: alias });
  }

  setDeliveryGroups(deliveyGroups: DeliveryGroupModel[]) {
    this.deliveryGroups = deliveyGroups;
  }
}
