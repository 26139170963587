import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

// Components
import { CocoaTypeModel } from 'src/app/models/cocoa-type.model';
import { CocoaQualityModel } from 'src/app/models/cocoa_quality.model';
import { CertificateModel } from 'src/app/models/certificate.model';

// Models
import { TradePriceModel } from 'src/app/models/trade-price';

// Services
import { TradePriceService } from 'src/app/services/trade-price.service';
import { CertificateService } from 'src/app/services/certificate.service';
import { CocoaTypesService } from 'src/app/services/cocoatype.service';
import { CocoaQualityService } from 'src/app/services/cocoa-quality.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-trade-prices-form',
  templateUrl: './trade-prices-form.component.html',
  styleUrls: ['./trade-prices-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TradePricesFormComponent implements OnInit {
  @ViewChild('content', { static: true }) private modal;

  @Output() result: EventEmitter<TradePriceModel[]> = new EventEmitter<TradePriceModel[]>();

  @Input() private tradePrices: TradePriceModel[] = new Array<TradePriceModel>();

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };

  cocoaTypes: Array<CocoaTypeModel> = new Array<CocoaTypeModel>();
  cocoaQualities: Array<CocoaQualityModel> = new Array<CocoaQualityModel>();
  certificates: Array<CertificateModel> = new Array<CertificateModel>();

  tradePrice: TradePriceModel = new TradePriceModel();
  sending: boolean = false;

  constructor(
    private _modalService: NgbModal,
    private _tradePricesService: TradePriceService,
    private _certificateService: CertificateService,
    private _cocoaTypeService: CocoaTypesService,
    private _cocoaQualityService: CocoaQualityService,
    private _notification: NotificationService) { }

  ngOnInit() {
  }

  compareFn(a, b): boolean {
    return a['id'] === b['id'];
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  onSubmit(tradePrice: TradePriceModel): void {
    // tradePrice['id'] ? this.updateTradePrice(tradePrice) : this.createTradePrice(tradePrice);
    this.createTradePrice(tradePrice);
  }

  openForm(tradePrice: TradePriceModel) {
    this.tradePrice = _.cloneDeep(tradePrice) || new TradePriceModel();
    this.getCertificates();
    this.getCocoaQualities();
    this.getCocoaTypes();
    this.openModal();
  }

  setTradePriceAttributteId(attribute: string, value: number): void {
    this.tradePrice[attribute] = value;

    if (attribute['cocoa_type_id']) {
      const cocoa = _.find(this.cocoaTypes, { id: value });

      if (cocoa && cocoa['name'].toLocaleLowerCase() === 'baba') {
        this.tradePrice['cocoa_quality_id'] = null;
      }
    }
  }

  updateTradePrice(tradePrice: TradePriceModel): void {
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);
    this.sending = true;

    this._tradePricesService
      .updateTradePirce(tradePrice)
      .then(
        res => {
          const index = _.findIndex(this.tradePrices, { id: res['trade_price']['id'] });
          this.tradePrices[index] = res['trade_price'];
          this.result.emit(this.tradePrices);

          this._notification.clear();
          this._notification.success('Actualizado correctamente');
          this.closeModal();
          this.sending = false;
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      );
  }

  private createTradePrice(tradePrice: TradePriceModel): void {
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);
    this.sending = true;

    this._tradePricesService
      .createTradePrice(tradePrice)
      .then(
        (res) => {
          if (tradePrice.id) {
            const index = _.findIndex(this.tradePrices, { id: tradePrice.id});
            this.tradePrices[index] = res['trade_price'];
          } else {
            this.tradePrices.push(res['trade_price']);
          }

          this.result.emit(this.tradePrices);

          this._notification.clear();
          this._notification.success('Creado correctamente');
          this.closeModal();
          this.sending = false;
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al crear');
          }
        }
      );
  }

  // newPrice(content) {
  //   this.getCertificates()
  //     .then(
  //       certificateRes => {
  //         this.certificates = certificateRes['certificates'];

  //         this.getCocoaQualities()
  //           .then(
  //             cocoaQualityRes => {
  //               this.cocoaQualities = cocoaQualityRes['cocoa_qualities'];

  //               this.getCocoaTypes()
  //                 .then(
  //                   cocoaTypesRes => {
  //                     this.cocoaTypes = cocoaTypesRes['cocoa_types'];
  //                     this.openModal(content);
  //                   }
  //                 )
  //                 .catch((cocoaTypeError) => console.error(cocoaTypeError));
  //             }
  //           )
  //           .catch(cocoaQualityError => console.error(cocoaQualityError));
  //       }
  //     )
  //     .catch(certificateError => console.error(certificateError));
  // }

  private getCertificates(): void {
    const params = {
      'q[certificate_type_list_eq]': true
    };

    this._certificateService.getCertificates(params)
      .then(res => this.certificates = res['certificates'])
      .catch(err => console.error(err));
  }

  private getCocoaQualities(): void {
    const params = { 'q[active_eq]': true };
    this._cocoaQualityService.getCocoaQualities(params)
      .then(
        res => {
          this.cocoaQualities = res['cocoa_qualities'];
        }
      )
      .catch(error => console.error(error));
  }

  private getCocoaTypes(): void {
    const params = { 'q[active_eq]': true };
    this._cocoaTypeService.getAll(params)
      .then(res => this.cocoaTypes = res['cocoa_types'])
      .catch(err => console.error(err));
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }
}
