
<app-navbar></app-navbar>
<!-- Container with filters and create button and expor to excel buttons -->
<div class="row">
  <div class="col-9">
    <!-- Filters here!! -->
  </div>

  <div class="col-3">
    <div class="filtersContainer d-flex justify-content-center">
      <button class="btn btn-outline-dark mr-2" (click)="openForm(null);">
        <mat-icon class="position-relative top-5 font-weight-bold">add</mat-icon>
        Crear nuevo
      </button>
      <!-- <button class="btn btn-outline-dark" (click)="exportToExcel(commissions,'comisiones')">
        <mat-icon class="position-relative top-5">save_alt</mat-icon>
        Descargar excel
      </button> -->
    </div>
  </div>
</div>



<!-- Table with datas -->

<div class="table-responsive" *ngIf="!loading">
  <table class="table table-striped table-hover table-sm">
    <thead class="thead-success">
      <tr>
        <th>#</th>
        <th>Nombre</th>
        <th>Descripción</th>
        <th>Cantidad</th>
        <th>Activo</th>
        <th>Acciones</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let commission of commissions | paginate: { itemsPerPage: 15, currentPage: p }; let i= index;">
        <td>{{i + 1}}</td>
        <td>{{commission.name}}</td>
        <td>{{commission.description}}</td>
        <td>{{commission.amount}}</td>
        <td>
          <mat-icon class="font-weight-bold">{{ commission.active ? 'check' : 'close' }}</mat-icon>
        </td>
        <td>
          <div class="col-12">
            <div class="nav-item dropdown" ngbDropdown>
              <a class="btn btn-success btn-sm dropdown-toggle white" ngbDropdownToggle>
                Selecciona acción
              </a>

              <div class="dropdown-menu" aria-labelledby="admin" ngbDropdownMenu>
                <button class="btn btn-primary btn-sm dropdown-item" (click)="openForm(commission)">
                  <mat-icon class="position-relative top-5 font-weight-bold">edit</mat-icon>
                  Editar
                </button>

                <button class="btn btn-danger btn-sm dropdown-item" (click)="deactivateActivateCommission(commission)">
                  <mat-icon class="position-relative top-5">delete</mat-icon>
                  {{ commission.active ? 'Desactivar' : 'Activar' }}
                </button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="loading" *ngIf="loading">
  <ng-template #loadingTemplate></ng-template>
  <ngx-loading [show]="loading" [template]="loadingTemplate"></ngx-loading>
</div>

<div class="paginationContainer" *ngIf="!loading">
  <pagination-controls autoHide="true" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
    (pageChange)="p= $event"></pagination-controls>
</div>

<app-commission-form [commissions]="commissions" (result)="setCommissions($event)"></app-commission-form>