

<ng-template #content let-modal let-d="dismiss">
  <form #Form="ngForm" (ngSubmit)="Form.form.valid && onSubmit(cocoaType)">
    <div class="modal-header">
      <h4 *ngIf="!cocoaType.id" class="modal-title">Crear tipo de cacao</h4>
      <h4 *ngIf="cocoaType.id" class="modal-title">Actualizar tipo de cacao</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">

      <div class="form-group">
        <label for="cocoaTypeName">Nombre</label>
        <input type="text" #cocoaName="ngModel" class="form-control" id="cocoaTypeName" [(ngModel)]="cocoaType.name"
          placeholder="Name" name="cocoaTypeName" [ngClass]="{ 'is-invalid': Form.submitted && cocoaName.invalid}"
          required>

        <div *ngIf="Form.submitted && cocoaName.invalid" class="invalid-tooltip">
          <div *ngIf="cocoaName.errors.required">El nombre es obligatorio</div>
        </div>
      </div>

      <div class="form-group">
        <label for="cocoaTypeDescription">Descripción</label>
        <textarea name="cocoaTypeDescription" type="text" id="cocoaTypeDescription" class="md-textarea form-control"
          [(ngModel)]="cocoaType.description" aria-describedby="cocoaTypeHelp"></textarea>
        <small id="cocoaTypeHelp" class="form-text text-muted">Descripcion del tipo de cacao</small>
      </div>

      <div>
        <mat-checkbox [(ngModel)]="cocoaType.active" name="cocoaActive">Activo</mat-checkbox>
      </div>

    </div>

    <div class="modal-footer">

      <button type="submit" class="btn btn-primary" name="updateTalonary">{{ cocoaType.id ? 'Guardar' : 'Crear' }}</button>

      <button type="button" class="btn btn-danger" (click)="d('button click')" name="closeModal">Cancelar</button>
    </div>
  </form>
</ng-template>