

<ng-template #content let-modal let-d="dismiss">
    <form #zoneGroupForm="ngForm" (ngSubmit)="zoneGroupForm.form.valid && onSubmit(zonegroup)">
      <div class="modal-header">
  
        <h4 class="modal-title"> {{zonegroup.id ? 'Actualizar grupo de zona' : 'Crear grupo de zona'}}</h4>
  
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
  
      <div class="modal-body">
  
        <div class="form-group">
          <label for="zoneGroupName">Nombre del grupo</label>
  
          <input type="text" #zoneGroupName="ngModel" class="form-control" id="zoneGroupName" [(ngModel)]="zonegroup.name"
            placeholder="Name" name="cocoaTypeName"
            [ngClass]="{ 'is-invalid': zoneGroupForm.submitted && zoneGroupName.invalid}" required>
  
          <div *ngIf="zoneGroupForm.submitted && zoneGroupName.invalid" class="invalid-tooltip">
            <div *ngIf="zoneGroupName.errors.required">El nombre es obligatorio</div>
          </div>
        </div>
  
        <div class="form-group">
          <mat-checkbox [(ngModel)]="zonegroup.active" name="zoneActive">
            Activo
          </mat-checkbox>
        </div>
  
      </div>
  
      <div class="modal-footer">
        <button type="submit"class="btn btn-primary" name="submitZone" [disabled]="sending">
          {{ zonegroup.id ? 'Guardar' : 'Crear'}}
        </button>
  
        <button type="button" class="btn btn-danger" (click)="d('button click')" name="closeModal" [disabled]="sending">
          Cancelar
        </button>
      </div>
    </form>
  </ng-template>