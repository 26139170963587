
<!-- PRODUCERS MODAL -->
<ng-template #producerModal let-modal let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Productores de {{deliveryGroup.name}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('close-producer-modal')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div>
      <table class="table table-striped table-hover table-sm">
        <thead>
          <tr class="green-success">
            <th>#</th>
            <th>Codigo</th>
            <th>Cédula</th>
            <th>Productor</th>
            <th>Suplidor</th>
            <th>Coordinador</th>
            <th>Nombre</th>
            <th>Apodo</th>
            <th>Teléfono</th>
            <th>Activo</th>
            <th>BSS.</th>
            <th>UE/NOP</th>
            <th>Trans.</th>
            <th>FFL</th>
            <th>FLO</th>
            <th>UTZ</th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="let producer of deliveryGroup.producers | paginate: { id:'producersPagination', itemsPerPage: 10, currentPage: producerPage } ; let i= index;">
            <td>
              {{i + 1}}
            </td>

            <td>
              {{producer.code}}
            </td>

            <td>
              {{producer.cedula}}
            </td>

            <td>
              {{producer.producer? 'Si' : 'No'}}
            </td>

            <td>
              {{producer.supplier? 'Si' : 'No'}}
            </td>

            <td>
              {{producer.coordinator? 'Si' : 'No'}}
            </td>

            <td>
              {{producer.name}} {{producer.surname}}
            </td>

            <td>
              {{producer.nickname}}
            </td>

            <td>
              {{producer.phone}}
            </td>

            <td>
              <mat-icon class="font-weight-bold"> {{ producer.active_as_contact ? 'check' : 'close' }}</mat-icon>
            </td>

            <td>
              {{getQuotaOfContact(producer, 'biosuisse')}}
            </td>

            <td>
              {{getQuotaOfContact(producer, 'ue/nop')}}
            </td>

            <td>
              {{getQuotaOfContact(producer, 'trans')}}
            </td>
            <td>
              <mat-icon class="font-weight-bold"> {{ haveCertificate(producer, 'ffl') ? 'check' : 'close' }}</mat-icon>
            </td>
            <td>
              <mat-icon class="font-weight-bold"> {{ haveCertificate(producer, 'flo') ? 'check' : 'close' }}</mat-icon>
            </td>
            <td>
              <mat-icon class="font-weight-bold"> {{ haveCertificate(producer, 'utz') ? 'check' : 'close' }}</mat-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="paginationContainer">
      <pagination-controls id="producersPagination" autoHide="true" responsive="true" previousLabel="Anterior"
        nextLabel="Siguiente" (pageChange)="producerPage= $event"></pagination-controls>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-danger" (click)="d('close-producer-modal')" name="closeModal">
      Cancelar
    </button>
  </div>
</ng-template>