import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

// Models
import { ZoneModel } from 'src/app/models/zone.model';
import { ZoneGroupModel } from 'src/app/models/zone-group.model';

// Services
import { ZoneService } from 'src/app/services/zone.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-zone-form',
  templateUrl: './zone-form.component.html',
  styleUrls: ['./zone-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZoneFormComponent implements OnInit {
  @ViewChild('content', { static: true }) private modal;
  @Output() result: EventEmitter<ZoneModel[]> = new EventEmitter<ZoneModel[]>();

  private modalRef: NgbModalRef;
  private modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg'
  };

  @Input() zones: ZoneModel[] = new Array<ZoneModel>();
  @Input() zoneGroups: ZoneGroupModel[] = new Array<ZoneGroupModel>();
  zone: ZoneModel = new ZoneModel();

  sending: boolean = false;

  constructor(private _modalService: NgbModal,
    private _zoneService: ZoneService,
    private _notification: NotificationService) { }

  ngOnInit() {
  }

  compareFn(a: any, b: any): boolean {
    return a['id'] === b;
  }

  onSubmit(zone: ZoneModel) {
    zone['id'] ? this.updateZone(zone) : this.createZone(zone);
  }

  openForm(zone: ZoneModel): void {
    this.zone = _.cloneDeep(zone) || new ZoneModel();
    this.openModal();
  }

  closeModal(reason: string = ''): void {
    this.modalRef && this.modalRef.dismiss(reason);
  }

  updateZone(zone: ZoneModel) {
    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    zone['zone_group'] && (zone['zone_group_id'] = zone['zone_group']['id']);
    this._zoneService
      .updateZone(zone)
      .then(
        res => {
          const index = _.findIndex(this.zones, { id: zone['id'] });
          this.zones[index] = res['zone'];
          this.result.emit(this.zones);

          this._notification.clear();
          this._notification.success('Actualizado correctamente');
          this.closeModal();
          this.sending = false;
        })
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al actualizar');
          }
        }
      );
  }

  private createZone(zone: ZoneModel) {
    this.sending = true;
    this._notification.info('Enviando datos...');
    this._notification.changeModalZIndexTo(1001);

    zone['zone_group'] && (zone['zone_group_id'] = zone['zone_group']['id']);
    this._zoneService
      .createZone(zone)
      .then(
        res => {
          this.zones.push(res['zone']);
          this.result.emit(this.zones);

          this._notification.clear();
          this._notification.success('Creado correctamente');
          this.closeModal();
          this.sending = false;
        }
      )
      .catch(
        err => {
          this.sending = false;
          console.error(err);
          this._notification.clear();
          this._notification.changeModalZIndexTo(1050);
          if (err.hasOwnProperty('errors')) {
            this._notification.error(err['errors'][0]);
          } else {
            this._notification.error('Se ha producido un error al crear');
          }
        }
      );
  }

  private openModal(options: NgbModalOptions = null): void {
    this.modalRef = this._modalService.open(this.modal, options ? options : this.modalOptions);
  }

}
