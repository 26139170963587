<!-- MODAL WITH FORM TO UPDATE TICKET -->
<ng-template #updateTicketModal let-modal let-update="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'pages.tickets_forms.edit_form' | translate}}{{ ticket.talbook_sheet }}
    </h4>

    <button type="button" class="close" aria-label="Close" (click)="update('Cross click')">
      <span aria-hidden="true">
        &times;
      </span>
    </button>
  </div>

  <div class="modal-body edit-ticket-modal">
    <form #Form="ngForm">
      <div class="row">


        <div class="col-7 d-inline-block">
          <div class="col-12">
            <h3>{{ 'pages.tickets_forms.image_tag' | translate}}</h3>

            <div class="col-12 mt-2" *ngIf="(ticket.receipt && ticket.receipt.url) || (!ticket.receipt && ticket.receipt_content)">
              <button class="btn btn-danger btn-sm btn-block" (click)="removeReceipt(ticket)"
              [disabled]="((globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor) && 
                (ticket.billed || ticket.reviewed || ticket.finished))
              || (!globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor && (ticket.billed || ticket.reviewed || ticket.finished))">
              {{ 'pages.tickets_forms.erase_image' | translate}}
              </button>
            </div>

            <div class="col-12 mt-2">
              <button class="btn btn-success btn-sm btn-block"
              [disabled]="((globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor) && (ticket.billed || ticket.finished))
              || (!globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor && (ticket.billed || ticket.reviewed || ticket.finished))">
                Subir imagen
                <input type="file" name="receiptImageUpload" class="upload-image-input" accept="image/*" (change)="setReceipt($event)">
              </button>
            </div>

            <div class="col-12 mt-2">
              <!-- <img [src]="ticket.receipt.url" style="max-width: 100%;" />  -->
              <angular-image-viewer *ngIf="ticket.receipt && ticket.receipt.url" [src]="[ticket.receipt.url]"
                [(index)]="receiptIndex" [config]="{ wheelZoom: true }"></angular-image-viewer>

                <angular-image-viewer *ngIf="!ticket.receipt && ticket.receipt_content" [src]="[ticket.receipt_content.receipt_content]"
                [(index)]="receiptIndex" [config]="{ wheelZoom: true }"></angular-image-viewer>
            </div>
          </div>
        </div>

        <div class="col-4 d-inline-block">
          <div class="form-group col-md-6 d-inline-block">
            <label for="collectDateId">
              {{ 'pages.tickets_forms.pick_up_Date' | translate}}
            </label>

            <input *ngIf="ticket.ticket_type_cd === ticketType.direct_purchase" id="collectDateId" class="form-control"
              [(ngModel)]="ticket.temp_contract_starts" placeholder="Fecha de recogida" name="collectedDate"
              ngbDatepicker #collectedDate="ngbDatepicker" (click)="collectedDate.toggle()"
              [disabled]="!ticket.completed || ticket.reviewed || ticket.billed" [class.is-invalid]="!dateIsInSeasonRange(ticket)"/>

            <input *ngIf="ticket.ticket_type_cd === ticketType.cocoa_receipt" id="collectDateId" class="form-control"
              [(ngModel)]="ticket.temp_collected_date" placeholder="Fecha de recogida" name="collectedDate"
              ngbDatepicker #collectedDate="ngbDatepicker" (click)="collectedDate.toggle()"
              [disabled]="!ticket.completed || ticket.reviewed || ticket.billed" [class.is-invalid]="!dateIsInSeasonRange(ticket)" />

              <div *ngIf="!dateIsInSeasonRange(ticket)" class="invalid-tooltip">
                <div>La fecha está fuera de la temporada.</div>
              </div>
          </div>

          <div class="col-6 d-inline-block">
            <div class="form-gorup">
              <label for="ticketSeason">
                {{ 'pages.tickets_forms.season' | translate}}
              </label>

              <input id="ticketSeason" type="text" class="form-control" readonly [value]="ticket.season_name">
            </div>

          </div>
          <!-- <div class="form-group col-md-12" *ngIf="ticket.ticket_type_cd === ticketType.cocoa_receipt">
            <label for="collectDateId">
              Fecha de recogida
            </label>

            <input id="collectDateId" class="form-control" [(ngModel)]="ticket.temp_collected_date"
              placeholder="Fecha de recogida" name="collectedDate" ngbDatepicker #collectedDate="ngbDatepicker"
              (click)="collectedDate.toggle()"
              [disabled]="ticket.finished || ticket.nulled || !ticket.completed || ticket.unload_accepted" />
          </div>

          <div class="form-group col-md-12" *ngIf="ticket.ticket_type_cd === ticketType.direct_purchase">
            <label for="collectDateId">
              Fecha de recogida
            </label>

            <input id="collectDateId" class="form-control" [(ngModel)]="ticket.temp_contract_starts"
              placeholder="Fecha de recogida" name="collectedDate" ngbDatepicker #collectedDate="ngbDatepicker"
              (click)="collectedDate.toggle()"
              [disabled]="ticket.finished || ticket.nulled || !ticket.completed || ticket.unload_accepted" />
          </div> -->
          <!-- <div class="row" *ngIf="ticket.ticket_type_cd === ticketType.direct_purchase">
          <div class="form-group col-md-6">
            <label for="startDateId">
              Fecha inicio contrato
            </label>

            <input id="startDateId" class="form-control" [(ngModel)]="ticket.temp_contract_starts"
              placeholder="Fecha de recogida" name="startedDate" ngbDatepicker #startedDate="ngbDatepicker"
              (click)="startedDate.toggle()"
              [disabled]="ticket.finished || ticket.nulled || (ticket.validated || ticket.unload_accepted)" />
          </div>

          <div class="form-group col-md-6">
            <label for="collectDateId">
              Fecha final contrato
            </label>

            <input id="finishedDateId" class="form-control" [(ngModel)]="ticket.temp_contract_ends"
              placeholder="Fecha de recogida" name="finishedDate" ngbDatepicker #finishedDate="ngbDatepicker"
              (click)="finishedDate.toggle()"
              [disabled]="ticket.finished || ticket.nulled || (ticket.validated || ticket.unload_accepted)" />
          </div>
        </div> -->
          <div class="form-group col-md-4 d-inline-block">
            <label for="ticketCertificateId">
              {{ 'pages.table_headers.certificate' | translate}}
            </label>

            <ng-select id="ticketCertificateId" [items]="certificates" [searchable] [clearSearchOnAdd]
              notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name"
              [(ngModel)]="ticket.certificate" name="certificateOfList" placeholder="Selecciona el tipo de certificado"
              [compareWith]="compareFn" [multiple]="false" [disabled]="!ticket.completed || ticket.reviewed || ticket.billed">
            </ng-select>
          </div>


          <div class="form-group col-md-4 d-inline-block" *ngIf="globals.currentUserRolesAsBooleanObject.admin">
            <label for="ticketCocoaTypeId">
              {{ 'pages.tickets_forms.cocoa_type' | translate}}
            </label>

            <ng-select id="ticketCocoaTypeId" [items]="cocoaTypes" [searchable] [clearSearchOnAdd]
              notFoundText="No hay coincidencias" loadingText="Buscando"
              [disabled]="!ticket.completed || ticket.reviewed || ticket.billed ||
              (ticket.unloading_state_cd !== ticketUnloadState.not_unloaded && ticket.unloading_state_cd !== ticketUnloadState.in_center)" bindLabel="name"
              [(ngModel)]="ticket.cocoa_type" name="cocoaType" placeholder="Selecciona el tipo de cacao"
              [compareWith]="compareFn" [multiple]="false">
            </ng-select>
          </div>

          <div class="col-4 d-inline-block">
            <div class="form-gorup">
              <label for="estimateValue">
                {{ (ticket.ticket_type_cd === ticketType.cocoa_receipt ? 'pages.tickets_forms.purchase_value' : 'pages.tickets_forms.estimated_value') | translate }} 
              </label>

              <input id="estimateValue" type="text" class="form-control" readonly
                [value]="globals.truncateDecimals(ticket.estimated_value, 2)">
            </div>
          </div>


          <div class="form-group col-md-4 d-inline-block" *ngIf="!globals.currentUserRolesAsBooleanObject.admin">
            <label for="ticketCocoaTypeId">
              {{ 'pages.tickets_forms.cocoa_type' | translate}}
            </label>

            <ng-select id="ticketCocoaTypeId" [items]="cocoaTypes" [searchable] [clearSearchOnAdd]
              notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name"
              [(ngModel)]="ticket.cocoa_type" name="cocoaType" readonly placeholder="Selecciona el tipo de cacao"
              [compareWith]="compareFn" [multiple]="false">
            </ng-select>
          </div>

          <div class="form-group col-md-4 d-inline-block" *ngIf="isDrySelected(ticket)">
            <label for="ticketCocoaQualityId">
              {{ 'pages.tickets_forms.cocoa_quality' | translate}}
            </label>

            <ng-select id="ticketCocoaQualityId" [items]="cocoaQualities" [searchable] [clearSearchOnAdd]
              bindLabel="name" bindValue="id" [disabled]="!ticket.completed || ticket.reviewed || ticket.billed"
              notFoundText="No hay coincidencias" loadingText="Buscando" [(ngModel)]="ticket.cocoa_quality_id"
              name="cocoaQuality" placeholder="Calidad del cacao" [compareWith]="compareFn" [multiple]="false">
            </ng-select>
          </div>

          <!--  For admins -->
          <div class="form-group col-md-6 d-inline-block" *ngIf="globals.currentUserRolesAsBooleanObject.admin">
            <label for="ticketReceiverId">
              {{ 'pages.table_headers.receiver' | translate}}
            </label>

            <ng-select id="ticketReceiverId" [items]="receivers" [searchable] [clearSearchOnAdd]
              [disabled]="ticket.reviewed || ticket.billed" notFoundText="No hay coincidencias" loadingText="Buscando"
              bindLabel="name" [(ngModel)]="ticket.original_receiver" name="receiver"
              placeholder="Selecciona el receptor" [compareWith]="compareFn" [multiple]="false">
            </ng-select>
          </div>

          <!-- No admins -->
          <div class="form-group col-md-6 d-inline-block" *ngIf="!globals.currentUserRolesAsBooleanObject.admin">
            <label for="ticketReceiverId">
              {{ 'pages.table_headers.receiver' | translate}}
            </label>

            <ng-select id="ticketReceiverId" [items]="receivers" [searchable] [clearSearchOnAdd]
              notFoundText="No hay coincidencias" loadingText="Buscando" bindLabel="name"
              [(ngModel)]="ticket.original_receiver" name="receiver" placeholder="Selecciona el receptor"
              [compareWith]="compareFn" [multiple]="false" disabled>
            </ng-select>
          </div>

          <div class="form-group col-md-6 d-inline-block">
            <label for="ticketSupplierId">
              {{ 'pages.table_headers.supplier' | translate}}
            </label>

            <ng-select id="ticketSupplierId" [items]="suppliers" [searchable] [clearSearchOnAdd]
              [disabled]="!ticket.completed || ticket.reviewed || ticket.billed" notFoundText="No hay coincidencias" loadingText="Buscando"
              bindLabel="full_name_and_diceros_id" [(ngModel)]="ticket.supplier" name="supplier" placeholder="Selecciona el suplidor"
              [compareWith]="compareFn" [multiple]="false" (change)="setTicketBillingDatas()">
            </ng-select>
          </div>

          <div class="form-group d-inline-block col-6" *ngIf="ticket.ticket_type_cd === ticketType.cocoa_receipt && !isDrySelected(ticket)">
            <label for="settlementTypeId">
              {{ 'pages.tickets_forms.billing_type' | translate}}
            </label>

            <ng-select id="settlementTypeId" [items]="settlementTypes" [searchable] notFoundText="No hay coincidencias"
              loadingText="Buscando" [(ngModel)]="ticket.settlement_state_cd" bindValue="id" bindLabel="name"
              name="settlementType" placeholder="Tipo de facturación" [compareWith]="compareFn" [multiple]="false"
              [disabled]="!globals.currentUserRolesAsBooleanObject.admin || (ticket.reviewed || ticket.billed)" (change)="setTicketBillingDatas()">
            </ng-select>
          </div>

          <div class="form-group d-inline-block col-6" *ngIf="ticket.ticket_type_cd === ticketType.cocoa_receipt && !isDrySelected(ticket)">
            <label for="prebillingWeight">
              {{ 'pages.tickets_forms.weight_preinvoice' | translate}}
            </label>

            <ng-select id="prebillingWeight" [items]="contactPrebillingWeight" [searchable]
              notFoundText="No hay coincidencias" loadingText="Buscando"
              [(ngModel)]="ticket.contact_prebilling_weight_cd" bindValue="id" bindLabel="name" name="prebillingWeight"
              placeholder="Peso a prefacturar" [compareWith]="compareFn" [multiple]="false"
              [disabled]="!globals.currentUserRolesAsBooleanObject.admin || (ticket.reviewed || ticket.billed || (ticket.settlement_state_cd === ticketSettlementTypes.dry_result)) ">
            </ng-select>
          </div>

          <div class="pl-3 row">
            <div class="form-group col-md-4 d-inline-block">
              <label for="weightPerDriverInput">
                {{ 'pages.tickets_forms.receiver_weight' | translate}}
              </label>

              <input type="number" class="form-control" id="weightPerDriverInput" [(ngModel)]="ticket.receiver_weight"
                placeholder="ej: 2000" name="weightPerDriver" readonly />
            </div>


            <div class="form-group col-md-4 d-inline-block">
              <label for="weightPerSupplierInput">
                {{ 'pages.tickets_forms.supplier_weight' | translate}}
              </label>

              <input type="number" class="form-control" id="weightPerSupplierInput" [(ngModel)]="ticket.supplier_weight"
                placeholder="ej: 2000" name="weightPerSupplier"
                [disabled]="!ticket.completed || !ticket.supplier || ticket.supplier.intermediary || ticket.reviewed || ticket.billed" />
            </div>

            <div class="form-group col-md-4 d-inline-block">
              <label class="col-4" for="bagArrayLenght">
                {{ 'pages.tickets_forms.bags' | translate}}{{ticket['bags_info']?.length}}
              </label>
              <button type="button" class="btn btn-info" (click)="openPackageForm(ticket)">{{ 'pages.tickets_forms.bags_details_button' | translate}}</button>
            </div>
          </div>

          <div class="form-group col-md-4 d-inline-block" *ngIf="ticket.ticket_type_cd === ticketType.cocoa_receipt && !isDrySelected(ticket)">
            <label for="maxDiff">
              {{ 'pages.tickets_forms.maximum_difference' | translate}}
            </label>

            <input type="number" class="form-control" id="maxDiff" [(ngModel)]="ticket.contact_max_diff"
              placeholder="Diferencia máxima" name="ticketMaxDiff" max="100"
              [disabled]="!globals.currentUserRolesAsBooleanObject.admin || (ticket.reviewed || ticket.billed  || (ticket.settlement_state_cd === ticketSettlementTypes.dry_result))" />
          </div>

          <div class="form-group col-md-4 d-inline-block" *ngIf="!isDrySelected(ticket)">
            <label for="supplierPerformance">
              {{ 'pages.tickets_forms.performance' | translate}}
            </label>

            <input type="number" class="form-control" id="supplierPerformance" [(ngModel)]="ticket.supplier_performance"
              placeholder="Rendimiento del suplidor" name="ticketSupplierPerformance"
              [disabled]="!globals.currentUserRolesAsBooleanObject.admin || (ticket.billed || ticket.reviewed  || (ticket.settlement_state_cd === ticketSettlementTypes.dry_result))" />
          </div>


          <div class="form-group col-md-12">
            <label for="ticketComments">
              {{ 'pages.tickets_forms.comments' | translate}}
            </label>

            <textarea class="form-control" id="ticketComments" [(ngModel)]="ticket.comments" rows="5"
              name="ticketComments" [disabled]="ticket.reviewed || ticket.billed"></textarea>
          </div>

          <!-- <div class="form-group col-md-12">
          <label for="weightPerSupplierInput">
            Peso según suplidor (en kg.)
          </label>

          <input type="number" class="form-control" id="weightPerSupplierInput" [(ngModel)]="ticket.supplier_weight"
            placeholder="ej: 2000" name="weightPerSupplier" [disabled]="ticket.finished || ticket.nulled"/>
        </div> -->
          <div class="form-group col-md-12">
            <!-- <div class="col-md-6">
            <mat-checkbox [(ngModel)]="ticket.active" name="ticketkActive" [disabled]="ticket.finished || ticket.nulled">
              Activo
            </mat-checkbox>
          </div> -->

            <div class="col-md-6" >
              <mat-checkbox [(ngModel)]="ticket.completed" name="ticketComplete"
                (change)="changeCompleteState($event, ticket)"
                [disabled]="((globals.currentUserRolesAsBooleanObject.admin || globals.currentUserRolesAsBooleanObject.supervisor) && ticket.billed)
                || (!globals.currentUserRolesAsBooleanObject.admin && !globals.currentUserRolesAsBooleanObject.supervisor && (ticket.billed || ticket.reviewed))"> 
                <!-- disabled si eres administrador o supervisor y el recibo está billed o si no eres adminin ni supervisor y recibo billed o reviewed -->
                {{ 'pages.tickets_forms.completed' | translate}}
              </mat-checkbox>
            </div>

            <div class="col-md-6">
              <mat-checkbox [(ngModel)]="ticket.nulled" name="ticketNull"
                [disabled]="ticket.reviewed || ticket.billed || ticket.unload_accepted">
                {{ 'pages.tickets_forms.nulled' | translate}}
              </mat-checkbox>
            </div>
          </div>
        </div>
        <!-- 
      <div class="right" *ngIf="ticket.provenance_sheet && ticket.provenance_sheet.sheet_images.length> 0">
        <div class="col-12">
          <h3>Imagenes del formulario de procedencia</h3>

          <div class="col-12 mt-2" *ngIf="!ticket.finished">
            <button class="btn btn-danger btn-sm btn-block" (click)="removeImage(ticket, 'sheet')"
              [disabled]="sending || ticket.nulled || !ticket.completed">
              Quitar imagen
            </button>
          </div>

          <div class="col-12 mt-2">
             <img [src]="provenanceSheetImages[provenanceSheetImageSelectedIndex]" style="max-width: 100%;" /> 
          </div> -->
        <!-- <div class="arrows-container" *ngIf="provenanceSheetImages.length> 1">
            <button class="btn" (click)="changeProvenanceSheetImage(-1)">
              <i class="fa fa-chevron-circle-left"></i>
            </button>
            <label class="font-weight-bold">{{provenanceSheetImageSelectedIndex + 1}} de
              {{ provenanceSheetImages.length }} </label>
            <button class="btn" (click)="changeProvenanceSheetImage(1)">
              <i class="fa fa-chevron-circle-right"></i>
            </button>form #
          </div> -->
        <!-- </div>
      </div> -->
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="setReviewedAndSend(ticket)" [disabled]="!canReviewed(ticket) || ticket.finished">
      {{ ticket.reviewed ? 'Desrevisar' : 'Recibo revisado' }}
    </button>

    <button class="btn btn-primary" (click)="updateTicket(ticket)" [disabled]="ticket.billed || ticket.finished">
      Guardar
    </button>

    <button class="btn btn-danger" (click)="update('button click')" name="closeModal">
      {{ 'pages.tickets_forms.button_cancel' | translate}}
    </button>
  </div>
</ng-template>

<app-package-form (outputTicket)="setTicket($event)"></app-package-form>
<app-alert-modal (result)="alertModalResult($event)"></app-alert-modal>